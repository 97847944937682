@font-face {
  font-family: 'v3';
  src: url('fonts/v3.woff?sdg2ct') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v3-"], [class*=" v3-"] {
  font-family: 'v3' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v3-Abacus:before {
  content: "\e900";
}

.v3-Abut-1:before {
  content: "\e901";
}

.v3-Abut-2:before {
  content: "\e902";
}

.v3-Abut-3:before {
  content: "\e903";
}

.v3-Across:before {
  content: "\e904";
}

.v3-Actinometer:before {
  content: "\e905";
}

.v3-Acupuncture:before {
  content: "\e906";
}

.v3-Add-Book:before {
  content: "\e907";
}

.v3-Add-Box:before {
  content: "\e908";
}

.v3-Add-Database:before {
  content: "\e909";
}

.v3-Add-Delivery:before {
  content: "\e90a";
}

.v3-Add-Drive:before {
  content: "\e90b";
}

.v3-Add-Key-Tag:before {
  content: "\e90c";
}

.v3-Add-Seo-Tag:before {
  content: "\e90d";
}

.v3-Add-Server:before {
  content: "\e90e";
}

.v3-Air-Balloon:before {
  content: "\e90f";
}

.v3-Alien:before {
  content: "\e910";
}

.v3-Alphabet-Book:before {
  content: "\e911";
}

.v3-Alt-1:before {
  content: "\e912";
}

.v3-Alt-2:before {
  content: "\e913";
}

.v3-Alt-3:before {
  content: "\e914";
}

.v3-Android-Smartphone:before {
  content: "\e915";
}

.v3-Android-Tablet:before {
  content: "\e916";
}

.v3-Angle-Ruler:before {
  content: "\e917";
}

.v3-Apart:before {
  content: "\e918";
}

.v3-Apple:before {
  content: "\e919";
}

.v3-Aquarius-1:before {
  content: "\e91a";
}

.v3-Aquarius-2:before {
  content: "\e91b";
}

.v3-Archive-File:before {
  content: "\e91c";
}

.v3-Archive-Folder:before {
  content: "\e91d";
}

.v3-Archive-Folders:before {
  content: "\e91e";
}

.v3-Archive-Letter:before {
  content: "\e91f";
}

.v3-Archive-Picture:before {
  content: "\e920";
}

.v3-Archive-Video:before {
  content: "\e921";
}

.v3-Archive:before {
  content: "\e922";
}

.v3-Aries-1:before {
  content: "\e923";
}

.v3-Aries-2:before {
  content: "\e924";
}

.v3-Arrow:before {
  content: "\e925";
}

.v3-Aspect-Ratio:before {
  content: "\e926";
}

.v3-AT-AT-Walker:before {
  content: "\e927";
}

.v3-AT-OP-Walker:before {
  content: "\e928";
}

.v3-Baby-Chair:before {
  content: "\e929";
}

.v3-Baby-Cutlery:before {
  content: "\e92a";
}

.v3-Baby-Face-1:before {
  content: "\e92b";
}

.v3-baby-Face-2:before {
  content: "\e92c";
}

.v3-Baby-Food:before {
  content: "\e92d";
}

.v3-Baby-Grow:before {
  content: "\e92e";
}

.v3-Baby-Monitor:before {
  content: "\e92f";
}

.v3-Baby-Toy-1:before {
  content: "\e930";
}

.v3-Baby-Toy-2:before {
  content: "\e931";
}

.v3-Baby-Toy-3:before {
  content: "\e932";
}

.v3-Baby:before {
  content: "\e933";
}

.v3-Back-1:before {
  content: "\e934";
}

.v3-Back-2:before {
  content: "\e935";
}

.v3-Back-3:before {
  content: "\e936";
}

.v3-Backspace-1:before {
  content: "\e937";
}

.v3-Backspace-2:before {
  content: "\e938";
}

.v3-Ball:before {
  content: "\e939";
}

.v3-Balloons:before {
  content: "\e93a";
}

.v3-Bamboo:before {
  content: "\e93b";
}

.v3-Battle-Droid:before {
  content: "\e93c";
}

.v3-BB-8:before {
  content: "\e93d";
}

.v3-Bed-Room:before {
  content: "\e93e";
}

.v3-Bib:before {
  content: "\e93f";
}

.v3-Boba-Fet-Starship:before {
  content: "\e940";
}

.v3-Boba-Fet:before {
  content: "\e941";
}

.v3-Book-2:before {
  content: "\e942";
}

.v3-Book-3:before {
  content: "\e943";
}

.v3-Book-Bitcoin:before {
  content: "\e944";
}

.v3-Book-Cloud:before {
  content: "\e945";
}

.v3-Book-Copyright:before {
  content: "\e946";
}

.v3-Book-Creditcard:before {
  content: "\e947";
}

.v3-Book-Dismiss:before {
  content: "\e948";
}

.v3-Book-Dollar:before {
  content: "\e949";
}

.v3-Book-Done:before {
  content: "\e94a";
}

.v3-Book-Down:before {
  content: "\e94b";
}

.v3-Book-Euro:before {
  content: "\e94c";
}

.v3-Book-No-Dollar:before {
  content: "\e94d";
}

.v3-Book-Open-1:before {
  content: "\e94e";
}

.v3-Book-Open-2:before {
  content: "\e94f";
}

.v3-Book-Open-3:before {
  content: "\e950";
}

.v3-Book-Open-4:before {
  content: "\e951";
}

.v3-Book-Open-6:before {
  content: "\e952";
}

.v3-Book-Percent:before {
  content: "\e953";
}

.v3-Book-Pound:before {
  content: "\e954";
}

.v3-Book-Question:before {
  content: "\e955";
}

.v3-Book-Remove:before {
  content: "\e956";
}

.v3-Book-Settings:before {
  content: "\e957";
}

.v3-Book-Shopping:before {
  content: "\e958";
}

.v3-Book-Star:before {
  content: "\e959";
}

.v3-Book-Up:before {
  content: "\e95a";
}

.v3-Book-User:before {
  content: "\e95b";
}

.v3-Book-Warning:before {
  content: "\e95c";
}

.v3-Book-Write:before {
  content: "\e95d";
}

.v3-Book:before {
  content: "\e95e";
}

.v3-BookClose:before {
  content: "\e95f";
}

.v3-Booklet:before {
  content: "\e960";
}

.v3-Bookmark-1:before {
  content: "\e961";
}

.v3-Bookmark-2:before {
  content: "\e962";
}

.v3-Bookmark-Open:before {
  content: "\e963";
}

.v3-BookOpen-5:before {
  content: "\e964";
}

.v3-Books:before {
  content: "\e965";
}

.v3-Bos-Pallet:before {
  content: "\e966";
}

.v3-Bottled-Message:before {
  content: "\e967";
}

.v3-Bouquet:before {
  content: "\e968";
}

.v3-Bow:before {
  content: "\e969";
}

.v3-Box-1:before {
  content: "\e96a";
}

.v3-Box-2:before {
  content: "\e96b";
}

.v3-Box-3:before {
  content: "\e96c";
}

.v3-Box-4:before {
  content: "\e96d";
}

.v3-Box-Answer:before {
  content: "\e96e";
}

.v3-Box-Back:before {
  content: "\e96f";
}

.v3-Box-Deliver:before {
  content: "\e970";
}

.v3-Box-DOne:before {
  content: "\e971";
}

.v3-Box-Down:before {
  content: "\e972";
}

.v3-Box-File:before {
  content: "\e973";
}

.v3-Box-Folder:before {
  content: "\e974";
}

.v3-Box-Folders:before {
  content: "\e975";
}

.v3-Box-Forward:before {
  content: "\e976";
}

.v3-Box-Home:before {
  content: "\e977";
}

.v3-Box-Keep-Dry:before {
  content: "\e978";
}

.v3-Box-Letter:before {
  content: "\e979";
}

.v3-Box-Picture:before {
  content: "\e97a";
}

.v3-Box-Place:before {
  content: "\e97b";
}

.v3-Box-Plane:before {
  content: "\e97c";
}

.v3-Box-Quesion:before {
  content: "\e97d";
}

.v3-Box-Security:before {
  content: "\e97e";
}

.v3-Box-Settings:before {
  content: "\e97f";
}

.v3-Box-Shipping:before {
  content: "\e980";
}

.v3-Box-Shopping:before {
  content: "\e981";
}

.v3-Box-Size:before {
  content: "\e982";
}

.v3-Box-Time:before {
  content: "\e983";
}

.v3-Box-Up:before {
  content: "\e984";
}

.v3-Box-Video:before {
  content: "\e985";
}

.v3-Box:before {
  content: "\e986";
}

.v3-Breast:before {
  content: "\e987";
}

.v3-Brightness-Down:before {
  content: "\e988";
}

.v3-Brightness-Up:before {
  content: "\e989";
}

.v3-Bulb-1:before {
  content: "\e98a";
}

.v3-Bulb-2:before {
  content: "\e98b";
}

.v3-Burn-Disk:before {
  content: "\e98c";
}

.v3-Button:before {
  content: "\e98d";
}

.v3-C3-PO:before {
  content: "\e98e";
}

.v3-Calculator:before {
  content: "\e98f";
}

.v3-Calendar-1:before {
  content: "\e990";
}

.v3-Calendar-2:before {
  content: "\e991";
}

.v3-Calming-Tea:before {
  content: "\e992";
}

.v3-Camera-Application:before {
  content: "\e993";
}

.v3-Camera-Button:before {
  content: "\e994";
}

.v3-Camera-Speach-Bubble-1:before {
  content: "\e995";
}

.v3-Camera-Speach-Bubble-2:before {
  content: "\e996";
}

.v3-Camera-Speach-Bubble-3:before {
  content: "\e997";
}

.v3-Cancer--2:before {
  content: "\e998";
}

.v3-Cancer-1:before {
  content: "\e999";
}

.v3-Candle:before {
  content: "\e99a";
}

.v3-Candlestick:before {
  content: "\e99b";
}

.v3-Canteen:before {
  content: "\e99c";
}

.v3-Capricorn-1:before {
  content: "\e99d";
}

.v3-Capricorn-2:before {
  content: "\e99e";
}

.v3-Caps-Lock:before {
  content: "\e99f";
}

.v3-Car-Seat:before {
  content: "\e9a0";
}

.v3-Cary-Box:before {
  content: "\e9a1";
}

.v3-Chandelier:before {
  content: "\e9a2";
}

.v3-Change:before {
  content: "\e9a3";
}

.v3-Choco-Strawberry:before {
  content: "\e9a4";
}

.v3-Chocolate-Box:before {
  content: "\e9a5";
}

.v3-Cigar:before {
  content: "\e9a6";
}

.v3-Cigarette-Holder:before {
  content: "\e9a7";
}

.v3-Clip:before {
  content: "\e9a8";
}

.v3-Clipboard-Write:before {
  content: "\e9a9";
}

.v3-Clipboard:before {
  content: "\e9aa";
}

.v3-Cloud-Database:before {
  content: "\e9ab";
}

.v3-Cloud-Drive:before {
  content: "\e9ac";
}

.v3-Cloud-Server-1:before {
  content: "\e9ad";
}

.v3-Cloud-Server-2:before {
  content: "\e9ae";
}

.v3-Coffee-Grinder:before {
  content: "\e9af";
}

.v3-Comand-1:before {
  content: "\e9b0";
}

.v3-Command-2:before {
  content: "\e9b1";
}

.v3-Command-3:before {
  content: "\e9b2";
}

.v3-Computer-1:before {
  content: "\e9b3";
}

.v3-Computer-2:before {
  content: "\e9b4";
}

.v3-Computer-Camera:before {
  content: "\e9b5";
}

.v3-Computer-Connection:before {
  content: "\e9b6";
}

.v3-Computer-Connections:before {
  content: "\e9b7";
}

.v3-Computer-Database:before {
  content: "\e9b8";
}

.v3-Computer-Sync:before {
  content: "\e9b9";
}

.v3-Connect-Computer:before {
  content: "\e9ba";
}

.v3-Connected-Folder:before {
  content: "\e9bb";
}

.v3-Connected-Home:before {
  content: "\e9bc";
}

.v3-Constellation:before {
  content: "\e9bd";
}

.v3-Contact-Booklet:before {
  content: "\e9be";
}

.v3-Contact-Note-Book:before {
  content: "\e9bf";
}

.v3-Container-Lifter:before {
  content: "\e9c0";
}

.v3-Control:before {
  content: "\e9c1";
}

.v3-Cookbook:before {
  content: "\e9c2";
}

.v3-Cooler:before {
  content: "\e9c3";
}

.v3-Corrector:before {
  content: "\e9c4";
}

.v3-Cotton-Buds:before {
  content: "\e9c5";
}

.v3-CPU-2:before {
  content: "\e9c6";
}

.v3-CPU-4:before {
  content: "\e9c7";
}

.v3-CPU-6:before {
  content: "\e9c8";
}

.v3-CPU-8:before {
  content: "\e9c9";
}

.v3-CPU-32:before {
  content: "\e9ca";
}

.v3-CPU-64:before {
  content: "\e9cb";
}

.v3-CPU-Done:before {
  content: "\e9cc";
}

.v3-CPU-Ekg:before {
  content: "\e9cd";
}

.v3-CPU-Flash:before {
  content: "\e9ce";
}

.v3-CPU-Protection:before {
  content: "\e9cf";
}

.v3-CPU-Setings:before {
  content: "\e9d0";
}

.v3-CPU-Warning:before {
  content: "\e9d1";
}

.v3-CPU:before {
  content: "\e9d2";
}

.v3-Crib:before {
  content: "\e9d3";
}

.v3-Crying-Baby-1:before {
  content: "\e9d4";
}

.v3-Crying-Baby-2:before {
  content: "\e9d5";
}

.v3-Cuckoo-clock:before {
  content: "\e9d6";
}

.v3-Cucumber:before {
  content: "\e9d7";
}

.v3-Cutlery-Booklet:before {
  content: "\e9d8";
}

.v3-Darth-Vader:before {
  content: "\e9d9";
}

.v3-Database-At:before {
  content: "\e9da";
}

.v3-Database-Connection:before {
  content: "\e9db";
}

.v3-Database-Connections:before {
  content: "\e9dc";
}

.v3-Database-Done:before {
  content: "\e9dd";
}

.v3-Database-Down:before {
  content: "\e9de";
}

.v3-Database-Download:before {
  content: "\e9df";
}

.v3-Database-Gaming:before {
  content: "\e9e0";
}

.v3-Database-Hand:before {
  content: "\e9e1";
}

.v3-Database-Heart:before {
  content: "\e9e2";
}

.v3-Database-Home-Sync:before {
  content: "\e9e3";
}

.v3-Database-Key:before {
  content: "\e9e4";
}

.v3-Database-Percentage:before {
  content: "\e9e5";
}

.v3-Database-Place:before {
  content: "\e9e6";
}

.v3-Database-Preferences:before {
  content: "\e9e7";
}

.v3-Database-Question:before {
  content: "\e9e8";
}

.v3-Database-Settings:before {
  content: "\e9e9";
}

.v3-Database-Shopping:before {
  content: "\e9ea";
}

.v3-Database-Sleep:before {
  content: "\e9eb";
}

.v3-Database-Space:before {
  content: "\e9ec";
}

.v3-Database-Sync:before {
  content: "\e9ed";
}

.v3-Database-Up:before {
  content: "\e9ee";
}

.v3-Database-Upload:before {
  content: "\e9ef";
}

.v3-Database-Warning:before {
  content: "\e9f0";
}

.v3-Delete-Book:before {
  content: "\e9f1";
}

.v3-Delete-Box:before {
  content: "\e9f2";
}

.v3-Delete-CPU:before {
  content: "\e9f3";
}

.v3-Delete-Database:before {
  content: "\e9f4";
}

.v3-Delete-Delivery:before {
  content: "\e9f5";
}

.v3-Delete-Drive:before {
  content: "\e9f6";
}

.v3-Delete-Key-Tag:before {
  content: "\e9f7";
}

.v3-Delete-Seo-Tag:before {
  content: "\e9f8";
}

.v3-Delete-Server:before {
  content: "\e9f9";
}

.v3-Delete:before {
  content: "\e9fa";
}

.v3-Delivery-Answer:before {
  content: "\e9fb";
}

.v3-Delivery-Back:before {
  content: "\e9fc";
}

.v3-Delivery-Forward:before {
  content: "\e9fd";
}

.v3-Delivery-Pay:before {
  content: "\e9fe";
}

.v3-Delivery-Question:before {
  content: "\e9ff";
}

.v3-Delivery:before {
  content: "\ea00";
}

.v3-Desk:before {
  content: "\ea01";
}

.v3-Desktop-Computer-2:before {
  content: "\ea02";
}

.v3-Desktop-Computer:before {
  content: "\ea03";
}

.v3-Deth-Star-Exploded:before {
  content: "\ea04";
}

.v3-Deth-Star:before {
  content: "\ea05";
}

.v3-Diaper:before {
  content: "\ea06";
}

.v3-Diet:before {
  content: "\ea07";
}

.v3-Diploma-1:before {
  content: "\ea08";
}

.v3-Diploma-2:before {
  content: "\ea09";
}

.v3-Diploma-3:before {
  content: "\ea0a";
}

.v3-Disable-Camera-1:before {
  content: "\ea0b";
}

.v3-Disable-Camera-2:before {
  content: "\ea0c";
}

.v3-Disk-Booklet:before {
  content: "\ea0d";
}

.v3-Disk-Drive:before {
  content: "\ea0e";
}

.v3-Disk:before {
  content: "\ea0f";
}

.v3-Dismiss-Box:before {
  content: "\ea10";
}

.v3-Dismiss-Database:before {
  content: "\ea11";
}

.v3-Dismiss-Drive:before {
  content: "\ea12";
}

.v3-Dismiss-Key-Tag:before {
  content: "\ea13";
}

.v3-Dismiss-Seo-Tag:before {
  content: "\ea14";
}

.v3-Dismiss-Server:before {
  content: "\ea15";
}

.v3-Diverge-1:before {
  content: "\ea16";
}

.v3-Diverge-2:before {
  content: "\ea17";
}

.v3-Diverge-3:before {
  content: "\ea18";
}

.v3-DollarBooklet:before {
  content: "\ea19";
}

.v3-DOne-Delivery:before {
  content: "\ea1a";
}

.v3-Dont-Disturb:before {
  content: "\ea1b";
}

.v3-Dossier:before {
  content: "\ea1c";
}

.v3-Dossiers-1:before {
  content: "\ea1d";
}

.v3-Dossiers-2:before {
  content: "\ea1e";
}

.v3-Double-Loop:before {
  content: "\ea1f";
}

.v3-Double-Rotate-1:before {
  content: "\ea20";
}

.v3-Double-Rotate-2:before {
  content: "\ea21";
}

.v3-Down:before {
  content: "\ea22";
}

.v3-Download:before {
  content: "\ea23";
}

.v3-Drawing-Class:before {
  content: "\ea24";
}

.v3-Drie-Settings:before {
  content: "\ea25";
}

.v3-Drive-1:before {
  content: "\ea26";
}

.v3-Drive-2:before {
  content: "\ea27";
}

.v3-Drive-Done:before {
  content: "\ea28";
}

.v3-Drive-Down:before {
  content: "\ea29";
}

.v3-Drive-Flash:before {
  content: "\ea2a";
}

.v3-Drive-Question:before {
  content: "\ea2b";
}

.v3-Drive-Time:before {
  content: "\ea2c";
}

.v3-Drive-Upl:before {
  content: "\ea2d";
}

.v3-Drive-Warning:before {
  content: "\ea2e";
}

.v3-Earth:before {
  content: "\ea2f";
}

.v3-Eco-Book:before {
  content: "\ea30";
}

.v3-Eco-Booklet:before {
  content: "\ea31";
}

.v3-Educational-Aids-1:before {
  content: "\ea32";
}

.v3-Educational-Aids-2:before {
  content: "\ea33";
}

.v3-Educational-Aids-3:before {
  content: "\ea34";
}

.v3-Empty-Box:before {
  content: "\ea35";
}

.v3-Enable-Camera-1:before {
  content: "\ea36";
}

.v3-Enable-Camera-2:before {
  content: "\ea37";
}

.v3-End:before {
  content: "\ea38";
}

.v3-Enlarge:before {
  content: "\ea39";
}

.v3-Enter-1:before {
  content: "\ea3a";
}

.v3-Enter-2:before {
  content: "\ea3b";
}

.v3-Escape:before {
  content: "\ea3c";
}

.v3-Export:before {
  content: "\ea3d";
}

.v3-Expot-2:before {
  content: "\ea3e";
}

.v3-Eye-Shadow:before {
  content: "\ea3f";
}

.v3-Eyebrow-Tweezers:before {
  content: "\ea40";
}

.v3-Feeding-Botle-2:before {
  content: "\ea41";
}

.v3-Feeding-Bottle-1:before {
  content: "\ea42";
}

.v3-Files:before {
  content: "\ea43";
}

.v3-First-Day:before {
  content: "\ea44";
}

.v3-Floating-Candle:before {
  content: "\ea45";
}

.v3-Flying-Rocket:before {
  content: "\ea46";
}

.v3-Foot-Spa-Bucket:before {
  content: "\ea47";
}

.v3-Fork-Lift:before {
  content: "\ea48";
}

.v3-Form-Toy:before {
  content: "\ea49";
}

.v3-Forward-1:before {
  content: "\ea4a";
}

.v3-Forward-2:before {
  content: "\ea4b";
}

.v3-Forward-3:before {
  content: "\ea4c";
}

.v3-Forward-4:before {
  content: "\ea4d";
}

.v3-Fragile-Box:before {
  content: "\ea4e";
}

.v3-Function:before {
  content: "\ea4f";
}

.v3-G--vbbvbvb:before {
  content: "\ea50";
}

.v3-Galactucal-Empire:before {
  content: "\ea51";
}

.v3-Galaxy:before {
  content: "\ea52";
}

.v3-Gaming-Server:before {
  content: "\ea53";
}

.v3-Gemini-1:before {
  content: "\ea54";
}

.v3-Gemini-2:before {
  content: "\ea55";
}

.v3-General-Grievous:before {
  content: "\ea56";
}

.v3-Gift:before {
  content: "\ea57";
}

.v3-Global-Box:before {
  content: "\ea58";
}

.v3-Global-Databaase:before {
  content: "\ea59";
}

.v3-Global-Delivery:before {
  content: "\ea5a";
}

.v3-Global-Folder:before {
  content: "\ea5b";
}

.v3-Global-Key-Tag:before {
  content: "\ea5c";
}

.v3-Global-Seo-Tag:before {
  content: "\ea5d";
}

.v3-Global-Seo:before {
  content: "\ea5e";
}

.v3-Global-Server-Sync:before {
  content: "\ea5f";
}

.v3-Global-Server:before {
  content: "\ea60";
}

.v3-Globe:before {
  content: "\ea61";
}

.v3-Graduation-Hat:before {
  content: "\ea62";
}

.v3-Gramophone:before {
  content: "\ea63";
}

.v3-Grandfather-Clock:before {
  content: "\ea64";
}

.v3-Hair-Care:before {
  content: "\ea65";
}

.v3-Hair-Dryer:before {
  content: "\ea66";
}

.v3-Hand-cargo:before {
  content: "\ea67";
}

.v3-Hat-Stand:before {
  content: "\ea68";
}

.v3-HDD-1:before {
  content: "\ea69";
}

.v3-HDD-2:before {
  content: "\ea6a";
}

.v3-HDD-3:before {
  content: "\ea6b";
}

.v3-Heart-1:before {
  content: "\ea6c";
}

.v3-Heart-2:before {
  content: "\ea6d";
}

.v3-Heart-3:before {
  content: "\ea6e";
}

.v3-Heart-4:before {
  content: "\ea6f";
}

.v3-Heart-5:before {
  content: "\ea70";
}

.v3-Heart-6:before {
  content: "\ea71";
}

.v3-Heart-Book:before {
  content: "\ea72";
}

.v3-Heart-Key:before {
  content: "\ea73";
}

.v3-Home-copy:before {
  content: "\ea74";
}

.v3-Home-Database:before {
  content: "\ea75";
}

.v3-Home:before {
  content: "\ea76";
}

.v3-Horseshoe:before {
  content: "\ea77";
}

.v3-Hot-Romance:before {
  content: "\ea78";
}

.v3-House-Key:before {
  content: "\ea79";
}

.v3-Import-2:before {
  content: "\ea7a";
}

.v3-Import:before {
  content: "\ea7b";
}

.v3-Incense-1:before {
  content: "\ea7c";
}

.v3-Incense-2:before {
  content: "\ea7d";
}

.v3-Infinite:before {
  content: "\ea7e";
}

.v3-iPad:before {
  content: "\ea7f";
}

.v3-iPhone:before {
  content: "\ea80";
}

.v3-iPod-Shuffle:before {
  content: "\ea81";
}

.v3-iPod:before {
  content: "\ea82";
}

.v3-Jar-Jar-Binks:before {
  content: "\ea83";
}

.v3-Jawa-Sandcrawler:before {
  content: "\ea84";
}

.v3-Jeans-Button:before {
  content: "\ea85";
}

.v3-Jedi-Order:before {
  content: "\ea86";
}

.v3-Jupiter:before {
  content: "\ea87";
}

.v3-Kerosene-Lamps-1:before {
  content: "\ea88";
}

.v3-Kerosene-Lamps-2:before {
  content: "\ea89";
}

.v3-Key-Tag-Answer:before {
  content: "\ea8a";
}

.v3-Key-Tag-Done:before {
  content: "\ea8b";
}

.v3-Key-Tag-Down:before {
  content: "\ea8c";
}

.v3-Key-Tag-Question:before {
  content: "\ea8d";
}

.v3-Key-Tag-Settings:before {
  content: "\ea8e";
}

.v3-Key-Tag-Time:before {
  content: "\ea8f";
}

.v3-Key-Tag-Up:before {
  content: "\ea90";
}

.v3-Key-Tag:before {
  content: "\ea91";
}

.v3-Keyboard:before {
  content: "\ea92";
}

.v3-Kylo-Ren:before {
  content: "\ea93";
}

.v3-Lace-Parasol-1:before {
  content: "\ea94";
}

.v3-Lace-Parasol-2:before {
  content: "\ea95";
}

.v3-Lamp:before {
  content: "\ea96";
}

.v3-Landing-Spacecraft:before {
  content: "\ea97";
}

.v3-Laptop-1:before {
  content: "\ea98";
}

.v3-Laptop-2:before {
  content: "\ea99";
}

.v3-Laser-gun:before {
  content: "\ea9a";
}

.v3-Lecture-1:before {
  content: "\ea9b";
}

.v3-Lecture-2:before {
  content: "\ea9c";
}

.v3-Lego-Toy:before {
  content: "\ea9d";
}

.v3-Leo-1:before {
  content: "\ea9e";
}

.v3-Leo-2:before {
  content: "\ea9f";
}

.v3-Letter:before {
  content: "\eaa0";
}

.v3-Libra-1:before {
  content: "\eaa1";
}

.v3-Libra-2:before {
  content: "\eaa2";
}

.v3-Light-Interceptor:before {
  content: "\eaa3";
}

.v3-Light-Saber:before {
  content: "\eaa4";
}

.v3-Lighters:before {
  content: "\eaa5";
}

.v3-Liqui-Soap-1:before {
  content: "\eaa6";
}

.v3-Liquid-Soap-2:before {
  content: "\eaa7";
}

.v3-Lock-Book:before {
  content: "\eaa8";
}

.v3-Lock-Box:before {
  content: "\eaa9";
}

.v3-Lock-Database:before {
  content: "\eaaa";
}

.v3-Lock-Server:before {
  content: "\eaab";
}

.v3-Loop-1:before {
  content: "\eaac";
}

.v3-Loop-2:before {
  content: "\eaad";
}

.v3-Loop-3:before {
  content: "\eaae";
}

.v3-Loop-4:before {
  content: "\eaaf";
}

.v3-Lotus:before {
  content: "\eab0";
}

.v3-Love-chat-1:before {
  content: "\eab1";
}

.v3-Love-chat-2:before {
  content: "\eab2";
}

.v3-Love-Computer-1:before {
  content: "\eab3";
}

.v3-Love-Computer-2:before {
  content: "\eab4";
}

.v3-Love-letter-1:before {
  content: "\eab5";
}

.v3-Love-letter-2:before {
  content: "\eab6";
}

.v3-Love-Letter-3:before {
  content: "\eab7";
}

.v3-Love-Message-1:before {
  content: "\eab8";
}

.v3-Love-Message-2:before {
  content: "\eab9";
}

.v3-Love-Place:before {
  content: "\eaba";
}

.v3-Love-Target:before {
  content: "\eabb";
}

.v3-Love:before {
  content: "\eabc";
}

.v3-Mac-Drive:before {
  content: "\eabd";
}

.v3-Mac:before {
  content: "\eabe";
}

.v3-Man-Pram:before {
  content: "\eabf";
}

.v3-Mark-Database:before {
  content: "\eac0";
}

.v3-Mark-Server:before {
  content: "\eac1";
}

.v3-Mars-Rover:before {
  content: "\eac2";
}

.v3-Mascara:before {
  content: "\eac3";
}

.v3-Mask:before {
  content: "\eac4";
}

.v3-Master-Yoda:before {
  content: "\eac5";
}

.v3-Math-Booklet:before {
  content: "\eac6";
}

.v3-Maximize-1:before {
  content: "\eac7";
}

.v3-Maximize-2:before {
  content: "\eac8";
}

.v3-Maximize-3:before {
  content: "\eac9";
}

.v3-Megaphone:before {
  content: "\eaca";
}

.v3-Menu:before {
  content: "\eacb";
}

.v3-Message-1:before {
  content: "\eacc";
}

.v3-Message-2:before {
  content: "\eacd";
}

.v3-Metal-Coffee-Mug:before {
  content: "\eace";
}

.v3-Meteorite:before {
  content: "\eacf";
}

.v3-Micro-SD:before {
  content: "\ead0";
}

.v3-Microphone:before {
  content: "\ead1";
}

.v3-Microscope:before {
  content: "\ead2";
}

.v3-Millenium-Falcon:before {
  content: "\ead3";
}

.v3-Minimize-1:before {
  content: "\ead4";
}

.v3-Minimize-2:before {
  content: "\ead5";
}

.v3-Mirror-1:before {
  content: "\ead6";
}

.v3-Mirror-2:before {
  content: "\ead7";
}

.v3-Mirror-3:before {
  content: "\ead8";
}

.v3-Mirror-4:before {
  content: "\ead9";
}

.v3-Mirror:before {
  content: "\eada";
}

.v3-Monitor-1:before {
  content: "\eadb";
}

.v3-Monitor-2:before {
  content: "\eadc";
}

.v3-Monitor-3:before {
  content: "\eadd";
}

.v3-Monitor-4:before {
  content: "\eade";
}

.v3-Monitor-5:before {
  content: "\eadf";
}

.v3-Moon-Footstep:before {
  content: "\eae0";
}

.v3-Moon-Rover-1:before {
  content: "\eae1";
}

.v3-Moon-Rover-2:before {
  content: "\eae2";
}

.v3-Moon-Rover-3:before {
  content: "\eae3";
}

.v3-Moon:before {
  content: "\eae4";
}

.v3-Mortal:before {
  content: "\eae5";
}

.v3-Mortar:before {
  content: "\eae6";
}

.v3-Mouse:before {
  content: "\eae7";
}

.v3-Move-2:before {
  content: "\eae8";
}

.v3-Move-3:before {
  content: "\eae9";
}

.v3-Move:before {
  content: "\eaea";
}

.v3-Movie-Tickets:before {
  content: "\eaeb";
}

.v3-Mute:before {
  content: "\eaec";
}

.v3-Nabu-Figther:before {
  content: "\eaed";
}

.v3-Nail-File-1:before {
  content: "\eaee";
}

.v3-Nail-File-2:before {
  content: "\eaef";
}

.v3-Nail-Polish-1:before {
  content: "\eaf0";
}

.v3-Nail-Polish-2:before {
  content: "\eaf1";
}

.v3-Nail-Scissors:before {
  content: "\eaf2";
}

.v3-Natural-History-1:before {
  content: "\eaf3";
}

.v3-Natural-History-2:before {
  content: "\eaf4";
}

.v3-News:before {
  content: "\eaf5";
}

.v3-Note-Book-1:before {
  content: "\eaf6";
}

.v3-Note-Book-2:before {
  content: "\eaf7";
}

.v3-Note-Book-3:before {
  content: "\eaf8";
}

.v3-Note-Book-4:before {
  content: "\eaf9";
}

.v3-Note-Book-Marker-1:before {
  content: "\eafa";
}

.v3-Note-Book-Marker-2:before {
  content: "\eafb";
}

.v3-Note-Book-Marker-3:before {
  content: "\eafc";
}

.v3-Note-Book-Write:before {
  content: "\eafd";
}

.v3-Notepad-Write:before {
  content: "\eafe";
}

.v3-Notepad:before {
  content: "\eaff";
}

.v3-Notes:before {
  content: "\eb00";
}

.v3-Numeric-Lock:before {
  content: "\eb01";
}

.v3-Oculus:before {
  content: "\eb02";
}

.v3-Old-Key-1:before {
  content: "\eb03";
}

.v3-Old-Key-2:before {
  content: "\eb04";
}

.v3-Old-Key-3:before {
  content: "\eb05";
}

.v3-Pacifier-1:before {
  content: "\eb06";
}

.v3-Pacifier-2:before {
  content: "\eb07";
}

.v3-Padlock-1:before {
  content: "\eb08";
}

.v3-Padlock-2:before {
  content: "\eb09";
}

.v3-Padlock:before {
  content: "\eb0a";
}

.v3-Page-Down:before {
  content: "\eb0b";
}

.v3-Page-Up:before {
  content: "\eb0c";
}

.v3-Pallet:before {
  content: "\eb0d";
}

.v3-Paper:before {
  content: "\eb0e";
}

.v3-Parabolic-Antenna-1:before {
  content: "\eb0f";
}

.v3-Parabolic-Antenna-2:before {
  content: "\eb10";
}

.v3-Parabolic-Antenna-3:before {
  content: "\eb11";
}

.v3-Passport:before {
  content: "\eb12";
}

.v3-Pay-Box:before {
  content: "\eb13";
}

.v3-Pay-Per-Click-2:before {
  content: "\eb14";
}

.v3-Pay-Per-Click:before {
  content: "\eb15";
}

.v3-Paying-Database:before {
  content: "\eb16";
}

.v3-Paying-Server:before {
  content: "\eb17";
}

.v3-Pen:before {
  content: "\eb18";
}

.v3-Perfume-1:before {
  content: "\eb19";
}

.v3-Perfume-2:before {
  content: "\eb1a";
}

.v3-Perfume:before {
  content: "\eb1b";
}

.v3-Philtre-1:before {
  content: "\eb1c";
}

.v3-Philtre-2:before {
  content: "\eb1d";
}

.v3-Phone-Book:before {
  content: "\eb1e";
}

.v3-Phone-Camera:before {
  content: "\eb1f";
}

.v3-Phone-Cloudy:before {
  content: "\eb20";
}

.v3-Phone-Fingerprint:before {
  content: "\eb21";
}

.v3-Phone-Flash:before {
  content: "\eb22";
}

.v3-Phone-Game:before {
  content: "\eb23";
}

.v3-Phone-Lock:before {
  content: "\eb24";
}

.v3-Phone-Loudsoeaker:before {
  content: "\eb25";
}

.v3-Phone-Mute:before {
  content: "\eb26";
}

.v3-Phone-Note-Book:before {
  content: "\eb27";
}

.v3-Phone-Oculus:before {
  content: "\eb28";
}

.v3-Phone-Pay:before {
  content: "\eb29";
}

.v3-Phone-Stylus:before {
  content: "\eb2a";
}

.v3-Phone-Sunny:before {
  content: "\eb2b";
}

.v3-Phone-Touch:before {
  content: "\eb2c";
}

.v3-Phone-Volume:before {
  content: "\eb2d";
}

.v3-Photo:before {
  content: "\eb2e";
}

.v3-Picture-Booklet:before {
  content: "\eb2f";
}

.v3-Pisces-1:before {
  content: "\eb30";
}

.v3-Pisces-2:before {
  content: "\eb31";
}

.v3-Pit-Droid:before {
  content: "\eb32";
}

.v3-Planet-Concquest:before {
  content: "\eb33";
}

.v3-Planet-Orbit:before {
  content: "\eb34";
}

.v3-Planet-Stars:before {
  content: "\eb35";
}

.v3-Planets:before {
  content: "\eb36";
}

.v3-Pocket-Watch:before {
  content: "\eb37";
}

.v3-Potty:before {
  content: "\eb38";
}

.v3-Pram-1:before {
  content: "\eb39";
}

.v3-Pram-2:before {
  content: "\eb3a";
}

.v3-Presentation-1:before {
  content: "\eb3b";
}

.v3-Presentation-2:before {
  content: "\eb3c";
}

.v3-Print-Screen:before {
  content: "\eb3d";
}

.v3-Protractor:before {
  content: "\eb3e";
}

.v3-Puzzle:before {
  content: "\eb3f";
}

.v3-R2-D2:before {
  content: "\eb40";
}

.v3-RAM:before {
  content: "\eb41";
}

.v3-Rattle-1:before {
  content: "\eb42";
}

.v3-Rattle-2:before {
  content: "\eb43";
}

.v3-Ray-Speeder-Bike:before {
  content: "\eb44";
}

.v3-Rebel-Alliance:before {
  content: "\eb45";
}

.v3-Rebel-Pilot:before {
  content: "\eb46";
}

.v3-Recycle:before {
  content: "\eb47";
}

.v3-Reduce:before {
  content: "\eb48";
}

.v3-Refresh-copy:before {
  content: "\eb49";
}

.v3-Refresh-Box:before {
  content: "\eb4a";
}

.v3-Refresh-Database:before {
  content: "\eb4b";
}

.v3-Refresh-Drive:before {
  content: "\eb4c";
}

.v3-Refresh-Key-Tag:before {
  content: "\eb4d";
}

.v3-Refresh-Server:before {
  content: "\eb4e";
}

.v3-Refresh:before {
  content: "\eb4f";
}

.v3-Remov-Key-Tag:before {
  content: "\eb50";
}

.v3-Remove-Book:before {
  content: "\eb51";
}

.v3-Remove-Box:before {
  content: "\eb52";
}

.v3-Remove-Database:before {
  content: "\eb53";
}

.v3-Remove-Delivery:before {
  content: "\eb54";
}

.v3-Remove-Drive:before {
  content: "\eb55";
}

.v3-Remove-Seo-Tag:before {
  content: "\eb56";
}

.v3-Remove-Server:before {
  content: "\eb57";
}

.v3-Replay-2:before {
  content: "\eb58";
}

.v3-Replay-3:before {
  content: "\eb59";
}

.v3-Replay:before {
  content: "\eb5a";
}

.v3-Retro-Camera-1:before {
  content: "\eb5b";
}

.v3-Retro-Camera-2:before {
  content: "\eb5c";
}

.v3-Retro-Clock:before {
  content: "\eb5d";
}

.v3-Retro-Iron:before {
  content: "\eb5e";
}

.v3-Retro-Mannequin:before {
  content: "\eb5f";
}

.v3-Retro-Pipe:before {
  content: "\eb60";
}

.v3-Retro-Radio-1:before {
  content: "\eb61";
}

.v3-Retro-Radio-2:before {
  content: "\eb62";
}

.v3-Retro-Radio-3:before {
  content: "\eb63";
}

.v3-Retro-Telephone-1:before {
  content: "\eb64";
}

.v3-Retro-Telephone-2:before {
  content: "\eb65";
}

.v3-Retro-Telephone-3:before {
  content: "\eb66";
}

.v3-Retro-TV-1:before {
  content: "\eb67";
}

.v3-Retro-TV-2:before {
  content: "\eb68";
}

.v3-Retro-TV-3:before {
  content: "\eb69";
}

.v3-Rey:before {
  content: "\eb6a";
}

.v3-Rocket-Backpack:before {
  content: "\eb6b";
}

.v3-Rocket-Boost:before {
  content: "\eb6c";
}

.v3-Rocket-Booster:before {
  content: "\eb6d";
}

.v3-Rocket-Boosters:before {
  content: "\eb6e";
}

.v3-Rocket-Launch:before {
  content: "\eb6f";
}

.v3-Rocket-Station:before {
  content: "\eb70";
}

.v3-Rocket:before {
  content: "\eb71";
}

.v3-Romantic-Dinner-1:before {
  content: "\eb72";
}

.v3-Romantic-Dinner-2:before {
  content: "\eb73";
}

.v3-Romantic-Music:before {
  content: "\eb74";
}

.v3-Rotate-1:before {
  content: "\eb75";
}

.v3-Rotate-2:before {
  content: "\eb76";
}

.v3-Rotate-3:before {
  content: "\eb77";
}

.v3-Rotate-4:before {
  content: "\eb78";
}

.v3-Rotation-1:before {
  content: "\eb79";
}

.v3-Rotation-2:before {
  content: "\eb7a";
}

.v3-Rouge:before {
  content: "\eb7b";
}

.v3-Royal-Guard:before {
  content: "\eb7c";
}

.v3-Roze:before {
  content: "\eb7d";
}

.v3-Rubber-Duck:before {
  content: "\eb7e";
}

.v3-Sagittarius-1:before {
  content: "\eb7f";
}

.v3-Sagittarius-2:before {
  content: "\eb80";
}

.v3-Satellite-1:before {
  content: "\eb81";
}

.v3-Satellite-2:before {
  content: "\eb82";
}

.v3-Saturn:before {
  content: "\eb83";
}

.v3-Scale:before {
  content: "\eb84";
}

.v3-Scales:before {
  content: "\eb85";
}

.v3-Scented-Candle-2:before {
  content: "\eb86";
}

.v3-Scented-Candle:before {
  content: "\eb87";
}

.v3-School-Bag:before {
  content: "\eb88";
}

.v3-School-Bell-2:before {
  content: "\eb89";
}

.v3-School-Bell:before {
  content: "\eb8a";
}

.v3-School-Board-1:before {
  content: "\eb8b";
}

.v3-School-Board-2:before {
  content: "\eb8c";
}

.v3-School-Board-3:before {
  content: "\eb8d";
}

.v3-School-Bus:before {
  content: "\eb8e";
}

.v3-School-Clock:before {
  content: "\eb8f";
}

.v3-Scorpio-1:before {
  content: "\eb90";
}

.v3-Scorpio-2:before {
  content: "\eb91";
}

.v3-Scout-Trooper:before {
  content: "\eb92";
}

.v3-Scroll-Lock:before {
  content: "\eb93";
}

.v3-SD-Card:before {
  content: "\eb94";
}

.v3-Search-Book:before {
  content: "\eb95";
}

.v3-Search-Box:before {
  content: "\eb96";
}

.v3-Search-Calendar:before {
  content: "\eb97";
}

.v3-Search-Computer:before {
  content: "\eb98";
}

.v3-Search-Cookie:before {
  content: "\eb99";
}

.v3-Search-Database:before {
  content: "\eb9a";
}

.v3-Search-Deliery:before {
  content: "\eb9b";
}

.v3-Search-Ekg:before {
  content: "\eb9c";
}

.v3-Search-Flash:before {
  content: "\eb9d";
}

.v3-Search-Key:before {
  content: "\eb9e";
}

.v3-Search-Lightbulb:before {
  content: "\eb9f";
}

.v3-Search-Loudspeaker:before {
  content: "\eba0";
}

.v3-Search-Love:before {
  content: "\eba1";
}

.v3-Search-Minus:before {
  content: "\eba2";
}

.v3-Search-Place-2:before {
  content: "\eba3";
}

.v3-Search-Place:before {
  content: "\eba4";
}

.v3-Search-Plus:before {
  content: "\eba5";
}

.v3-Search-Rook:before {
  content: "\eba6";
}

.v3-Search-Seo:before {
  content: "\eba7";
}

.v3-Search-Server:before {
  content: "\eba8";
}

.v3-Search-Statistic-2:before {
  content: "\eba9";
}

.v3-Search-Statistic-3:before {
  content: "\ebaa";
}

.v3-Search-Statistic:before {
  content: "\ebab";
}

.v3-Search-Target:before {
  content: "\ebac";
}

.v3-Search:before {
  content: "\ebad";
}

.v3-Seo-Bag:before {
  content: "\ebae";
}

.v3-Seo-Box:before {
  content: "\ebaf";
}

.v3-Seo-Computer:before {
  content: "\ebb0";
}

.v3-Seo-Defense:before {
  content: "\ebb1";
}

.v3-Seo-Shop:before {
  content: "\ebb2";
}

.v3-Seo-Shopping-Basket:before {
  content: "\ebb3";
}

.v3-Seo-Shopping-Cart:before {
  content: "\ebb4";
}

.v3-Seo-Tag-Answer:before {
  content: "\ebb5";
}

.v3-Seo-Tag-Done:before {
  content: "\ebb6";
}

.v3-Seo-Tag-Down:before {
  content: "\ebb7";
}

.v3-Seo-Tag-Place:before {
  content: "\ebb8";
}

.v3-Seo-Tag-Question:before {
  content: "\ebb9";
}

.v3-Seo-Tag-Refresh:before {
  content: "\ebba";
}

.v3-Seo-Tag-Settings:before {
  content: "\ebbb";
}

.v3-Seo-Tag-Time:before {
  content: "\ebbc";
}

.v3-Seo-Tag-Up:before {
  content: "\ebbd";
}

.v3-Seo-Tag:before {
  content: "\ebbe";
}

.v3-Seo-Webpage:before {
  content: "\ebbf";
}

.v3-Separatists:before {
  content: "\ebc0";
}

.v3-Serer-Connections:before {
  content: "\ebc1";
}

.v3-Server-1:before {
  content: "\ebc2";
}

.v3-Server-2:before {
  content: "\ebc3";
}

.v3-Server-3:before {
  content: "\ebc4";
}

.v3-Server-4:before {
  content: "\ebc5";
}

.v3-Server-5:before {
  content: "\ebc6";
}

.v3-Server-At:before {
  content: "\ebc7";
}

.v3-Server-Database-Sync:before {
  content: "\ebc8";
}

.v3-Server-Database:before {
  content: "\ebc9";
}

.v3-Server-Done:before {
  content: "\ebca";
}

.v3-Server-Down:before {
  content: "\ebcb";
}

.v3-Server-Download:before {
  content: "\ebcc";
}

.v3-Server-Ethernet:before {
  content: "\ebcd";
}

.v3-Server-Firewall:before {
  content: "\ebce";
}

.v3-Server-Folder-Sync:before {
  content: "\ebcf";
}

.v3-Server-Hand:before {
  content: "\ebd0";
}

.v3-Server-Heart:before {
  content: "\ebd1";
}

.v3-Server-Home:before {
  content: "\ebd2";
}

.v3-Server-Key:before {
  content: "\ebd3";
}

.v3-Server-Password:before {
  content: "\ebd4";
}

.v3-Server-Percentage:before {
  content: "\ebd5";
}

.v3-Server-Place-1:before {
  content: "\ebd6";
}

.v3-Server-Place-2:before {
  content: "\ebd7";
}

.v3-Server-Preferences:before {
  content: "\ebd8";
}

.v3-Server-Question:before {
  content: "\ebd9";
}

.v3-Server-Settings:before {
  content: "\ebda";
}

.v3-Server-Shopping:before {
  content: "\ebdb";
}

.v3-Server-Sleep:before {
  content: "\ebdc";
}

.v3-Server-Space:before {
  content: "\ebdd";
}

.v3-Server-Statistics:before {
  content: "\ebde";
}

.v3-Server-Sync:before {
  content: "\ebdf";
}

.v3-Server-Syncronize:before {
  content: "\ebe0";
}

.v3-Server-Up:before {
  content: "\ebe1";
}

.v3-Server-Upload:before {
  content: "\ebe2";
}

.v3-Server-User-Sync:before {
  content: "\ebe3";
}

.v3-Server-Warning:before {
  content: "\ebe4";
}

.v3-Settings-Booklet:before {
  content: "\ebe5";
}

.v3-Settings:before {
  content: "\ebe6";
}

.v3-Sewing-Machine:before {
  content: "\ebe7";
}

.v3-Share-Database:before {
  content: "\ebe8";
}

.v3-Share-Server:before {
  content: "\ebe9";
}

.v3-Shift-1:before {
  content: "\ebea";
}

.v3-Shift-2:before {
  content: "\ebeb";
}

.v3-Ship:before {
  content: "\ebec";
}

.v3-Showerheads-01:before {
  content: "\ebed";
}

.v3-Shuffle-2:before {
  content: "\ebee";
}

.v3-Shuffle-3:before {
  content: "\ebef";
}

.v3-Shuffle1:before {
  content: "\ebf0";
}

.v3-Skin-Care-1:before {
  content: "\ebf1";
}

.v3-Skin-Care-2:before {
  content: "\ebf2";
}

.v3-Smart-Bracelet:before {
  content: "\ebf3";
}

.v3-Smart-Devices:before {
  content: "\ebf4";
}

.v3-Smart-Glasses:before {
  content: "\ebf5";
}

.v3-Smart-TV-3D:before {
  content: "\ebf6";
}

.v3-Smart-TV-HD:before {
  content: "\ebf7";
}

.v3-Smart-TV-Wifi:before {
  content: "\ebf8";
}

.v3-Smart-TV:before {
  content: "\ebf9";
}

.v3-Smartphone-1:before {
  content: "\ebfa";
}

.v3-Smartphone-2:before {
  content: "\ebfb";
}

.v3-Smartphone-3:before {
  content: "\ebfc";
}

.v3-Smartwatch-1:before {
  content: "\ebfd";
}

.v3-Smartwatch-2:before {
  content: "\ebfe";
}

.v3-Smartwatch-Alarm-1:before {
  content: "\ebff";
}

.v3-Smartwatch-Alarm-2:before {
  content: "\ec00";
}

.v3-Smartwatch-Call:before {
  content: "\ec01";
}

.v3-Smartwatch-EKG-1:before {
  content: "\ec02";
}

.v3-Smartwatch-EKG-2:before {
  content: "\ec03";
}

.v3-Smartwatch-Fingerprint-1:before {
  content: "\ec04";
}

.v3-Smartwatch-Fingerprint-2:before {
  content: "\ec05";
}

.v3-Smartwatch-Message-1:before {
  content: "\ec06";
}

.v3-Smartwatch-Message-2:before {
  content: "\ec07";
}

.v3-Smartwatch-Mute-1:before {
  content: "\ec08";
}

.v3-Smartwatch-Mute-2:before {
  content: "\ec09";
}

.v3-Smartwatch-Picture-1:before {
  content: "\ec0a";
}

.v3-Smartwatch-Picture-2:before {
  content: "\ec0b";
}

.v3-Smartwatch-Place-1:before {
  content: "\ec0c";
}

.v3-Smartwatch-Place-2:before {
  content: "\ec0d";
}

.v3-Smartwatch-Reminder-1:before {
  content: "\ec0e";
}

.v3-Smartwatch-Reminder-2:before {
  content: "\ec0f";
}

.v3-Smartwatch-Speakers-1:before {
  content: "\ec10";
}

.v3-Smartwatch-Speakers-2:before {
  content: "\ec11";
}

.v3-Smartwatch-Touch:before {
  content: "\ec12";
}

.v3-Smartwatch-Volume-1:before {
  content: "\ec13";
}

.v3-Smartwatch-Volume-2:before {
  content: "\ec14";
}

.v3-Smartwatch-Wifi-1:before {
  content: "\ec15";
}

.v3-Smartwatch-Wifi-2:before {
  content: "\ec16";
}

.v3-Snack:before {
  content: "\ec17";
}

.v3-Soap:before {
  content: "\ec18";
}

.v3-Solar-System:before {
  content: "\ec19";
}

.v3-Spa-1:before {
  content: "\ec1a";
}

.v3-Spa-2:before {
  content: "\ec1b";
}

.v3-Spa-Oil-Glass-1:before {
  content: "\ec1c";
}

.v3-Spa-oil-glass-2:before {
  content: "\ec1d";
}

.v3-Spa-Oil-Glass-3:before {
  content: "\ec1e";
}

.v3-Spa-Salon:before {
  content: "\ec1f";
}

.v3-Spa-Sign-2:before {
  content: "\ec20";
}

.v3-Spa-stones-01:before {
  content: "\ec21";
}

.v3-Space-Shuttle:before {
  content: "\ec22";
}

.v3-Space-Station:before {
  content: "\ec23";
}

.v3-Spacecraft-1:before {
  content: "\ec24";
}

.v3-Spacecraft-2:before {
  content: "\ec25";
}

.v3-Spaceship-Cabin:before {
  content: "\ec26";
}

.v3-Spaceship-Window:before {
  content: "\ec27";
}

.v3-Spacesuit:before {
  content: "\ec28";
}

.v3-Speakers-On:before {
  content: "\ec29";
}

.v3-Spiral:before {
  content: "\ec2a";
}

.v3-Square-Arrow:before {
  content: "\ec2b";
}

.v3-SSD:before {
  content: "\ec2c";
}

.v3-Star-destroyer:before {
  content: "\ec2d";
}

.v3-Star-Trek-Ship:before {
  content: "\ec2e";
}

.v3-Star-Trek:before {
  content: "\ec2f";
}

.v3-Start-Menu:before {
  content: "\ec30";
}

.v3-Steel-Flask:before {
  content: "\ec31";
}

.v3-Storage:before {
  content: "\ec32";
}

.v3-Stormtrooper-1:before {
  content: "\ec33";
}

.v3-Stormtrooper-2:before {
  content: "\ec34";
}

.v3-Street-Lamp-1:before {
  content: "\ec35";
}

.v3-Street-Lamp-2:before {
  content: "\ec36";
}

.v3-Street-Plate:before {
  content: "\ec37";
}

.v3-Street-Sign-1:before {
  content: "\ec38";
}

.v3-Syncronize-1:before {
  content: "\ec39";
}

.v3-Tab-1:before {
  content: "\ec3a";
}

.v3-Tab-2:before {
  content: "\ec3b";
}

.v3-Tab-3:before {
  content: "\ec3c";
}

.v3-Tablet-1:before {
  content: "\ec3d";
}

.v3-Tablet-2:before {
  content: "\ec3e";
}

.v3-Tablet-3:before {
  content: "\ec3f";
}

.v3-Tablet-Camera:before {
  content: "\ec40";
}

.v3-Taurus-1:before {
  content: "\ec41";
}

.v3-Taurus-2:before {
  content: "\ec42";
}

.v3-Teapot:before {
  content: "\ec43";
}

.v3-Teddy-BEar-copy:before {
  content: "\ec44";
}

.v3-Teddy-Bear:before {
  content: "\ec45";
}

.v3-Telescope:before {
  content: "\ec46";
}

.v3-Test:before {
  content: "\ec47";
}

.v3-Tie-Fighter:before {
  content: "\ec48";
}

.v3-Toddler:before {
  content: "\ec49";
}

.v3-Towels-1:before {
  content: "\ec4a";
}

.v3-Towels-2:before {
  content: "\ec4b";
}

.v3-Towels-3:before {
  content: "\ec4c";
}

.v3-Toy-Bucket:before {
  content: "\ec4d";
}

.v3-Toy-Car:before {
  content: "\ec4e";
}

.v3-Toy-Cube:before {
  content: "\ec4f";
}

.v3-Toy-Train:before {
  content: "\ec50";
}

.v3-Traffic-Conversion:before {
  content: "\ec51";
}

.v3-Triangle-Ruler:before {
  content: "\ec52";
}

.v3-Trolley-2:before {
  content: "\ec53";
}

.v3-Trolley:before {
  content: "\ec54";
}

.v3-Truck:before {
  content: "\ec55";
}

.v3-Typewriter:before {
  content: "\ec56";
}

.v3-UFO:before {
  content: "\ec57";
}

.v3-Up:before {
  content: "\ec58";
}

.v3-Upload:before {
  content: "\ec59";
}

.v3-USB-2:before {
  content: "\ec5a";
}

.v3-USB-3:before {
  content: "\ec5b";
}

.v3-USB-Cable:before {
  content: "\ec5c";
}

.v3-USB-Done:before {
  content: "\ec5d";
}

.v3-USB-Error:before {
  content: "\ec5e";
}

.v3-USB:before {
  content: "\ec5f";
}

.v3-User-Database-Connect:before {
  content: "\ec60";
}

.v3-User-Database:before {
  content: "\ec61";
}

.v3-User-Server:before {
  content: "\ec62";
}

.v3-Viddeo-Call-Phone:before {
  content: "\ec63";
}

.v3-Video-Call-Computer:before {
  content: "\ec64";
}

.v3-Video-Call-Notebook:before {
  content: "\ec65";
}

.v3-Video-Call-Tablet:before {
  content: "\ec66";
}

.v3-Video-Call:before {
  content: "\ec67";
}

.v3-Video-Camera-1:before {
  content: "\ec68";
}

.v3-Video-Camera-2:before {
  content: "\ec69";
}

.v3-Video-Conference:before {
  content: "\ec6a";
}

.v3-Videocard:before {
  content: "\ec6b";
}

.v3-Vintage-Corset:before {
  content: "\ec6c";
}

.v3-Vintage-Fan:before {
  content: "\ec6d";
}

.v3-Vintage-Suitcase:before {
  content: "\ec6e";
}

.v3-Virgo-1:before {
  content: "\ec6f";
}

.v3-Virgo-2:before {
  content: "\ec70";
}

.v3-Voice-Book:before {
  content: "\ec71";
}

.v3-Volume-Down:before {
  content: "\ec72";
}

.v3-Volume-Up:before {
  content: "\ec73";
}

.v3-Walking-Stick:before {
  content: "\ec74";
}

.v3-Warning-Computer:before {
  content: "\ec75";
}

.v3-Warning-Database:before {
  content: "\ec76";
}

.v3-Water-care01:before {
  content: "\ec77";
}

.v3-Water-Pot:before {
  content: "\ec78";
}

.v3-Wavy:before {
  content: "\ec79";
}

.v3-Web-Development-2:before {
  content: "\ec7a";
}

.v3-Web-Development:before {
  content: "\ec7b";
}

.v3-Webcam-copy:before {
  content: "\ec7c";
}

.v3-Webcam:before {
  content: "\ec7d";
}

.v3-Windows-Drive:before {
  content: "\ec7e";
}

.v3-Windows-Phone:before {
  content: "\ec7f";
}

.v3-Windows-Tablet:before {
  content: "\ec80";
}

.v3-Windows:before {
  content: "\ec81";
}

.v3-Wine:before {
  content: "\ec82";
}

.v3-Wire-Mouse:before {
  content: "\ec83";
}

.v3-Wireless-Keyboard:before {
  content: "\ec84";
}

.v3-Wireless-Mouse:before {
  content: "\ec85";
}

.v3-Wireless-USB:before {
  content: "\ec86";
}

.v3-Woman-Pram:before {
  content: "\ec87";
}

.v3-X-34-Landspeeder:before {
  content: "\ec88";
}

.v3-X-key:before {
  content: "\ec89";
}

.v3-X-Wing:before {
  content: "\ec8a";
}

.v3-Y-Wing:before {
  content: "\ec8b";
}

.v3-Yin-Yang:before {
  content: "\ec8c";
}

