@font-face {
  font-family: 'v3b';
  src: url('fonts/v3b.woff?zbly8g') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v3b-"], [class*=" v3b-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v3b' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v3b-Abacus:before {
  content: "\e900";
}

.v3b-Abut-1:before {
  content: "\e901";
}

.v3b-Abut-2:before {
  content: "\e902";
}

.v3b-Abut-3:before {
  content: "\e903";
}

.v3b-Across:before {
  content: "\e904";
}

.v3b-Actinometer:before {
  content: "\e905";
}

.v3b-Acupuncture:before {
  content: "\e906";
}

.v3b-Add-Book:before {
  content: "\e907";
}

.v3b-Add-Box:before {
  content: "\e908";
}

.v3b-Add-Database:before {
  content: "\e909";
}

.v3b-Add-Delivery:before {
  content: "\e90a";
}

.v3b-Add-Drive:before {
  content: "\e90b";
}

.v3b-Add-Key-Tag:before {
  content: "\e90c";
}

.v3b-Add-Seo-Tag:before {
  content: "\e90d";
}

.v3b-Add-Server:before {
  content: "\e90e";
}

.v3b-Air-Balloon:before {
  content: "\e90f";
}

.v3b-Alien:before {
  content: "\e910";
}

.v3b-Alphabet-Book:before {
  content: "\e911";
}

.v3b-Alt-1:before {
  content: "\e912";
}

.v3b-Alt-2:before {
  content: "\e913";
}

.v3b-Alt-3:before {
  content: "\e914";
}

.v3b-Android-Smartphone:before {
  content: "\e915";
}

.v3b-Android-Tablet:before {
  content: "\e916";
}

.v3b-Angle-Ruler:before {
  content: "\e917";
}

.v3b-Apart:before {
  content: "\e918";
}

.v3b-Apple:before {
  content: "\e919";
}

.v3b-Aquarius-1:before {
  content: "\e91a";
}

.v3b-Aquarius-2:before {
  content: "\e91b";
}

.v3b-Archive-File:before {
  content: "\e91c";
}

.v3b-Archive-Folder:before {
  content: "\e91d";
}

.v3b-Archive-Folders:before {
  content: "\e91e";
}

.v3b-Archive-Letter:before {
  content: "\e91f";
}

.v3b-Archive-Picture:before {
  content: "\e920";
}

.v3b-Archive-Video:before {
  content: "\e921";
}

.v3b-Archive:before {
  content: "\e922";
}

.v3b-Aries-1:before {
  content: "\e923";
}

.v3b-Aries-2:before {
  content: "\e924";
}

.v3b-Arrow:before {
  content: "\e925";
}

.v3b-Aspect-Ratio:before {
  content: "\e926";
}

.v3b-AT-AT-Walker:before {
  content: "\e927";
}

.v3b-AT-OP-Walker:before {
  content: "\e928";
}

.v3b-Baby-Chair:before {
  content: "\e929";
}

.v3b-Baby-Cutlery:before {
  content: "\e92a";
}

.v3b-Baby-Face-1:before {
  content: "\e92b";
}

.v3b-baby-Face-2:before {
  content: "\e92c";
}

.v3b-Baby-Food:before {
  content: "\e92d";
}

.v3b-Baby-Grow:before {
  content: "\e92e";
}

.v3b-Baby-Monitor:before {
  content: "\e92f";
}

.v3b-Baby-Toy-1:before {
  content: "\e930";
}

.v3b-Baby-Toy-2:before {
  content: "\e931";
}

.v3b-Baby-Toy-3:before {
  content: "\e932";
}

.v3b-Baby:before {
  content: "\e933";
}

.v3b-Back-1:before {
  content: "\e934";
}

.v3b-Back-2:before {
  content: "\e935";
}

.v3b-Back-3:before {
  content: "\e936";
}

.v3b-Backspace-1:before {
  content: "\e937";
}

.v3b-Backspace-2:before {
  content: "\e938";
}

.v3b-Ball:before {
  content: "\e939";
}

.v3b-Balloons:before {
  content: "\e93a";
}

.v3b-Bamboo:before {
  content: "\e93b";
}

.v3b-Battle-Droid:before {
  content: "\e93c";
}

.v3b-BB-8:before {
  content: "\e93d";
}

.v3b-Bed-Room:before {
  content: "\e93e";
}

.v3b-Bib:before {
  content: "\e93f";
}

.v3b-Boba-Fet-Starship:before {
  content: "\e940";
}

.v3b-Boba-Fet:before {
  content: "\e941";
}

.v3b-Book-2:before {
  content: "\e942";
}

.v3b-Book-3:before {
  content: "\e943";
}

.v3b-Book-Bitcoin:before {
  content: "\e944";
}

.v3b-Book-Cloud:before {
  content: "\e945";
}

.v3b-Book-Copyright:before {
  content: "\e946";
}

.v3b-Book-Creditcard:before {
  content: "\e947";
}

.v3b-Book-Dismiss:before {
  content: "\e948";
}

.v3b-Book-Dollar:before {
  content: "\e949";
}

.v3b-Book-Done:before {
  content: "\e94a";
}

.v3b-Book-Down:before {
  content: "\e94b";
}

.v3b-Book-Euro:before {
  content: "\e94c";
}

.v3b-Book-No-Dollar:before {
  content: "\e94d";
}

.v3b-Book-Open-1:before {
  content: "\e94e";
}

.v3b-Book-Open-2:before {
  content: "\e94f";
}

.v3b-Book-Open-3:before {
  content: "\e950";
}

.v3b-Book-Open-4:before {
  content: "\e951";
}

.v3b-Book-Open-6:before {
  content: "\e952";
}

.v3b-Book-Percent:before {
  content: "\e953";
}

.v3b-Book-Pound:before {
  content: "\e954";
}

.v3b-Book-Question:before {
  content: "\e955";
}

.v3b-Book-Remove:before {
  content: "\e956";
}

.v3b-Book-Settings:before {
  content: "\e957";
}

.v3b-Book-Shopping:before {
  content: "\e958";
}

.v3b-Book-Star:before {
  content: "\e959";
}

.v3b-Book-Up:before {
  content: "\e95a";
}

.v3b-Book-User:before {
  content: "\e95b";
}

.v3b-Book-Warning:before {
  content: "\e95c";
}

.v3b-Book-Write:before {
  content: "\e95d";
}

.v3b-Book:before {
  content: "\e95e";
}

.v3b-BookClose:before {
  content: "\e95f";
}

.v3b-Booklet:before {
  content: "\e960";
}

.v3b-Bookmark-1:before {
  content: "\e961";
}

.v3b-Bookmark-2:before {
  content: "\e962";
}

.v3b-Bookmark-Open:before {
  content: "\e963";
}

.v3b-BookOpen-5:before {
  content: "\e964";
}

.v3b-Books:before {
  content: "\e965";
}

.v3b-Bos-Pallet:before {
  content: "\e966";
}

.v3b-Bottled-Message:before {
  content: "\e967";
}

.v3b-Bouquet:before {
  content: "\e968";
}

.v3b-Bow:before {
  content: "\e969";
}

.v3b-Box-1:before {
  content: "\e96a";
}

.v3b-Box-2:before {
  content: "\e96b";
}

.v3b-Box-3:before {
  content: "\e96c";
}

.v3b-Box-4:before {
  content: "\e96d";
}

.v3b-Box-Answer:before {
  content: "\e96e";
}

.v3b-Box-Back:before {
  content: "\e96f";
}

.v3b-Box-Deliver:before {
  content: "\e970";
}

.v3b-Box-DOne:before {
  content: "\e971";
}

.v3b-Box-Down:before {
  content: "\e972";
}

.v3b-Box-File:before {
  content: "\e973";
}

.v3b-Box-Folder:before {
  content: "\e974";
}

.v3b-Box-Folders:before {
  content: "\e975";
}

.v3b-Box-Forward:before {
  content: "\e976";
}

.v3b-Box-Home:before {
  content: "\e977";
}

.v3b-Box-Keep-Dry:before {
  content: "\e978";
}

.v3b-Box-Letter:before {
  content: "\e979";
}

.v3b-Box-Picture:before {
  content: "\e97a";
}

.v3b-Box-Place:before {
  content: "\e97b";
}

.v3b-Box-Plane:before {
  content: "\e97c";
}

.v3b-Box-Quesion:before {
  content: "\e97d";
}

.v3b-Box-Security:before {
  content: "\e97e";
}

.v3b-Box-Settings:before {
  content: "\e97f";
}

.v3b-Box-Shipping:before {
  content: "\e980";
}

.v3b-Box-Shopping:before {
  content: "\e981";
}

.v3b-Box-Size:before {
  content: "\e982";
}

.v3b-Box-Time:before {
  content: "\e983";
}

.v3b-Box-Up:before {
  content: "\e984";
}

.v3b-Box-Video:before {
  content: "\e985";
}

.v3b-Box:before {
  content: "\e986";
}

.v3b-Breast:before {
  content: "\e987";
}

.v3b-Brightness-Down:before {
  content: "\e988";
}

.v3b-Brightness-Up:before {
  content: "\e989";
}

.v3b-Bulb-1:before {
  content: "\e98a";
}

.v3b-Bulb-2:before {
  content: "\e98b";
}

.v3b-Burn-Disk:before {
  content: "\e98c";
}

.v3b-Button:before {
  content: "\e98d";
}

.v3b-C3-PO:before {
  content: "\e98e";
}

.v3b-Calculator:before {
  content: "\e98f";
}

.v3b-Calendar-1:before {
  content: "\e990";
}

.v3b-Calendar-2:before {
  content: "\e991";
}

.v3b-Calming-Tea:before {
  content: "\e992";
}

.v3b-Camera-Application:before {
  content: "\e993";
}

.v3b-Camera-Button:before {
  content: "\e994";
}

.v3b-Camera-Speach-Bubble-1:before {
  content: "\e995";
}

.v3b-Camera-Speach-Bubble-2:before {
  content: "\e996";
}

.v3b-Camera-Speach-Bubble-3:before {
  content: "\e997";
}

.v3b-Cancer--2:before {
  content: "\e998";
}

.v3b-Cancer-1:before {
  content: "\e999";
}

.v3b-Candle:before {
  content: "\e99a";
}

.v3b-Candlestick:before {
  content: "\e99b";
}

.v3b-Canteen:before {
  content: "\e99c";
}

.v3b-Capricorn-1:before {
  content: "\e99d";
}

.v3b-Capricorn-2:before {
  content: "\e99e";
}

.v3b-Caps-Lock:before {
  content: "\e99f";
}

.v3b-Car-Seat:before {
  content: "\e9a0";
}

.v3b-Cary-Box:before {
  content: "\e9a1";
}

.v3b-Chandelier:before {
  content: "\e9a2";
}

.v3b-Change:before {
  content: "\e9a3";
}

.v3b-Choco-Strawberry:before {
  content: "\e9a4";
}

.v3b-Chocolate-Box:before {
  content: "\e9a5";
}

.v3b-Cigar:before {
  content: "\e9a6";
}

.v3b-Cigarette-Holder:before {
  content: "\e9a7";
}

.v3b-Clip:before {
  content: "\e9a8";
}

.v3b-Clipboard-Write:before {
  content: "\e9a9";
}

.v3b-Clipboard:before {
  content: "\e9aa";
}

.v3b-Cloud-Database:before {
  content: "\e9ab";
}

.v3b-Cloud-Drive:before {
  content: "\e9ac";
}

.v3b-Cloud-Server-1:before {
  content: "\e9ad";
}

.v3b-Cloud-Server-2:before {
  content: "\e9ae";
}

.v3b-Coffee-Grinder:before {
  content: "\e9af";
}

.v3b-Comand-1:before {
  content: "\e9b0";
}

.v3b-Command-2:before {
  content: "\e9b1";
}

.v3b-Command-3:before {
  content: "\e9b2";
}

.v3b-Computer-1:before {
  content: "\e9b3";
}

.v3b-Computer-2:before {
  content: "\e9b4";
}

.v3b-Computer-Camera:before {
  content: "\e9b5";
}

.v3b-Computer-Connection:before {
  content: "\e9b6";
}

.v3b-Computer-Connections:before {
  content: "\e9b7";
}

.v3b-Computer-Database:before {
  content: "\e9b8";
}

.v3b-Computer-Sync:before {
  content: "\e9b9";
}

.v3b-Connect-Computer:before {
  content: "\e9ba";
}

.v3b-Connected-Folder:before {
  content: "\e9bb";
}

.v3b-Connected-Home:before {
  content: "\e9bc";
}

.v3b-Constellation:before {
  content: "\e9bd";
}

.v3b-Contact-Booklet:before {
  content: "\e9be";
}

.v3b-Contact-Note-Book:before {
  content: "\e9bf";
}

.v3b-Container-Lifter:before {
  content: "\e9c0";
}

.v3b-Control:before {
  content: "\e9c1";
}

.v3b-Cookbook:before {
  content: "\e9c2";
}

.v3b-Cooler:before {
  content: "\e9c3";
}

.v3b-Corrector:before {
  content: "\e9c4";
}

.v3b-Cotton-Buds:before {
  content: "\e9c5";
}

.v3b-CPU-2:before {
  content: "\e9c6";
}

.v3b-CPU-4:before {
  content: "\e9c7";
}

.v3b-CPU-6:before {
  content: "\e9c8";
}

.v3b-CPU-8:before {
  content: "\e9c9";
}

.v3b-CPU-32:before {
  content: "\e9ca";
}

.v3b-CPU-64:before {
  content: "\e9cb";
}

.v3b-CPU-Done:before {
  content: "\e9cc";
}

.v3b-CPU-Ekg:before {
  content: "\e9cd";
}

.v3b-CPU-Flash:before {
  content: "\e9ce";
}

.v3b-CPU-Protection:before {
  content: "\e9cf";
}

.v3b-CPU-Setings:before {
  content: "\e9d0";
}

.v3b-CPU-Warning:before {
  content: "\e9d1";
}

.v3b-CPU:before {
  content: "\e9d2";
}

.v3b-Crib:before {
  content: "\e9d3";
}

.v3b-Crying-Baby-1:before {
  content: "\e9d4";
}

.v3b-Crying-Baby-2:before {
  content: "\e9d5";
}

.v3b-Cuckoo-clock:before {
  content: "\e9d6";
}

.v3b-Cucumber:before {
  content: "\e9d7";
}

.v3b-Cutlery-Booklet:before {
  content: "\e9d8";
}

.v3b-Darth-Vader:before {
  content: "\e9d9";
}

.v3b-Database-At:before {
  content: "\e9da";
}

.v3b-Database-Connection:before {
  content: "\e9db";
}

.v3b-Database-Connections:before {
  content: "\e9dc";
}

.v3b-Database-Done:before {
  content: "\e9dd";
}

.v3b-Database-Down:before {
  content: "\e9de";
}

.v3b-Database-Download:before {
  content: "\e9df";
}

.v3b-Database-Gaming:before {
  content: "\e9e0";
}

.v3b-Database-Hand:before {
  content: "\e9e1";
}

.v3b-Database-Heart:before {
  content: "\e9e2";
}

.v3b-Database-Home-Sync:before {
  content: "\e9e3";
}

.v3b-Database-Key:before {
  content: "\e9e4";
}

.v3b-Database-Percentage:before {
  content: "\e9e5";
}

.v3b-Database-Place:before {
  content: "\e9e6";
}

.v3b-Database-Preferences:before {
  content: "\e9e7";
}

.v3b-Database-Question:before {
  content: "\e9e8";
}

.v3b-Database-Settings:before {
  content: "\e9e9";
}

.v3b-Database-Shopping:before {
  content: "\e9ea";
}

.v3b-Database-Sleep:before {
  content: "\e9eb";
}

.v3b-Database-Space:before {
  content: "\e9ec";
}

.v3b-Database-Sync:before {
  content: "\e9ed";
}

.v3b-Database-Up:before {
  content: "\e9ee";
}

.v3b-Database-Upload:before {
  content: "\e9ef";
}

.v3b-Database-Warning:before {
  content: "\e9f0";
}

.v3b-Delete-Book:before {
  content: "\e9f1";
}

.v3b-Delete-Box:before {
  content: "\e9f2";
}

.v3b-Delete-CPU:before {
  content: "\e9f3";
}

.v3b-Delete-Database:before {
  content: "\e9f4";
}

.v3b-Delete-Delivery:before {
  content: "\e9f5";
}

.v3b-Delete-Drive:before {
  content: "\e9f6";
}

.v3b-Delete-Key-Tag:before {
  content: "\e9f7";
}

.v3b-Delete-Seo-Tag:before {
  content: "\e9f8";
}

.v3b-Delete-Server:before {
  content: "\e9f9";
}

.v3b-Delete:before {
  content: "\e9fa";
}

.v3b-Delivery-Answer:before {
  content: "\e9fb";
}

.v3b-Delivery-Back:before {
  content: "\e9fc";
}

.v3b-Delivery-Forward:before {
  content: "\e9fd";
}

.v3b-Delivery-Pay:before {
  content: "\e9fe";
}

.v3b-Delivery-Question:before {
  content: "\e9ff";
}

.v3b-Delivery:before {
  content: "\ea00";
}

.v3b-Desk:before {
  content: "\ea01";
}

.v3b-Desktop-Computer-2:before {
  content: "\ea02";
}

.v3b-Desktop-Computer:before {
  content: "\ea03";
}

.v3b-Deth-Star-Exploded:before {
  content: "\ea04";
}

.v3b-Deth-Star:before {
  content: "\ea05";
}

.v3b-Diaper:before {
  content: "\ea06";
}

.v3b-Diet:before {
  content: "\ea07";
}

.v3b-Diploma-1:before {
  content: "\ea08";
}

.v3b-Diploma-2:before {
  content: "\ea09";
}

.v3b-Diploma-3:before {
  content: "\ea0a";
}

.v3b-Disable-Camera-1:before {
  content: "\ea0b";
}

.v3b-Disable-Camera-2:before {
  content: "\ea0c";
}

.v3b-Disk-Booklet:before {
  content: "\ea0d";
}

.v3b-Disk-Drive:before {
  content: "\ea0e";
}

.v3b-Disk:before {
  content: "\ea0f";
}

.v3b-Dismiss-Box:before {
  content: "\ea10";
}

.v3b-Dismiss-Database:before {
  content: "\ea11";
}

.v3b-Dismiss-Drive:before {
  content: "\ea12";
}

.v3b-Dismiss-Key-Tag:before {
  content: "\ea13";
}

.v3b-Dismiss-Seo-Tag:before {
  content: "\ea14";
}

.v3b-Dismiss-Server:before {
  content: "\ea15";
}

.v3b-Diverge-1:before {
  content: "\ea16";
}

.v3b-Diverge-2:before {
  content: "\ea17";
}

.v3b-Diverge-3:before {
  content: "\ea18";
}

.v3b-DollarBooklet:before {
  content: "\ea19";
}

.v3b-DOne-Delivery:before {
  content: "\ea1a";
}

.v3b-Dont-Disturb:before {
  content: "\ea1b";
}

.v3b-Dossier:before {
  content: "\ea1c";
}

.v3b-Dossiers-1:before {
  content: "\ea1d";
}

.v3b-Dossiers-2:before {
  content: "\ea1e";
}

.v3b-Double-Loop:before {
  content: "\ea1f";
}

.v3b-Double-Rotate-1:before {
  content: "\ea20";
}

.v3b-Double-Rotate-2:before {
  content: "\ea21";
}

.v3b-Down:before {
  content: "\ea22";
}

.v3b-Download:before {
  content: "\ea23";
}

.v3b-Drawing-Class:before {
  content: "\ea24";
}

.v3b-Drie-Settings:before {
  content: "\ea25";
}

.v3b-Drive-1:before {
  content: "\ea26";
}

.v3b-Drive-2:before {
  content: "\ea27";
}

.v3b-Drive-Done:before {
  content: "\ea28";
}

.v3b-Drive-Down:before {
  content: "\ea29";
}

.v3b-Drive-Flash:before {
  content: "\ea2a";
}

.v3b-Drive-Question:before {
  content: "\ea2b";
}

.v3b-Drive-Time:before {
  content: "\ea2c";
}

.v3b-Drive-Upl:before {
  content: "\ea2d";
}

.v3b-Drive-Warning:before {
  content: "\ea2e";
}

.v3b-Earth:before {
  content: "\ea2f";
}

.v3b-Eco-Book:before {
  content: "\ea30";
}

.v3b-Eco-Booklet:before {
  content: "\ea31";
}

.v3b-Educational-Aids-1:before {
  content: "\ea32";
}

.v3b-Educational-Aids-2:before {
  content: "\ea33";
}

.v3b-Educational-Aids-3:before {
  content: "\ea34";
}

.v3b-Empty-Box:before {
  content: "\ea35";
}

.v3b-Enable-Camera-1:before {
  content: "\ea36";
}

.v3b-Enable-Camera-2:before {
  content: "\ea37";
}

.v3b-End:before {
  content: "\ea38";
}

.v3b-Enlarge:before {
  content: "\ea39";
}

.v3b-Enter-1:before {
  content: "\ea3a";
}

.v3b-Enter-2:before {
  content: "\ea3b";
}

.v3b-Escape:before {
  content: "\ea3c";
}

.v3b-Export:before {
  content: "\ea3d";
}

.v3b-Expot-2:before {
  content: "\ea3e";
}

.v3b-Eye-Shadow:before {
  content: "\ea3f";
}

.v3b-Eyebrow-Tweezers:before {
  content: "\ea40";
}

.v3b-Feeding-Botle-2:before {
  content: "\ea41";
}

.v3b-Feeding-Bottle-1:before {
  content: "\ea42";
}

.v3b-Files:before {
  content: "\ea43";
}

.v3b-First-Day:before {
  content: "\ea44";
}

.v3b-Floating-Candle:before {
  content: "\ea45";
}

.v3b-Flying-Rocket:before {
  content: "\ea46";
}

.v3b-Foot-Spa-Bucket:before {
  content: "\ea47";
}

.v3b-Fork-Lift:before {
  content: "\ea48";
}

.v3b-Form-Toy:before {
  content: "\ea49";
}

.v3b-Forward-1:before {
  content: "\ea4a";
}

.v3b-Forward-2:before {
  content: "\ea4b";
}

.v3b-Forward-3:before {
  content: "\ea4c";
}

.v3b-Forward-4:before {
  content: "\ea4d";
}

.v3b-Fragile-Box:before {
  content: "\ea4e";
}

.v3b-Function:before {
  content: "\ea4f";
}

.v3b-Galactucal-Empire:before {
  content: "\ea50";
}

.v3b-Galaxy:before {
  content: "\ea51";
}

.v3b-Gaming-Server:before {
  content: "\ea52";
}

.v3b-Gemini-1:before {
  content: "\ea53";
}

.v3b-Gemini-2:before {
  content: "\ea54";
}

.v3b-General-Grievous:before {
  content: "\ea55";
}

.v3b-Gift:before {
  content: "\ea56";
}

.v3b-Global-Box:before {
  content: "\ea57";
}

.v3b-Global-Databaase:before {
  content: "\ea58";
}

.v3b-Global-Delivery:before {
  content: "\ea59";
}

.v3b-Global-Folder:before {
  content: "\ea5a";
}

.v3b-Global-Key-Tag:before {
  content: "\ea5b";
}

.v3b-Global-Seo-Tag:before {
  content: "\ea5c";
}

.v3b-Global-Seo:before {
  content: "\ea5d";
}

.v3b-Global-Server-Sync:before {
  content: "\ea5e";
}

.v3b-Global-Server:before {
  content: "\ea5f";
}

.v3b-Globe:before {
  content: "\ea60";
}

.v3b-Graduation-Hat:before {
  content: "\ea61";
}

.v3b-Gramophone:before {
  content: "\ea62";
}

.v3b-Grandfather-Clock:before {
  content: "\ea63";
}

.v3b-Hair-Care:before {
  content: "\ea64";
}

.v3b-Hair-Dryer:before {
  content: "\ea65";
}

.v3b-Hand-cargo:before {
  content: "\ea66";
}

.v3b-Hat-Stand:before {
  content: "\ea67";
}

.v3b-HDD-1:before {
  content: "\ea68";
}

.v3b-HDD-2:before {
  content: "\ea69";
}

.v3b-HDD-3:before {
  content: "\ea6a";
}

.v3b-Heart-1:before {
  content: "\ea6b";
}

.v3b-Heart-2:before {
  content: "\ea6c";
}

.v3b-Heart-3:before {
  content: "\ea6d";
}

.v3b-Heart-4:before {
  content: "\ea6e";
}

.v3b-Heart-5:before {
  content: "\ea6f";
}

.v3b-Heart-6:before {
  content: "\ea70";
}

.v3b-Heart-Book:before {
  content: "\ea71";
}

.v3b-Heart-Key:before {
  content: "\ea72";
}

.v3b-Home-copy:before {
  content: "\ea73";
}

.v3b-Home-Database:before {
  content: "\ea74";
}

.v3b-Home:before {
  content: "\ea75";
}

.v3b-Horseshoe:before {
  content: "\ea76";
}

.v3b-Hot-Romance:before {
  content: "\ea77";
}

.v3b-House-Key:before {
  content: "\ea78";
}

.v3b-Import-2:before {
  content: "\ea79";
}

.v3b-Import:before {
  content: "\ea7a";
}

.v3b-Incense-1:before {
  content: "\ea7b";
}

.v3b-Incense-2:before {
  content: "\ea7c";
}

.v3b-Infinite:before {
  content: "\ea7d";
}

.v3b-iPad:before {
  content: "\ea7e";
}

.v3b-iPhone:before {
  content: "\ea7f";
}

.v3b-iPod-Shuffle:before {
  content: "\ea80";
}

.v3b-iPod:before {
  content: "\ea81";
}

.v3b-Jar-Jar-Binks:before {
  content: "\ea82";
}

.v3b-Jawa-Sandcrawler:before {
  content: "\ea83";
}

.v3b-Jeans-Button:before {
  content: "\ea84";
}

.v3b-Jedi-Order:before {
  content: "\ea85";
}

.v3b-Jupiter:before {
  content: "\ea86";
}

.v3b-Kerosene-Lamps-1:before {
  content: "\ea87";
}

.v3b-Kerosene-Lamps-2:before {
  content: "\ea88";
}

.v3b-Key-Tag-Answer:before {
  content: "\ea89";
}

.v3b-Key-Tag-Done:before {
  content: "\ea8a";
}

.v3b-Key-Tag-Down:before {
  content: "\ea8b";
}

.v3b-Key-Tag-Question:before {
  content: "\ea8c";
}

.v3b-Key-Tag-Settings:before {
  content: "\ea8d";
}

.v3b-Key-Tag-Time:before {
  content: "\ea8e";
}

.v3b-Key-Tag-Up:before {
  content: "\ea8f";
}

.v3b-Key-Tag:before {
  content: "\ea90";
}

.v3b-Keyboard:before {
  content: "\ea91";
}

.v3b-Kylo-Ren:before {
  content: "\ea92";
}

.v3b-Lace-Parasol-1:before {
  content: "\ea93";
}

.v3b-Lace-Parasol-2:before {
  content: "\ea94";
}

.v3b-Lamp:before {
  content: "\ea95";
}

.v3b-Landing-Spacecraft:before {
  content: "\ea96";
}

.v3b-Laptop-1:before {
  content: "\ea97";
}

.v3b-Laptop-2:before {
  content: "\ea98";
}

.v3b-Laser-gun:before {
  content: "\ea99";
}

.v3b-Layer-54:before {
  content: "\ea9a";
}

.v3b-Lecture-1:before {
  content: "\ea9b";
}

.v3b-Lecture-2:before {
  content: "\ea9c";
}

.v3b-Lego-Toy:before {
  content: "\ea9d";
}

.v3b-Leo-1:before {
  content: "\ea9e";
}

.v3b-Leo-2:before {
  content: "\ea9f";
}

.v3b-Letter:before {
  content: "\eaa0";
}

.v3b-Libra-1:before {
  content: "\eaa1";
}

.v3b-Libra-2:before {
  content: "\eaa2";
}

.v3b-Light-Interceptor:before {
  content: "\eaa3";
}

.v3b-Light-Saber:before {
  content: "\eaa4";
}

.v3b-Lighters:before {
  content: "\eaa5";
}

.v3b-Liqui-Soap-1:before {
  content: "\eaa6";
}

.v3b-Liquid-Soap-2:before {
  content: "\eaa7";
}

.v3b-Lock-Book:before {
  content: "\eaa8";
}

.v3b-Lock-Box:before {
  content: "\eaa9";
}

.v3b-Lock-Database:before {
  content: "\eaaa";
}

.v3b-Lock-Server:before {
  content: "\eaab";
}

.v3b-Loop-1:before {
  content: "\eaac";
}

.v3b-Loop-2:before {
  content: "\eaad";
}

.v3b-Loop-3:before {
  content: "\eaae";
}

.v3b-Loop-4:before {
  content: "\eaaf";
}

.v3b-Lotus:before {
  content: "\eab0";
}

.v3b-Love-chat-1:before {
  content: "\eab1";
}

.v3b-Love-chat-2:before {
  content: "\eab2";
}

.v3b-Love-Computer-1:before {
  content: "\eab3";
}

.v3b-Love-Computer-2:before {
  content: "\eab4";
}

.v3b-Love-letter-1:before {
  content: "\eab5";
}

.v3b-Love-letter-2:before {
  content: "\eab6";
}

.v3b-Love-Letter-3:before {
  content: "\eab7";
}

.v3b-Love-Message-1:before {
  content: "\eab8";
}

.v3b-Love-Message-2:before {
  content: "\eab9";
}

.v3b-Love-Place:before {
  content: "\eaba";
}

.v3b-Love-Target:before {
  content: "\eabb";
}

.v3b-Love:before {
  content: "\eabc";
}

.v3b-Mac-Drive:before {
  content: "\eabd";
}

.v3b-Mac:before {
  content: "\eabe";
}

.v3b-Man-Pram:before {
  content: "\eabf";
}

.v3b-Mark-Database:before {
  content: "\eac0";
}

.v3b-Mark-Server:before {
  content: "\eac1";
}

.v3b-Mars-Rover:before {
  content: "\eac2";
}

.v3b-Mascara:before {
  content: "\eac3";
}

.v3b-Mask:before {
  content: "\eac4";
}

.v3b-Master-Yoda:before {
  content: "\eac5";
}

.v3b-Math-Booklet:before {
  content: "\eac6";
}

.v3b-Maximize-1:before {
  content: "\eac7";
}

.v3b-Maximize-2:before {
  content: "\eac8";
}

.v3b-Maximize-3:before {
  content: "\eac9";
}

.v3b-Megaphone:before {
  content: "\eaca";
}

.v3b-Menu:before {
  content: "\eacb";
}

.v3b-Message-1:before {
  content: "\eacc";
}

.v3b-Message-2:before {
  content: "\eacd";
}

.v3b-Metal-Coffee-Mug:before {
  content: "\eace";
}

.v3b-Meteorite:before {
  content: "\eacf";
}

.v3b-Micro-SD:before {
  content: "\ead0";
}

.v3b-Microphone:before {
  content: "\ead1";
}

.v3b-Microscope:before {
  content: "\ead2";
}

.v3b-Millenium-Falcon:before {
  content: "\ead3";
}

.v3b-Minimize-1:before {
  content: "\ead4";
}

.v3b-Minimize-2:before {
  content: "\ead5";
}

.v3b-Mirror-1:before {
  content: "\ead6";
}

.v3b-Mirror-2:before {
  content: "\ead7";
}

.v3b-Mirror-3:before {
  content: "\ead8";
}

.v3b-Mirror-4:before {
  content: "\ead9";
}

.v3b-Mirror:before {
  content: "\eada";
}

.v3b-Monitor-1:before {
  content: "\eadb";
}

.v3b-Monitor-2:before {
  content: "\eadc";
}

.v3b-Monitor-3:before {
  content: "\eadd";
}

.v3b-Monitor-4:before {
  content: "\eade";
}

.v3b-Monitor-5:before {
  content: "\eadf";
}

.v3b-Moon-Footstep:before {
  content: "\eae0";
}

.v3b-Moon-Rover-1:before {
  content: "\eae1";
}

.v3b-Moon-Rover-2:before {
  content: "\eae2";
}

.v3b-Moon-Rover-3:before {
  content: "\eae3";
}

.v3b-Moon:before {
  content: "\eae4";
}

.v3b-Mortal:before {
  content: "\eae5";
}

.v3b-Mortar:before {
  content: "\eae6";
}

.v3b-Mouse:before {
  content: "\eae7";
}

.v3b-Move-2:before {
  content: "\eae8";
}

.v3b-Move-3:before {
  content: "\eae9";
}

.v3b-Move:before {
  content: "\eaea";
}

.v3b-Movie-Tickets:before {
  content: "\eaeb";
}

.v3b-Mute:before {
  content: "\eaec";
}

.v3b-Nabu-Figther:before {
  content: "\eaed";
}

.v3b-Nail-File-1:before {
  content: "\eaee";
}

.v3b-Nail-File-2:before {
  content: "\eaef";
}

.v3b-Nail-Polish-1:before {
  content: "\eaf0";
}

.v3b-Nail-Polish-2:before {
  content: "\eaf1";
}

.v3b-Nail-Scissors:before {
  content: "\eaf2";
}

.v3b-Natural-History-1:before {
  content: "\eaf3";
}

.v3b-Natural-History-2:before {
  content: "\eaf4";
}

.v3b-News:before {
  content: "\eaf5";
}

.v3b-Note-Book-1:before {
  content: "\eaf6";
}

.v3b-Note-Book-2:before {
  content: "\eaf7";
}

.v3b-Note-Book-3:before {
  content: "\eaf8";
}

.v3b-Note-Book-4:before {
  content: "\eaf9";
}

.v3b-Note-Book-Marker-1:before {
  content: "\eafa";
}

.v3b-Note-Book-Marker-2:before {
  content: "\eafb";
}

.v3b-Note-Book-Marker-3:before {
  content: "\eafc";
}

.v3b-Note-Book-Write:before {
  content: "\eafd";
}

.v3b-Notepad-Write:before {
  content: "\eafe";
}

.v3b-Notepad:before {
  content: "\eaff";
}

.v3b-Notes:before {
  content: "\eb00";
}

.v3b-Numeric-Lock:before {
  content: "\eb01";
}

.v3b-Oculus:before {
  content: "\eb02";
}

.v3b-Old-Key-1:before {
  content: "\eb03";
}

.v3b-Old-Key-2:before {
  content: "\eb04";
}

.v3b-Old-Key-3:before {
  content: "\eb05";
}

.v3b-Pacifier-1:before {
  content: "\eb06";
}

.v3b-Pacifier-2:before {
  content: "\eb07";
}

.v3b-Padlock-1:before {
  content: "\eb08";
}

.v3b-Padlock-2:before {
  content: "\eb09";
}

.v3b-Padlock:before {
  content: "\eb0a";
}

.v3b-Page-Down:before {
  content: "\eb0b";
}

.v3b-Page-Up:before {
  content: "\eb0c";
}

.v3b-Pallet:before {
  content: "\eb0d";
}

.v3b-Paper:before {
  content: "\eb0e";
}

.v3b-Parabolic-Antenna-1:before {
  content: "\eb0f";
}

.v3b-Parabolic-Antenna-2:before {
  content: "\eb10";
}

.v3b-Parabolic-Antenna-3:before {
  content: "\eb11";
}

.v3b-Passport:before {
  content: "\eb12";
}

.v3b-Pay-Box:before {
  content: "\eb13";
}

.v3b-Pay-Per-Click-2:before {
  content: "\eb14";
}

.v3b-Pay-Per-Click:before {
  content: "\eb15";
}

.v3b-Paying-Database:before {
  content: "\eb16";
}

.v3b-Paying-Server:before {
  content: "\eb17";
}

.v3b-Pen:before {
  content: "\eb18";
}

.v3b-Perfume-1:before {
  content: "\eb19";
}

.v3b-Perfume-2:before {
  content: "\eb1a";
}

.v3b-Perfume:before {
  content: "\eb1b";
}

.v3b-Philtre-1:before {
  content: "\eb1c";
}

.v3b-Philtre-2:before {
  content: "\eb1d";
}

.v3b-Phone-Book:before {
  content: "\eb1e";
}

.v3b-Phone-Camera:before {
  content: "\eb1f";
}

.v3b-Phone-Cloudy:before {
  content: "\eb20";
}

.v3b-Phone-Fingerprint:before {
  content: "\eb21";
}

.v3b-Phone-Flash:before {
  content: "\eb22";
}

.v3b-Phone-Game:before {
  content: "\eb23";
}

.v3b-Phone-Lock:before {
  content: "\eb24";
}

.v3b-Phone-Loudsoeaker:before {
  content: "\eb25";
}

.v3b-Phone-Mute:before {
  content: "\eb26";
}

.v3b-Phone-Note-Book:before {
  content: "\eb27";
}

.v3b-Phone-Oculus:before {
  content: "\eb28";
}

.v3b-Phone-Pay:before {
  content: "\eb29";
}

.v3b-Phone-Stylus:before {
  content: "\eb2a";
}

.v3b-Phone-Sunny:before {
  content: "\eb2b";
}

.v3b-Phone-Touch:before {
  content: "\eb2c";
}

.v3b-Phone-Volume:before {
  content: "\eb2d";
}

.v3b-Photo:before {
  content: "\eb2e";
}

.v3b-Picture-Booklet:before {
  content: "\eb2f";
}

.v3b-Pisces-1:before {
  content: "\eb30";
}

.v3b-Pisces-2:before {
  content: "\eb31";
}

.v3b-Pit-Droid:before {
  content: "\eb32";
}

.v3b-Planet-Concquest:before {
  content: "\eb33";
}

.v3b-Planet-Orbit:before {
  content: "\eb34";
}

.v3b-Planet-Stars:before {
  content: "\eb35";
}

.v3b-Planets:before {
  content: "\eb36";
}

.v3b-Pocket-Watch:before {
  content: "\eb37";
}

.v3b-Potty:before {
  content: "\eb38";
}

.v3b-Pram-1:before {
  content: "\eb39";
}

.v3b-Pram-2:before {
  content: "\eb3a";
}

.v3b-Presentation-1:before {
  content: "\eb3b";
}

.v3b-Presentation-2:before {
  content: "\eb3c";
}

.v3b-Print-Screen:before {
  content: "\eb3d";
}

.v3b-Protractor:before {
  content: "\eb3e";
}

.v3b-Puzzle:before {
  content: "\eb3f";
}

.v3b-R2-D2:before {
  content: "\eb40";
}

.v3b-RAM:before {
  content: "\eb41";
}

.v3b-Rattle-1:before {
  content: "\eb42";
}

.v3b-Rattle-2:before {
  content: "\eb43";
}

.v3b-Ray-Speeder-Bike:before {
  content: "\eb44";
}

.v3b-Rebel-Alliance:before {
  content: "\eb45";
}

.v3b-Rebel-Pilot:before {
  content: "\eb46";
}

.v3b-Recycle:before {
  content: "\eb47";
}

.v3b-Reduce:before {
  content: "\eb48";
}

.v3b-Refresh-copy:before {
  content: "\eb49";
}

.v3b-Refresh-Box:before {
  content: "\eb4a";
}

.v3b-Refresh-Database:before {
  content: "\eb4b";
}

.v3b-Refresh-Drive:before {
  content: "\eb4c";
}

.v3b-Refresh-Key-Tag:before {
  content: "\eb4d";
}

.v3b-Refresh-Server:before {
  content: "\eb4e";
}

.v3b-Refresh:before {
  content: "\eb4f";
}

.v3b-Remov-Key-Tag:before {
  content: "\eb50";
}

.v3b-Remove-Book:before {
  content: "\eb51";
}

.v3b-Remove-Box:before {
  content: "\eb52";
}

.v3b-Remove-Database:before {
  content: "\eb53";
}

.v3b-Remove-Delivery:before {
  content: "\eb54";
}

.v3b-Remove-Drive:before {
  content: "\eb55";
}

.v3b-Remove-Seo-Tag:before {
  content: "\eb56";
}

.v3b-Remove-Server:before {
  content: "\eb57";
}

.v3b-Replay-2:before {
  content: "\eb58";
}

.v3b-Replay-3:before {
  content: "\eb59";
}

.v3b-Replay:before {
  content: "\eb5a";
}

.v3b-Retro-Camera-1:before {
  content: "\eb5b";
}

.v3b-Retro-Camera-2:before {
  content: "\eb5c";
}

.v3b-Retro-Clock:before {
  content: "\eb5d";
}

.v3b-Retro-Iron:before {
  content: "\eb5e";
}

.v3b-Retro-Mannequin:before {
  content: "\eb5f";
}

.v3b-Retro-Pipe:before {
  content: "\eb60";
}

.v3b-Retro-Radio-1:before {
  content: "\eb61";
}

.v3b-Retro-Radio-2:before {
  content: "\eb62";
}

.v3b-Retro-Radio-3:before {
  content: "\eb63";
}

.v3b-Retro-Telephone-1:before {
  content: "\eb64";
}

.v3b-Retro-Telephone-2:before {
  content: "\eb65";
}

.v3b-Retro-Telephone-3:before {
  content: "\eb66";
}

.v3b-Retro-TV-1:before {
  content: "\eb67";
}

.v3b-Retro-TV-2:before {
  content: "\eb68";
}

.v3b-Retro-TV-3:before {
  content: "\eb69";
}

.v3b-Rey:before {
  content: "\eb6a";
}

.v3b-Rocket-Backpack:before {
  content: "\eb6b";
}

.v3b-Rocket-Boost:before {
  content: "\eb6c";
}

.v3b-Rocket-Booster:before {
  content: "\eb6d";
}

.v3b-Rocket-Boosters:before {
  content: "\eb6e";
}

.v3b-Rocket-Launch:before {
  content: "\eb6f";
}

.v3b-Rocket-Station:before {
  content: "\eb70";
}

.v3b-Rocket:before {
  content: "\eb71";
}

.v3b-Romantic-Dinner-1:before {
  content: "\eb72";
}

.v3b-Romantic-Dinner-2:before {
  content: "\eb73";
}

.v3b-Romantic-Music:before {
  content: "\eb74";
}

.v3b-Rotate-1:before {
  content: "\eb75";
}

.v3b-Rotate-2:before {
  content: "\eb76";
}

.v3b-Rotate-3:before {
  content: "\eb77";
}

.v3b-Rotate-4:before {
  content: "\eb78";
}

.v3b-Rotation-1:before {
  content: "\eb79";
}

.v3b-Rotation-2:before {
  content: "\eb7a";
}

.v3b-Rouge:before {
  content: "\eb7b";
}

.v3b-Royal-Guard:before {
  content: "\eb7c";
}

.v3b-Roze:before {
  content: "\eb7d";
}

.v3b-Rubber-Duck:before {
  content: "\eb7e";
}

.v3b-Sagittarius-1:before {
  content: "\eb7f";
}

.v3b-Sagittarius-2:before {
  content: "\eb80";
}

.v3b-Satellite-1:before {
  content: "\eb81";
}

.v3b-Satellite-2:before {
  content: "\eb82";
}

.v3b-Saturn:before {
  content: "\eb83";
}

.v3b-Scale:before {
  content: "\eb84";
}

.v3b-Scales:before {
  content: "\eb85";
}

.v3b-Scented-Candle-2:before {
  content: "\eb86";
}

.v3b-Scented-Candle:before {
  content: "\eb87";
}

.v3b-School-Bag:before {
  content: "\eb88";
}

.v3b-School-Bell-2:before {
  content: "\eb89";
}

.v3b-School-Bell:before {
  content: "\eb8a";
}

.v3b-School-Board-1:before {
  content: "\eb8b";
}

.v3b-School-Board-2:before {
  content: "\eb8c";
}

.v3b-School-Board-4:before {
  content: "\eb8d";
}

.v3b-School-Bus:before {
  content: "\eb8e";
}

.v3b-School-Clock:before {
  content: "\eb8f";
}

.v3b-Scorpio-1:before {
  content: "\eb90";
}

.v3b-Scorpio-2:before {
  content: "\eb91";
}

.v3b-Scout-Trooper:before {
  content: "\eb92";
}

.v3b-Scroll-Lock:before {
  content: "\eb93";
}

.v3b-SD-Card:before {
  content: "\eb94";
}

.v3b-Search-Book:before {
  content: "\eb95";
}

.v3b-Search-Box:before {
  content: "\eb96";
}

.v3b-Search-Calendar:before {
  content: "\eb97";
}

.v3b-Search-Computer:before {
  content: "\eb98";
}

.v3b-Search-Cookie:before {
  content: "\eb99";
}

.v3b-Search-Database:before {
  content: "\eb9a";
}

.v3b-Search-Deliery:before {
  content: "\eb9b";
}

.v3b-Search-Ekg:before {
  content: "\eb9c";
}

.v3b-Search-Flash:before {
  content: "\eb9d";
}

.v3b-Search-Key:before {
  content: "\eb9e";
}

.v3b-Search-Lightbulb:before {
  content: "\eb9f";
}

.v3b-Search-Loudspeaker:before {
  content: "\eba0";
}

.v3b-Search-Love:before {
  content: "\eba1";
}

.v3b-Search-Minus:before {
  content: "\eba2";
}

.v3b-Search-Place-2:before {
  content: "\eba3";
}

.v3b-Search-Place:before {
  content: "\eba4";
}

.v3b-Search-Plus:before {
  content: "\eba5";
}

.v3b-Search-Rook:before {
  content: "\eba6";
}

.v3b-Search-Seo:before {
  content: "\eba7";
}

.v3b-Search-Server:before {
  content: "\eba8";
}

.v3b-Search-Statistic-2:before {
  content: "\eba9";
}

.v3b-Search-Statistic-3:before {
  content: "\ebaa";
}

.v3b-Search-Statistic:before {
  content: "\ebab";
}

.v3b-Search-Target:before {
  content: "\ebac";
}

.v3b-Search:before {
  content: "\ebad";
}

.v3b-Seo-Bag:before {
  content: "\ebae";
}

.v3b-Seo-Box:before {
  content: "\ebaf";
}

.v3b-Seo-Computer:before {
  content: "\ebb0";
}

.v3b-Seo-Defense:before {
  content: "\ebb1";
}

.v3b-Seo-Shop:before {
  content: "\ebb2";
}

.v3b-Seo-Shopping-Basket:before {
  content: "\ebb3";
}

.v3b-Seo-Shopping-Cart:before {
  content: "\ebb4";
}

.v3b-Seo-Tag-Answer:before {
  content: "\ebb5";
}

.v3b-Seo-Tag-Done:before {
  content: "\ebb6";
}

.v3b-Seo-Tag-Down:before {
  content: "\ebb7";
}

.v3b-Seo-Tag-Place:before {
  content: "\ebb8";
}

.v3b-Seo-Tag-Question:before {
  content: "\ebb9";
}

.v3b-Seo-Tag-Refresh:before {
  content: "\ebba";
}

.v3b-Seo-Tag-Settings:before {
  content: "\ebbb";
}

.v3b-Seo-Tag-Time:before {
  content: "\ebbc";
}

.v3b-Seo-Tag-Up:before {
  content: "\ebbd";
}

.v3b-Seo-Tag:before {
  content: "\ebbe";
}

.v3b-Seo-Webpage:before {
  content: "\ebbf";
}

.v3b-Separatists:before {
  content: "\ebc0";
}

.v3b-Serer-Connections:before {
  content: "\ebc1";
}

.v3b-Server-1:before {
  content: "\ebc2";
}

.v3b-Server-2:before {
  content: "\ebc3";
}

.v3b-Server-3:before {
  content: "\ebc4";
}

.v3b-Server-4:before {
  content: "\ebc5";
}

.v3b-Server-5:before {
  content: "\ebc6";
}

.v3b-Server-At:before {
  content: "\ebc7";
}

.v3b-Server-Database-Sync:before {
  content: "\ebc8";
}

.v3b-Server-Database:before {
  content: "\ebc9";
}

.v3b-Server-Done:before {
  content: "\ebca";
}

.v3b-Server-Down:before {
  content: "\ebcb";
}

.v3b-Server-Download:before {
  content: "\ebcc";
}

.v3b-Server-Ethernet:before {
  content: "\ebcd";
}

.v3b-Server-Firewall:before {
  content: "\ebce";
}

.v3b-Server-Folder-Sync:before {
  content: "\ebcf";
}

.v3b-Server-Hand:before {
  content: "\ebd0";
}

.v3b-Server-Heart:before {
  content: "\ebd1";
}

.v3b-Server-Home:before {
  content: "\ebd2";
}

.v3b-Server-Key:before {
  content: "\ebd3";
}

.v3b-Server-Password:before {
  content: "\ebd4";
}

.v3b-Server-Percentage:before {
  content: "\ebd5";
}

.v3b-Server-Place-1:before {
  content: "\ebd6";
}

.v3b-Server-Place-2:before {
  content: "\ebd7";
}

.v3b-Server-Preferences:before {
  content: "\ebd8";
}

.v3b-Server-Question:before {
  content: "\ebd9";
}

.v3b-Server-Settings:before {
  content: "\ebda";
}

.v3b-Server-Shopping:before {
  content: "\ebdb";
}

.v3b-Server-Sleep:before {
  content: "\ebdc";
}

.v3b-Server-Space:before {
  content: "\ebdd";
}

.v3b-Server-Statistics:before {
  content: "\ebde";
}

.v3b-Server-Sync:before {
  content: "\ebdf";
}

.v3b-Server-Syncronize:before {
  content: "\ebe0";
}

.v3b-Server-Up:before {
  content: "\ebe1";
}

.v3b-Server-Upload:before {
  content: "\ebe2";
}

.v3b-Server-User-Sync:before {
  content: "\ebe3";
}

.v3b-Server-Warning:before {
  content: "\ebe4";
}

.v3b-Settings-Booklet:before {
  content: "\ebe5";
}

.v3b-Settings:before {
  content: "\ebe6";
}

.v3b-Sewing-Machine:before {
  content: "\ebe7";
}

.v3b-Share-Database:before {
  content: "\ebe8";
}

.v3b-Share-Server:before {
  content: "\ebe9";
}

.v3b-Shift-1:before {
  content: "\ebea";
}

.v3b-Shift-2:before {
  content: "\ebeb";
}

.v3b-Ship:before {
  content: "\ebec";
}

.v3b-Showerheads-01:before {
  content: "\ebed";
}

.v3b-Shuffle-2:before {
  content: "\ebee";
}

.v3b-Shuffle-3:before {
  content: "\ebef";
}

.v3b-Shuffle1:before {
  content: "\ebf0";
}

.v3b-Skin-Care-1:before {
  content: "\ebf1";
}

.v3b-Skin-Care-2:before {
  content: "\ebf2";
}

.v3b-Smart-Bracelet:before {
  content: "\ebf3";
}

.v3b-Smart-Devices:before {
  content: "\ebf4";
}

.v3b-Smart-Glasses:before {
  content: "\ebf5";
}

.v3b-Smart-TV-3D:before {
  content: "\ebf6";
}

.v3b-Smart-TV-HD:before {
  content: "\ebf7";
}

.v3b-Smart-TV-Wifi:before {
  content: "\ebf8";
}

.v3b-Smart-TV:before {
  content: "\ebf9";
}

.v3b-Smartphone-1:before {
  content: "\ebfa";
}

.v3b-Smartphone-2:before {
  content: "\ebfb";
}

.v3b-Smartphone-3:before {
  content: "\ebfc";
}

.v3b-Smartwatch-1:before {
  content: "\ebfd";
}

.v3b-Smartwatch-2:before {
  content: "\ebfe";
}

.v3b-Smartwatch-Alarm-1:before {
  content: "\ebff";
}

.v3b-Smartwatch-Alarm-2:before {
  content: "\ec00";
}

.v3b-Smartwatch-Call:before {
  content: "\ec01";
}

.v3b-Smartwatch-EKG-1:before {
  content: "\ec02";
}

.v3b-Smartwatch-EKG-2:before {
  content: "\ec03";
}

.v3b-Smartwatch-Fingerprint-1:before {
  content: "\ec04";
}

.v3b-Smartwatch-Fingerprint-2:before {
  content: "\ec05";
}

.v3b-Smartwatch-Message-1:before {
  content: "\ec06";
}

.v3b-Smartwatch-Message-2:before {
  content: "\ec07";
}

.v3b-Smartwatch-Mute-1:before {
  content: "\ec08";
}

.v3b-Smartwatch-Mute-2:before {
  content: "\ec09";
}

.v3b-Smartwatch-Picture-1:before {
  content: "\ec0a";
}

.v3b-Smartwatch-Picture-2:before {
  content: "\ec0b";
}

.v3b-Smartwatch-Place-1:before {
  content: "\ec0c";
}

.v3b-Smartwatch-Place-2:before {
  content: "\ec0d";
}

.v3b-Smartwatch-Reminder-1:before {
  content: "\ec0e";
}

.v3b-Smartwatch-Reminder-2:before {
  content: "\ec0f";
}

.v3b-Smartwatch-Speakers-1:before {
  content: "\ec10";
}

.v3b-Smartwatch-Speakers-2:before {
  content: "\ec11";
}

.v3b-Smartwatch-Touch:before {
  content: "\ec12";
}

.v3b-Smartwatch-Volume-1:before {
  content: "\ec13";
}

.v3b-Smartwatch-Volume-2:before {
  content: "\ec14";
}

.v3b-Smartwatch-Wifi-1:before {
  content: "\ec15";
}

.v3b-Smartwatch-Wifi-2:before {
  content: "\ec16";
}

.v3b-Snack:before {
  content: "\ec17";
}

.v3b-Soap:before {
  content: "\ec18";
}

.v3b-Solar-System:before {
  content: "\ec19";
}

.v3b-Spa-1:before {
  content: "\ec1a";
}

.v3b-Spa-2:before {
  content: "\ec1b";
}

.v3b-Spa-Oil-Glass-1:before {
  content: "\ec1c";
}

.v3b-Spa-oil-glass-2:before {
  content: "\ec1d";
}

.v3b-Spa-Oil-Glass-3:before {
  content: "\ec1e";
}

.v3b-Spa-Salon:before {
  content: "\ec1f";
}

.v3b-Spa-Sign-2:before {
  content: "\ec20";
}

.v3b-Spa-stones-01:before {
  content: "\ec21";
}

.v3b-Space-Shuttle:before {
  content: "\ec22";
}

.v3b-Space-Station:before {
  content: "\ec23";
}

.v3b-Spacecraft-1:before {
  content: "\ec24";
}

.v3b-Spacecraft-2:before {
  content: "\ec25";
}

.v3b-Spaceship-Cabin:before {
  content: "\ec26";
}

.v3b-Spaceship-Window:before {
  content: "\ec27";
}

.v3b-Spacesuit:before {
  content: "\ec28";
}

.v3b-Speakers-On:before {
  content: "\ec29";
}

.v3b-Spiral:before {
  content: "\ec2a";
}

.v3b-Square-Arrow:before {
  content: "\ec2b";
}

.v3b-SSD:before {
  content: "\ec2c";
}

.v3b-Star-destroyer:before {
  content: "\ec2d";
}

.v3b-Star-Trek-Ship:before {
  content: "\ec2e";
}

.v3b-Star-Trek:before {
  content: "\ec2f";
}

.v3b-Start-Menu:before {
  content: "\ec30";
}

.v3b-Steel-Flask:before {
  content: "\ec31";
}

.v3b-Storage:before {
  content: "\ec32";
}

.v3b-Stormtrooper-1:before {
  content: "\ec33";
}

.v3b-Stormtrooper-2:before {
  content: "\ec34";
}

.v3b-Street-Lamp-1:before {
  content: "\ec35";
}

.v3b-Street-Lamp-2:before {
  content: "\ec36";
}

.v3b-Street-Plate:before {
  content: "\ec37";
}

.v3b-Street-Sign-1:before {
  content: "\ec38";
}

.v3b-Syncronize-1:before {
  content: "\ec39";
}

.v3b-Tab-1:before {
  content: "\ec3a";
}

.v3b-Tab-2:before {
  content: "\ec3b";
}

.v3b-Tab-3:before {
  content: "\ec3c";
}

.v3b-Tablet-1:before {
  content: "\ec3d";
}

.v3b-Tablet-2:before {
  content: "\ec3e";
}

.v3b-Tablet-3:before {
  content: "\ec3f";
}

.v3b-Tablet-Camera:before {
  content: "\ec40";
}

.v3b-Taurus-1:before {
  content: "\ec41";
}

.v3b-Taurus-2:before {
  content: "\ec42";
}

.v3b-Teapot:before {
  content: "\ec43";
}

.v3b-Teddy-BEar-copy:before {
  content: "\ec44";
}

.v3b-Teddy-Bear:before {
  content: "\ec45";
}

.v3b-Telescope:before {
  content: "\ec46";
}

.v3b-Test:before {
  content: "\ec47";
}

.v3b-Tie-Fighter:before {
  content: "\ec48";
}

.v3b-Toddler:before {
  content: "\ec49";
}

.v3b-Towels-1:before {
  content: "\ec4a";
}

.v3b-Towels-2:before {
  content: "\ec4b";
}

.v3b-Towels-3:before {
  content: "\ec4c";
}

.v3b-Toy-Bucket:before {
  content: "\ec4d";
}

.v3b-Toy-Car:before {
  content: "\ec4e";
}

.v3b-Toy-Cube:before {
  content: "\ec4f";
}

.v3b-Toy-Train:before {
  content: "\ec50";
}

.v3b-Traffic-Conversion:before {
  content: "\ec51";
}

.v3b-Triangle-Ruler:before {
  content: "\ec52";
}

.v3b-Trolley-2:before {
  content: "\ec53";
}

.v3b-Trolley:before {
  content: "\ec54";
}

.v3b-Truck:before {
  content: "\ec55";
}

.v3b-Typewriter:before {
  content: "\ec56";
}

.v3b-UFO:before {
  content: "\ec57";
}

.v3b-Up:before {
  content: "\ec58";
}

.v3b-Upload:before {
  content: "\ec59";
}

.v3b-USB-2:before {
  content: "\ec5a";
}

.v3b-USB-3:before {
  content: "\ec5b";
}

.v3b-USB-Cable:before {
  content: "\ec5c";
}

.v3b-USB-Done:before {
  content: "\ec5d";
}

.v3b-USB-Error:before {
  content: "\ec5e";
}

.v3b-USB:before {
  content: "\ec5f";
}

.v3b-User-Database-Connect:before {
  content: "\ec60";
}

.v3b-User-Database:before {
  content: "\ec61";
}

.v3b-User-Server:before {
  content: "\ec62";
}

.v3b-Viddeo-Call-Phone:before {
  content: "\ec63";
}

.v3b-Video-Call-Computer:before {
  content: "\ec64";
}

.v3b-Video-Call-Notebook:before {
  content: "\ec65";
}

.v3b-Video-Call-Tablet:before {
  content: "\ec66";
}

.v3b-Video-Call:before {
  content: "\ec67";
}

.v3b-Video-Camera-1:before {
  content: "\ec68";
}

.v3b-Video-Camera-2:before {
  content: "\ec69";
}

.v3b-Video-Conference:before {
  content: "\ec6a";
}

.v3b-Videocard:before {
  content: "\ec6b";
}

.v3b-Vintage-Corset:before {
  content: "\ec6c";
}

.v3b-Vintage-Fan:before {
  content: "\ec6d";
}

.v3b-Vintage-Suitcase:before {
  content: "\ec6e";
}

.v3b-Virgo-1:before {
  content: "\ec6f";
}

.v3b-Virgo-2:before {
  content: "\ec70";
}

.v3b-Voice-Book:before {
  content: "\ec71";
}

.v3b-Volume-Down:before {
  content: "\ec72";
}

.v3b-Volume-Up:before {
  content: "\ec73";
}

.v3b-Walking-Stick:before {
  content: "\ec74";
}

.v3b-Warning-Computer:before {
  content: "\ec75";
}

.v3b-Warning-Database:before {
  content: "\ec76";
}

.v3b-Water-care01:before {
  content: "\ec77";
}

.v3b-Water-Pot:before {
  content: "\ec78";
}

.v3b-Wavy:before {
  content: "\ec79";
}

.v3b-Web-Development-2:before {
  content: "\ec7a";
}

.v3b-Web-Development:before {
  content: "\ec7b";
}

.v3b-Webcam-copy:before {
  content: "\ec7c";
}

.v3b-Webcam:before {
  content: "\ec7d";
}

.v3b-Windows-Drive:before {
  content: "\ec7e";
}

.v3b-Windows-Phone:before {
  content: "\ec7f";
}

.v3b-Windows-Tablet:before {
  content: "\ec80";
}

.v3b-Windows:before {
  content: "\ec81";
}

.v3b-Wine:before {
  content: "\ec82";
}

.v3b-Wire-Mouse:before {
  content: "\ec83";
}

.v3b-Wireless-Keyboard:before {
  content: "\ec84";
}

.v3b-Wireless-Mouse:before {
  content: "\ec85";
}

.v3b-Wireless-USB:before {
  content: "\ec86";
}

.v3b-Woman-Pram:before {
  content: "\ec87";
}

.v3b-X-34-Landspeeder:before {
  content: "\ec88";
}

.v3b-X-key:before {
  content: "\ec89";
}

.v3b-X-Wing:before {
  content: "\ec8a";
}

.v3b-Y-Wing:before {
  content: "\ec8b";
}

.v3b-Yin-Yang:before {
  content: "\ec8c";
}
