@font-face {
  font-family: 'v4';
  src: url('fonts/v4.woff?uf6sbu') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v4-"], [class*=" v4-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v4' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v4-D-Box:before {
  content: "\e900";
}

.v4-D-Cube:before {
  content: "\e901";
}

.v4-Accessible:before {
  content: "\e902";
}

.v4-Active-Protection:before {
  content: "\e903";
}

.v4-Add-Event:before {
  content: "\e904";
}

.v4-Add-Shopping-Cart:before {
  content: "\e905";
}

.v4-Add-User-2:before {
  content: "\e906";
}

.v4-Add-User:before {
  content: "\e907";
}

.v4-Add-Users:before {
  content: "\e908";
}

.v4-Add:before {
  content: "\e909";
}

.v4-Adjustments:before {
  content: "\e90a";
}

.v4-Airplane:before {
  content: "\e90b";
}

.v4-Alarm:before {
  content: "\e90c";
}

.v4-Alcohol-Drink:before {
  content: "\e90d";
}

.v4-Anchor:before {
  content: "\e90e";
}

.v4-Android:before {
  content: "\e90f";
}

.v4-Announcement:before {
  content: "\e910";
}

.v4-Anonymus-2:before {
  content: "\e911";
}

.v4-Anonymus:before {
  content: "\e912";
}

.v4-Answer-2:before {
  content: "\e913";
}

.v4-Answer-3:before {
  content: "\e914";
}

.v4-Answer:before {
  content: "\e915";
}

.v4-Apron:before {
  content: "\e916";
}

.v4-Archive-File:before {
  content: "\e917";
}

.v4-Archive:before {
  content: "\e918";
}

.v4-Armchair-1:before {
  content: "\e919";
}

.v4-Armchair-2:before {
  content: "\e91a";
}

.v4-Armchair-3:before {
  content: "\e91b";
}

.v4-Armchair-4:before {
  content: "\e91c";
}

.v4-Artifical-Intelligence-1:before {
  content: "\e91d";
}

.v4-Artifical-Intelligence-2:before {
  content: "\e91e";
}

.v4-Artist:before {
  content: "\e91f";
}

.v4-Assignment:before {
  content: "\e920";
}

.v4-Astronaut:before {
  content: "\e921";
}

.v4-At-Symbol-2:before {
  content: "\e922";
}

.v4-At-Symbol-3:before {
  content: "\e923";
}

.v4-At-Symbol:before {
  content: "\e924";
}

.v4-At:before {
  content: "\e925";
}

.v4-Atlas:before {
  content: "\e926";
}

.v4-Attach-2:before {
  content: "\e927";
}

.v4-Attach-3:before {
  content: "\e928";
}

.v4-Attach-User:before {
  content: "\e929";
}

.v4-Audio:before {
  content: "\e92a";
}

.v4-Avatar-Chef:before {
  content: "\e92b";
}

.v4-Back-Arrow:before {
  content: "\e92c";
}

.v4-Badminton:before {
  content: "\e92d";
}

.v4-Baguette:before {
  content: "\e92e";
}

.v4-Bald-Man:before {
  content: "\e92f";
}

.v4-Balloons-1:before {
  content: "\e930";
}

.v4-Balloons-2:before {
  content: "\e931";
}

.v4-Baloon:before {
  content: "\e932";
}

.v4-Banknote:before {
  content: "\e933";
}

.v4-Bar-Chart-2:before {
  content: "\e934";
}

.v4-Bar-Chart:before {
  content: "\e935";
}

.v4-Barbeque:before {
  content: "\e936";
}

.v4-Bat:before {
  content: "\e937";
}

.v4-Batman:before {
  content: "\e938";
}

.v4-Battery-Charge:before {
  content: "\e939";
}

.v4-Battery-Full:before {
  content: "\e93a";
}

.v4-Battery-Half:before {
  content: "\e93b";
}

.v4-Battery-Low:before {
  content: "\e93c";
}

.v4-Beach-Sign:before {
  content: "\e93d";
}

.v4-Bed-1:before {
  content: "\e93e";
}

.v4-Bed-2:before {
  content: "\e93f";
}

.v4-Bed-3:before {
  content: "\e940";
}

.v4-Bed-4:before {
  content: "\e941";
}

.v4-Bed-5:before {
  content: "\e942";
}

.v4-Beer-Glass:before {
  content: "\e943";
}

.v4-Beer:before {
  content: "\e944";
}

.v4-Bell-2:before {
  content: "\e945";
}

.v4-Bell-3:before {
  content: "\e946";
}

.v4-Bell-4:before {
  content: "\e947";
}

.v4-Bell-Alarm:before {
  content: "\e948";
}

.v4-Bell:before {
  content: "\e949";
}

.v4-Bender:before {
  content: "\e94a";
}

.v4-Bikini:before {
  content: "\e94b";
}

.v4-Bill-2:before {
  content: "\e94c";
}

.v4-Bill:before {
  content: "\e94d";
}

.v4-Birthay-Hat-2:before {
  content: "\e94e";
}

.v4-Birthday-1:before {
  content: "\e94f";
}

.v4-Birthday-2:before {
  content: "\e950";
}

.v4-Birthday-Cake-1:before {
  content: "\e951";
}

.v4-Birthday-Cake-2:before {
  content: "\e952";
}

.v4-Birthday-Card:before {
  content: "\e953";
}

.v4-Birthday-Decoration-1:before {
  content: "\e954";
}

.v4-Birthday-Decoration-2:before {
  content: "\e955";
}

.v4-Birthday-Decoration-3:before {
  content: "\e956";
}

.v4-Birthday-Greetings-1:before {
  content: "\e957";
}

.v4-Birthday-Hat3:before {
  content: "\e958";
}

.v4-Birthday-Invitation:before {
  content: "\e959";
}

.v4-Birthday-Message-1:before {
  content: "\e95a";
}

.v4-Birthday-Message-2:before {
  content: "\e95b";
}

.v4-Birthday-Party:before {
  content: "\e95c";
}

.v4-Birthday-Picture:before {
  content: "\e95d";
}

.v4-Birthday-Song:before {
  content: "\e95e";
}

.v4-Birthyday-Hat-1:before {
  content: "\e95f";
}

.v4-Bitcoin-2:before {
  content: "\e960";
}

.v4-Bitcoin-3:before {
  content: "\e961";
}

.v4-Bitcoin:before {
  content: "\e962";
}

.v4-Black-Cat:before {
  content: "\e963";
}

.v4-Blende:before {
  content: "\e964";
}

.v4-Blender:before {
  content: "\e965";
}

.v4-Bllboy:before {
  content: "\e966";
}

.v4-Bloody-Axe:before {
  content: "\e967";
}

.v4-Bloody-Knife:before {
  content: "\e968";
}

.v4-Blueprint:before {
  content: "\e969";
}

.v4-Bluetooth:before {
  content: "\e96a";
}

.v4-Boat:before {
  content: "\e96b";
}

.v4-Bone-Hand:before {
  content: "\e96c";
}

.v4-Bones:before {
  content: "\e96d";
}

.v4-Book:before {
  content: "\e96e";
}

.v4-Bookmarrk:before {
  content: "\e96f";
}

.v4-Bottle-Opener:before {
  content: "\e970";
}

.v4-Bow-Tie:before {
  content: "\e971";
}

.v4-Box:before {
  content: "\e972";
}

.v4-Brake-Link:before {
  content: "\e973";
}

.v4-Bread-and-wine:before {
  content: "\e974";
}

.v4-Bread-Knife:before {
  content: "\e975";
}

.v4-Bricks:before {
  content: "\e976";
}

.v4-Bride:before {
  content: "\e977";
}

.v4-Briefcase:before {
  content: "\e978";
}

.v4-Bring-Down-2:before {
  content: "\e979";
}

.v4-Bring-Down-3:before {
  content: "\e97a";
}

.v4-Bring-Down:before {
  content: "\e97b";
}

.v4-Bring-Up-2:before {
  content: "\e97c";
}

.v4-Bring-Up-3:before {
  content: "\e97d";
}

.v4-Bring-Up:before {
  content: "\e97e";
}

.v4-Brush:before {
  content: "\e97f";
}

.v4-Bucket:before {
  content: "\e980";
}

.v4-Bug:before {
  content: "\e981";
}

.v4-Building-Construction:before {
  content: "\e982";
}

.v4-Building:before {
  content: "\e983";
}

.v4-Bunk-Beds:before {
  content: "\e984";
}

.v4-Bunny-2:before {
  content: "\e985";
}

.v4-Bunny:before {
  content: "\e986";
}

.v4-Business-Man-1:before {
  content: "\e987";
}

.v4-Business-Man-2:before {
  content: "\e988";
}

.v4-Business-Woman-1:before {
  content: "\e989";
}

.v4-Business-Woman-2:before {
  content: "\e98a";
}

.v4-Cad-1:before {
  content: "\e98b";
}

.v4-Cad-2:before {
  content: "\e98c";
}

.v4-Cad-3:before {
  content: "\e98d";
}

.v4-Cad-Compasses:before {
  content: "\e98e";
}

.v4-Cad-File:before {
  content: "\e98f";
}

.v4-Cad-Paper:before {
  content: "\e990";
}

.v4-Cad-Papers:before {
  content: "\e991";
}

.v4-Cad-Pencil:before {
  content: "\e992";
}

.v4-Cake-Knife:before {
  content: "\e993";
}

.v4-Cake-Piece:before {
  content: "\e994";
}

.v4-Cake-Slice:before {
  content: "\e995";
}

.v4-Cake:before {
  content: "\e996";
}

.v4-Calculator:before {
  content: "\e997";
}

.v4-Calendar:before {
  content: "\e998";
}

.v4-Callendar:before {
  content: "\e999";
}

.v4-Camera-1:before {
  content: "\e99a";
}

.v4-Camera-2:before {
  content: "\e99b";
}

.v4-Candle-Light:before {
  content: "\e99c";
}

.v4-Candle:before {
  content: "\e99d";
}

.v4-Candy-Holder:before {
  content: "\e99e";
}

.v4-Candy:before {
  content: "\e99f";
}

.v4-Canlestick:before {
  content: "\e9a0";
}

.v4-Captain-America:before {
  content: "\e9a1";
}

.v4-Captain:before {
  content: "\e9a2";
}

.v4-Captin-Hat:before {
  content: "\e9a3";
}

.v4-Car-2:before {
  content: "\e9a4";
}

.v4-Car-3:before {
  content: "\e9a5";
}

.v4-Car:before {
  content: "\e9a6";
}

.v4-Castle:before {
  content: "\e9a7";
}

.v4-Cauldron:before {
  content: "\e9a8";
}

.v4-Celebration-Glasses:before {
  content: "\e9a9";
}

.v4-Cemetery-1:before {
  content: "\e9aa";
}

.v4-Cemetery-2:before {
  content: "\e9ab";
}

.v4-Cezve:before {
  content: "\e9ac";
}

.v4-Champagne-in-ice-bucket:before {
  content: "\e9ad";
}

.v4-Champagne-Open:before {
  content: "\e9ae";
}

.v4-Champagne:before {
  content: "\e9af";
}

.v4-Charlie-Chaplin:before {
  content: "\e9b0";
}

.v4-Check-1:before {
  content: "\e9b1";
}

.v4-Check-2:before {
  content: "\e9b2";
}

.v4-Chef:before {
  content: "\e9b3";
}

.v4-Chef:before {
  content: "\e9b4";
}

.v4-Chef:before {
  content: "\e9b5";
}

.v4-Chicken-2:before {
  content: "\e9b6";
}

.v4-Chicken-3:before {
  content: "\e9b7";
}

.v4-Chicken:before {
  content: "\e9b8";
}

.v4-Chilled-Champagne:before {
  content: "\e9b9";
}

.v4-Chopsticks:before {
  content: "\e9ba";
}

.v4-Church-Bells:before {
  content: "\e9bb";
}

.v4-Church:before {
  content: "\e9bc";
}

.v4-Claptrap:before {
  content: "\e9bd";
}

.v4-Clock:before {
  content: "\e9be";
}

.v4-Close:before {
  content: "\e9bf";
}

.v4-Cloud-2:before {
  content: "\e9c0";
}

.v4-Cloud-3:before {
  content: "\e9c1";
}

.v4-Cloud-Off:before {
  content: "\e9c2";
}

.v4-Cloud:before {
  content: "\e9c3";
}

.v4-Clown-2:before {
  content: "\e9c4";
}

.v4-Clown:before {
  content: "\e9c5";
}

.v4-Cobweb-1:before {
  content: "\e9c6";
}

.v4-Cobweb-2:before {
  content: "\e9c7";
}

.v4-Cocktail:before {
  content: "\e9c8";
}

.v4-Coding:before {
  content: "\e9c9";
}

.v4-Coffee-Maker:before {
  content: "\e9ca";
}

.v4-Coffin:before {
  content: "\e9cb";
}

.v4-Cold:before {
  content: "\e9cc";
}

.v4-Collaboration:before {
  content: "\e9cd";
}

.v4-Compass:before {
  content: "\e9ce";
}

.v4-Computer:before {
  content: "\e9cf";
}

.v4-Confetti-1:before {
  content: "\e9d0";
}

.v4-Confetti-2:before {
  content: "\e9d1";
}

.v4-Connection:before {
  content: "\e9d2";
}

.v4-Construction-Plan:before {
  content: "\e9d3";
}

.v4-Cookbook:before {
  content: "\e9d4";
}

.v4-Cooker-Hood:before {
  content: "\e9d5";
}

.v4-Cooker:before {
  content: "\e9d6";
}

.v4-Cooler:before {
  content: "\e9d7";
}

.v4-Copyright-2:before {
  content: "\e9d8";
}

.v4-Copyright-3:before {
  content: "\e9d9";
}

.v4-Copyright:before {
  content: "\e9da";
}

.v4-Cord:before {
  content: "\e9db";
}

.v4-Corkscrew:before {
  content: "\e9dc";
}

.v4-Corn-Candy:before {
  content: "\e9dd";
}

.v4-Corner-Tub:before {
  content: "\e9de";
}

.v4-Course:before {
  content: "\e9df";
}

.v4-Cowboy:before {
  content: "\e9e0";
}

.v4-Creative-Commun-2:before {
  content: "\e9e1";
}

.v4-Creative-Commun-3:before {
  content: "\e9e2";
}

.v4-Creative-Commun:before {
  content: "\e9e3";
}

.v4-Credit-Card:before {
  content: "\e9e4";
}

.v4-Crescent:before {
  content: "\e9e5";
}

.v4-Criminal:before {
  content: "\e9e6";
}

.v4-Crop:before {
  content: "\e9e7";
}

.v4-Crow:before {
  content: "\e9e8";
}

.v4-Cup:before {
  content: "\e9e9";
}

.v4-Cupcake:before {
  content: "\e9ea";
}

.v4-Cut:before {
  content: "\e9eb";
}

.v4-Cutlery-Decoration:before {
  content: "\e9ec";
}

.v4-Cutlery-Set:before {
  content: "\e9ed";
}

.v4-Cutlery:before {
  content: "\e9ee";
}

.v4-Cutting-Board-1:before {
  content: "\e9ef";
}

.v4-Cutting-Board-2:before {
  content: "\e9f0";
}

.v4-Daffodil:before {
  content: "\e9f1";
}

.v4-Darth-Vader:before {
  content: "\e9f2";
}

.v4-Dashed-Frame-1:before {
  content: "\e9f3";
}

.v4-Dashed-Frame-2:before {
  content: "\e9f4";
}

.v4-Dashed-Frame-3:before {
  content: "\e9f5";
}

.v4-Dashed-Frame-4:before {
  content: "\e9f6";
}

.v4-Dashed-Frame-5:before {
  content: "\e9f7";
}

.v4-Dashed-Frame-6:before {
  content: "\e9f8";
}

.v4-Dashed-Frame-7:before {
  content: "\e9f9";
}

.v4-Dashed-Frame-8:before {
  content: "\e9fa";
}

.v4-Dashed-Frame-9:before {
  content: "\e9fb";
}

.v4-Dashed-Frame-10:before {
  content: "\e9fc";
}

.v4-Dashed-Frame-11:before {
  content: "\e9fd";
}

.v4-Dashed-Frame-12:before {
  content: "\e9fe";
}

.v4-Dashed-Frame-13:before {
  content: "\e9ff";
}

.v4-Dashed-Frame-14:before {
  content: "\ea00";
}

.v4-Dashed-Frame-15:before {
  content: "\ea01";
}

.v4-Dashed-Frame-16:before {
  content: "\ea02";
}

.v4-Dashed-Frame-17:before {
  content: "\ea03";
}

.v4-Dashed-Frame-18:before {
  content: "\ea04";
}

.v4-Dashed-Frame-19:before {
  content: "\ea05";
}

.v4-Dashed-Frame-20:before {
  content: "\ea06";
}

.v4-Dashed-Frame-21:before {
  content: "\ea07";
}

.v4-Dashed-Frame-22:before {
  content: "\ea08";
}

.v4-Dashed-Frame-23:before {
  content: "\ea09";
}

.v4-Dashed-Frame-24:before {
  content: "\ea0a";
}

.v4-Dashed-Frame-25:before {
  content: "\ea0b";
}

.v4-Dashed-Frame-26:before {
  content: "\ea0c";
}

.v4-Dashed-Frame-27:before {
  content: "\ea0d";
}

.v4-Dashed-Frame-28:before {
  content: "\ea0e";
}

.v4-Dashed-Frame-29:before {
  content: "\ea0f";
}

.v4-Dashed-Frame-30:before {
  content: "\ea10";
}

.v4-Dashed-Frame-31:before {
  content: "\ea11";
}

.v4-Dashed-Frame-32:before {
  content: "\ea12";
}

.v4-Dashed-Frame-33:before {
  content: "\ea13";
}

.v4-Dashed-Frame-34:before {
  content: "\ea14";
}

.v4-Dashed-Frame-35:before {
  content: "\ea15";
}

.v4-Database-2:before {
  content: "\ea16";
}

.v4-Database-3:before {
  content: "\ea17";
}

.v4-Database:before {
  content: "\ea18";
}

.v4-Deactivate-Protection:before {
  content: "\ea19";
}

.v4-Deckchair:before {
  content: "\ea1a";
}

.v4-Delete-User-2:before {
  content: "\ea1b";
}

.v4-Delete-User:before {
  content: "\ea1c";
}

.v4-Delete-Users:before {
  content: "\ea1d";
}

.v4-Design-Mug:before {
  content: "\ea1e";
}

.v4-Design:before {
  content: "\ea1f";
}

.v4-Desk-1:before {
  content: "\ea20";
}

.v4-Desk-2:before {
  content: "\ea21";
}

.v4-Desk-3:before {
  content: "\ea22";
}

.v4-Dessert:before {
  content: "\ea23";
}

.v4-Devil:before {
  content: "\ea24";
}

.v4-Diamond:before {
  content: "\ea25";
}

.v4-Dice:before {
  content: "\ea26";
}

.v4-Dictator:before {
  content: "\ea27";
}

.v4-Disabled-User-2:before {
  content: "\ea28";
}

.v4-Disabled-User:before {
  content: "\ea29";
}

.v4-Disabled-Users:before {
  content: "\ea2a";
}

.v4-Disc:before {
  content: "\ea2b";
}

.v4-Disco-Ball:before {
  content: "\ea2c";
}

.v4-Dismiss-User-2:before {
  content: "\ea2d";
}

.v4-Dismiss-User:before {
  content: "\ea2e";
}

.v4-Dismiss-Users:before {
  content: "\ea2f";
}

.v4-Dispatcher-1:before {
  content: "\ea30";
}

.v4-Dispatcher-2:before {
  content: "\ea31";
}

.v4-Diver:before {
  content: "\ea32";
}

.v4-Diving-Mask:before {
  content: "\ea33";
}

.v4-DJ:before {
  content: "\ea34";
}

.v4-Doctor:before {
  content: "\ea35";
}

.v4-Dollar-2:before {
  content: "\ea36";
}

.v4-Dollar-3:before {
  content: "\ea37";
}

.v4-Dollar:before {
  content: "\ea38";
}

.v4-Dolphin:before {
  content: "\ea39";
}

.v4-Done-2:before {
  content: "\ea3a";
}

.v4-Done-3:before {
  content: "\ea3b";
}

.v4-Done:before {
  content: "\ea3c";
}

.v4-Door:before {
  content: "\ea3d";
}

.v4-Download-2:before {
  content: "\ea3e";
}

.v4-Download-3:before {
  content: "\ea3f";
}

.v4-Download-Cloud:before {
  content: "\ea40";
}

.v4-Download:before {
  content: "\ea41";
}

.v4-Dracula:before {
  content: "\ea42";
}

.v4-Drive:before {
  content: "\ea43";
}

.v4-Earth:before {
  content: "\ea44";
}

.v4-Easter-basket:before {
  content: "\ea45";
}

.v4-Easter-celebration:before {
  content: "\ea46";
}

.v4-Easter-Egg-1:before {
  content: "\ea47";
}

.v4-Easter-Egg-2:before {
  content: "\ea48";
}

.v4-Easter-Egg-3:before {
  content: "\ea49";
}

.v4-Easter-message:before {
  content: "\ea4a";
}

.v4-Easter-willow:before {
  content: "\ea4b";
}

.v4-Eat-in-Kitchen:before {
  content: "\ea4c";
}

.v4-Eco-Friendly-2:before {
  content: "\ea4d";
}

.v4-Eco-Friendly-3:before {
  content: "\ea4e";
}

.v4-Eco-Friendly:before {
  content: "\ea4f";
}

.v4-ED-209:before {
  content: "\ea50";
}

.v4-Egg-Beater:before {
  content: "\ea51";
}

.v4-Egg-painting:before {
  content: "\ea52";
}

.v4-Egg-Timer:before {
  content: "\ea53";
}

.v4-Egg:before {
  content: "\ea54";
}

.v4-Eggs-1:before {
  content: "\ea55";
}

.v4-Eggs-2:before {
  content: "\ea56";
}

.v4-Eject:before {
  content: "\ea57";
}

.v4-Engagement-1:before {
  content: "\ea58";
}

.v4-Engagement-2:before {
  content: "\ea59";
}

.v4-Equalizer:before {
  content: "\ea5a";
}

.v4-Error-Mark:before {
  content: "\ea5b";
}

.v4-Error:before {
  content: "\ea5c";
}

.v4-Eudder:before {
  content: "\ea5d";
}

.v4-Euro-2:before {
  content: "\ea5e";
}

.v4-Euro-3:before {
  content: "\ea5f";
}

.v4-Euro:before {
  content: "\ea60";
}

.v4-Eva:before {
  content: "\ea61";
}

.v4-Event-2:before {
  content: "\ea62";
}

.v4-Event-Reminder:before {
  content: "\ea63";
}

.v4-Event-Ticket:before {
  content: "\ea64";
}

.v4-Event:before {
  content: "\ea65";
}

.v4-Eye:before {
  content: "\ea66";
}

.v4-Eyeball:before {
  content: "\ea67";
}

.v4-Farmer:before {
  content: "\ea68";
}

.v4-Feather-Pen:before {
  content: "\ea69";
}

.v4-Female:before {
  content: "\ea6a";
}

.v4-File-1:before {
  content: "\ea6b";
}

.v4-File-2:before {
  content: "\ea6c";
}

.v4-Filleting-Knife:before {
  content: "\ea6d";
}

.v4-Fingerprint:before {
  content: "\ea6e";
}

.v4-Firefigyhter:before {
  content: "\ea6f";
}

.v4-Firework-1:before {
  content: "\ea70";
}

.v4-Firework-2:before {
  content: "\ea71";
}

.v4-Fish:before {
  content: "\ea72";
}

.v4-Flag:before {
  content: "\ea73";
}

.v4-Flash:before {
  content: "\ea74";
}

.v4-Flashlight:before {
  content: "\ea75";
}

.v4-Flip-Flops:before {
  content: "\ea76";
}

.v4-Float:before {
  content: "\ea77";
}

.v4-Flowchart:before {
  content: "\ea78";
}

.v4-Flowers:before {
  content: "\ea79";
}

.v4-Folder:before {
  content: "\ea7a";
}

.v4-Food-Photo:before {
  content: "\ea7b";
}

.v4-Fork:before {
  content: "\ea7c";
}

.v4-Format-Size:before {
  content: "\ea7d";
}

.v4-Frame-1:before {
  content: "\ea7e";
}

.v4-Frame-2:before {
  content: "\ea7f";
}

.v4-Frame-3:before {
  content: "\ea80";
}

.v4-Frame-4:before {
  content: "\ea81";
}

.v4-Frame-5:before {
  content: "\ea82";
}

.v4-Frame-6:before {
  content: "\ea83";
}

.v4-Frame-7:before {
  content: "\ea84";
}

.v4-Frame-8:before {
  content: "\ea85";
}

.v4-Frame-9:before {
  content: "\ea86";
}

.v4-Frame-10:before {
  content: "\ea87";
}

.v4-Frame-11:before {
  content: "\ea88";
}

.v4-Frame-12:before {
  content: "\ea89";
}

.v4-Frame-13:before {
  content: "\ea8a";
}

.v4-Frame-14:before {
  content: "\ea8b";
}

.v4-Frame-15:before {
  content: "\ea8c";
}

.v4-Frame-16:before {
  content: "\ea8d";
}

.v4-Frame-17:before {
  content: "\ea8e";
}

.v4-Frame-18:before {
  content: "\ea8f";
}

.v4-Frame-19:before {
  content: "\ea90";
}

.v4-Frame-20:before {
  content: "\ea91";
}

.v4-Frame-21:before {
  content: "\ea92";
}

.v4-Frame-22:before {
  content: "\ea93";
}

.v4-Frame-23:before {
  content: "\ea94";
}

.v4-Frame-24:before {
  content: "\ea95";
}

.v4-Frame-25:before {
  content: "\ea96";
}

.v4-Frame-26:before {
  content: "\ea97";
}

.v4-Frame-27:before {
  content: "\ea98";
}

.v4-Frame-28:before {
  content: "\ea99";
}

.v4-Frame-29:before {
  content: "\ea9a";
}

.v4-Frame-30:before {
  content: "\ea9b";
}

.v4-Frame-31:before {
  content: "\ea9c";
}

.v4-Frame-32:before {
  content: "\ea9d";
}

.v4-Frame-33:before {
  content: "\ea9e";
}

.v4-Frame-34:before {
  content: "\ea9f";
}

.v4-Frame-35:before {
  content: "\eaa0";
}

.v4-Frame-Hole-1:before {
  content: "\eaa1";
}

.v4-Frame-Hole-2:before {
  content: "\eaa2";
}

.v4-Frame-Hole-3:before {
  content: "\eaa3";
}

.v4-Frame-Hole-4:before {
  content: "\eaa4";
}

.v4-Frankenstein:before {
  content: "\eaa5";
}

.v4-Freezer:before {
  content: "\eaa6";
}

.v4-Full-Moon:before {
  content: "\eaa7";
}

.v4-Funnel:before {
  content: "\eaa8";
}

.v4-Gaming-2:before {
  content: "\eaa9";
}

.v4-Gaming-3:before {
  content: "\eaaa";
}

.v4-Gaming:before {
  content: "\eaab";
}

.v4-Gate:before {
  content: "\eaac";
}

.v4-Ghost:before {
  content: "\eaad";
}

.v4-Giving-Gift:before {
  content: "\eaae";
}

.v4-Glass-Decoration:before {
  content: "\eaaf";
}

.v4-Glass:before {
  content: "\eab0";
}

.v4-Glasses:before {
  content: "\eab1";
}

.v4-Global-User-2:before {
  content: "\eab2";
}

.v4-Global-User:before {
  content: "\eab3";
}

.v4-Global-Users:before {
  content: "\eab4";
}

.v4-Globe:before {
  content: "\eab5";
}

.v4-Glowes:before {
  content: "\eab6";
}

.v4-Graduated-Man:before {
  content: "\eab7";
}

.v4-Graduated-Woman:before {
  content: "\eab8";
}

.v4-Grater:before {
  content: "\eab9";
}

.v4-Grill:before {
  content: "\eaba";
}

.v4-Grim-Reaper:before {
  content: "\eabb";
}

.v4-Groom:before {
  content: "\eabc";
}

.v4-Ham:before {
  content: "\eabd";
}

.v4-Hand-2:before {
  content: "\eabe";
}

.v4-Hand-Blender:before {
  content: "\eabf";
}

.v4-Hand:before {
  content: "\eac0";
}

.v4-Hands-with-rings:before {
  content: "\eac1";
}

.v4-Handshake:before {
  content: "\eac2";
}

.v4-Harry-Potter:before {
  content: "\eac3";
}

.v4-Hat:before {
  content: "\eac4";
}

.v4-Hatchet:before {
  content: "\eac5";
}

.v4-Heart:before {
  content: "\eac6";
}

.v4-Herb-Knife:before {
  content: "\eac7";
}

.v4-Hipster-Girl:before {
  content: "\eac8";
}

.v4-Hipster-Guy:before {
  content: "\eac9";
}

.v4-Home-2:before {
  content: "\eaca";
}

.v4-Home-3:before {
  content: "\eacb";
}

.v4-Home:before {
  content: "\eacc";
}

.v4-Honey-Dipper:before {
  content: "\eacd";
}

.v4-Horn-1:before {
  content: "\eace";
}

.v4-Horn-2:before {
  content: "\eacf";
}

.v4-Horn:before {
  content: "\ead0";
}

.v4-Hot-Chocolate:before {
  content: "\ead1";
}

.v4-Hot-Drink-1:before {
  content: "\ead2";
}

.v4-Hot-Drink-2:before {
  content: "\ead3";
}

.v4-Hot-Drink:before {
  content: "\ead4";
}

.v4-Hot-Sauce:before {
  content: "\ead5";
}

.v4-Hot-Temperature:before {
  content: "\ead6";
}

.v4-House-Construction:before {
  content: "\ead7";
}

.v4-House-Plan:before {
  content: "\ead8";
}

.v4-House:before {
  content: "\ead9";
}

.v4-Household-Mixer:before {
  content: "\eada";
}

.v4-Ice-Lolly:before {
  content: "\eadb";
}

.v4-Ice-Skate:before {
  content: "\eadc";
}

.v4-Icecream:before {
  content: "\eadd";
}

.v4-Icicle:before {
  content: "\eade";
}

.v4-Information:before {
  content: "\eadf";
}

.v4-Invisible:before {
  content: "\eae0";
}

.v4-Invitation-1:before {
  content: "\eae1";
}

.v4-Invitation-2:before {
  content: "\eae2";
}

.v4-Iron-Giant:before {
  content: "\eae3";
}

.v4-Island:before {
  content: "\eae4";
}

.v4-Jacuzzi:before {
  content: "\eae5";
}

.v4-Jason:before {
  content: "\eae6";
}

.v4-Jing-Jang:before {
  content: "\eae7";
}

.v4-K5-Robot:before {
  content: "\eae8";
}

.v4-Kettle:before {
  content: "\eae9";
}

.v4-Key-1:before {
  content: "\eaea";
}

.v4-Key-2:before {
  content: "\eaeb";
}

.v4-Keyboard:before {
  content: "\eaec";
}

.v4-Kid:before {
  content: "\eaed";
}

.v4-Kitchen-Clock:before {
  content: "\eaee";
}

.v4-Kitchen-Scale:before {
  content: "\eaef";
}

.v4-Kitchen-Sink:before {
  content: "\eaf0";
}

.v4-Kitchen-Spoon-1:before {
  content: "\eaf1";
}

.v4-Kitchen-Spoon-2:before {
  content: "\eaf2";
}

.v4-Kite-1:before {
  content: "\eaf3";
}

.v4-Kite-2:before {
  content: "\eaf4";
}

.v4-Knife-Holder:before {
  content: "\eaf5";
}

.v4-Knife:before {
  content: "\eaf6";
}

.v4-Ladle:before {
  content: "\eaf7";
}

.v4-Lamp:before {
  content: "\eaf8";
}

.v4-Lantern:before {
  content: "\eaf9";
}

.v4-Layer-10:before {
  content: "\eafa";
}

.v4-Layer-29:before {
  content: "\eafb";
}

.v4-Layer-40:before {
  content: "\eafc";
}

.v4-Layer-58:before {
  content: "\eafd";
}

.v4-Layers:before {
  content: "\eafe";
}

.v4-Leaf:before {
  content: "\eaff";
}

.v4-Left-Arrow-2:before {
  content: "\eb00";
}

.v4-Lemon-Juicer:before {
  content: "\eb01";
}

.v4-Lifebelt:before {
  content: "\eb02";
}

.v4-Lifebuoy:before {
  content: "\eb03";
}

.v4-Lightbulb:before {
  content: "\eb04";
}

.v4-Lighthouse:before {
  content: "\eb05";
}

.v4-Limonade-1:before {
  content: "\eb06";
}

.v4-Limonade-2:before {
  content: "\eb07";
}

.v4-Line-Chart:before {
  content: "\eb08";
}

.v4-Link:before {
  content: "\eb09";
}

.v4-List:before {
  content: "\eb0a";
}

.v4-Location-2:before {
  content: "\eb0b";
}

.v4-Lock-Open:before {
  content: "\eb0c";
}

.v4-Lock-User-2:before {
  content: "\eb0d";
}

.v4-Lock-User:before {
  content: "\eb0e";
}

.v4-Lock-Users:before {
  content: "\eb0f";
}

.v4-Lock:before {
  content: "\eb10";
}

.v4-Loction-1:before {
  content: "\eb11";
}

.v4-Login:before {
  content: "\eb12";
}

.v4-Logout:before {
  content: "\eb13";
}

.v4-Lollipop:before {
  content: "\eb14";
}

.v4-Loudspeaker:before {
  content: "\eb15";
}

.v4-Magnet:before {
  content: "\eb16";
}

.v4-Maid:before {
  content: "\eb17";
}

.v4-Mail-Attachmet:before {
  content: "\eb18";
}

.v4-Mail:before {
  content: "\eb19";
}

.v4-Mailbox:before {
  content: "\eb1a";
}

.v4-Mails:before {
  content: "\eb1b";
}

.v4-Male:before {
  content: "\eb1c";
}

.v4-Man-1:before {
  content: "\eb1d";
}

.v4-Man-2:before {
  content: "\eb1e";
}

.v4-Man-3:before {
  content: "\eb1f";
}

.v4-Man-Beard:before {
  content: "\eb20";
}

.v4-Man-Cap:before {
  content: "\eb21";
}

.v4-Man-Glasses:before {
  content: "\eb22";
}

.v4-Man-Hat:before {
  content: "\eb23";
}

.v4-Map:before {
  content: "\eb24";
}

.v4-Mariage-Couple:before {
  content: "\eb25";
}

.v4-Mark-User-2:before {
  content: "\eb26";
}

.v4-Mark-User:before {
  content: "\eb27";
}

.v4-Mark-Users:before {
  content: "\eb28";
}

.v4-Marriage-Settlement:before {
  content: "\eb29";
}

.v4-Marvin:before {
  content: "\eb2a";
}

.v4-Matches:before {
  content: "\eb2b";
}

.v4-Maximize:before {
  content: "\eb2c";
}

.v4-Measuring-Cup:before {
  content: "\eb2d";
}

.v4-Meat-Fork:before {
  content: "\eb2e";
}

.v4-Meat-Grinder:before {
  content: "\eb2f";
}

.v4-Medal:before {
  content: "\eb30";
}

.v4-Men-4:before {
  content: "\eb31";
}

.v4-Menu-1:before {
  content: "\eb32";
}

.v4-Menu-2:before {
  content: "\eb33";
}

.v4-Menu:before {
  content: "\eb34";
}

.v4-Microphone-1:before {
  content: "\eb35";
}

.v4-Microphone-2:before {
  content: "\eb36";
}

.v4-Microwave:before {
  content: "\eb37";
}

.v4-Mini-Dress:before {
  content: "\eb38";
}

.v4-Minimize:before {
  content: "\eb39";
}

.v4-Mobile-Phone:before {
  content: "\eb3a";
}

.v4-Money:before {
  content: "\eb3b";
}

.v4-Mouse-Pointer-1:before {
  content: "\eb3c";
}

.v4-Mouse-Pointer-2:before {
  content: "\eb3d";
}

.v4-Mouse:before {
  content: "\eb3e";
}

.v4-Mug:before {
  content: "\eb3f";
}

.v4-Mute:before {
  content: "\eb40";
}

.v4-Napkin:before {
  content: "\eb41";
}

.v4-News:before {
  content: "\eb42";
}

.v4-Newsfeed:before {
  content: "\eb43";
}

.v4-Night-Candle:before {
  content: "\eb44";
}

.v4-No-Battery:before {
  content: "\eb45";
}

.v4-No-Camera:before {
  content: "\eb46";
}

.v4-No-Copyright-2:before {
  content: "\eb47";
}

.v4-No-Copyright:before {
  content: "\eb48";
}

.v4-No-Copzright-3:before {
  content: "\eb49";
}

.v4-No-Flash:before {
  content: "\eb4a";
}

.v4-No-Microphone:before {
  content: "\eb4b";
}

.v4-No-Wifi-Tethering:before {
  content: "\eb4c";
}

.v4-Note-Book:before {
  content: "\eb4d";
}

.v4-Notebook:before {
  content: "\eb4e";
}

.v4-Nurse:before {
  content: "\eb4f";
}

.v4-Old-Man:before {
  content: "\eb50";
}

.v4-Olive-Oil-Pot:before {
  content: "\eb51";
}

.v4-Open-Folder:before {
  content: "\eb52";
}

.v4-Open-Mail:before {
  content: "\eb53";
}

.v4-Open:before {
  content: "\eb54";
}

.v4-Optimus-Prime:before {
  content: "\eb55";
}

.v4-Other:before {
  content: "\eb56";
}

.v4-Outlet-Symbol:before {
  content: "\eb57";
}

.v4-Oval-Frame-1:before {
  content: "\eb58";
}

.v4-Oval-Frame-2:before {
  content: "\eb59";
}

.v4-Oven-Gloves:before {
  content: "\eb5a";
}

.v4-Owl:before {
  content: "\eb5b";
}

.v4-Palette:before {
  content: "\eb5c";
}

.v4-Pan:before {
  content: "\eb5d";
}

.v4-Pancake-Griddle:before {
  content: "\eb5e";
}

.v4-Pantheon:before {
  content: "\eb5f";
}

.v4-Paper-Plane:before {
  content: "\eb60";
}

.v4-Paperclip:before {
  content: "\eb61";
}

.v4-Parabolic-Antenna:before {
  content: "\eb62";
}

.v4-Park-2:before {
  content: "\eb63";
}

.v4-Park-3:before {
  content: "\eb64";
}

.v4-Park:before {
  content: "\eb65";
}

.v4-Party-Cake:before {
  content: "\eb66";
}

.v4-Party-Glasses:before {
  content: "\eb67";
}

.v4-Party-Greetings-2:before {
  content: "\eb68";
}

.v4-Pastry-Bag-1:before {
  content: "\eb69";
}

.v4-Pastry-Bag-2:before {
  content: "\eb6a";
}

.v4-Pause:before {
  content: "\eb6b";
}

.v4-Paying-1:before {
  content: "\eb6c";
}

.v4-Paying-2:before {
  content: "\eb6d";
}

.v4-Peeler:before {
  content: "\eb6e";
}

.v4-Pencil:before {
  content: "\eb6f";
}

.v4-Pepper-Pot-1:before {
  content: "\eb70";
}

.v4-Pepper-Pot-2:before {
  content: "\eb71";
}

.v4-Percent:before {
  content: "\eb72";
}

.v4-Percentage-2:before {
  content: "\eb73";
}

.v4-Percentage-3:before {
  content: "\eb74";
}

.v4-Percentage:before {
  content: "\eb75";
}

.v4-Phone:before {
  content: "\eb76";
}

.v4-Photo-frame-4:before {
  content: "\eb77";
}

.v4-Photo-frame-2:before {
  content: "\eb78";
}

.v4-Photo-frame-3:before {
  content: "\eb79";
}

.v4-Photo-frame-5:before {
  content: "\eb7a";
}

.v4-Photo-frame-6:before {
  content: "\eb7b";
}

.v4-Photo-frame-7:before {
  content: "\eb7c";
}

.v4-Photo-frame:before {
  content: "\eb7d";
}

.v4-Photo:before {
  content: "\eb7e";
}

.v4-Picture:before {
  content: "\eb7f";
}

.v4-Pie-Chart-1:before {
  content: "\eb80";
}

.v4-Pie-Chart-2:before {
  content: "\eb81";
}

.v4-Pillar:before {
  content: "\eb82";
}

.v4-Pin-1:before {
  content: "\eb83";
}

.v4-Pin-2:before {
  content: "\eb84";
}

.v4-Pinata:before {
  content: "\eb85";
}

.v4-Pizza-Cutter:before {
  content: "\eb86";
}

.v4-Pizza-Food:before {
  content: "\eb87";
}

.v4-Pizza-Slice:before {
  content: "\eb88";
}

.v4-Pizza:before {
  content: "\eb89";
}

.v4-Plate:before {
  content: "\eb8a";
}

.v4-Play:before {
  content: "\eb8b";
}

.v4-Plugin-1:before {
  content: "\eb8c";
}

.v4-Plugin-2:before {
  content: "\eb8d";
}

.v4-Policeman:before {
  content: "\eb8e";
}

.v4-Pompkin:before {
  content: "\eb8f";
}

.v4-Pool-Ladder:before {
  content: "\eb90";
}

.v4-Pool:before {
  content: "\eb91";
}

.v4-Popcorn:before {
  content: "\eb92";
}

.v4-Pot-1:before {
  content: "\eb93";
}

.v4-Pot-2:before {
  content: "\eb94";
}

.v4-Potato-Musher:before {
  content: "\eb95";
}

.v4-Pound-2:before {
  content: "\eb96";
}

.v4-Pound-3:before {
  content: "\eb97";
}

.v4-Pound:before {
  content: "\eb98";
}

.v4-Power:before {
  content: "\eb99";
}

.v4-Preferences-1:before {
  content: "\eb9a";
}

.v4-Preferences-2:before {
  content: "\eb9b";
}

.v4-Present-1:before {
  content: "\eb9c";
}

.v4-Present-2:before {
  content: "\eb9d";
}

.v4-Present-3:before {
  content: "\eb9e";
}

.v4-Present-4:before {
  content: "\eb9f";
}

.v4-Present-5:before {
  content: "\eba0";
}

.v4-Present-Cake:before {
  content: "\eba1";
}

.v4-Present:before {
  content: "\eba2";
}

.v4-Presentation:before {
  content: "\eba3";
}

.v4-Print:before {
  content: "\eba4";
}

.v4-Prisoner:before {
  content: "\eba5";
}

.v4-Protection:before {
  content: "\eba6";
}

.v4-Punk:before {
  content: "\eba7";
}

.v4-Question-2:before {
  content: "\eba8";
}

.v4-Question-3:before {
  content: "\eba9";
}

.v4-Question-Mark:before {
  content: "\ebaa";
}

.v4-Question:before {
  content: "\ebab";
}

.v4-Radar:before {
  content: "\ebac";
}

.v4-Radio:before {
  content: "\ebad";
}

.v4-Recicle-User-2:before {
  content: "\ebae";
}

.v4-Recicle-User:before {
  content: "\ebaf";
}

.v4-Recicle-Users:before {
  content: "\ebb0";
}

.v4-Record-1:before {
  content: "\ebb1";
}

.v4-Record-2:before {
  content: "\ebb2";
}

.v4-Rectangle-Frame-1:before {
  content: "\ebb3";
}

.v4-Rectangle-Frame-2:before {
  content: "\ebb4";
}

.v4-Rectangle-Frame-3:before {
  content: "\ebb5";
}

.v4-Rectangle-Frame-4:before {
  content: "\ebb6";
}

.v4-Rectangle-Frame-5:before {
  content: "\ebb7";
}

.v4-Rectangle-Frame-6:before {
  content: "\ebb8";
}

.v4-Rectangle-Frame-7:before {
  content: "\ebb9";
}

.v4-Rectangle-Frame-8:before {
  content: "\ebba";
}

.v4-Rectangle-Frame-9:before {
  content: "\ebbb";
}

.v4-Rectangle-Frame-10:before {
  content: "\ebbc";
}

.v4-Rectangle-Frame-11:before {
  content: "\ebbd";
}

.v4-Rectangle-Frame-12:before {
  content: "\ebbe";
}

.v4-Rectangle-Frame-13:before {
  content: "\ebbf";
}

.v4-Refresh-2:before {
  content: "\ebc0";
}

.v4-Refresh-Users:before {
  content: "\ebc1";
}

.v4-Refresh:before {
  content: "\ebc2";
}

.v4-Refrigerator:before {
  content: "\ebc3";
}

.v4-Reindeer:before {
  content: "\ebc4";
}

.v4-Reload:before {
  content: "\ebc5";
}

.v4-Remove-Event:before {
  content: "\ebc6";
}

.v4-Remove-Shopping-Cart:before {
  content: "\ebc7";
}

.v4-Remove-User-2:before {
  content: "\ebc8";
}

.v4-Remove-User:before {
  content: "\ebc9";
}

.v4-Remove-Users:before {
  content: "\ebca";
}

.v4-Remove:before {
  content: "\ebcb";
}

.v4-Reply:before {
  content: "\ebcc";
}

.v4-Reserved-1:before {
  content: "\ebcd";
}

.v4-Reserved-2:before {
  content: "\ebce";
}

.v4-Restaurant-Place:before {
  content: "\ebcf";
}

.v4-Restaurant-sign-1:before {
  content: "\ebd0";
}

.v4-Restaurant-Sign-2:before {
  content: "\ebd1";
}

.v4-Restaurant-Sign-3:before {
  content: "\ebd2";
}

.v4-Retro-Bot-1:before {
  content: "\ebd3";
}

.v4-Retro-Bot-2:before {
  content: "\ebd4";
}

.v4-Retro-Bot-3:before {
  content: "\ebd5";
}

.v4-Retro-Bot-4:before {
  content: "\ebd6";
}

.v4-Right-Arrow-2:before {
  content: "\ebd7";
}

.v4-Roberto:before {
  content: "\ebd8";
}

.v4-Robocop:before {
  content: "\ebd9";
}

.v4-Robot-1:before {
  content: "\ebda";
}

.v4-Robot-2:before {
  content: "\ebdb";
}

.v4-Robot-3:before {
  content: "\ebdc";
}

.v4-Robot-4:before {
  content: "\ebdd";
}

.v4-Robot-5:before {
  content: "\ebde";
}

.v4-Robot-6:before {
  content: "\ebdf";
}

.v4-Robot-7:before {
  content: "\ebe0";
}

.v4-Robot-8:before {
  content: "\ebe1";
}

.v4-Robot-9:before {
  content: "\ebe2";
}

.v4-Robot-10:before {
  content: "\ebe3";
}

.v4-Robot-11:before {
  content: "\ebe4";
}

.v4-Robot-12:before {
  content: "\ebe5";
}

.v4-Robot-13:before {
  content: "\ebe6";
}

.v4-Robot-Arm-1:before {
  content: "\ebe7";
}

.v4-Robot-Arm-2:before {
  content: "\ebe8";
}

.v4-Robot-Arm-3:before {
  content: "\ebe9";
}

.v4-Robot-Arm-4:before {
  content: "\ebea";
}

.v4-Robot-Arm-5:before {
  content: "\ebeb";
}

.v4-Robot-Devil:before {
  content: "\ebec";
}

.v4-Robot-Machine-1:before {
  content: "\ebed";
}

.v4-Robot-Machine-2:before {
  content: "\ebee";
}

.v4-Rocket:before {
  content: "\ebef";
}

.v4-Rolling-Pin:before {
  content: "\ebf0";
}

.v4-Roof-Plan:before {
  content: "\ebf1";
}

.v4-Round-Frame-1:before {
  content: "\ebf2";
}

.v4-Round-Frame-2:before {
  content: "\ebf3";
}

.v4-Round-Frame-3:before {
  content: "\ebf4";
}

.v4-Round-Frame:before {
  content: "\ebf5";
}

.v4-Rug-1:before {
  content: "\ebf6";
}

.v4-Rug-2:before {
  content: "\ebf7";
}

.v4-Rug-3:before {
  content: "\ebf8";
}

.v4-Ruler-1:before {
  content: "\ebf9";
}

.v4-Ruler-2:before {
  content: "\ebfa";
}

.v4-Ruler:before {
  content: "\ebfb";
}

.v4-Sailboat:before {
  content: "\ebfc";
}

.v4-Salt-Pot:before {
  content: "\ebfd";
}

.v4-Salver:before {
  content: "\ebfe";
}

.v4-Sand-Castle:before {
  content: "\ebff";
}

.v4-Sand-Glass:before {
  content: "\ec00";
}

.v4-Santa:before {
  content: "\ec01";
}

.v4-Sauce-Boat:before {
  content: "\ec02";
}

.v4-Sauce-Spoon:before {
  content: "\ec03";
}

.v4-Sausage:before {
  content: "\ec04";
}

.v4-Save:before {
  content: "\ec05";
}

.v4-Scarf:before {
  content: "\ec06";
}

.v4-Scream:before {
  content: "\ec07";
}

.v4-Scythe:before {
  content: "\ec08";
}

.v4-Search-User-2:before {
  content: "\ec09";
}

.v4-Search-User:before {
  content: "\ec0a";
}

.v4-Search-Users:before {
  content: "\ec0b";
}

.v4-Search:before {
  content: "\ec0c";
}

.v4-Section-Symbol:before {
  content: "\ec0d";
}

.v4-Server:before {
  content: "\ec0e";
}

.v4-Service:before {
  content: "\ec0f";
}

.v4-Share-User-2:before {
  content: "\ec10";
}

.v4-Share-User:before {
  content: "\ec11";
}

.v4-Share-Users:before {
  content: "\ec12";
}

.v4-Share:before {
  content: "\ec13";
}

.v4-Sheep-2:before {
  content: "\ec14";
}

.v4-Sheep:before {
  content: "\ec15";
}

.v4-Shell-1:before {
  content: "\ec16";
}

.v4-Shell-2:before {
  content: "\ec17";
}

.v4-Shop:before {
  content: "\ec18";
}

.v4-Shopping-2:before {
  content: "\ec19";
}

.v4-Shopping-3:before {
  content: "\ec1a";
}

.v4-Shopping-Bag:before {
  content: "\ec1b";
}

.v4-Shopping-Basket:before {
  content: "\ec1c";
}

.v4-Shopping-Cart:before {
  content: "\ec1d";
}

.v4-Shopping:before {
  content: "\ec1e";
}

.v4-Shot-Glasses:before {
  content: "\ec1f";
}

.v4-Shower-Tray:before {
  content: "\ec20";
}

.v4-Shuffle:before {
  content: "\ec21";
}

.v4-Sieve:before {
  content: "\ec22";
}

.v4-Signal:before {
  content: "\ec23";
}

.v4-Signpost:before {
  content: "\ec24";
}

.v4-Sim-Card:before {
  content: "\ec25";
}

.v4-Sink-1:before {
  content: "\ec26";
}

.v4-Sink-2:before {
  content: "\ec27";
}

.v4-Skis:before {
  content: "\ec28";
}

.v4-Skull-Cake:before {
  content: "\ec29";
}

.v4-Skull:before {
  content: "\ec2a";
}

.v4-Sky-Glasses:before {
  content: "\ec2b";
}

.v4-Skyscraper:before {
  content: "\ec2c";
}

.v4-Sledge:before {
  content: "\ec2d";
}

.v4-Sleeping-2:before {
  content: "\ec2e";
}

.v4-Sleeping-3:before {
  content: "\ec2f";
}

.v4-Sleeping:before {
  content: "\ec30";
}

.v4-Slippery-Road:before {
  content: "\ec31";
}

.v4-Snow-Boots:before {
  content: "\ec32";
}

.v4-Snow-Chain:before {
  content: "\ec33";
}

.v4-Snow-Shoes-1:before {
  content: "\ec34";
}

.v4-Snow-Shoes-2:before {
  content: "\ec35";
}

.v4-Snow-Shovel:before {
  content: "\ec36";
}

.v4-Snowboard:before {
  content: "\ec37";
}

.v4-Snowcapped-House:before {
  content: "\ec38";
}

.v4-Snowcapped-Mountains:before {
  content: "\ec39";
}

.v4-Snowflake-1:before {
  content: "\ec3a";
}

.v4-Snowflake-2:before {
  content: "\ec3b";
}

.v4-Snowflake-3:before {
  content: "\ec3c";
}

.v4-Snowflake-4:before {
  content: "\ec3d";
}

.v4-Snowing:before {
  content: "\ec3e";
}

.v4-Snowman:before {
  content: "\ec3f";
}

.v4-Snowmobile:before {
  content: "\ec40";
}

.v4-Sofa-1:before {
  content: "\ec41";
}

.v4-Sofa-2:before {
  content: "\ec42";
}

.v4-Sofa-3:before {
  content: "\ec43";
}

.v4-Sofa-4:before {
  content: "\ec44";
}

.v4-Sofa-5:before {
  content: "\ec45";
}

.v4-Sofa-6:before {
  content: "\ec46";
}

.v4-Soldier:before {
  content: "\ec47";
}

.v4-Soup:before {
  content: "\ec48";
}

.v4-Speach-Bubble:before {
  content: "\ec49";
}

.v4-Speach-Bubbles:before {
  content: "\ec4a";
}

.v4-Speaker:before {
  content: "\ec4b";
}

.v4-Spices:before {
  content: "\ec4c";
}

.v4-Spider:before {
  content: "\ec4d";
}

.v4-Spoon:before {
  content: "\ec4e";
}

.v4-Square-Frame-1:before {
  content: "\ec4f";
}

.v4-Square-Frame-2:before {
  content: "\ec50";
}

.v4-Square-Frame-3:before {
  content: "\ec51";
}

.v4-Square-Frame-4:before {
  content: "\ec52";
}

.v4-Square-Frame-5:before {
  content: "\ec53";
}

.v4-Stairs:before {
  content: "\ec54";
}

.v4-Stand-Mixer:before {
  content: "\ec55";
}

.v4-Star:before {
  content: "\ec56";
}

.v4-Starfish:before {
  content: "\ec57";
}

.v4-Stop:before {
  content: "\ec58";
}

.v4-Stove:before {
  content: "\ec59";
}

.v4-Strand-Bag:before {
  content: "\ec5a";
}

.v4-Strand-Ball:before {
  content: "\ec5b";
}

.v4-Straw-Hat:before {
  content: "\ec5c";
}

.v4-Sugar-Bowl:before {
  content: "\ec5d";
}

.v4-Suitcase:before {
  content: "\ec5e";
}

.v4-Summer-Time:before {
  content: "\ec5f";
}

.v4-Sun-Light:before {
  content: "\ec60";
}

.v4-Sun:before {
  content: "\ec61";
}

.v4-Suncream:before {
  content: "\ec62";
}

.v4-Sunglasses:before {
  content: "\ec63";
}

.v4-Sunset:before {
  content: "\ec64";
}

.v4-Sunshade:before {
  content: "\ec65";
}

.v4-Suntan-Oil:before {
  content: "\ec66";
}

.v4-Superman:before {
  content: "\ec67";
}

.v4-Surf-Board:before {
  content: "\ec68";
}

.v4-Sushi:before {
  content: "\ec69";
}

.v4-Sweater-1:before {
  content: "\ec6a";
}

.v4-Sweater-2:before {
  content: "\ec6b";
}

.v4-Sweater-3:before {
  content: "\ec6c";
}

.v4-Sweet-16:before {
  content: "\ec6d";
}

.v4-Swimming-Trunks:before {
  content: "\ec6e";
}

.v4-Switch-Button-1:before {
  content: "\ec6f";
}

.v4-Switch-Button-2:before {
  content: "\ec70";
}

.v4-Syncronizaion:before {
  content: "\ec71";
}

.v4-Table-1:before {
  content: "\ec72";
}

.v4-Table-2:before {
  content: "\ec73";
}

.v4-Table-3:before {
  content: "\ec74";
}

.v4-Table-4:before {
  content: "\ec75";
}

.v4-Table-Cad:before {
  content: "\ec76";
}

.v4-Table:before {
  content: "\ec77";
}

.v4-Tag:before {
  content: "\ec78";
}

.v4-Tape-Measure:before {
  content: "\ec79";
}

.v4-Target:before {
  content: "\ec7a";
}

.v4-Tea-Sieve:before {
  content: "\ec7b";
}

.v4-Teapot:before {
  content: "\ec7c";
}

.v4-Temperature:before {
  content: "\ec7d";
}

.v4-Tent:before {
  content: "\ec7e";
}

.v4-Thermometer-1:before {
  content: "\ec7f";
}

.v4-Thermometer-2:before {
  content: "\ec80";
}

.v4-Thumbs-Down:before {
  content: "\ec81";
}

.v4-Thumbs-Up:before {
  content: "\ec82";
}

.v4-Ticket:before {
  content: "\ec83";
}

.v4-Time-2:before {
  content: "\ec84";
}

.v4-Time-3:before {
  content: "\ec85";
}

.v4-Time:before {
  content: "\ec86";
}

.v4-Toast:before {
  content: "\ec87";
}

.v4-Toilet-Paper:before {
  content: "\ec88";
}

.v4-Toilette:before {
  content: "\ec89";
}

.v4-Tools-1:before {
  content: "\ec8a";
}

.v4-Tools-2:before {
  content: "\ec8b";
}

.v4-Toos:before {
  content: "\ec8c";
}

.v4-Towel:before {
  content: "\ec8d";
}

.v4-Transmitter:before {
  content: "\ec8e";
}

.v4-Trash-Full:before {
  content: "\ec8f";
}

.v4-Trash:before {
  content: "\ec90";
}

.v4-Tree-Ghost:before {
  content: "\ec91";
}

.v4-Truffles:before {
  content: "\ec92";
}

.v4-T-Shirt-1:before {
  content: "\ec93";
}

.v4-T-Shirt-2:before {
  content: "\ec94";
}

.v4-Tub-1:before {
  content: "\ec95";
}

.v4-Tub-2:before {
  content: "\ec96";
}

.v4-Tulip-2:before {
  content: "\ec97";
}

.v4-Tulip:before {
  content: "\ec98";
}

.v4-Tv-Stand:before {
  content: "\ec99";
}

.v4-Type:before {
  content: "\ec9a";
}

.v4-Umbrella:before {
  content: "\ec9b";
}

.v4-Undo:before {
  content: "\ec9c";
}

.v4-Up-Arrow:before {
  content: "\ec9d";
}

.v4-Upgrade:before {
  content: "\ec9e";
}

.v4-Upload-2:before {
  content: "\ec9f";
}

.v4-Upload-3:before {
  content: "\eca0";
}

.v4-Upload-Cloud:before {
  content: "\eca1";
}

.v4-Upload:before {
  content: "\eca2";
}

.v4-USB-Device:before {
  content: "\eca3";
}

.v4-USB:before {
  content: "\eca4";
}

.v4-User-1:before {
  content: "\eca5";
}

.v4-User-2:before {
  content: "\eca6";
}

.v4-User-Car:before {
  content: "\eca7";
}

.v4-User-Card-2:before {
  content: "\eca8";
}

.v4-User-Card:before {
  content: "\eca9";
}

.v4-User-Cloud:before {
  content: "\ecaa";
}

.v4-User-Database:before {
  content: "\ecab";
}

.v4-User-Defense-2:before {
  content: "\ecac";
}

.v4-User-Defense:before {
  content: "\ecad";
}

.v4-User-Download:before {
  content: "\ecae";
}

.v4-User-Home:before {
  content: "\ecaf";
}

.v4-User-Key-2:before {
  content: "\ecb0";
}

.v4-User-Key:before {
  content: "\ecb1";
}

.v4-User-Love-2:before {
  content: "\ecb2";
}

.v4-User-Love:before {
  content: "\ecb3";
}

.v4-User-Place:before {
  content: "\ecb4";
}

.v4-User-Rate-2:before {
  content: "\ecb5";
}

.v4-User-Refresh:before {
  content: "\ecb6";
}

.v4-User-Settings-2:before {
  content: "\ecb7";
}

.v4-User-Settings:before {
  content: "\ecb8";
}

.v4-User-Speed-2:before {
  content: "\ecb9";
}

.v4-User-Speed:before {
  content: "\ecba";
}

.v4-User-Statistics-2:before {
  content: "\ecbb";
}

.v4-User-Statistics:before {
  content: "\ecbc";
}

.v4-User-Time:before {
  content: "\ecbd";
}

.v4-User-Upload:before {
  content: "\ecbe";
}

.v4-User-Wifi:before {
  content: "\ecbf";
}

.v4-Users-Card:before {
  content: "\ecc0";
}

.v4-Users-Defense:before {
  content: "\ecc1";
}

.v4-Users-Key:before {
  content: "\ecc2";
}

.v4-Users-Love:before {
  content: "\ecc3";
}

.v4-Users-Place:before {
  content: "\ecc4";
}

.v4-Users-Settings:before {
  content: "\ecc5";
}

.v4-Users-Speed-3:before {
  content: "\ecc6";
}

.v4-Users-Statistics:before {
  content: "\ecc7";
}

.v4-Users:before {
  content: "\ecc8";
}

.v4-Vacation-Photo:before {
  content: "\ecc9";
}

.v4-Video:before {
  content: "\ecca";
}

.v4-VIP-Ticket:before {
  content: "\eccb";
}

.v4-VIP:before {
  content: "\eccc";
}

.v4-Volume:before {
  content: "\eccd";
}

.v4-Waiter:before {
  content: "\ecce";
}

.v4-Waiting-User-2:before {
  content: "\eccf";
}

.v4-Waiting-User:before {
  content: "\ecd0";
}

.v4-Waiting-Users:before {
  content: "\ecd1";
}

.v4-Waitress:before {
  content: "\ecd2";
}

.v4-Wall-E:before {
  content: "\ecd3";
}

.v4-Wallet-2:before {
  content: "\ecd4";
}

.v4-Wallet:before {
  content: "\ecd5";
}

.v4-Watch:before {
  content: "\ecd6";
}

.v4-Webpage:before {
  content: "\ecd7";
}

.v4-Wedding-Altar-1:before {
  content: "\ecd8";
}

.v4-Wedding-Altar-2:before {
  content: "\ecd9";
}

.v4-Wedding-Bouquet-2:before {
  content: "\ecda";
}

.v4-Wedding-Bouquet:before {
  content: "\ecdb";
}

.v4-Wedding-Cake-1:before {
  content: "\ecdc";
}

.v4-Wedding-Cake-2:before {
  content: "\ecdd";
}

.v4-Wedding-Cake-3:before {
  content: "\ecde";
}

.v4-Wedding-Car:before {
  content: "\ecdf";
}

.v4-Wedding-Date:before {
  content: "\ece0";
}

.v4-Wedding-Decoration-1:before {
  content: "\ece1";
}

.v4-Wedding-Decoration-2:before {
  content: "\ece2";
}

.v4-Wedding-Invitation-2:before {
  content: "\ece3";
}

.v4-Wedding-Invitation:before {
  content: "\ece4";
}

.v4-Wedding-music:before {
  content: "\ece5";
}

.v4-wedding-party:before {
  content: "\ece6";
}

.v4-Wedding-Photo:before {
  content: "\ece7";
}

.v4-Wedding-Planner-Book:before {
  content: "\ece8";
}

.v4-Wedding-Present-1:before {
  content: "\ece9";
}

.v4-Wedding-Present-2:before {
  content: "\ecea";
}

.v4-Wedding-Ring:before {
  content: "\eceb";
}

.v4-Wedding-Tiara:before {
  content: "\ecec";
}

.v4-Wedding-Way-Sign:before {
  content: "\eced";
}

.v4-Whetting:before {
  content: "\ecee";
}

.v4-Wifi-1:before {
  content: "\ecef";
}

.v4-Wifi-2:before {
  content: "\ecf0";
}

.v4-Wifi-3:before {
  content: "\ecf1";
}

.v4-Wifi-Tethering:before {
  content: "\ecf2";
}

.v4-Wifi:before {
  content: "\ecf3";
}

.v4-Wine-Glass:before {
  content: "\ecf4";
}

.v4-Wine-List:before {
  content: "\ecf5";
}

.v4-Wine:before {
  content: "\ecf6";
}

.v4-Winter-Calendar:before {
  content: "\ecf7";
}

.v4-Winter-Cap-1:before {
  content: "\ecf8";
}

.v4-Winter-Cap-2:before {
  content: "\ecf9";
}

.v4-Winter-Coat:before {
  content: "\ecfa";
}

.v4-Winter-Sock:before {
  content: "\ecfb";
}

.v4-Winter-Socks:before {
  content: "\ecfc";
}

.v4-Witch-Broom:before {
  content: "\ecfd";
}

.v4-Witch-Cauldron:before {
  content: "\ecfe";
}

.v4-Witch:before {
  content: "\ecff";
}

.v4-Wok-1:before {
  content: "\ed00";
}

.v4-Wok-2:before {
  content: "\ed01";
}

.v4-Woman-1:before {
  content: "\ed02";
}

.v4-Woman-2:before {
  content: "\ed03";
}

.v4-Woman-3:before {
  content: "\ed04";
}

.v4-Woman-4:before {
  content: "\ed05";
}

.v4-Woman-Cap:before {
  content: "\ed06";
}

.v4-Woman-Glasses:before {
  content: "\ed07";
}

.v4-Woman-Hat:before {
  content: "\ed08";
}

.v4-Wooden-House:before {
  content: "\ed09";
}

.v4-Woodsman:before {
  content: "\ed0a";
}

.v4-Work-Table:before {
  content: "\ed0b";
}

.v4-Worker:before {
  content: "\ed0c";
}

.v4-Workspace-1:before {
  content: "\ed0d";
}

.v4-Workspace-2:before {
  content: "\ed0e";
}

.v4-Writing-User-2:before {
  content: "\ed0f";
}

.v4-Writing-User:before {
  content: "\ed10";
}

.v4-Writing-Users:before {
  content: "\ed11";
}

.v4-Zoom-In:before {
  content: "\ed12";
}

.v4-Zoom-Out:before {
  content: "\ed13";
}
