table {
  width: 100%;
}

.mat-table thead {
  background: whitesmoke !important;
}

tr.mat-header-row {
  height: 40px !important;
}

tr.mat-row {
  height: 40px !important;
}

.mat-cell {
  font-size: 13px !important;
}

.row-cursor {
  cursor: pointer;
}
