@font-face {
  font-family: 'v1b';
  src: url('fonts/v1b.woff?8sz899') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v1b-"], [class*=" v1b-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v1b' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v1b-Add-Bag:before {
  content: "\e900";
}

.v1b-Add-Cart:before {
  content: "\e901";
}

.v1b-Bitcoin-Bag:before {
  content: "\e902";
}

.v1b-Bitcoin-Cart:before {
  content: "\e903";
}

.v1b-Cart:before {
  content: "\e904";
}

.v1b-Check-Out:before {
  content: "\e905";
}

.v1b-Clear-Bag:before {
  content: "\e906";
}

.v1b-Clear-Cart:before {
  content: "\e907";
}

.v1b-Credit-Card:before {
  content: "\e908";
}

.v1b-Cuppon:before {
  content: "\e909";
}

.v1b-Delete-Mail:before {
  content: "\e90a";
}

.v1b-Dismiss-Bag:before {
  content: "\e90b";
}

.v1b-Dismiss-Cart:before {
  content: "\e90c";
}

.v1b-Dollar-Bag:before {
  content: "\e90d";
}

.v1b-Dollar-Cart:before {
  content: "\e90e";
}

.v1b-Done-Bag:before {
  content: "\e90f";
}

.v1b-Done-Cat:before {
  content: "\e910";
}

.v1b-Euro-Bag:before {
  content: "\e911";
}

.v1b-Euro-Cart:before {
  content: "\e912";
}

.v1b-Favorite-Bag:before {
  content: "\e913";
}

.v1b-Favorite-Cart:before {
  content: "\e914";
}

.v1b-Financial-Care2:before {
  content: "\e915";
}

.v1b-Full-Cart:before {
  content: "\e916";
}

.v1b-Gear-12:before {
  content: "\e917";
}

.v1b-Gift-Box2:before {
  content: "\e918";
}

.v1b-Gift-Card2:before {
  content: "\e919";
}

.v1b-Globe-1:before {
  content: "\e91a";
}

.v1b-Globe-2:before {
  content: "\e91b";
}

.v1b-Globe-4:before {
  content: "\e91c";
}

.v1b-Gold-Bars1:before {
  content: "\e91d";
}

.v1b-Gold-Bars2:before {
  content: "\e91e";
}

.v1b-Handshake:before {
  content: "\e91f";
}

.v1b-Home-Bag:before {
  content: "\e920";
}

.v1b-Hot-Offer:before {
  content: "\e921";
}

.v1b-Inshurance-2:before {
  content: "\e922";
}

.v1b-Inshurance:before {
  content: "\e923";
}

.v1b-Key-ToSuccess:before {
  content: "\e924";
}

.v1b-Master-Card2:before {
  content: "\e925";
}

.v1b-Master-Card:before {
  content: "\e926";
}

.v1b-Mobile-Shopping1:before {
  content: "\e927";
}

.v1b-Mobile-Shopping2:before {
  content: "\e928";
}

.v1b-Money-Bag:before {
  content: "\e929";
}

.v1b-Money-Network:before {
  content: "\e92a";
}

.v1b-Networking-1:before {
  content: "\e92b";
}

.v1b-Networking-2:before {
  content: "\e92c";
}

.v1b-On-Sale2:before {
  content: "\e92d";
}

.v1b-On-Sale:before {
  content: "\e92e";
}

.v1b-Online-shopping1:before {
  content: "\e92f";
}

.v1b-Online-Store:before {
  content: "\e930";
}

.v1b-Open-box:before {
  content: "\e931";
}

.v1b-Pound-Bag:before {
  content: "\e932";
}

.v1b-Pound-Card:before {
  content: "\e933";
}

.v1b-Pound-Cart:before {
  content: "\e934";
}

.v1b-Predator:before {
  content: "\e935";
}

.v1b-Presentation-2:before {
  content: "\e936";
}

.v1b-Presentation-3:before {
  content: "\e937";
}

.v1b-Prezentation-1:before {
  content: "\e938";
}

.v1b-Price-Tag1:before {
  content: "\e939";
}

.v1b-Price-Tag2:before {
  content: "\e93a";
}

.v1b-Remouve-Bag:before {
  content: "\e93b";
}

.v1b-Remouve-Card:before {
  content: "\e93c";
}

.v1b-Remouve-Cart:before {
  content: "\e93d";
}

.v1b-Renew-Card:before {
  content: "\e93e";
}

.v1b-Safe-Box1:before {
  content: "\e93f";
}

.v1b-Safe-Box2:before {
  content: "\e940";
}

.v1b-Search-Bag:before {
  content: "\e941";
}

.v1b-Search-Cart:before {
  content: "\e942";
}

.v1b-Search-Job:before {
  content: "\e943";
}

.v1b-Secure-Shopping1:before {
  content: "\e944";
}

.v1b-Secure-Shopping3:before {
  content: "\e945";
}

.v1b-Secure-Shopping5:before {
  content: "\e946";
}

.v1b-Shipping-box:before {
  content: "\e947";
}

.v1b-Shipping-Scedule:before {
  content: "\e948";
}

.v1b-Shipping:before {
  content: "\e949";
}

.v1b-Shopping-bag:before {
  content: "\e94a";
}

.v1b-Sign-Contract:before {
  content: "\e94b";
}

.v1b-Strategy-1:before {
  content: "\e94c";
}

.v1b-Target-Group:before {
  content: "\e94d";
}

.v1b-Target-Money:before {
  content: "\e94e";
}

.v1b-Target:before {
  content: "\e94f";
}

.v1b-Timemoney:before {
  content: "\e950";
}

.v1b-Upload-Fond:before {
  content: "\e951";
}

.v1b-Visa-2:before {
  content: "\e952";
}

.v1b-Visa:before {
  content: "\e953";
}

.v1b-Web-shop1:before {
  content: "\e954";
}

.v1b-Web-shop2:before {
  content: "\e955";
}

.v1b-Web-shop3:before {
  content: "\e956";
}

.v1b-Withrow-Fond:before {
  content: "\e957";
}

.v1b-112:before {
  content: "\e958";
}

.v1b-911:before {
  content: "\e959";
}

.v1b-Add-Call:before {
  content: "\e95a";
}

.v1b-Add-Cloud:before {
  content: "\e95b";
}

.v1b-Alarm-Clock2:before {
  content: "\e95c";
}

.v1b-Alligator:before {
  content: "\e95d";
}

.v1b-Alu-Recycle:before {
  content: "\e95e";
}

.v1b-Ant:before {
  content: "\e95f";
}

.v1b-Antilop:before {
  content: "\e960";
}

.v1b-Aries:before {
  content: "\e961";
}

.v1b-Artboard:before {
  content: "\e962";
}

.v1b-Atm-3:before {
  content: "\e963";
}

.v1b-Attention-Cloud:before {
  content: "\e964";
}

.v1b-Ball-Pen:before {
  content: "\e965";
}

.v1b-Bat:before {
  content: "\e966";
}

.v1b-Bear:before {
  content: "\e967";
}

.v1b-Beatle:before {
  content: "\e968";
}

.v1b-Bee:before {
  content: "\e969";
}

.v1b-Bezier-1:before {
  content: "\e96a";
}

.v1b-Bezier-2:before {
  content: "\e96b";
}

.v1b-Bitcoin-Cloud:before {
  content: "\e96c";
}

.v1b-Blend-Tool:before {
  content: "\e96d";
}

.v1b-Block-Call:before {
  content: "\e96e";
}

.v1b-Bluetuth-PhoneSpeaker:before {
  content: "\e96f";
}

.v1b-Brush-1:before {
  content: "\e970";
}

.v1b-Brush-2:before {
  content: "\e971";
}

.v1b-Butterfly:before {
  content: "\e972";
}

.v1b-Call-24h:before {
  content: "\e973";
}

.v1b-Call-Center24h:before {
  content: "\e974";
}

.v1b-Call-Forward:before {
  content: "\e975";
}

.v1b-Call-Made:before {
  content: "\e976";
}

.v1b-Call-Recieved:before {
  content: "\e977";
}

.v1b-Call-Reservation:before {
  content: "\e978";
}

.v1b-Camel:before {
  content: "\e979";
}

.v1b-Cat:before {
  content: "\e97a";
}

.v1b-Char-Search2:before {
  content: "\e97b";
}

.v1b-Cheetah:before {
  content: "\e97c";
}

.v1b-Chicken:before {
  content: "\e97d";
}

.v1b-Clear-call:before {
  content: "\e97e";
}

.v1b-Clone-Cloud:before {
  content: "\e97f";
}

.v1b-Cloud-Download:before {
  content: "\e980";
}

.v1b-Cloud-Drive:before {
  content: "\e981";
}

.v1b-Cloud-Folder:before {
  content: "\e982";
}

.v1b-Cloud-Help:before {
  content: "\e983";
}

.v1b-Cloud-List:before {
  content: "\e984";
}

.v1b-Cloud-Server:before {
  content: "\e985";
}

.v1b-Cloud-Setting:before {
  content: "\e986";
}

.v1b-Cloud-Upload:before {
  content: "\e987";
}

.v1b-Cloud:before {
  content: "\e988";
}

.v1b-CMYK:before {
  content: "\e989";
}

.v1b-Cockroach:before {
  content: "\e98a";
}

.v1b-Computer-Download:before {
  content: "\e98b";
}

.v1b-Computer-Upload:before {
  content: "\e98c";
}

.v1b-Cow:before {
  content: "\e98d";
}

.v1b-Crain-Truck:before {
  content: "\e98e";
}

.v1b-Crop-Tool:before {
  content: "\e98f";
}

.v1b-Cuter:before {
  content: "\e990";
}

.v1b-Deer:before {
  content: "\e991";
}

.v1b-Delayed-Call:before {
  content: "\e992";
}

.v1b-Design-Software:before {
  content: "\e993";
}

.v1b-Dial-Pad:before {
  content: "\e994";
}

.v1b-Digital-Design:before {
  content: "\e995";
}

.v1b-Dismiss-Cloud:before {
  content: "\e996";
}

.v1b-Dog:before {
  content: "\e997";
}

.v1b-Dollar-Cloud:before {
  content: "\e998";
}

.v1b-Dolphin:before {
  content: "\e999";
}

.v1b-Done-Cloud:before {
  content: "\e99a";
}

.v1b-Donkey:before {
  content: "\e99b";
}

.v1b-Dove:before {
  content: "\e99c";
}

.v1b-Download:before {
  content: "\e99d";
}

.v1b-Duck:before {
  content: "\e99e";
}

.v1b-Dumper-Truck:before {
  content: "\e99f";
}

.v1b-Eagle-1:before {
  content: "\e9a0";
}

.v1b-Eagle-2:before {
  content: "\e9a1";
}

.v1b-Elephant:before {
  content: "\e9a2";
}

.v1b-Enter-1:before {
  content: "\e9a3";
}

.v1b-Enter-2:before {
  content: "\e9a4";
}

.v1b-Eraser-Tool:before {
  content: "\e9a5";
}

.v1b-Error-Cloud:before {
  content: "\e9a6";
}

.v1b-Euro-Cloud:before {
  content: "\e9a7";
}

.v1b-Exit-1:before {
  content: "\e9a8";
}

.v1b-Exit-2:before {
  content: "\e9a9";
}

.v1b-Eyedropper:before {
  content: "\e9aa";
}

.v1b-Favorite-Cloud:before {
  content: "\e9ab";
}

.v1b-Fax-Phone:before {
  content: "\e9ac";
}

.v1b-Female-User:before {
  content: "\e9ad";
}

.v1b-Fish:before {
  content: "\e9ae";
}

.v1b-Flamingo:before {
  content: "\e9af";
}

.v1b-Flower:before {
  content: "\e9b0";
}

.v1b-Fly:before {
  content: "\e9b1";
}

.v1b-Fox:before {
  content: "\e9b2";
}

.v1b-Frog:before {
  content: "\e9b3";
}

.v1b-Giraffe:before {
  content: "\e9b4";
}

.v1b-Gorilla:before {
  content: "\e9b5";
}

.v1b-Gradient-Tool:before {
  content: "\e9b6";
}

.v1b-Grid-Tool:before {
  content: "\e9b7";
}

.v1b-Hamster:before {
  content: "\e9b8";
}

.v1b-Hawk:before {
  content: "\e9b9";
}

.v1b-Hipo:before {
  content: "\e9ba";
}

.v1b-Hold-TheLine:before {
  content: "\e9bb";
}

.v1b-Horse:before {
  content: "\e9bc";
}

.v1b-ID-Tag:before {
  content: "\e9bd";
}

.v1b-Key-22:before {
  content: "\e9be";
}

.v1b-Key-Hole1:before {
  content: "\e9bf";
}

.v1b-Key-Hole2:before {
  content: "\e9c0";
}

.v1b-Koala:before {
  content: "\e9c1";
}

.v1b-Lama:before {
  content: "\e9c2";
}

.v1b-Lasso-Tool:before {
  content: "\e9c3";
}

.v1b-Leaf-1:before {
  content: "\e9c4";
}

.v1b-Line-Tool:before {
  content: "\e9c5";
}

.v1b-Lion:before {
  content: "\e9c6";
}

.v1b-Load-Cloud1:before {
  content: "\e9c7";
}

.v1b-Load-Cloud2:before {
  content: "\e9c8";
}

.v1b-Load-Cloud3:before {
  content: "\e9c9";
}

.v1b-Lock-1:before {
  content: "\e9ca";
}

.v1b-Lock-Call:before {
  content: "\e9cb";
}

.v1b-Lock-User1:before {
  content: "\e9cc";
}

.v1b-Lock-User2:before {
  content: "\e9cd";
}

.v1b-Login-Form1:before {
  content: "\e9ce";
}

.v1b-Login-Form2:before {
  content: "\e9cf";
}

.v1b-Magic-Wand:before {
  content: "\e9d0";
}

.v1b-Marge-Call:before {
  content: "\e9d1";
}

.v1b-Missed-Call:before {
  content: "\e9d2";
}

.v1b-Monkey:before {
  content: "\e9d3";
}

.v1b-Mosquito:before {
  content: "\e9d4";
}

.v1b-Mouse:before {
  content: "\e9d5";
}

.v1b-Name-Tag:before {
  content: "\e9d6";
}

.v1b-Open-Lock:before {
  content: "\e9d7";
}

.v1b-Owl:before {
  content: "\e9d8";
}

.v1b-Paint-Bucket:before {
  content: "\e9d9";
}

.v1b-Paint-Roller:before {
  content: "\e9da";
}

.v1b-Painting-Stand:before {
  content: "\e9db";
}

.v1b-Panda:before {
  content: "\e9dc";
}

.v1b-Parrot:before {
  content: "\e9dd";
}

.v1b-Pause-Call:before {
  content: "\e9de";
}

.v1b-Pelican:before {
  content: "\e9df";
}

.v1b-Pen-Tool:before {
  content: "\e9e0";
}

.v1b-Pencil-tool:before {
  content: "\e9e1";
}

.v1b-Penguin:before {
  content: "\e9e2";
}

.v1b-Pet-Recycle:before {
  content: "\e9e3";
}

.v1b-Phone-1:before {
  content: "\e9e4";
}

.v1b-Phone-2:before {
  content: "\e9e5";
}

.v1b-Phone-3:before {
  content: "\e9e6";
}

.v1b-Phone-4:before {
  content: "\e9e7";
}

.v1b-Phone-Book:before {
  content: "\e9e8";
}

.v1b-Phone-Box:before {
  content: "\e9e9";
}

.v1b-Phone-Download:before {
  content: "\e9ea";
}

.v1b-Phone-InTalk:before {
  content: "\e9eb";
}

.v1b-Phone-Ring:before {
  content: "\e9ec";
}

.v1b-Phone-Shopping:before {
  content: "\e9ed";
}

.v1b-Phone-Upload:before {
  content: "\e9ee";
}

.v1b-Pin-Code:before {
  content: "\e9ef";
}

.v1b-Pound-Cloud:before {
  content: "\e9f0";
}

.v1b-Public-Phone:before {
  content: "\e9f1";
}

.v1b-Rabit:before {
  content: "\e9f2";
}

.v1b-Raccoon:before {
  content: "\e9f3";
}

.v1b-Recall:before {
  content: "\e9f4";
}

.v1b-Record-call:before {
  content: "\e9f5";
}

.v1b-Recycle-2:before {
  content: "\e9f6";
}

.v1b-Reflection-Tool:before {
  content: "\e9f7";
}

.v1b-Remouve-Call:before {
  content: "\e9f8";
}

.v1b-Remouve-Cloud:before {
  content: "\e9f9";
}

.v1b-Responsive-Design:before {
  content: "\e9fa";
}

.v1b-RGB:before {
  content: "\e9fb";
}

.v1b-Rhyno:before {
  content: "\e9fc";
}

.v1b-Rotation-Tool:before {
  content: "\e9fd";
}

.v1b-Ruler-1:before {
  content: "\e9fe";
}

.v1b-Ruler-2:before {
  content: "\e9ff";
}

.v1b-Ruler-Tool:before {
  content: "\ea00";
}

.v1b-Save-fromCloud:before {
  content: "\ea01";
}

.v1b-Save-toCloud:before {
  content: "\ea02";
}

.v1b-Scale-Tool:before {
  content: "\ea03";
}

.v1b-Seadog:before {
  content: "\ea04";
}

.v1b-Search-Cloud:before {
  content: "\ea05";
}

.v1b-Select-1:before {
  content: "\ea06";
}

.v1b-Select-2:before {
  content: "\ea07";
}

.v1b-Selection-Tool:before {
  content: "\ea08";
}

.v1b-Share-Cloud:before {
  content: "\ea09";
}

.v1b-Shark:before {
  content: "\ea0a";
}

.v1b-Shear-Tool:before {
  content: "\ea0b";
}

.v1b-Sheep:before {
  content: "\ea0c";
}

.v1b-Signal-0:before {
  content: "\ea0d";
}

.v1b-Signal-1:before {
  content: "\ea0e";
}

.v1b-Signal-2:before {
  content: "\ea0f";
}

.v1b-Signal-3:before {
  content: "\ea10";
}

.v1b-Signal-4:before {
  content: "\ea11";
}

.v1b-Signal-5:before {
  content: "\ea12";
}

.v1b-Snail:before {
  content: "\ea13";
}

.v1b-Spider:before {
  content: "\ea14";
}

.v1b-Split-Call:before {
  content: "\ea15";
}

.v1b-Sprayer-Tool:before {
  content: "\ea16";
}

.v1b-Squirel:before {
  content: "\ea17";
}

.v1b-Starfish:before {
  content: "\ea18";
}

.v1b-Steve-Jobs:before {
  content: "\ea19";
}

.v1b-Swan:before {
  content: "\ea1a";
}

.v1b-Sync-Cloud:before {
  content: "\ea1b";
}

.v1b-Tablet-Download:before {
  content: "\ea1c";
}

.v1b-Tablet-Upload:before {
  content: "\ea1d";
}

.v1b-Text-box:before {
  content: "\ea1e";
}

.v1b-Thin-Pen:before {
  content: "\ea1f";
}

.v1b-Tiger:before {
  content: "\ea20";
}

.v1b-Turtle:before {
  content: "\ea21";
}

.v1b-Type-Tool:before {
  content: "\ea22";
}

.v1b-Unclean-Water:before {
  content: "\ea23";
}

.v1b-Upload:before {
  content: "\ea24";
}

.v1b-User:before {
  content: "\ea25";
}

.v1b-Wifi-Cloud:before {
  content: "\ea26";
}

.v1b-Wolf:before {
  content: "\ea27";
}

.v1b-Abascus-Calculator:before {
  content: "\ea28";
}

.v1b-Add-Card:before {
  content: "\ea29";
}

.v1b-Addvertise:before {
  content: "\ea2a";
}

.v1b-Allert-Card:before {
  content: "\ea2b";
}

.v1b-Ambulance-1:before {
  content: "\ea2c";
}

.v1b-Amex-2:before {
  content: "\ea2d";
}

.v1b-AMEX:before {
  content: "\ea2e";
}

.v1b-Angel:before {
  content: "\ea2f";
}

.v1b-Atm-1:before {
  content: "\ea30";
}

.v1b-Atm-2:before {
  content: "\ea31";
}

.v1b-Auction:before {
  content: "\ea32";
}

.v1b-Auto-Gearbox:before {
  content: "\ea33";
}

.v1b-Bank-1:before {
  content: "\ea34";
}

.v1b-Bank-2:before {
  content: "\ea35";
}

.v1b-Bank3:before {
  content: "\ea36";
}

.v1b-Battery:before {
  content: "\ea37";
}

.v1b-Bell2:before {
  content: "\ea38";
}

.v1b-Bike-1:before {
  content: "\ea39";
}

.v1b-Bike-2:before {
  content: "\ea3a";
}

.v1b-Bike-3:before {
  content: "\ea3b";
}

.v1b-Bow:before {
  content: "\ea3c";
}

.v1b-Briefcase-1:before {
  content: "\ea3d";
}

.v1b-Briefcase-2:before {
  content: "\ea3e";
}

.v1b-Bus-1:before {
  content: "\ea3f";
}

.v1b-Bus-2:before {
  content: "\ea40";
}

.v1b-Bus-3:before {
  content: "\ea41";
}

.v1b-Cabine-Lift:before {
  content: "\ea42";
}

.v1b-Calculator-1:before {
  content: "\ea43";
}

.v1b-Calculator-2:before {
  content: "\ea44";
}

.v1b-Calculator-3:before {
  content: "\ea45";
}

.v1b-Calculator-4:before {
  content: "\ea46";
}

.v1b-Candell:before {
  content: "\ea47";
}

.v1b-Candy-Stick:before {
  content: "\ea48";
}

.v1b-Candy:before {
  content: "\ea49";
}

.v1b-Car-1:before {
  content: "\ea4a";
}

.v1b-Car-2:before {
  content: "\ea4b";
}

.v1b-Car-3:before {
  content: "\ea4c";
}

.v1b-Car-Service:before {
  content: "\ea4d";
}

.v1b-Car-Wash:before {
  content: "\ea4e";
}

.v1b-Card-Pay:before {
  content: "\ea4f";
}

.v1b-Card-Validity:before {
  content: "\ea50";
}

.v1b-Cargo-Ship:before {
  content: "\ea51";
}

.v1b-Cash-Pay:before {
  content: "\ea52";
}

.v1b-Celsius:before {
  content: "\ea53";
}

.v1b-Check:before {
  content: "\ea54";
}

.v1b-Chimney:before {
  content: "\ea55";
}

.v1b-Christmas-Decoration:before {
  content: "\ea56";
}

.v1b-Christmas-Light:before {
  content: "\ea57";
}

.v1b-Christmas-Star:before {
  content: "\ea58";
}

.v1b-Christmas-Tree:before {
  content: "\ea59";
}

.v1b-Cloud2:before {
  content: "\ea5a";
}

.v1b-Cloudy-Day:before {
  content: "\ea5b";
}

.v1b-Cloudy-Fog:before {
  content: "\ea5c";
}

.v1b-Cloudy-Night:before {
  content: "\ea5d";
}

.v1b-Cloudy:before {
  content: "\ea5e";
}

.v1b-Contact-Book1:before {
  content: "\ea5f";
}

.v1b-Contact-Book2:before {
  content: "\ea60";
}

.v1b-Contact-Info:before {
  content: "\ea61";
}

.v1b-Contract-1:before {
  content: "\ea62";
}

.v1b-Contract-2:before {
  content: "\ea63";
}

.v1b-Cookie-Man:before {
  content: "\ea64";
}

.v1b-Crain-Truck2:before {
  content: "\ea65";
}

.v1b-Dismiss-Card:before {
  content: "\ea66";
}

.v1b-Dollar-Card:before {
  content: "\ea67";
}

.v1b-Done-Card:before {
  content: "\ea68";
}

.v1b-Elf:before {
  content: "\ea69";
}

.v1b-Error-Card:before {
  content: "\ea6a";
}

.v1b-Euro-Card:before {
  content: "\ea6b";
}

.v1b-Fahrenheit:before {
  content: "\ea6c";
}

.v1b-Favorite-Card:before {
  content: "\ea6d";
}

.v1b-Fog-day:before {
  content: "\ea6e";
}

.v1b-Fog-Night:before {
  content: "\ea6f";
}

.v1b-Fog:before {
  content: "\ea70";
}

.v1b-Full-Moon:before {
  content: "\ea71";
}

.v1b-Gear-Box:before {
  content: "\ea72";
}

.v1b-Hailstorm-Day:before {
  content: "\ea73";
}

.v1b-Hailstorm-Night:before {
  content: "\ea74";
}

.v1b-Hailstorm:before {
  content: "\ea75";
}

.v1b-Helicopter:before {
  content: "\ea76";
}

.v1b-Jeep:before {
  content: "\ea77";
}

.v1b-Jetplnade:before {
  content: "\ea78";
}

.v1b-Landing-Plane:before {
  content: "\ea79";
}

.v1b-Light-1:before {
  content: "\ea7a";
}

.v1b-Light-2:before {
  content: "\ea7b";
}

.v1b-Lightning-day:before {
  content: "\ea7c";
}

.v1b-Lightning-night:before {
  content: "\ea7d";
}

.v1b-Lightning:before {
  content: "\ea7e";
}

.v1b-Metro:before {
  content: "\ea7f";
}

.v1b-Mini-bus:before {
  content: "\ea80";
}

.v1b-Mini-Truck2:before {
  content: "\ea81";
}

.v1b-Mini-Truck:before {
  content: "\ea82";
}

.v1b-Mini-Van:before {
  content: "\ea83";
}

.v1b-Mistletoe:before {
  content: "\ea84";
}

.v1b-Moon-2:before {
  content: "\ea85";
}

.v1b-Moon-3:before {
  content: "\ea86";
}

.v1b-Motorcycle:before {
  content: "\ea87";
}

.v1b-Negative-Temperature:before {
  content: "\ea88";
}

.v1b-Ornament:before {
  content: "\ea89";
}

.v1b-Ornamnt-2:before {
  content: "\ea8a";
}

.v1b-Pickup:before {
  content: "\ea8b";
}

.v1b-Plane-1:before {
  content: "\ea8c";
}

.v1b-Plane-2:before {
  content: "\ea8d";
}

.v1b-Plane-3:before {
  content: "\ea8e";
}

.v1b-Plane-4:before {
  content: "\ea8f";
}

.v1b-Plane-Front:before {
  content: "\ea90";
}

.v1b-Police-car:before {
  content: "\ea91";
}

.v1b-Pozitive-Temperature:before {
  content: "\ea92";
}

.v1b-Present-Box:before {
  content: "\ea93";
}

.v1b-Question-Doc:before {
  content: "\ea94";
}

.v1b-Rain-Day:before {
  content: "\ea95";
}

.v1b-Rain-Night:before {
  content: "\ea96";
}

.v1b-Rain-Storm:before {
  content: "\ea97";
}

.v1b-Raining:before {
  content: "\ea98";
}

.v1b-Roadster:before {
  content: "\ea99";
}

.v1b-Rudolf-Deer:before {
  content: "\ea9a";
}

.v1b-Santa-bag:before {
  content: "\ea9b";
}

.v1b-Santa-Claus2:before {
  content: "\ea9c";
}

.v1b-Santa-Hat1:before {
  content: "\ea9d";
}

.v1b-Santa-Hat2:before {
  content: "\ea9e";
}

.v1b-Santa-Slide:before {
  content: "\ea9f";
}

.v1b-School-Bus:before {
  content: "\eaa0";
}

.v1b-Scooter-2:before {
  content: "\eaa1";
}

.v1b-Scooter:before {
  content: "\eaa2";
}

.v1b-Seatbelt:before {
  content: "\eaa3";
}

.v1b-Sedan:before {
  content: "\eaa4";
}

.v1b-Ship-1:before {
  content: "\eaa5";
}

.v1b-Ship-2:before {
  content: "\eaa6";
}

.v1b-Snow-Day:before {
  content: "\eaa7";
}

.v1b-Snow-Flake:before {
  content: "\eaa8";
}

.v1b-Snow-man:before {
  content: "\eaa9";
}

.v1b-Snow-Night:before {
  content: "\eaaa";
}

.v1b-Snow-RainDay:before {
  content: "\eaab";
}

.v1b-Snow-RainNight:before {
  content: "\eaac";
}

.v1b-Snow-Rain:before {
  content: "\eaad";
}

.v1b-Snow:before {
  content: "\eaae";
}

.v1b-Snowbulb:before {
  content: "\eaaf";
}

.v1b-Snowing:before {
  content: "\eab0";
}

.v1b-Speed-Meter:before {
  content: "\eab1";
}

.v1b-Sport-Car1:before {
  content: "\eab2";
}

.v1b-Star-Night:before {
  content: "\eab3";
}

.v1b-Star:before {
  content: "\eab4";
}

.v1b-Storm-day:before {
  content: "\eab5";
}

.v1b-Storm-night:before {
  content: "\eab6";
}

.v1b-Sun:before {
  content: "\eab7";
}

.v1b-Take-offPlane:before {
  content: "\eab8";
}

.v1b-Taxi:before {
  content: "\eab9";
}

.v1b-Temperatur-night:before {
  content: "\eaba";
}

.v1b-Temperature-Day:before {
  content: "\eabb";
}

.v1b-Termometer:before {
  content: "\eabc";
}

.v1b-Thunder-StormDay:before {
  content: "\eabd";
}

.v1b-Thunder-StormNight:before {
  content: "\eabe";
}

.v1b-Thunderstorm:before {
  content: "\eabf";
}

.v1b-Tornado:before {
  content: "\eac0";
}

.v1b-Trafic-light1:before {
  content: "\eac1";
}

.v1b-Trafic-Light2:before {
  content: "\eac2";
}

.v1b-Train-1:before {
  content: "\eac3";
}

.v1b-Train-2:before {
  content: "\eac4";
}

.v1b-Train-3:before {
  content: "\eac5";
}

.v1b-Tram:before {
  content: "\eac6";
}

.v1b-Troleybus:before {
  content: "\eac7";
}

.v1b-Truck-1:before {
  content: "\eac8";
}

.v1b-Truck-2:before {
  content: "\eac9";
}

.v1b-Truck:before {
  content: "\eaca";
}

.v1b-VW-Bug:before {
  content: "\eacb";
}

.v1b-VW-T1 .path1:before {
  content: "\eacc";
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path2:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path3:before {
  content: "\eace";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path4:before {
  content: "\eacf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.v1b-VW-T1 .path5:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path6:before {
  content: "\ead1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path7:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path8:before {
  content: "\ead3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path9:before {
  content: "\ead4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path10:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path11:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-VW-T1 .path12:before {
  content: "\ead7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Wind:before {
  content: "\ead8";
}

.v1b-Window:before {
  content: "\ead9";
}

.v1b-Windshield-Cleaner:before {
  content: "\eada";
}

.v1b-Winter-Temperature:before {
  content: "\eadb";
}

.v1b-Xmas-Soks:before {
  content: "\eadc";
}

.v1b-Xmas-Tag:before {
  content: "\eadd";
}

.v1b-BedRoom:before {
  content: "\eade";
}

.v1b-FingerTouch:before {
  content: "\eadf";
}

.v1b-Hour:before {
  content: "\eae0";
}

.v1b-BedRoom2:before {
  content: "\eae1";
}

.v1b-Files:before {
  content: "\eae2";
}

.v1b-FingerTouch2:before {
  content: "\eae3";
}

.v1b-Files2:before {
  content: "\eae4";
}

.v1b-FingerTouch3:before {
  content: "\eae5";
}

.v1b-QuarterHour:before {
  content: "\eae6";
}

.v1b-StarBed:before {
  content: "\eae7";
}

.v1b-D-Blockchart1:before {
  content: "\eae8";
}

.v1b-G-Network:before {
  content: "\eae9";
}

.v1b-gp-Doc:before {
  content: "\eaea";
}

.v1b-FingerRoatate:before {
  content: "\eaeb";
}

.v1b-FingerTouch4:before {
  content: "\eaec";
}

.v1b-G-Network2:before {
  content: "\eaed";
}

.v1b-StarHotel1:before {
  content: "\eaee";
}

.v1b-Starhotel2:before {
  content: "\eaef";
}

.v1b-Ball:before {
  content: "\eaf0";
}

.v1b-by7:before {
  content: "\eaf1";
}

.v1b-Accordion:before {
  content: "\eaf2";
}

.v1b-Acrobat-File:before {
  content: "\eaf3";
}

.v1b-Add-Basket:before {
  content: "\eaf4";
}

.v1b-Add-Bookmark:before {
  content: "\eaf5";
}

.v1b-Add-Cart2:before {
  content: "\eaf6";
}

.v1b-Add-Chat1:before {
  content: "\eaf7";
}

.v1b-Add-Chat2:before {
  content: "\eaf8";
}

.v1b-Add-Chat3:before {
  content: "\eaf9";
}

.v1b-Add-Doc:before {
  content: "\eafa";
}

.v1b-Add-File:before {
  content: "\eafb";
}

.v1b-Add-Folder:before {
  content: "\eafc";
}

.v1b-Add-Location:before {
  content: "\eafd";
}

.v1b-Add-Mail:before {
  content: "\eafe";
}

.v1b-Add-Wifi:before {
  content: "\eaff";
}

.v1b-Addon-Setting:before {
  content: "\eb00";
}

.v1b-Adobe-FlashPlayer:before {
  content: "\eb01";
}

.v1b-Adobe:before {
  content: "\eb02";
}

.v1b-Aif-Doc:before {
  content: "\eb03";
}

.v1b-Air-Conditioner2:before {
  content: "\eb04";
}

.v1b-Air-Conditioner:before {
  content: "\eb05";
}

.v1b-Air-Conditioner1:before {
  content: "\eb06";
}

.v1b-Airbnb:before {
  content: "\eb07";
}

.v1b-Alambic:before {
  content: "\eb08";
}

.v1b-Alarm-Clock1:before {
  content: "\eb09";
}

.v1b-Alarm-Clock3:before {
  content: "\eb0a";
}

.v1b-Alarm-Sound:before {
  content: "\eb0b";
}

.v1b-Align-Center:before {
  content: "\eb0c";
}

.v1b-Align-Left:before {
  content: "\eb0d";
}

.v1b-Align-Right:before {
  content: "\eb0e";
}

.v1b-All-Directions:before {
  content: "\eb0f";
}

.v1b-Alphabet-List:before {
  content: "\eb10";
}

.v1b-Amazon:before {
  content: "\eb11";
}

.v1b-Ambulance:before {
  content: "\eb12";
}

.v1b-Amd:before {
  content: "\eb13";
}

.v1b-Amplifier-2:before {
  content: "\eb14";
}

.v1b-Amplifier:before {
  content: "\eb15";
}

.v1b-Analogue-Antenna:before {
  content: "\eb16";
}

.v1b-Anchor:before {
  content: "\eb17";
}

.v1b-Android:before {
  content: "\eb18";
}

.v1b-Angel2:before {
  content: "\eb19";
}

.v1b-Angry-Birds2:before {
  content: "\eb1a";
}

.v1b-Angry-Birds:before {
  content: "\eb1b";
}

.v1b-Anonymous-1:before {
  content: "\eb1c";
}

.v1b-Anonymous-2:before {
  content: "\eb1d";
}

.v1b-App-Setting:before {
  content: "\eb1e";
}

.v1b-Apple2:before {
  content: "\eb1f";
}

.v1b-Apple:before {
  content: "\eb20";
}

.v1b-Appstore-2:before {
  content: "\eb21";
}

.v1b-Arc-Phisics:before {
  content: "\eb22";
}

.v1b-Arcade:before {
  content: "\eb23";
}

.v1b-Archery-1:before {
  content: "\eb24";
}

.v1b-Archery-2:before {
  content: "\eb25";
}

.v1b-Army-Solider:before {
  content: "\eb26";
}

.v1b-Astronaut:before {
  content: "\eb27";
}

.v1b-At-Symbol:before {
  content: "\eb28";
}

.v1b-Ati:before {
  content: "\eb29";
}

.v1b-Atom:before {
  content: "\eb2a";
}

.v1b-Atomic-Reactor:before {
  content: "\eb2b";
}

.v1b-Attach-File:before {
  content: "\eb2c";
}

.v1b-Attache-Mail:before {
  content: "\eb2d";
}

.v1b-Attacher:before {
  content: "\eb2e";
}

.v1b-Attention1-Doc:before {
  content: "\eb2f";
}

.v1b-Attention2-Doc:before {
  content: "\eb30";
}

.v1b-Auto-Flash:before {
  content: "\eb31";
}

.v1b-Aux-Cable:before {
  content: "\eb32";
}

.v1b-Avi-Doc:before {
  content: "\eb33";
}

.v1b-Axe:before {
  content: "\eb34";
}

.v1b-Backward-Button:before {
  content: "\eb35";
}

.v1b-Backward:before {
  content: "\eb36";
}

.v1b-Bacteria-1:before {
  content: "\eb37";
}

.v1b-Bacteria-4:before {
  content: "\eb38";
}

.v1b-Bad-Pig:before {
  content: "\eb39";
}

.v1b-Badminton:before {
  content: "\eb3a";
}

.v1b-Bag-1:before {
  content: "\eb3b";
}

.v1b-Bag-2:before {
  content: "\eb3c";
}

.v1b-Balance-1:before {
  content: "\eb3d";
}

.v1b-Balance-2:before {
  content: "\eb3e";
}

.v1b-Bald-Male:before {
  content: "\eb3f";
}

.v1b-Balloons:before {
  content: "\eb40";
}

.v1b-Baloon:before {
  content: "\eb41";
}

.v1b-Banana:before {
  content: "\eb42";
}

.v1b-Banch:before {
  content: "\eb43";
}

.v1b-Barbeque:before {
  content: "\eb44";
}

.v1b-Barcode-Scanner:before {
  content: "\eb45";
}

.v1b-Barcode:before {
  content: "\eb46";
}

.v1b-Barrow:before {
  content: "\eb47";
}

.v1b-Baseball-2:before {
  content: "\eb48";
}

.v1b-Baseball-Cap:before {
  content: "\eb49";
}

.v1b-Baseball:before {
  content: "\eb4a";
}

.v1b-Basket-1:before {
  content: "\eb4b";
}

.v1b-Basket-2:before {
  content: "\eb4c";
}

.v1b-Basket-Ball:before {
  content: "\eb4d";
}

.v1b-Basket:before {
  content: "\eb4e";
}

.v1b-Bass-key:before {
  content: "\eb4f";
}

.v1b-Bat-Man:before {
  content: "\eb50";
}

.v1b-Bath-Robe:before {
  content: "\eb51";
}

.v1b-Battery2:before {
  content: "\eb52";
}

.v1b-Battery-0:before {
  content: "\eb53";
}

.v1b-Battery-1:before {
  content: "\eb54";
}

.v1b-Battery-2:before {
  content: "\eb55";
}

.v1b-Battery-3:before {
  content: "\eb56";
}

.v1b-Battery-4:before {
  content: "\eb57";
}

.v1b-Battrey-Charge:before {
  content: "\eb58";
}

.v1b-Beach-Ball:before {
  content: "\eb59";
}

.v1b-Beanie-Hat:before {
  content: "\eb5a";
}

.v1b-Beard-Man:before {
  content: "\eb5b";
}

.v1b-Beats:before {
  content: "\eb5c";
}

.v1b-Beer-Mug:before {
  content: "\eb5d";
}

.v1b-Behance:before {
  content: "\eb5e";
}

.v1b-Bell:before {
  content: "\eb5f";
}

.v1b-Bellboy:before {
  content: "\eb60";
}

.v1b-Bellhop:before {
  content: "\eb61";
}

.v1b-Belt:before {
  content: "\eb62";
}

.v1b-Benjo:before {
  content: "\eb63";
}

.v1b-Big-Eye:before {
  content: "\eb64";
}

.v1b-Big-Jack:before {
  content: "\eb65";
}

.v1b-Big-Shopping:before {
  content: "\eb66";
}

.v1b-Big-Smile:before {
  content: "\eb67";
}

.v1b-Bike:before {
  content: "\eb68";
}

.v1b-Bikini:before {
  content: "\eb69";
}

.v1b-Binders:before {
  content: "\eb6a";
}

.v1b-Bing:before {
  content: "\eb6b";
}

.v1b-Binocular:before {
  content: "\eb6c";
}

.v1b-Bio-1:before {
  content: "\eb6d";
}

.v1b-Bio-2:before {
  content: "\eb6e";
}

.v1b-Bio-Ennergy:before {
  content: "\eb6f";
}

.v1b-Bio-Gas:before {
  content: "\eb70";
}

.v1b-Biohazzard:before {
  content: "\eb71";
}

.v1b-Bitcoin-2:before {
  content: "\eb72";
}

.v1b-Bitcoin-3:before {
  content: "\eb73";
}

.v1b-Bitcoin-Doc:before {
  content: "\eb74";
}

.v1b-Black-Friday:before {
  content: "\eb75";
}

.v1b-Blank-File:before {
  content: "\eb76";
}

.v1b-Block-Chart1:before {
  content: "\eb77";
}

.v1b-Block-Chart2:before {
  content: "\eb78";
}

.v1b-Block-Chart3:before {
  content: "\eb79";
}

.v1b-Block-Chart4:before {
  content: "\eb7a";
}

.v1b-Blogger:before {
  content: "\eb7b";
}

.v1b-Blood-Cells:before {
  content: "\eb7c";
}

.v1b-Blood-Drop:before {
  content: "\eb7d";
}

.v1b-Bluetuth:before {
  content: "\eb7e";
}

.v1b-Blututh-Headset:before {
  content: "\eb7f";
}

.v1b-Boiled-Egg:before {
  content: "\eb80";
}

.v1b-Bold:before {
  content: "\eb81";
}

.v1b-Bomb:before {
  content: "\eb82";
}

.v1b-Bomber-Man:before {
  content: "\eb83";
}

.v1b-Bookmark-1:before {
  content: "\eb84";
}

.v1b-Bookmark-2:before {
  content: "\eb85";
}

.v1b-Bookmark-3:before {
  content: "\eb86";
}

.v1b-Bookmark-4:before {
  content: "\eb87";
}

.v1b-Bookmark-Settings:before {
  content: "\eb88";
}

.v1b-Bookmark-Site2:before {
  content: "\eb89";
}

.v1b-Bookmark-Site:before {
  content: "\eb8a";
}

.v1b-Bookmarked-File:before {
  content: "\eb8b";
}

.v1b-Boot:before {
  content: "\eb8c";
}

.v1b-Botcoin-1:before {
  content: "\eb8d";
}

.v1b-Bow-Tie:before {
  content: "\eb8e";
}

.v1b-Bowler-Hat:before {
  content: "\eb8f";
}

.v1b-Bowling-Ball:before {
  content: "\eb90";
}

.v1b-Bowling:before {
  content: "\eb91";
}

.v1b-Boxing-Bag:before {
  content: "\eb92";
}

.v1b-Boxing-Glov:before {
  content: "\eb93";
}

.v1b-Boxing-Helmet:before {
  content: "\eb94";
}

.v1b-Boxing-Ring:before {
  content: "\eb95";
}

.v1b-Bra:before {
  content: "\eb96";
}

.v1b-Brain:before {
  content: "\eb97";
}

.v1b-Bread:before {
  content: "\eb98";
}

.v1b-Breakout:before {
  content: "\eb99";
}

.v1b-Bridge:before {
  content: "\eb9a";
}

.v1b-Brightness-2:before {
  content: "\eb9b";
}

.v1b-Brightness-3:before {
  content: "\eb9c";
}

.v1b-Brigthness-1:before {
  content: "\eb9d";
}

.v1b-Broken-Glas:before {
  content: "\eb9e";
}

.v1b-Broken-Heart:before {
  content: "\eb9f";
}

.v1b-Broken-Link:before {
  content: "\eba0";
}

.v1b-Bucket:before {
  content: "\eba1";
}

.v1b-Buddybuilding:before {
  content: "\eba2";
}

.v1b-Bug-Protect:before {
  content: "\eba3";
}

.v1b-Bullet-List:before {
  content: "\eba4";
}

.v1b-Bulleted-List:before {
  content: "\eba5";
}

.v1b-Busi-2:before {
  content: "\eba6";
}

.v1b-Business-Man:before {
  content: "\eba7";
}

.v1b-Business-Woman:before {
  content: "\eba8";
}

.v1b-Busy-1:before {
  content: "\eba9";
}

.v1b-Button:before {
  content: "\ebaa";
}

.v1b-Cab:before {
  content: "\ebab";
}

.v1b-Cactus:before {
  content: "\ebac";
}

.v1b-Caffe-Bean:before {
  content: "\ebad";
}

.v1b-Caffe-Mug:before {
  content: "\ebae";
}

.v1b-Calendar-Clock:before {
  content: "\ebaf";
}

.v1b-Calendar:before {
  content: "\ebb0";
}

.v1b-Call:before {
  content: "\ebb1";
}

.v1b-Camera-12:before {
  content: "\ebb2";
}

.v1b-Camera-1:before {
  content: "\ebb3";
}

.v1b-Camera-22:before {
  content: "\ebb4";
}

.v1b-Camera-2:before {
  content: "\ebb5";
}

.v1b-Camera-3:before {
  content: "\ebb6";
}

.v1b-Camera-42:before {
  content: "\ebb7";
}

.v1b-Camera-4:before {
  content: "\ebb8";
}

.v1b-Camera-5:before {
  content: "\ebb9";
}

.v1b-Camera-6:before {
  content: "\ebba";
}

.v1b-Camera-7:before {
  content: "\ebbb";
}

.v1b-Camera-8:before {
  content: "\ebbc";
}

.v1b-Camera-9:before {
  content: "\ebbd";
}

.v1b-Camera-Rear:before {
  content: "\ebbe";
}

.v1b-Camera-roll:before {
  content: "\ebbf";
}

.v1b-Camp-Bag:before {
  content: "\ebc0";
}

.v1b-Camp-Fire2:before {
  content: "\ebc1";
}

.v1b-Camp-Fire:before {
  content: "\ebc2";
}

.v1b-Camping-Knief:before {
  content: "\ebc3";
}

.v1b-Captain-America:before {
  content: "\ebc4";
}

.v1b-Car-AirPump:before {
  content: "\ebc5";
}

.v1b-Car-Secure:before {
  content: "\ebc6";
}

.v1b-Carnaval:before {
  content: "\ebc7";
}

.v1b-Carrot:before {
  content: "\ebc8";
}

.v1b-Cart-1:before {
  content: "\ebc9";
}

.v1b-Cart-2:before {
  content: "\ebca";
}

.v1b-Cart-Done:before {
  content: "\ebcb";
}

.v1b-Cash-Payment:before {
  content: "\ebcc";
}

.v1b-Cassette:before {
  content: "\ebcd";
}

.v1b-CD-case:before {
  content: "\ebce";
}

.v1b-Cello:before {
  content: "\ebcf";
}

.v1b-Chaplin:before {
  content: "\ebd0";
}

.v1b-Character-Spacing:before {
  content: "\ebd1";
}

.v1b-Chat-1:before {
  content: "\ebd2";
}

.v1b-Chat-2:before {
  content: "\ebd3";
}

.v1b-Chat-3:before {
  content: "\ebd4";
}

.v1b-Chat-Bubble1:before {
  content: "\ebd5";
}

.v1b-Chat-Bubble2:before {
  content: "\ebd6";
}

.v1b-Chat-Bubble4:before {
  content: "\ebd7";
}

.v1b-Chat-Bubble5:before {
  content: "\ebd8";
}

.v1b-Chat-Buble3:before {
  content: "\ebd9";
}

.v1b-Chat-Delete1:before {
  content: "\ebda";
}

.v1b-Chat-Delete2:before {
  content: "\ebdb";
}

.v1b-Chat-Delete3:before {
  content: "\ebdc";
}

.v1b-Chat-Error1:before {
  content: "\ebdd";
}

.v1b-Chat-Error2:before {
  content: "\ebde";
}

.v1b-Chat-Error3:before {
  content: "\ebdf";
}

.v1b-Chat-Help1:before {
  content: "\ebe0";
}

.v1b-Chat-Help2:before {
  content: "\ebe1";
}

.v1b-Chat-Help3:before {
  content: "\ebe2";
}

.v1b-Chat-Remouve1:before {
  content: "\ebe3";
}

.v1b-Chat-Remouve2:before {
  content: "\ebe4";
}

.v1b-Chat-Remouve3:before {
  content: "\ebe5";
}

.v1b-Chat-Search1:before {
  content: "\ebe6";
}

.v1b-Chat-Search3:before {
  content: "\ebe7";
}

.v1b-Chat-Settings1:before {
  content: "\ebe8";
}

.v1b-Chat-Settings2:before {
  content: "\ebe9";
}

.v1b-Chat-settings3:before {
  content: "\ebea";
}

.v1b-Chat-User1:before {
  content: "\ebeb";
}

.v1b-Chat-User2:before {
  content: "\ebec";
}

.v1b-Chat-User3:before {
  content: "\ebed";
}

.v1b-Chat-User4:before {
  content: "\ebee";
}

.v1b-Check-File:before {
  content: "\ebef";
}

.v1b-Checkout-Bitcoin1:before {
  content: "\ebf0";
}

.v1b-Checkout-Bitcoin2:before {
  content: "\ebf1";
}

.v1b-Checkout-Dollar1:before {
  content: "\ebf2";
}

.v1b-Checkout-Dollar2:before {
  content: "\ebf3";
}

.v1b-Checkout-Euro1:before {
  content: "\ebf4";
}

.v1b-Checkout-Euro2:before {
  content: "\ebf5";
}

.v1b-Checkout-Pound1:before {
  content: "\ebf6";
}

.v1b-Checout-Pound2:before {
  content: "\ebf7";
}

.v1b-Cheese:before {
  content: "\ebf8";
}

.v1b-Chef:before {
  content: "\ebf9";
}

.v1b-Cherry:before {
  content: "\ebfa";
}

.v1b-Chess:before {
  content: "\ebfb";
}

.v1b-Chicken-leg:before {
  content: "\ebfc";
}

.v1b-Chilly:before {
  content: "\ebfd";
}

.v1b-Chip:before {
  content: "\ebfe";
}

.v1b-Chrome:before {
  content: "\ebff";
}

.v1b-Circus:before {
  content: "\ec00";
}

.v1b-Clear-Basket:before {
  content: "\ec01";
}

.v1b-Clear-Bookmark:before {
  content: "\ec02";
}

.v1b-Clear-Cart2:before {
  content: "\ec03";
}

.v1b-Clear-File:before {
  content: "\ec04";
}

.v1b-Clear-Folder:before {
  content: "\ec05";
}

.v1b-Clear-Format:before {
  content: "\ec06";
}

.v1b-Clear-Formatting:before {
  content: "\ec07";
}

.v1b-Clear-Location:before {
  content: "\ec08";
}

.v1b-Clear-Network:before {
  content: "\ec09";
}

.v1b-Click-1:before {
  content: "\ec0a";
}

.v1b-Click-2:before {
  content: "\ec0b";
}

.v1b-Clock-1:before {
  content: "\ec0c";
}

.v1b-Clock-2:before {
  content: "\ec0d";
}

.v1b-Close-2:before {
  content: "\ec0e";
}

.v1b-Closr-1:before {
  content: "\ec0f";
}

.v1b-Cloud-Folder2:before {
  content: "\ec10";
}

.v1b-Cloud-Network:before {
  content: "\ec11";
}

.v1b-Clown:before {
  content: "\ec12";
}

.v1b-Clubs-AceCard:before {
  content: "\ec13";
}

.v1b-Cmera-3:before {
  content: "\ec14";
}

.v1b-Coal-Railcar:before {
  content: "\ec15";
}

.v1b-Coat-Rank:before {
  content: "\ec16";
}

.v1b-Coat:before {
  content: "\ec17";
}

.v1b-Coce-withGlass:before {
  content: "\ec18";
}

.v1b-Coctail-Glass1:before {
  content: "\ec19";
}

.v1b-Coctail-Glass2:before {
  content: "\ec1a";
}

.v1b-Coding:before {
  content: "\ec1b";
}

.v1b-Coffe-ToGo:before {
  content: "\ec1c";
}

.v1b-Coffe:before {
  content: "\ec1d";
}

.v1b-Coin-andCash:before {
  content: "\ec1e";
}

.v1b-Coins-1:before {
  content: "\ec1f";
}

.v1b-Coins-2:before {
  content: "\ec20";
}

.v1b-Coins-3:before {
  content: "\ec21";
}

.v1b-Combine-File:before {
  content: "\ec22";
}

.v1b-Compact-Disc:before {
  content: "\ec23";
}

.v1b-Compas-Rose:before {
  content: "\ec24";
}

.v1b-Compass-1:before {
  content: "\ec25";
}

.v1b-Compass-2:before {
  content: "\ec26";
}

.v1b-Compose-Mail1:before {
  content: "\ec27";
}

.v1b-Compose-Mail2:before {
  content: "\ec28";
}

.v1b-Computer-Network1:before {
  content: "\ec29";
}

.v1b-Computer-Network2:before {
  content: "\ec2a";
}

.v1b-Concert-Lighting:before {
  content: "\ec2b";
}

.v1b-Concrete-Truck:before {
  content: "\ec2c";
}

.v1b-Conference-Chat:before {
  content: "\ec2d";
}

.v1b-Conference-Speach:before {
  content: "\ec2e";
}

.v1b-Conga-1:before {
  content: "\ec2f";
}

.v1b-Conga-2:before {
  content: "\ec30";
}

.v1b-Contact-Folder:before {
  content: "\ec31";
}

.v1b-Container-Railcar:before {
  content: "\ec32";
}

.v1b-Converse:before {
  content: "\ec33";
}

.v1b-Convert-Bitcoin:before {
  content: "\ec34";
}

.v1b-Convert-Curency:before {
  content: "\ec35";
}

.v1b-Copy-Machine:before {
  content: "\ec36";
}

.v1b-Corn:before {
  content: "\ec37";
}

.v1b-Coroflot:before {
  content: "\ec38";
}

.v1b-Corrector:before {
  content: "\ec39";
}

.v1b-Countdown-1:before {
  content: "\ec3a";
}

.v1b-Countdown-2:before {
  content: "\ec3b";
}

.v1b-Countdown-3:before {
  content: "\ec3c";
}

.v1b-Countdown-4:before {
  content: "\ec3d";
}

.v1b-Countdown-5:before {
  content: "\ec3e";
}

.v1b-Crain-Hook:before {
  content: "\ec3f";
}

.v1b-Crain:before {
  content: "\ec40";
}

.v1b-Credit-Card1:before {
  content: "\ec41";
}

.v1b-Credit-Card2:before {
  content: "\ec42";
}

.v1b-Credit-Card3:before {
  content: "\ec43";
}

.v1b-Criminal:before {
  content: "\ec44";
}

.v1b-Croissant:before {
  content: "\ec45";
}

.v1b-Cronometer:before {
  content: "\ec46";
}

.v1b-Crop-Image:before {
  content: "\ec47";
}

.v1b-Crown-1:before {
  content: "\ec48";
}

.v1b-Crown-2:before {
  content: "\ec49";
}

.v1b-Crunchyroll:before {
  content: "\ec4a";
}

.v1b-Cry-Hard:before {
  content: "\ec4b";
}

.v1b-Cry:before {
  content: "\ec4c";
}

.v1b-CSS-3:before {
  content: "\ec4d";
}

.v1b-Cup-1:before {
  content: "\ec4e";
}

.v1b-Cup-2:before {
  content: "\ec4f";
}

.v1b-Cup-3:before {
  content: "\ec50";
}

.v1b-Cursor-Select1:before {
  content: "\ec51";
}

.v1b-Cusror-Select2:before {
  content: "\ec52";
}

.v1b-Cylinder-Hat:before {
  content: "\ec53";
}

.v1b-Cymbal:before {
  content: "\ec54";
}

.v1b-Cystern-Railcar:before {
  content: "\ec55";
}

.v1b-Cystern-Truck:before {
  content: "\ec56";
}

.v1b-Dailybooth:before {
  content: "\ec57";
}

.v1b-Darth-Vader:before {
  content: "\ec58";
}

.v1b-Darts:before {
  content: "\ec59";
}

.v1b-Decode-File:before {
  content: "\ec5a";
}

.v1b-Decrease-Indent:before {
  content: "\ec5b";
}

.v1b-Decrease-Margin:before {
  content: "\ec5c";
}

.v1b-Decreasing-Chart1:before {
  content: "\ec5d";
}

.v1b-Decreasing-Chart2:before {
  content: "\ec5e";
}

.v1b-Delicious:before {
  content: "\ec5f";
}

.v1b-Desert:before {
  content: "\ec60";
}

.v1b-Designfloat:before {
  content: "\ec61";
}

.v1b-Designmoo:before {
  content: "\ec62";
}

.v1b-Desktop-Security:before {
  content: "\ec63";
}

.v1b-Deviant-Art:before {
  content: "\ec64";
}

.v1b-Devil:before {
  content: "\ec65";
}

.v1b-Diagram-1:before {
  content: "\ec66";
}

.v1b-Diagram-2:before {
  content: "\ec67";
}

.v1b-Diagram-3:before {
  content: "\ec68";
}

.v1b-Diagram-4:before {
  content: "\ec69";
}

.v1b-Dial-Pad2:before {
  content: "\ec6a";
}

.v1b-Diamond-AceCard:before {
  content: "\ec6b";
}

.v1b-Diamond:before {
  content: "\ec6c";
}

.v1b-Dice2:before {
  content: "\ec6d";
}

.v1b-Dice:before {
  content: "\ec6e";
}

.v1b-Digg:before {
  content: "\ec6f";
}

.v1b-Digital-AlarmClock:before {
  content: "\ec70";
}

.v1b-Diigo:before {
  content: "\ec71";
}

.v1b-Direction-Control1:before {
  content: "\ec72";
}

.v1b-Direction-Control2:before {
  content: "\ec73";
}

.v1b-Direction-Select1:before {
  content: "\ec74";
}

.v1b-Direction-Select2:before {
  content: "\ec75";
}

.v1b-Directions-2:before {
  content: "\ec76";
}

.v1b-Directions:before {
  content: "\ec77";
}

.v1b-Disc-Doc:before {
  content: "\ec78";
}

.v1b-Discount-Bitcoin:before {
  content: "\ec79";
}

.v1b-Discount-Coupon:before {
  content: "\ec7a";
}

.v1b-Discount-Dollar:before {
  content: "\ec7b";
}

.v1b-Discount-Euro:before {
  content: "\ec7c";
}

.v1b-Discount-Pound:before {
  content: "\ec7d";
}

.v1b-Disk-Cutter:before {
  content: "\ec7e";
}

.v1b-Dismiss-Bookmark:before {
  content: "\ec7f";
}

.v1b-Dismiss-Chat1:before {
  content: "\ec80";
}

.v1b-Dismiss-Chat2:before {
  content: "\ec81";
}

.v1b-Dismiss-Chat3:before {
  content: "\ec82";
}

.v1b-Dismiss-Doc:before {
  content: "\ec83";
}

.v1b-Dismiss-File:before {
  content: "\ec84";
}

.v1b-Dismiss-Firewall:before {
  content: "\ec85";
}

.v1b-Dismiss-Folder:before {
  content: "\ec86";
}

.v1b-Dismiss-Mail:before {
  content: "\ec87";
}

.v1b-Dismiss-Network:before {
  content: "\ec88";
}

.v1b-Dismiss-Settings:before {
  content: "\ec89";
}

.v1b-Dispacher-1:before {
  content: "\ec8a";
}

.v1b-Dispacher-2:before {
  content: "\ec8b";
}

.v1b-Distance-1:before {
  content: "\ec8c";
}

.v1b-Distance-2:before {
  content: "\ec8d";
}

.v1b-Diving-Mask:before {
  content: "\ec8e";
}

.v1b-Dj-mixer:before {
  content: "\ec8f";
}

.v1b-DNA:before {
  content: "\ec90";
}

.v1b-Do-NotDisturbe:before {
  content: "\ec91";
}

.v1b-Document-Cutter:before {
  content: "\ec92";
}

.v1b-Document-File:before {
  content: "\ec93";
}

.v1b-Documents:before {
  content: "\ec94";
}

.v1b-Dodgem:before {
  content: "\ec95";
}

.v1b-Dollar-1:before {
  content: "\ec96";
}

.v1b-Dollar-2:before {
  content: "\ec97";
}

.v1b-Dollar-3:before {
  content: "\ec98";
}

.v1b-Dollar-Doc:before {
  content: "\ec99";
}

.v1b-Dollar-Fall:before {
  content: "\ec9a";
}

.v1b-Dollar-Rise:before {
  content: "\ec9b";
}

.v1b-Domino:before {
  content: "\ec9c";
}

.v1b-Donate-Blood:before {
  content: "\ec9d";
}

.v1b-Donate .path1:before {
  content: "\ec9e";
  color: rgb(0, 0, 0);
}

.v1b-Donate .path2:before {
  content: "\ec9f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Donate .path3:before {
  content: "\eca0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.v1b-Donate .path4:before {
  content: "\eca1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Done-Basket:before {
  content: "\eca2";
}

.v1b-Done-Bookmark:before {
  content: "\eca3";
}

.v1b-Done-Doc:before {
  content: "\eca4";
}

.v1b-Done-Folder:before {
  content: "\eca5";
}

.v1b-Done-Location:before {
  content: "\eca6";
}

.v1b-Done-Mail:before {
  content: "\eca7";
}

.v1b-Dont-TouchRound:before {
  content: "\eca8";
}

.v1b-Dont-Touch:before {
  content: "\eca9";
}

.v1b-Donut:before {
  content: "\ecaa";
}

.v1b-Door-Hanger:before {
  content: "\ecab";
}

.v1b-Dot-Chart1:before {
  content: "\ecac";
}

.v1b-Dot-Chart2:before {
  content: "\ecad";
}

.v1b-Dot-Chart3:before {
  content: "\ecae";
}

.v1b-Double-Click1:before {
  content: "\ecaf";
}

.v1b-Double-Click2:before {
  content: "\ecb0";
}

.v1b-Double-Tap:before {
  content: "\ecb1";
}

.v1b-Down-1:before {
  content: "\ecb2";
}

.v1b-Down-2:before {
  content: "\ecb3";
}

.v1b-Down-3:before {
  content: "\ecb4";
}

.v1b-Down-4:before {
  content: "\ecb5";
}

.v1b-Down-5:before {
  content: "\ecb6";
}

.v1b-Down-6:before {
  content: "\ecb7";
}

.v1b-Down-7:before {
  content: "\ecb8";
}

.v1b-Down-8:before {
  content: "\ecb9";
}

.v1b-Down-9:before {
  content: "\ecba";
}

.v1b-Down-10:before {
  content: "\ecbb";
}

.v1b-Down-11:before {
  content: "\ecbc";
}

.v1b-Down-12:before {
  content: "\ecbd";
}

.v1b-Down-Left1:before {
  content: "\ecbe";
}

.v1b-Down-Right1:before {
  content: "\ecbf";
}

.v1b-Download-Bookmark:before {
  content: "\ecc0";
}

.v1b-Download-Doc:before {
  content: "\ecc1";
}

.v1b-Download-File:before {
  content: "\ecc2";
}

.v1b-Download-Folder:before {
  content: "\ecc3";
}

.v1b-Drag-Drop:before {
  content: "\ecc4";
}

.v1b-Drag-Down:before {
  content: "\ecc5";
}

.v1b-Drag-Hand1:before {
  content: "\ecc6";
}

.v1b-Drag-Hand2:before {
  content: "\ecc7";
}

.v1b-Drag-Location:before {
  content: "\ecc8";
}

.v1b-Drag-Up:before {
  content: "\ecc9";
}

.v1b-Dream-Bubble1:before {
  content: "\ecca";
}

.v1b-Dream-Bubble2:before {
  content: "\eccb";
}

.v1b-Dreamimg-2:before {
  content: "\eccc";
}

.v1b-Dreaming-1:before {
  content: "\eccd";
}

.v1b-Dress-1:before {
  content: "\ecce";
}

.v1b-Dress-2:before {
  content: "\eccf";
}

.v1b-Dribbble:before {
  content: "\ecd0";
}

.v1b-Driller:before {
  content: "\ecd1";
}

.v1b-Drive-File:before {
  content: "\ecd2";
}

.v1b-Drive-Folder:before {
  content: "\ecd3";
}

.v1b-Drool:before {
  content: "\ecd4";
}

.v1b-Dropbox-File:before {
  content: "\ecd5";
}

.v1b-Dropbox-Folder:before {
  content: "\ecd6";
}

.v1b-Dropbox:before {
  content: "\ecd7";
}

.v1b-Drowing:before {
  content: "\ecd8";
}

.v1b-Drum-1:before {
  content: "\ecd9";
}

.v1b-Drum-2:before {
  content: "\ecda";
}

.v1b-Drum-sticks:before {
  content: "\ecdb";
}

.v1b-Drupal:before {
  content: "\ecdc";
}

.v1b-Dumbbell:before {
  content: "\ecdd";
}

.v1b-DVD-Case:before {
  content: "\ecde";
}

.v1b-DVD-Disc:before {
  content: "\ecdf";
}

.v1b-DVD-Sign:before {
  content: "\ece0";
}

.v1b-Dzone:before {
  content: "\ece1";
}

.v1b-Earphone-1:before {
  content: "\ece2";
}

.v1b-Earphone-2:before {
  content: "\ece3";
}

.v1b-Eatrh-Support:before {
  content: "\ece4";
}

.v1b-Ebay:before {
  content: "\ece5";
}

.v1b-eBooks-Folder:before {
  content: "\ece6";
}

.v1b-Eco-Badge:before {
  content: "\ece7";
}

.v1b-Eco-Bulb1:before {
  content: "\ece8";
}

.v1b-Eco-Bulb2:before {
  content: "\ece9";
}

.v1b-Eco-Earth:before {
  content: "\ecea";
}

.v1b-Ecuation:before {
  content: "\eceb";
}

.v1b-Edge:before {
  content: "\ecec";
}

.v1b-Edit-Doc:before {
  content: "\eced";
}

.v1b-Edit-Wifi:before {
  content: "\ecee";
}

.v1b-Egg-Holder:before {
  content: "\ecef";
}

.v1b-Einstein:before {
  content: "\ecf0";
}

.v1b-Electric-Guitar1:before {
  content: "\ecf1";
}

.v1b-Electric-Guitar2:before {
  content: "\ecf2";
}

.v1b-Electric-Plug:before {
  content: "\ecf3";
}

.v1b-Elevator:before {
  content: "\ecf4";
}

.v1b-Elvis:before {
  content: "\ecf5";
}

.v1b-Email-File:before {
  content: "\ecf6";
}

.v1b-Email-Folder:before {
  content: "\ecf7";
}

.v1b-Encode-File:before {
  content: "\ecf8";
}

.v1b-End-Call:before {
  content: "\ecf9";
}

.v1b-Energy-Drink:before {
  content: "\ecfa";
}

.v1b-Enter-Pin2:before {
  content: "\ecfb";
}

.v1b-Enter-Pin:before {
  content: "\ecfc";
}

.v1b-Envato:before {
  content: "\ecfd";
}

.v1b-Eroor-Folder:before {
  content: "\ecfe";
}

.v1b-Error-Bookmark:before {
  content: "\ecff";
}

.v1b-Error-Doc:before {
  content: "\ed00";
}

.v1b-Espresso:before {
  content: "\ed01";
}

.v1b-Ethernet:before {
  content: "\ed02";
}

.v1b-Euro-1:before {
  content: "\ed03";
}

.v1b-Euro-2:before {
  content: "\ed04";
}

.v1b-Euro-3:before {
  content: "\ed05";
}

.v1b-Euro-Doc:before {
  content: "\ed06";
}

.v1b-Euro-Fall:before {
  content: "\ed07";
}

.v1b-Euro-Rise:before {
  content: "\ed08";
}

.v1b-Evernote:before {
  content: "\ed09";
}

.v1b-Evil:before {
  content: "\ed0a";
}

.v1b-Excavator-1:before {
  content: "\ed0b";
}

.v1b-Excavator-2:before {
  content: "\ed0c";
}

.v1b-Excel:before {
  content: "\ed0d";
}

.v1b-Exit-Sign:before {
  content: "\ed0e";
}

.v1b-Expensive:before {
  content: "\ed0f";
}

.v1b-Expisior:before {
  content: "\ed10";
}

.v1b-Facebook-1:before {
  content: "\ed11";
}

.v1b-Facebook-2:before {
  content: "\ed12";
}

.v1b-Facebook-Messenger:before {
  content: "\ed13";
}

.v1b-Factory-1:before {
  content: "\ed14";
}

.v1b-Factory-2:before {
  content: "\ed15";
}

.v1b-Factory-3:before {
  content: "\ed16";
}

.v1b-Factory-Chimneys:before {
  content: "\ed17";
}

.v1b-Factory-Line:before {
  content: "\ed18";
}

.v1b-Fan:before {
  content: "\ed19";
}

.v1b-Favorit-File:before {
  content: "\ed1a";
}

.v1b-Favorit-Location:before {
  content: "\ed1b";
}

.v1b-Favorit-Network:before {
  content: "\ed1c";
}

.v1b-Favorite-Chat1:before {
  content: "\ed1d";
}

.v1b-Favorite-Chat2:before {
  content: "\ed1e";
}

.v1b-Favorite-Chat3:before {
  content: "\ed1f";
}

.v1b-Favorite-Doc:before {
  content: "\ed20";
}

.v1b-Favorite-Folder:before {
  content: "\ed21";
}

.v1b-Favorite-Mail:before {
  content: "\ed22";
}

.v1b-Favorite-Store:before {
  content: "\ed23";
}

.v1b-Favorite-Wifi:before {
  content: "\ed24";
}

.v1b-Favorite:before {
  content: "\ed25";
}

.v1b-Feather:before {
  content: "\ed26";
}

.v1b-Feedburner:before {
  content: "\ed27";
}

.v1b-Feeling-Sick:before {
  content: "\ed28";
}

.v1b-Female-1:before {
  content: "\ed29";
}

.v1b-Female-2:before {
  content: "\ed2a";
}

.v1b-Fever:before {
  content: "\ed2b";
}

.v1b-File-Error:before {
  content: "\ed2c";
}

.v1b-File-Settings:before {
  content: "\ed2d";
}

.v1b-Film-Clapper:before {
  content: "\ed2e";
}

.v1b-Film-Roll:before {
  content: "\ed2f";
}

.v1b-Film-Stripe:before {
  content: "\ed30";
}

.v1b-Filter:before {
  content: "\ed31";
}

.v1b-Financial-Care1:before {
  content: "\ed32";
}

.v1b-Financial-Care3:before {
  content: "\ed33";
}

.v1b-Finder:before {
  content: "\ed34";
}

.v1b-Finger-Print:before {
  content: "\ed35";
}

.v1b-Fire-Alarm:before {
  content: "\ed36";
}

.v1b-Fire-Extinguisher:before {
  content: "\ed37";
}

.v1b-Fire-Fighjter:before {
  content: "\ed38";
}

.v1b-Firefox:before {
  content: "\ed39";
}

.v1b-Firewall-Attention:before {
  content: "\ed3a";
}

.v1b-Firewall-Error:before {
  content: "\ed3b";
}

.v1b-Firewall-Off:before {
  content: "\ed3c";
}

.v1b-Firewall-Ok:before {
  content: "\ed3d";
}

.v1b-Firewall-On:before {
  content: "\ed3e";
}

.v1b-Firewall-Settings:before {
  content: "\ed3f";
}

.v1b-Firewall:before {
  content: "\ed40";
}

.v1b-Fireworks-1:before {
  content: "\ed41";
}

.v1b-Fireworks-2:before {
  content: "\ed42";
}

.v1b-Fishing:before {
  content: "\ed43";
}

.v1b-Flash-1 .path1:before {
  content: "\ed44";
  color: rgb(0, 0, 0);
}

.v1b-Flash-1 .path2:before {
  content: "\ed45";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Flash-1 .path3:before {
  content: "\ed46";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.v1b-Flash-1 .path4:before {
  content: "\ed47";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.v1b-Flash-1 .path5:before {
  content: "\ed48";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Flash-1 .path6:before {
  content: "\ed49";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Flash-1 .path7:before {
  content: "\ed4a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Flash-1 .path8:before {
  content: "\ed4b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.v1b-Flash-Light:before {
  content: "\ed4c";
}

.v1b-Flash-Off:before {
  content: "\ed4d";
}

.v1b-Flash-On:before {
  content: "\ed4e";
}

.v1b-Flash-Video:before {
  content: "\ed4f";
}

.v1b-Flickr-2:before {
  content: "\ed50";
}

.v1b-Flickr:before {
  content: "\ed51";
}

.v1b-Flusk-Holder:before {
  content: "\ed52";
}

.v1b-Flute2:before {
  content: "\ed53";
}

.v1b-Flute:before {
  content: "\ed54";
}

.v1b-Flv-Doc:before {
  content: "\ed55";
}

.v1b-Focus-Auto:before {
  content: "\ed56";
}

.v1b-Focus-Center:before {
  content: "\ed57";
}

.v1b-Folder-1:before {
  content: "\ed58";
}

.v1b-Folder-Tree:before {
  content: "\ed59";
}

.v1b-Folder-withDoc:before {
  content: "\ed5a";
}

.v1b-Font-Szie:before {
  content: "\ed5b";
}

.v1b-Foodspotting:before {
  content: "\ed5c";
}

.v1b-Football:before {
  content: "\ed5d";
}

.v1b-Fork-Knife:before {
  content: "\ed5e";
}

.v1b-Fork-Lifter:before {
  content: "\ed5f";
}

.v1b-Formal-Coat:before {
  content: "\ed60";
}

.v1b-Formal-Pants:before {
  content: "\ed61";
}

.v1b-Forrest:before {
  content: "\ed62";
}

.v1b-Forrst:before {
  content: "\ed63";
}

.v1b-Forward-AllMail:before {
  content: "\ed64";
}

.v1b-Forward-Button:before {
  content: "\ed65";
}

.v1b-Forward-Mail:before {
  content: "\ed66";
}

.v1b-Forward:before {
  content: "\ed67";
}

.v1b-Foursquare:before {
  content: "\ed68";
}

.v1b-Fragment-Chart:before {
  content: "\ed69";
}

.v1b-Free-Tag1:before {
  content: "\ed6a";
}

.v1b-Free-Tag2:before {
  content: "\ed6b";
}

.v1b-French-Fries:before {
  content: "\ed6c";
}

.v1b-Fried-Egg:before {
  content: "\ed6d";
}

.v1b-Front-Camera:before {
  content: "\ed6e";
}

.v1b-Full-Cart2:before {
  content: "\ed6f";
}

.v1b-Full-HD:before {
  content: "\ed70";
}

.v1b-Gallery-1:before {
  content: "\ed71";
}

.v1b-Gallery-2:before {
  content: "\ed72";
}

.v1b-Game-Console1:before {
  content: "\ed73";
}

.v1b-Game-Console2:before {
  content: "\ed74";
}

.v1b-Game-Console3:before {
  content: "\ed75";
}

.v1b-Game-Console:before {
  content: "\ed76";
}

.v1b-Gameboy-1:before {
  content: "\ed77";
}

.v1b-Gameboy-2:before {
  content: "\ed78";
}

.v1b-Games-Folder:before {
  content: "\ed79";
}

.v1b-Gas-Can:before {
  content: "\ed7a";
}

.v1b-Gas-Container:before {
  content: "\ed7b";
}

.v1b-Gas-Pump2:before {
  content: "\ed7c";
}

.v1b-Gas-Pump:before {
  content: "\ed7d";
}

.v1b-Gdgt:before {
  content: "\ed7e";
}

.v1b-Gear-1:before {
  content: "\ed7f";
}

.v1b-Gear-2:before {
  content: "\ed80";
}

.v1b-Gear-3:before {
  content: "\ed81";
}

.v1b-Gear-4:before {
  content: "\ed82";
}

.v1b-Geek-1:before {
  content: "\ed83";
}

.v1b-Geek-2:before {
  content: "\ed84";
}

.v1b-Gem:before {
  content: "\ed85";
}

.v1b-Gender-Symbols:before {
  content: "\ed86";
}

.v1b-Geooveshark:before {
  content: "\ed87";
}

.v1b-Gift-Box:before {
  content: "\ed88";
}

.v1b-Gift-Card:before {
  content: "\ed89";
}

.v1b-Github:before {
  content: "\ed8a";
}

.v1b-Glases-1:before {
  content: "\ed8b";
}

.v1b-Glases-2:before {
  content: "\ed8c";
}

.v1b-Glitter-Eye:before {
  content: "\ed8d";
}

.v1b-Global-Network1:before {
  content: "\ed8e";
}

.v1b-Global-Network2:before {
  content: "\ed8f";
}

.v1b-Global-Position:before {
  content: "\ed90";
}

.v1b-Globe:before {
  content: "\ed91";
}

.v1b-Glue:before {
  content: "\ed92";
}

.v1b-Gmail:before {
  content: "\ed93";
}

.v1b-Golf-Ball:before {
  content: "\ed94";
}

.v1b-Golf:before {
  content: "\ed95";
}

.v1b-Gong:before {
  content: "\ed96";
}

.v1b-Google-:before {
  content: "\ed97";
}

.v1b-Google-1:before {
  content: "\ed98";
}

.v1b-Google-2:before {
  content: "\ed99";
}

.v1b-Google-Camera:before {
  content: "\ed9a";
}

.v1b-Google-Drive:before {
  content: "\ed9b";
}

.v1b-Google-Earth:before {
  content: "\ed9c";
}

.v1b-Google-Maps:before {
  content: "\ed9d";
}

.v1b-Google-Play:before {
  content: "\ed9e";
}

.v1b-Google-Walet:before {
  content: "\ed9f";
}

.v1b-Gowala:before {
  content: "\eda0";
}

.v1b-Gps-Fixed:before {
  content: "\eda1";
}

.v1b-Gps-NotFixed:before {
  content: "\eda2";
}

.v1b-Gps-Off:before {
  content: "\eda3";
}

.v1b-Grab-Hand:before {
  content: "\eda4";
}

.v1b-Grill:before {
  content: "\eda5";
}

.v1b-Guitar-Amplifier:before {
  content: "\eda6";
}

.v1b-Guitar-Head1:before {
  content: "\eda7";
}

.v1b-Guitar-Head2:before {
  content: "\eda8";
}

.v1b-Guitar:before {
  content: "\eda9";
}

.v1b-Hairdryer:before {
  content: "\edaa";
}

.v1b-Half-Hour:before {
  content: "\edab";
}

.v1b-Half-Life:before {
  content: "\edac";
}

.v1b-Ham:before {
  content: "\edad";
}

.v1b-Hamburger:before {
  content: "\edae";
}

.v1b-Hammer2:before {
  content: "\edaf";
}

.v1b-Hammer:before {
  content: "\edb0";
}

.v1b-Hanger:before {
  content: "\edb1";
}

.v1b-Hangout:before {
  content: "\edb2";
}

.v1b-Happy-Wink:before {
  content: "\edb3";
}

.v1b-Happy:before {
  content: "\edb4";
}

.v1b-Harph-1:before {
  content: "\edb5";
}

.v1b-Harph-2:before {
  content: "\edb6";
}

.v1b-Harry-Potter:before {
  content: "\edb7";
}

.v1b-Hash-Tag:before {
  content: "\edb8";
}

.v1b-Hat:before {
  content: "\edb9";
}

.v1b-Hdmi:before {
  content: "\edba";
}

.v1b-HDR-Off:before {
  content: "\edbb";
}

.v1b-HDR-On:before {
  content: "\edbc";
}

.v1b-Headache:before {
  content: "\edbd";
}

.v1b-Headset-1:before {
  content: "\edbe";
}

.v1b-Headset-2:before {
  content: "\edbf";
}

.v1b-Heart2:before {
  content: "\edc0";
}

.v1b-Heart-AceCard:before {
  content: "\edc1";
}

.v1b-Heart-Beat:before {
  content: "\edc2";
}

.v1b-Heart:before {
  content: "\edc3";
}

.v1b-Heat-Balloon:before {
  content: "\edc4";
}

.v1b-Heater:before {
  content: "\edc5";
}

.v1b-Heels:before {
  content: "\edc6";
}

.v1b-Helmet-1:before {
  content: "\edc7";
}

.v1b-Helmet-2:before {
  content: "\edc8";
}

.v1b-Helmet-3:before {
  content: "\edc9";
}

.v1b-Hide-File:before {
  content: "\edca";
}

.v1b-Hiden-File:before {
  content: "\edcb";
}

.v1b-Hiden-Folder:before {
  content: "\edcc";
}

.v1b-Hidrant:before {
  content: "\edcd";
}

.v1b-Hokey-Disc:before {
  content: "\edce";
}

.v1b-Hokey-Skate:before {
  content: "\edcf";
}

.v1b-Hokey:before {
  content: "\edd0";
}

.v1b-Hold:before {
  content: "\edd1";
}

.v1b-Hole-Puncher:before {
  content: "\edd2";
}

.v1b-Home-Location:before {
  content: "\edd3";
}

.v1b-Home-Security:before {
  content: "\edd4";
}

.v1b-Home-Wifi:before {
  content: "\edd5";
}

.v1b-Hoodie:before {
  content: "\edd6";
}

.v1b-Horn-Trompet:before {
  content: "\edd7";
}

.v1b-Horn:before {
  content: "\edd8";
}

.v1b-Horse-Shoe:before {
  content: "\edd9";
}

.v1b-Hospital-1:before {
  content: "\edda";
}

.v1b-Hospital-Bed:before {
  content: "\eddb";
}

.v1b-Hot-Dog1:before {
  content: "\eddc";
}

.v1b-Hotdog-2:before {
  content: "\eddd";
}

.v1b-Hotel-Bell:before {
  content: "\edde";
}

.v1b-Hotel-Sign1:before {
  content: "\eddf";
}

.v1b-Hotel:before {
  content: "\ede0";
}

.v1b-Hotspot-Mobile:before {
  content: "\ede1";
}

.v1b-HTML-5:before {
  content: "\ede2";
}

.v1b-Hypnotized:before {
  content: "\ede3";
}

.v1b-Hypster:before {
  content: "\ede4";
}

.v1b-Icecream:before {
  content: "\ede5";
}

.v1b-iCloud:before {
  content: "\ede6";
}

.v1b-ICQ:before {
  content: "\ede7";
}

.v1b-Illustrator:before {
  content: "\ede8";
}

.v1b-Image-file:before {
  content: "\ede9";
}

.v1b-IMDb:before {
  content: "\edea";
}

.v1b-In-LineImage:before {
  content: "\edeb";
}

.v1b-In-Love:before {
  content: "\edec";
}

.v1b-Inbox-Google:before {
  content: "\eded";
}

.v1b-Inbox-In:before {
  content: "\edee";
}

.v1b-Inbox-Letter:before {
  content: "\edef";
}

.v1b-Inbox-Out:before {
  content: "\edf0";
}

.v1b-Inbox:before {
  content: "\edf1";
}

.v1b-Increase-Indent:before {
  content: "\edf2";
}

.v1b-Increase-Margin:before {
  content: "\edf3";
}

.v1b-Increasing-Chart1:before {
  content: "\edf4";
}

.v1b-Increasing-Chart2:before {
  content: "\edf5";
}

.v1b-Info-Point:before {
  content: "\edf6";
}

.v1b-Infuzion:before {
  content: "\edf7";
}

.v1b-Initial:before {
  content: "\edf8";
}

.v1b-Insert-Image:before {
  content: "\edf9";
}

.v1b-Instagram:before {
  content: "\edfa";
}

.v1b-Intel:before {
  content: "\edfb";
}

.v1b-Internet-Explorer:before {
  content: "\edfc";
}

.v1b-Iron-Man:before {
  content: "\edfd";
}

.v1b-Isert-Tabel:before {
  content: "\edfe";
}

.v1b-Italic:before {
  content: "\edff";
}

.v1b-Jack-Hammer:before {
  content: "\ee00";
}

.v1b-Jack-Sparrow:before {
  content: "\ee01";
}

.v1b-Jacket:before {
  content: "\ee02";
}

.v1b-Jason:before {
  content: "\ee03";
}

.v1b-Jeans:before {
  content: "\ee04";
}

.v1b-Joy-Stick:before {
  content: "\ee05";
}

.v1b-Juice:before {
  content: "\ee06";
}

.v1b-Justify-Center:before {
  content: "\ee07";
}

.v1b-Justify-Left:before {
  content: "\ee08";
}

.v1b-Justify-Right:before {
  content: "\ee09";
}

.v1b-Karate:before {
  content: "\ee0a";
}

.v1b-Keep-Out:before {
  content: "\ee0b";
}

.v1b-Kerneling:before {
  content: "\ee0c";
}

.v1b-Key-1:before {
  content: "\ee0d";
}

.v1b-Key-2:before {
  content: "\ee0e";
}

.v1b-Key-3:before {
  content: "\ee0f";
}

.v1b-Kickstarter:before {
  content: "\ee10";
}

.v1b-Kidneys:before {
  content: "\ee11";
}

.v1b-Kiss:before {
  content: "\ee12";
}

.v1b-Kite:before {
  content: "\ee13";
}

.v1b-Ladys-T-Shirt:before {
  content: "\ee14";
}

.v1b-Ladys-Underwear:before {
  content: "\ee15";
}

.v1b-Lamp-1:before {
  content: "\ee16";
}

.v1b-Lamp-2:before {
  content: "\ee17";
}

.v1b-Lamp-3:before {
  content: "\ee18";
}

.v1b-Last-Fm:before {
  content: "\ee19";
}

.v1b-Laugh-Hard:before {
  content: "\ee1a";
}

.v1b-Layer-2:before {
  content: "\ee1b";
}

.v1b-Layer-56:before {
  content: "\ee1c";
}

.v1b-Left-1:before {
  content: "\ee1d";
}

.v1b-Left-2:before {
  content: "\ee1e";
}

.v1b-Left-3:before {
  content: "\ee1f";
}

.v1b-Left-4:before {
  content: "\ee20";
}

.v1b-Left-5:before {
  content: "\ee21";
}

.v1b-Left-6:before {
  content: "\ee22";
}

.v1b-Left-7:before {
  content: "\ee23";
}

.v1b-Left-8:before {
  content: "\ee24";
}

.v1b-Left-9:before {
  content: "\ee25";
}

.v1b-Left-10:before {
  content: "\ee26";
}

.v1b-Left-11:before {
  content: "\ee27";
}

.v1b-Left-12:before {
  content: "\ee28";
}

.v1b-Left-13:before {
  content: "\ee29";
}

.v1b-Left-14:before {
  content: "\ee2a";
}

.v1b-Lego-Brick:before {
  content: "\ee2b";
}

.v1b-Lego-Head:before {
  content: "\ee2c";
}

.v1b-Lens-1:before {
  content: "\ee2d";
}

.v1b-Lens-2:before {
  content: "\ee2e";
}

.v1b-Lifter:before {
  content: "\ee2f";
}

.v1b-Lifting-Phisics:before {
  content: "\ee30";
}

.v1b-Ligatures2:before {
  content: "\ee31";
}

.v1b-Ligatures:before {
  content: "\ee32";
}

.v1b-Light-Bulb:before {
  content: "\ee33";
}

.v1b-Light-Switch:before {
  content: "\ee34";
}

.v1b-Line-Chart1:before {
  content: "\ee35";
}

.v1b-Line-Chart2:before {
  content: "\ee36";
}

.v1b-Line-Chart3:before {
  content: "\ee37";
}

.v1b-Line-Spacing2:before {
  content: "\ee38";
}

.v1b-Line-Spacing:before {
  content: "\ee39";
}

.v1b-Link-Select1:before {
  content: "\ee3a";
}

.v1b-Link-Select2:before {
  content: "\ee3b";
}

.v1b-Link:before {
  content: "\ee3c";
}

.v1b-Linkedin:before {
  content: "\ee3d";
}

.v1b-List-Doc:before {
  content: "\ee3e";
}

.v1b-List-Folder:before {
  content: "\ee3f";
}

.v1b-Litter:before {
  content: "\ee40";
}

.v1b-Liver:before {
  content: "\ee41";
}

.v1b-Load-Button:before {
  content: "\ee42";
}

.v1b-Load-Doc:before {
  content: "\ee43";
}

.v1b-Load-File:before {
  content: "\ee44";
}

.v1b-Load-Folder:before {
  content: "\ee45";
}

.v1b-Local-Airport:before {
  content: "\ee46";
}

.v1b-Local-Bank:before {
  content: "\ee47";
}

.v1b-Local-GasStation:before {
  content: "\ee48";
}

.v1b-Local-Hospital:before {
  content: "\ee49";
}

.v1b-Local-Library:before {
  content: "\ee4a";
}

.v1b-Local-Monument:before {
  content: "\ee4b";
}

.v1b-Local-Parking:before {
  content: "\ee4c";
}

.v1b-Local-Port:before {
  content: "\ee4d";
}

.v1b-Local-Pub:before {
  content: "\ee4e";
}

.v1b-Local-Restaurant:before {
  content: "\ee4f";
}

.v1b-Local-Transport:before {
  content: "\ee50";
}

.v1b-Location-1:before {
  content: "\ee51";
}

.v1b-Location-3:before {
  content: "\ee52";
}

.v1b-Location-4:before {
  content: "\ee53";
}

.v1b-Location-A:before {
  content: "\ee54";
}

.v1b-Location-B:before {
  content: "\ee55";
}

.v1b-Locatoin-2:before {
  content: "\ee56";
}

.v1b-Lock-12:before {
  content: "\ee57";
}

.v1b-Lock-2:before {
  content: "\ee58";
}

.v1b-Lock-Mail:before {
  content: "\ee59";
}

.v1b-Lock-ScreenRotation:before {
  content: "\ee5a";
}

.v1b-Lock-Wifi:before {
  content: "\ee5b";
}

.v1b-Locked-Parking:before {
  content: "\ee5c";
}

.v1b-Locl-Shop:before {
  content: "\ee5d";
}

.v1b-Locomotive:before {
  content: "\ee5e";
}

.v1b-LOL:before {
  content: "\ee5f";
}

.v1b-Loudspeaker:before {
  content: "\ee60";
}

.v1b-Love-Mail:before {
  content: "\ee61";
}

.v1b-Loyalty-Card2:before {
  content: "\ee62";
}

.v1b-Loyalty-Card:before {
  content: "\ee63";
}

.v1b-Luggage:before {
  content: "\ee64";
}

.v1b-Lungs:before {
  content: "\ee65";
}

.v1b-Macro:before {
  content: "\ee66";
}

.v1b-Magic-Hat:before {
  content: "\ee67";
}

.v1b-Magnet:before {
  content: "\ee68";
}

.v1b-Magnetic-Field:before {
  content: "\ee69";
}

.v1b-Maid:before {
  content: "\ee6a";
}

.v1b-Mail-At:before {
  content: "\ee6b";
}

.v1b-Mail-Box1:before {
  content: "\ee6c";
}

.v1b-Mail-Box2:before {
  content: "\ee6d";
}

.v1b-Mail-Box3:before {
  content: "\ee6e";
}

.v1b-Mail-Error:before {
  content: "\ee6f";
}

.v1b-Mail-Help:before {
  content: "\ee70";
}

.v1b-Mail-Inbox:before {
  content: "\ee71";
}

.v1b-Mail-Notification:before {
  content: "\ee72";
}

.v1b-Mail-Outbox:before {
  content: "\ee73";
}

.v1b-Mail-Settings:before {
  content: "\ee74";
}

.v1b-Mail:before {
  content: "\ee75";
}

.v1b-Mailing-List:before {
  content: "\ee76";
}

.v1b-Male-1:before {
  content: "\ee77";
}

.v1b-Male-2:before {
  content: "\ee78";
}

.v1b-Mans-Shoe:before {
  content: "\ee79";
}

.v1b-Mans-Uderweare:before {
  content: "\ee7a";
}

.v1b-Map-1:before {
  content: "\ee7b";
}

.v1b-Map-2:before {
  content: "\ee7c";
}

.v1b-Map-3:before {
  content: "\ee7d";
}

.v1b-Map-Pin1:before {
  content: "\ee7e";
}

.v1b-Map-Pin2:before {
  content: "\ee7f";
}

.v1b-Map-Pin3:before {
  content: "\ee80";
}

.v1b-Map-Pin4:before {
  content: "\ee81";
}

.v1b-Map-pin5:before {
  content: "\ee82";
}

.v1b-Map-pin6:before {
  content: "\ee83";
}

.v1b-Map-Screen:before {
  content: "\ee84";
}

.v1b-Mario-Mushroom:before {
  content: "\ee85";
}

.v1b-Marker:before {
  content: "\ee86";
}

.v1b-Marry-GoRound:before {
  content: "\ee87";
}

.v1b-Martini-Glass:before {
  content: "\ee88";
}

.v1b-Master-Yoda:before {
  content: "\ee89";
}

.v1b-Medal-1:before {
  content: "\ee8a";
}

.v1b-Medal-2:before {
  content: "\ee8b";
}

.v1b-Medal-3:before {
  content: "\ee8c";
}

.v1b-Medic:before {
  content: "\ee8d";
}

.v1b-Medical-Bag:before {
  content: "\ee8e";
}

.v1b-Medical-Symbol:before {
  content: "\ee8f";
}

.v1b-Medicine-Mixing:before {
  content: "\ee90";
}

.v1b-Metronome:before {
  content: "\ee91";
}

.v1b-Microphone-1:before {
  content: "\ee92";
}

.v1b-Microphone-2:before {
  content: "\ee93";
}

.v1b-Microphone-3:before {
  content: "\ee94";
}

.v1b-Microscope:before {
  content: "\ee95";
}

.v1b-Microsoft-Store:before {
  content: "\ee96";
}

.v1b-Midi-Doc:before {
  content: "\ee97";
}

.v1b-Milk:before {
  content: "\ee98";
}

.v1b-Mini-Bar:before {
  content: "\ee99";
}

.v1b-Mining-Helmet:before {
  content: "\ee9a";
}

.v1b-Mining-Pick:before {
  content: "\ee9b";
}

.v1b-Mining-Railcar:before {
  content: "\ee9c";
}

.v1b-Minion-1:before {
  content: "\ee9d";
}

.v1b-Minion-2:before {
  content: "\ee9e";
}

.v1b-mirc:before {
  content: "\ee9f";
}

.v1b-Mirror2:before {
  content: "\eea0";
}

.v1b-Mirror:before {
  content: "\eea1";
}

.v1b-Mixer-1:before {
  content: "\eea2";
}

.v1b-Mixer-2:before {
  content: "\eea3";
}

.v1b-Mobile-1:before {
  content: "\eea4";
}

.v1b-Mobile-2:before {
  content: "\eea5";
}

.v1b-Mobile-3:before {
  content: "\eea6";
}

.v1b-Mobile-4:before {
  content: "\eea7";
}

.v1b-Mobile-5:before {
  content: "\eea8";
}

.v1b-Mobile-Map:before {
  content: "\eea9";
}

.v1b-Mobile-Network:before {
  content: "\eeaa";
}

.v1b-Mobile-Security:before {
  content: "\eeab";
}

.v1b-Mobile-Touch:before {
  content: "\eeac";
}

.v1b-Molecule-1:before {
  content: "\eead";
}

.v1b-Molecule-2:before {
  content: "\eeae";
}

.v1b-Money-Bag2:before {
  content: "\eeaf";
}

.v1b-Money-Eye:before {
  content: "\eeb0";
}

.v1b-Money-Protect:before {
  content: "\eeb1";
}

.v1b-Mountain:before {
  content: "\eeb2";
}

.v1b-Mouse-Pointer1:before {
  content: "\eeb3";
}

.v1b-Mouse-Pointer2:before {
  content: "\eeb4";
}

.v1b-Mov-Doc:before {
  content: "\eeb5";
}

.v1b-Mp3-Doc:before {
  content: "\eeb6";
}

.v1b-Mp3-Player1:before {
  content: "\eeb7";
}

.v1b-Mp3-Player2:before {
  content: "\eeb8";
}

.v1b-Mp4-Doc:before {
  content: "\eeb9";
}

.v1b-Mpg-Doc:before {
  content: "\eeba";
}

.v1b-Mpu-Doc:before {
  content: "\eebb";
}

.v1b-Multimeter:before {
  content: "\eebc";
}

.v1b-Mushroom-Cloud:before {
  content: "\eebd";
}

.v1b-Music-Doc:before {
  content: "\eebe";
}

.v1b-Music-Folder2:before {
  content: "\eebf";
}

.v1b-Music-Folder:before {
  content: "\eec0";
}

.v1b-Music-Note1:before {
  content: "\eec1";
}

.v1b-Music-Note2:before {
  content: "\eec2";
}

.v1b-Music-Note3:before {
  content: "\eec3";
}

.v1b-Music-Note4:before {
  content: "\eec4";
}

.v1b-Music-Note5:before {
  content: "\eec5";
}

.v1b-Music-Note6:before {
  content: "\eec6";
}

.v1b-Music-Note7:before {
  content: "\eec7";
}

.v1b-Mute-Headset:before {
  content: "\eec8";
}

.v1b-Mute-Microphone:before {
  content: "\eec9";
}

.v1b-My-Space:before {
  content: "\eeca";
}

.v1b-N-W-8:before {
  content: "\eecb";
}

.v1b-N-W-9:before {
  content: "\eecc";
}

.v1b-Navigation-1:before {
  content: "\eecd";
}

.v1b-Navigation-2:before {
  content: "\eece";
}

.v1b-Needle:before {
  content: "\eecf";
}

.v1b-Nest:before {
  content: "\eed0";
}

.v1b-Network-Add:before {
  content: "\eed1";
}

.v1b-Network-Cable:before {
  content: "\eed2";
}

.v1b-Network-Error:before {
  content: "\eed3";
}

.v1b-Network-File:before {
  content: "\eed4";
}

.v1b-Network-Folder:before {
  content: "\eed5";
}

.v1b-Network-Plug:before {
  content: "\eed6";
}

.v1b-Network-Question:before {
  content: "\eed7";
}

.v1b-Network-Settings:before {
  content: "\eed8";
}

.v1b-New-Mail:before {
  content: "\eed9";
}

.v1b-New-Tag1:before {
  content: "\eeda";
}

.v1b-New-Tag2:before {
  content: "\eedb";
}

.v1b-New-Tag3:before {
  content: "\eedc";
}

.v1b-New-Tga3:before {
  content: "\eedd";
}

.v1b-New-Tga4:before {
  content: "\eede";
}

.v1b-Newtons-Cradle:before {
  content: "\eedf";
}

.v1b-Next-Button:before {
  content: "\eee0";
}

.v1b-Night-Mode:before {
  content: "\eee1";
}

.v1b-Ninja:before {
  content: "\eee2";
}

.v1b-Nird:before {
  content: "\eee3";
}

.v1b-No-Battery:before {
  content: "\eee4";
}

.v1b-No-Sim:before {
  content: "\eee5";
}

.v1b-No-Television:before {
  content: "\eee6";
}

.v1b-No-Wifi2:before {
  content: "\eee7";
}

.v1b-No-Wifi:before {
  content: "\eee8";
}

.v1b-Nuclear-Symbol2:before {
  content: "\eee9";
}

.v1b-Nuclear-Symbol:before {
  content: "\eeea";
}

.v1b-Numbered-List2:before {
  content: "\eeeb";
}

.v1b-Numbered-List:before {
  content: "\eeec";
}

.v1b-Nurse:before {
  content: "\eeed";
}

.v1b-Nvidia:before {
  content: "\eeee";
}

.v1b-Observatory:before {
  content: "\eeef";
}

.v1b-Odnoklassniki:before {
  content: "\eef0";
}

.v1b-Office-Chair:before {
  content: "\eef1";
}

.v1b-Office:before {
  content: "\eef2";
}

.v1b-Oil-Extractor:before {
  content: "\eef3";
}

.v1b-Oil-Tower:before {
  content: "\eef4";
}

.v1b-Old-Clock:before {
  content: "\eef5";
}

.v1b-Old-Man:before {
  content: "\eef6";
}

.v1b-Omega:before {
  content: "\eef7";
}

.v1b-On-air1:before {
  content: "\eef8";
}

.v1b-On-air2:before {
  content: "\eef9";
}

.v1b-One-Click1:before {
  content: "\eefa";
}

.v1b-One-Click2:before {
  content: "\eefb";
}

.v1b-Open-1:before {
  content: "\eefc";
}

.v1b-Open-2:before {
  content: "\eefd";
}

.v1b-Open-Bookmark:before {
  content: "\eefe";
}

.v1b-Open-Folder:before {
  content: "\eeff";
}

.v1b-Open-Hand1:before {
  content: "\ef00";
}

.v1b-Open-Hand2:before {
  content: "\ef01";
}

.v1b-Open-Hand:before {
  content: "\ef02";
}

.v1b-Open-Mail2:before {
  content: "\ef03";
}

.v1b-Open-Mail:before {
  content: "\ef04";
}

.v1b-Open-SourceIniciative:before {
  content: "\ef05";
}

.v1b-Opera:before {
  content: "\ef06";
}

.v1b-Packman:before {
  content: "\ef07";
}

.v1b-Pacman-Ghost:before {
  content: "\ef08";
}

.v1b-Page-Break:before {
  content: "\ef09";
}

.v1b-Page-Size:before {
  content: "\ef0a";
}

.v1b-Paint-Format:before {
  content: "\ef0b";
}

.v1b-Palm-Tree:before {
  content: "\ef0c";
}

.v1b-Palm:before {
  content: "\ef0d";
}

.v1b-Panorama:before {
  content: "\ef0e";
}

.v1b-Paper-Clip1:before {
  content: "\ef0f";
}

.v1b-Paper-Clip2:before {
  content: "\ef10";
}

.v1b-Paper-Clip3:before {
  content: "\ef11";
}

.v1b-Paper-Roll:before {
  content: "\ef12";
}

.v1b-Paprika:before {
  content: "\ef13";
}

.v1b-Parabolic-Antena:before {
  content: "\ef14";
}

.v1b-Paragraph-Tool:before {
  content: "\ef15";
}

.v1b-Park:before {
  content: "\ef16";
}

.v1b-Party-Hat:before {
  content: "\ef17";
}

.v1b-Party-Ribbon:before {
  content: "\ef18";
}

.v1b-Paste-Here:before {
  content: "\ef19";
}

.v1b-Path:before {
  content: "\ef1a";
}

.v1b-Pause-Button:before {
  content: "\ef1b";
}

.v1b-Pause:before {
  content: "\ef1c";
}

.v1b-Paypal-1:before {
  content: "\ef1d";
}

.v1b-Paypal-2:before {
  content: "\ef1e";
}

.v1b-Peace:before {
  content: "\ef1f";
}

.v1b-Pear:before {
  content: "\ef20";
}

.v1b-Pen-Holder:before {
  content: "\ef21";
}

.v1b-Pencil-Sharpener:before {
  content: "\ef22";
}

.v1b-Pet-Bottle:before {
  content: "\ef23";
}

.v1b-Pharmaceutical-Symbol:before {
  content: "\ef24";
}

.v1b-Photo-Library:before {
  content: "\ef25";
}

.v1b-Photoshop:before {
  content: "\ef26";
}

.v1b-Piano-Keyboard:before {
  content: "\ef27";
}

.v1b-Piano:before {
  content: "\ef28";
}

.v1b-Picasa:before {
  content: "\ef29";
}

.v1b-Pickup-1:before {
  content: "\ef2a";
}

.v1b-Pickup-2:before {
  content: "\ef2b";
}

.v1b-Pickup-3:before {
  content: "\ef2c";
}

.v1b-Picture-1:before {
  content: "\ef2d";
}

.v1b-Picture-2:before {
  content: "\ef2e";
}

.v1b-Picture-3:before {
  content: "\ef2f";
}

.v1b-Picture-Folder:before {
  content: "\ef30";
}

.v1b-Pie-Chart1:before {
  content: "\ef31";
}

.v1b-Pie-chart2:before {
  content: "\ef32";
}

.v1b-Pie-Chart3:before {
  content: "\ef33";
}

.v1b-Pie:before {
  content: "\ef34";
}

.v1b-Pikachu:before {
  content: "\ef35";
}

.v1b-Pills:before {
  content: "\ef36";
}

.v1b-Pin:before {
  content: "\ef37";
}

.v1b-Pine-Tree:before {
  content: "\ef38";
}

.v1b-Pinterest:before {
  content: "\ef39";
}

.v1b-Pirate:before {
  content: "\ef3a";
}

.v1b-Pizza-Slice:before {
  content: "\ef3b";
}

.v1b-Pizza:before {
  content: "\ef3c";
}

.v1b-Planet:before {
  content: "\ef3d";
}

.v1b-Plant-2:before {
  content: "\ef3e";
}

.v1b-Plant-Care:before {
  content: "\ef3f";
}

.v1b-Plant:before {
  content: "\ef40";
}

.v1b-Play-Button:before {
  content: "\ef41";
}

.v1b-Play-Stor:before {
  content: "\ef42";
}

.v1b-Play-Store2:before {
  content: "\ef43";
}

.v1b-Play:before {
  content: "\ef44";
}

.v1b-Player-1:before {
  content: "\ef45";
}

.v1b-Player-2:before {
  content: "\ef46";
}

.v1b-Playground:before {
  content: "\ef47";
}

.v1b-Playing:before {
  content: "\ef48";
}

.v1b-Plurk:before {
  content: "\ef49";
}

.v1b-Podium:before {
  content: "\ef4a";
}

.v1b-Pointer-Help1:before {
  content: "\ef4b";
}

.v1b-Pointer-Help2:before {
  content: "\ef4c";
}

.v1b-Pointer-Working1:before {
  content: "\ef4d";
}

.v1b-Pointer-Working2:before {
  content: "\ef4e";
}

.v1b-Poke:before {
  content: "\ef4f";
}

.v1b-Pokemon:before {
  content: "\ef50";
}

.v1b-Police:before {
  content: "\ef51";
}

.v1b-Pong:before {
  content: "\ef52";
}

.v1b-Pool2:before {
  content: "\ef53";
}

.v1b-Pool:before {
  content: "\ef54";
}

.v1b-Postit:before {
  content: "\ef55";
}

.v1b-Pound-1:before {
  content: "\ef56";
}

.v1b-Pound-2:before {
  content: "\ef57";
}

.v1b-Pound-3:before {
  content: "\ef58";
}

.v1b-Pound-Doc:before {
  content: "\ef59";
}

.v1b-Pound-Fall:before {
  content: "\ef5a";
}

.v1b-Pound-Rise:before {
  content: "\ef5b";
}

.v1b-Power-Socket1:before {
  content: "\ef5c";
}

.v1b-Power-Socket2:before {
  content: "\ef5d";
}

.v1b-Powerpoint:before {
  content: "\ef5e";
}

.v1b-Precision-Seledt1:before {
  content: "\ef5f";
}

.v1b-Precision-Seledt2:before {
  content: "\ef60";
}

.v1b-Precision-Seledt3:before {
  content: "\ef61";
}

.v1b-Presentation-File:before {
  content: "\ef62";
}

.v1b-Pressure-Checker:before {
  content: "\ef63";
}

.v1b-Prev-Button:before {
  content: "\ef64";
}

.v1b-Print:before {
  content: "\ef65";
}

.v1b-Prisoner:before {
  content: "\ef66";
}

.v1b-Projector:before {
  content: "\ef67";
}

.v1b-Protect-Wifi:before {
  content: "\ef68";
}

.v1b-Protection-Helmet:before {
  content: "\ef69";
}

.v1b-Public-Wifi:before {
  content: "\ef6a";
}

.v1b-Pulley-Phisics:before {
  content: "\ef6b";
}

.v1b-Punk:before {
  content: "\ef6c";
}

.v1b-Puzzle-Piece:before {
  content: "\ef6d";
}

.v1b-QR-Code:before {
  content: "\ef6e";
}

.v1b-QuarterHour2:before {
  content: "\ef6f";
}

.v1b-Question-Bookmark:before {
  content: "\ef70";
}

.v1b-Question-File:before {
  content: "\ef71";
}

.v1b-Question-Folder:before {
  content: "\ef72";
}

.v1b-Quicktime-Doc:before {
  content: "\ef73";
}

.v1b-Quicktime-Player:before {
  content: "\ef74";
}

.v1b-Quiet:before {
  content: "\ef75";
}

.v1b-Quote-1:before {
  content: "\ef76";
}

.v1b-Quote-2:before {
  content: "\ef77";
}

.v1b-Radio-1:before {
  content: "\ef78";
}

.v1b-Radio-3:before {
  content: "\ef79";
}

.v1b-Radio2:before {
  content: "\ef7a";
}

.v1b-rattles-1:before {
  content: "\ef7b";
}

.v1b-rattles-2:before {
  content: "\ef7c";
}

.v1b-Razor-Blade:before {
  content: "\ef7d";
}

.v1b-Rec-Button:before {
  content: "\ef7e";
}

.v1b-Reception:before {
  content: "\ef7f";
}

.v1b-Recieve-Mail:before {
  content: "\ef80";
}

.v1b-Recycle-1:before {
  content: "\ef81";
}

.v1b-Recycle-BinFile:before {
  content: "\ef82";
}

.v1b-Recycle-Container:before {
  content: "\ef83";
}

.v1b-Recycle-Water:before {
  content: "\ef84";
}

.v1b-Reddit:before {
  content: "\ef85";
}

.v1b-Redo:before {
  content: "\ef86";
}

.v1b-Refresh-Folder:before {
  content: "\ef87";
}

.v1b-Refresh-Mail:before {
  content: "\ef88";
}

.v1b-Refresh-Network:before {
  content: "\ef89";
}

.v1b-Refresh-Wifi:before {
  content: "\ef8a";
}

.v1b-Registry-1:before {
  content: "\ef8b";
}

.v1b-Registry-2:before {
  content: "\ef8c";
}

.v1b-Registry-3:before {
  content: "\ef8d";
}

.v1b-Registry-List:before {
  content: "\ef8e";
}

.v1b-Relativity:before {
  content: "\ef8f";
}

.v1b-Reload-Mail:before {
  content: "\ef90";
}

.v1b-Remote-Control:before {
  content: "\ef91";
}

.v1b-Remouve-Basket:before {
  content: "\ef92";
}

.v1b-Remouve-Bookmark:before {
  content: "\ef93";
}

.v1b-Remouve-Cart2:before {
  content: "\ef94";
}

.v1b-Remouve-Doc:before {
  content: "\ef95";
}

.v1b-Remouve-File:before {
  content: "\ef96";
}

.v1b-Remouve-Folder:before {
  content: "\ef97";
}

.v1b-Remouve-Location:before {
  content: "\ef98";
}

.v1b-Remouve-Network:before {
  content: "\ef99";
}

.v1b-Remouve-Wifi:before {
  content: "\ef9a";
}

.v1b-Repeat-Doc:before {
  content: "\ef9b";
}

.v1b-Replay-Doc:before {
  content: "\ef9c";
}

.v1b-Reply-All:before {
  content: "\ef9d";
}

.v1b-Reply:before {
  content: "\ef9e";
}

.v1b-Reset-Settings:before {
  content: "\ef9f";
}

.v1b-Resize-Corner:before {
  content: "\efa0";
}

.v1b-Restaurant:before {
  content: "\efa1";
}

.v1b-Retina-Scan:before {
  content: "\efa2";
}

.v1b-Right-1:before {
  content: "\efa3";
}

.v1b-Right-2:before {
  content: "\efa4";
}

.v1b-Right-3:before {
  content: "\efa5";
}

.v1b-Right-4:before {
  content: "\efa6";
}

.v1b-Right-5:before {
  content: "\efa7";
}

.v1b-Right-6:before {
  content: "\efa8";
}

.v1b-Right-7:before {
  content: "\efa9";
}

.v1b-Right-8:before {
  content: "\efaa";
}

.v1b-Right-9:before {
  content: "\efab";
}

.v1b-Right-10:before {
  content: "\efac";
}

.v1b-Right-11:before {
  content: "\efad";
}

.v1b-Right-12:before {
  content: "\efae";
}

.v1b-Right-13:before {
  content: "\efaf";
}

.v1b-Right-14:before {
  content: "\efb0";
}

.v1b-Ring-Chart1:before {
  content: "\efb1";
}

.v1b-Ring-Chart2:before {
  content: "\efb2";
}

.v1b-Roasted-Chicken:before {
  content: "\efb3";
}

.v1b-Rocket-2:before {
  content: "\efb4";
}

.v1b-Rocket:before {
  content: "\efb5";
}

.v1b-Roller-Coaster:before {
  content: "\efb6";
}

.v1b-RollingonFloor:before {
  content: "\efb7";
}

.v1b-Rolling-Skate:before {
  content: "\efb8";
}

.v1b-Room-Key:before {
  content: "\efb9";
}

.v1b-Room-Service2:before {
  content: "\efba";
}

.v1b-Room-Service:before {
  content: "\efbb";
}

.v1b-Rotate-Camera:before {
  content: "\efbc";
}

.v1b-Rotate-Left:before {
  content: "\efbd";
}

.v1b-Rotate-Right:before {
  content: "\efbe";
}

.v1b-Rotate:before {
  content: "\efbf";
}

.v1b-Round-Swipe:before {
  content: "\efc0";
}

.v1b-Rss:before {
  content: "\efc1";
}

.v1b-Rubber:before {
  content: "\efc2";
}

.v1b-S-E-1:before {
  content: "\efc3";
}

.v1b-S-E-4:before {
  content: "\efc4";
}

.v1b-S-E-5:before {
  content: "\efc5";
}

.v1b-S-E-7:before {
  content: "\efc6";
}

.v1b-S-E-11:before {
  content: "\efc7";
}

.v1b-S-E-12:before {
  content: "\efc8";
}

.v1b-S-W-1:before {
  content: "\efc9";
}

.v1b-S-W-4:before {
  content: "\efca";
}

.v1b-S-W-5:before {
  content: "\efcb";
}

.v1b-S-W-7:before {
  content: "\efcc";
}

.v1b-S-W-8:before {
  content: "\efcd";
}

.v1b-S-W-9:before {
  content: "\efce";
}

.v1b-S-W-11:before {
  content: "\efcf";
}

.v1b-S-W-12:before {
  content: "\efd0";
}

.v1b-Safari:before {
  content: "\efd1";
}

.v1b-Safty-Pin1:before {
  content: "\efd2";
}

.v1b-Safty-Pin2:before {
  content: "\efd3";
}

.v1b-Sale-1:before {
  content: "\efd4";
}

.v1b-Sale-2:before {
  content: "\efd5";
}

.v1b-Sale-Tag1:before {
  content: "\efd6";
}

.v1b-Salt-Pepper:before {
  content: "\efd7";
}

.v1b-Sand-Clock:before {
  content: "\efd8";
}

.v1b-Sandwich:before {
  content: "\efd9";
}

.v1b-Santa-Claus:before {
  content: "\efda";
}

.v1b-Satellite:before {
  content: "\efdb";
}

.v1b-Save-Settings:before {
  content: "\efdc";
}

.v1b-Saxophone:before {
  content: "\efdd";
}

.v1b-Scorll-LeftRight1:before {
  content: "\efde";
}

.v1b-Scorll-LeftRight2:before {
  content: "\efdf";
}

.v1b-Scream:before {
  content: "\efe0";
}

.v1b-Screen-Lock:before {
  content: "\efe1";
}

.v1b-Screen-Rotation:before {
  content: "\efe2";
}

.v1b-Screw:before {
  content: "\efe3";
}

.v1b-Screwdriver2:before {
  content: "\efe4";
}

.v1b-Screwdriver:before {
  content: "\efe5";
}

.v1b-Scroll-AllDirection1:before {
  content: "\efe6";
}

.v1b-Scroll-AllDirection2:before {
  content: "\efe7";
}

.v1b-Scroll-Down:before {
  content: "\efe8";
}

.v1b-Scroll-Up:before {
  content: "\efe9";
}

.v1b-Search-Doc:before {
  content: "\efea";
}

.v1b-Search-File:before {
  content: "\efeb";
}

.v1b-Search-Folder:before {
  content: "\efec";
}

.v1b-Search-Mail:before {
  content: "\efed";
}

.v1b-Search-Money:before {
  content: "\efee";
}

.v1b-Search-Network:before {
  content: "\efef";
}

.v1b-Search-Wifi:before {
  content: "\eff0";
}

.v1b-Secure-Card:before {
  content: "\eff1";
}

.v1b-Secure-File:before {
  content: "\eff2";
}

.v1b-Secure-Folder:before {
  content: "\eff3";
}

.v1b-Secure-Shopping2:before {
  content: "\eff4";
}

.v1b-Secure-Shopping4:before {
  content: "\eff5";
}

.v1b-Security-Camera1:before {
  content: "\eff6";
}

.v1b-Security-Camera2:before {
  content: "\eff7";
}

.v1b-Security-Camera3:before {
  content: "\eff8";
}

.v1b-Send-Mail1:before {
  content: "\eff9";
}

.v1b-Send-Mail2:before {
  content: "\effa";
}

.v1b-Send-Mail:before {
  content: "\effb";
}

.v1b-Send-Money:before {
  content: "\effc";
}

.v1b-Settings-Attention:before {
  content: "\effd";
}

.v1b-Settings-Doc:before {
  content: "\effe";
}

.v1b-Settings-Done:before {
  content: "\efff";
}

.v1b-Settings-Error:before {
  content: "\f000";
}

.v1b-Settings-Folder:before {
  content: "\f001";
}

.v1b-Settings-Help:before {
  content: "\f002";
}

.v1b-Settings-Window:before {
  content: "\f003";
}

.v1b-Sextant:before {
  content: "\f004";
}

.v1b-Shake:before {
  content: "\f005";
}

.v1b-Share-Conversation1:before {
  content: "\f006";
}

.v1b-Share-Conversation2:before {
  content: "\f007";
}

.v1b-Share-Conversation3:before {
  content: "\f008";
}

.v1b-Share-File1:before {
  content: "\f009";
}

.v1b-Share-File2:before {
  content: "\f00a";
}

.v1b-Share-Folder:before {
  content: "\f00b";
}

.v1b-Sheet-File:before {
  content: "\f00c";
}

.v1b-Shirt-1:before {
  content: "\f00d";
}

.v1b-Shirt-2:before {
  content: "\f00e";
}

.v1b-Shopping-list:before {
  content: "\f00f";
}

.v1b-Shorts:before {
  content: "\f010";
}

.v1b-Shower-1:before {
  content: "\f011";
}

.v1b-Shower-2:before {
  content: "\f012";
}

.v1b-Shufle-Doc:before {
  content: "\f013";
}

.v1b-Shufle-Folder:before {
  content: "\f014";
}

.v1b-Shy:before {
  content: "\f015";
}

.v1b-Sign-Post:before {
  content: "\f016";
}

.v1b-Silo-Storage:before {
  content: "\f017";
}

.v1b-Sim-Card:before {
  content: "\f018";
}

.v1b-Sims:before {
  content: "\f019";
}

.v1b-Simulator:before {
  content: "\f01a";
}

.v1b-Sinus:before {
  content: "\f01b";
}

.v1b-Siutecase:before {
  content: "\f01c";
}

.v1b-Skate-Board:before {
  content: "\f01d";
}

.v1b-Skeleton:before {
  content: "\f01e";
}

.v1b-Skirt:before {
  content: "\f01f";
}

.v1b-Skrill:before {
  content: "\f020";
}

.v1b-Skull:before {
  content: "\f021";
}

.v1b-Skype:before {
  content: "\f022";
}

.v1b-Sleeping:before {
  content: "\f023";
}

.v1b-Slide-LeftRight:before {
  content: "\f024";
}

.v1b-Slippers:before {
  content: "\f025";
}

.v1b-Slot-7:before {
  content: "\f026";
}

.v1b-Slot-Machine:before {
  content: "\f027";
}

.v1b-Small-Jack:before {
  content: "\f028";
}

.v1b-Smile:before {
  content: "\f029";
}

.v1b-Snake:before {
  content: "\f02a";
}

.v1b-Snooze:before {
  content: "\f02b";
}

.v1b-Soccer-Ball:before {
  content: "\f02c";
}

.v1b-Soda-Can:before {
  content: "\f02d";
}

.v1b-Solar-Panel:before {
  content: "\f02e";
}

.v1b-Solar-System:before {
  content: "\f02f";
}

.v1b-Solider:before {
  content: "\f030";
}

.v1b-Sonic:before {
  content: "\f031";
}

.v1b-Sonny-See:before {
  content: "\f032";
}

.v1b-Soop:before {
  content: "\f033";
}

.v1b-Sound-Cloud:before {
  content: "\f034";
}

.v1b-Sound-Off:before {
  content: "\f035";
}

.v1b-Sound-wave1:before {
  content: "\f036";
}

.v1b-Sound-wave2:before {
  content: "\f037";
}

.v1b-Sound-wave3:before {
  content: "\f038";
}

.v1b-Spa-Towel:before {
  content: "\f039";
}

.v1b-Space-Invaders:before {
  content: "\f03a";
}

.v1b-Spade-AceCard:before {
  content: "\f03b";
}

.v1b-Speaker-1:before {
  content: "\f03c";
}

.v1b-Speaker-2:before {
  content: "\f03d";
}

.v1b-Speaker-3:before {
  content: "\f03e";
}

.v1b-Speaker-4:before {
  content: "\f03f";
}

.v1b-Speakers-1:before {
  content: "\f040";
}

.v1b-Speakers-Phone:before {
  content: "\f041";
}

.v1b-Split-File:before {
  content: "\f042";
}

.v1b-Split:before {
  content: "\f043";
}

.v1b-Sport-Flusk:before {
  content: "\f044";
}

.v1b-Sport-Shirt:before {
  content: "\f045";
}

.v1b-Sport-Shoe:before {
  content: "\f046";
}

.v1b-Spotifi:before {
  content: "\f047";
}

.v1b-Sputnic:before {
  content: "\f048";
}

.v1b-Spy:before {
  content: "\f049";
}

.v1b-Sroll-UpDown1:before {
  content: "\f04a";
}

.v1b-Sroll-UpDown2:before {
  content: "\f04b";
}

.v1b-Stamp-1:before {
  content: "\f04c";
}

.v1b-Stamp-2:before {
  content: "\f04d";
}

.v1b-Stamp:before {
  content: "\f04e";
}

.v1b-Steak:before {
  content: "\f04f";
}

.v1b-Steam:before {
  content: "\f050";
}

.v1b-Stetoscope:before {
  content: "\f051";
}

.v1b-Stock-ofMoney:before {
  content: "\f052";
}

.v1b-Stomach:before {
  content: "\f053";
}

.v1b-Stop-Button:before {
  content: "\f054";
}

.v1b-Stop-Watch1:before {
  content: "\f055";
}

.v1b-Stop-Watch2:before {
  content: "\f056";
}

.v1b-Store:before {
  content: "\f057";
}

.v1b-Stormtrooper:before {
  content: "\f058";
}

.v1b-Strait:before {
  content: "\f059";
}

.v1b-Street-View:before {
  content: "\f05a";
}

.v1b-Striketrough:before {
  content: "\f05b";
}

.v1b-Student:before {
  content: "\f05c";
}

.v1b-Stumbleupon:before {
  content: "\f05d";
}

.v1b-Subscript:before {
  content: "\f05e";
}

.v1b-Success-Chart:before {
  content: "\f05f";
}

.v1b-Sum:before {
  content: "\f060";
}

.v1b-Sun-Glases:before {
  content: "\f061";
}

.v1b-Sunglass:before {
  content: "\f062";
}

.v1b-Sunny-Fields:before {
  content: "\f063";
}

.v1b-Super-Mario:before {
  content: "\f064";
}

.v1b-Superscript:before {
  content: "\f065";
}

.v1b-Sushi:before {
  content: "\f066";
}

.v1b-Swarm:before {
  content: "\f067";
}

.v1b-Sweating:before {
  content: "\f068";
}

.v1b-Swiming-Short:before {
  content: "\f069";
}

.v1b-Swimwear:before {
  content: "\f06a";
}

.v1b-Swipe-LeftRight:before {
  content: "\f06b";
}

.v1b-Swipe-Left:before {
  content: "\f06c";
}

.v1b-Swipe-Right:before {
  content: "\f06d";
}

.v1b-Switch-1:before {
  content: "\f06e";
}

.v1b-Switch-2:before {
  content: "\f06f";
}

.v1b-Switch-3:before {
  content: "\f070";
}

.v1b-Switch-4:before {
  content: "\f071";
}

.v1b-Switch-5:before {
  content: "\f072";
}

.v1b-Syncronize-Files:before {
  content: "\f073";
}

.v1b-Syncronize-Folder2:before {
  content: "\f074";
}

.v1b-Syncronize-Folders:before {
  content: "\f075";
}

.v1b-Synthesizer:before {
  content: "\f076";
}

.v1b-Syringe:before {
  content: "\f077";
}

.v1b-T-Shirt-2:before {
  content: "\f078";
}

.v1b-T-Shirt:before {
  content: "\f079";
}

.v1b-Table-Chart1:before {
  content: "\f07a";
}

.v1b-Table-Chart2:before {
  content: "\f07b";
}

.v1b-Table-Tenis:before {
  content: "\f07c";
}

.v1b-Tablet-Security:before {
  content: "\f07d";
}

.v1b-Tablet-Touch:before {
  content: "\f07e";
}

.v1b-Tag-1:before {
  content: "\f07f";
}

.v1b-Tag-2:before {
  content: "\f080";
}

.v1b-Tangent:before {
  content: "\f081";
}

.v1b-Tap-one:before {
  content: "\f082";
}

.v1b-Tap:before {
  content: "\f083";
}

.v1b-Tape-1:before {
  content: "\f084";
}

.v1b-Tape-2:before {
  content: "\f085";
}

.v1b-Tape-Meter:before {
  content: "\f086";
}

.v1b-Tea-Mug:before {
  content: "\f087";
}

.v1b-Teamviewer:before {
  content: "\f088";
}

.v1b-Tect-Cursor:before {
  content: "\f089";
}

.v1b-Telephone:before {
  content: "\f08a";
}

.v1b-Telescope:before {
  content: "\f08b";
}

.v1b-Television:before {
  content: "\f08c";
}

.v1b-Tempometer:before {
  content: "\f08d";
}

.v1b-Tenis-Ball:before {
  content: "\f08e";
}

.v1b-Tenis:before {
  content: "\f08f";
}

.v1b-Tent:before {
  content: "\f090";
}

.v1b-Termometer2:before {
  content: "\f091";
}

.v1b-Test-Flusk1:before {
  content: "\f092";
}

.v1b-Test-Flusk2:before {
  content: "\f093";
}

.v1b-Test-Tube:before {
  content: "\f094";
}

.v1b-Tetris:before {
  content: "\f095";
}

.v1b-Text-Box2:before {
  content: "\f096";
}

.v1b-Text-Color:before {
  content: "\f097";
}

.v1b-Text-Select1:before {
  content: "\f098";
}

.v1b-Text-Select2:before {
  content: "\f099";
}

.v1b-Text-Size:before {
  content: "\f09a";
}

.v1b-Theatre-Scene:before {
  content: "\f09b";
}

.v1b-Theatre:before {
  content: "\f09c";
}

.v1b-Thoung-Out:before {
  content: "\f09d";
}

.v1b-Thread:before {
  content: "\f09e";
}

.v1b-Thumbs-Down:before {
  content: "\f09f";
}

.v1b-Thumbs-Up:before {
  content: "\f0a0";
}

.v1b-Tic-TacToe:before {
  content: "\f0a1";
}

.v1b-Ticket:before {
  content: "\f0a2";
}

.v1b-Tie:before {
  content: "\f0a3";
}

.v1b-Time-Doc:before {
  content: "\f0a4";
}

.v1b-Time-Folder:before {
  content: "\f0a5";
}

.v1b-Timer-Egg:before {
  content: "\f0a6";
}

.v1b-Toast:before {
  content: "\f0a7";
}

.v1b-Toilet-Paper:before {
  content: "\f0a8";
}

.v1b-Toilet-Pump:before {
  content: "\f0a9";
}

.v1b-Toilet:before {
  content: "\f0aa";
}

.v1b-Tomato:before {
  content: "\f0ab";
}

.v1b-Tools:before {
  content: "\f0ac";
}

.v1b-Tooth:before {
  content: "\f0ad";
}

.v1b-Total-Commander:before {
  content: "\f0ae";
}

.v1b-Touch-Lock:before {
  content: "\f0af";
}

.v1b-Towel:before {
  content: "\f0b0";
}

.v1b-Traffic-Cone:before {
  content: "\f0b1";
}

.v1b-Translate2:before {
  content: "\f0b2";
}

.v1b-Translate:before {
  content: "\f0b3";
}

.v1b-Transmition-Tower:before {
  content: "\f0b4";
}

.v1b-Trash-Protection:before {
  content: "\f0b5";
}

.v1b-Trashcan:before {
  content: "\f0b6";
}

.v1b-Tree-1:before {
  content: "\f0b7";
}

.v1b-Treehouse:before {
  content: "\f0b8";
}

.v1b-Tresure-Chest:before {
  content: "\f0b9";
}

.v1b-Tripit:before {
  content: "\f0ba";
}

.v1b-Trompet-2:before {
  content: "\f0bb";
}

.v1b-Trompet:before {
  content: "\f0bc";
}

.v1b-Tub:before {
  content: "\f0bd";
}

.v1b-Tuenti:before {
  content: "\f0be";
}

.v1b-Tumblr:before {
  content: "\f0bf";
}

.v1b-Turn-Page:before {
  content: "\f0c0";
}

.v1b-Twitch:before {
  content: "\f0c1";
}

.v1b-Twitter-1:before {
  content: "\f0c2";
}

.v1b-Twitter-2:before {
  content: "\f0c3";
}

.v1b-Ubuntu:before {
  content: "\f0c4";
}

.v1b-Ufo:before {
  content: "\f0c5";
}

.v1b-Umbrella:before {
  content: "\f0c6";
}

.v1b-Unavailable-1:before {
  content: "\f0c7";
}

.v1b-Unavailable-2:before {
  content: "\f0c8";
}

.v1b-Underline:before {
  content: "\f0c9";
}

.v1b-Undo:before {
  content: "\f0ca";
}

.v1b-Unlock:before {
  content: "\f0cb";
}

.v1b-Up-1:before {
  content: "\f0cc";
}

.v1b-Up-2:before {
  content: "\f0cd";
}

.v1b-Up-3:before {
  content: "\f0ce";
}

.v1b-Up-4:before {
  content: "\f0cf";
}

.v1b-Up-5:before {
  content: "\f0d0";
}

.v1b-Up-6:before {
  content: "\f0d1";
}

.v1b-Up-7:before {
  content: "\f0d2";
}

.v1b-Up-8:before {
  content: "\f0d3";
}

.v1b-Up-9:before {
  content: "\f0d4";
}

.v1b-Up-10:before {
  content: "\f0d5";
}

.v1b-Up-11:before {
  content: "\f0d6";
}

.v1b-Up-12:before {
  content: "\f0d7";
}

.v1b-Update-Time:before {
  content: "\f0d8";
}

.v1b-Upload-Bookmark:before {
  content: "\f0d9";
}

.v1b-Upload-Doc:before {
  content: "\f0da";
}

.v1b-Upload-File:before {
  content: "\f0db";
}

.v1b-Upload-Folder:before {
  content: "\f0dc";
}

.v1b-Usb-Modem:before {
  content: "\f0dd";
}

.v1b-User-Security1:before {
  content: "\f0de";
}

.v1b-User-Security2:before {
  content: "\f0df";
}

.v1b-Vase:before {
  content: "\f0e0";
}

.v1b-Vector-File:before {
  content: "\f0e1";
}

.v1b-Vest:before {
  content: "\f0e2";
}

.v1b-VHS-Cassette:before {
  content: "\f0e3";
}

.v1b-Viddler:before {
  content: "\f0e4";
}

.v1b-Video-Disc:before {
  content: "\f0e5";
}

.v1b-Video-File:before {
  content: "\f0e6";
}

.v1b-Video-Folder2:before {
  content: "\f0e7";
}

.v1b-Video-Folder:before {
  content: "\f0e8";
}

.v1b-Video-Message:before {
  content: "\f0e9";
}

.v1b-Vimeo:before {
  content: "\f0ea";
}

.v1b-Violine-Key:before {
  content: "\f0eb";
}

.v1b-Violine:before {
  content: "\f0ec";
}

.v1b-Virus-1:before {
  content: "\f0ed";
}

.v1b-Virus-2:before {
  content: "\f0ee";
}

.v1b-VK:before {
  content: "\f0ef";
}

.v1b-Vlc-Player:before {
  content: "\f0f0";
}

.v1b-Voice-Mail:before {
  content: "\f0f1";
}

.v1b-Voice-Message:before {
  content: "\f0f2";
}

.v1b-Voley-Ball:before {
  content: "\f0f3";
}

.v1b-Volume-10:before {
  content: "\f0f4";
}

.v1b-Volume-11:before {
  content: "\f0f5";
}

.v1b-Volume-12:before {
  content: "\f0f6";
}

.v1b-Volume-13:before {
  content: "\f0f7";
}

.v1b-Volume-1Down:before {
  content: "\f0f8";
}

.v1b-Volume-1Mute:before {
  content: "\f0f9";
}

.v1b-Volume-1Up:before {
  content: "\f0fa";
}

.v1b-Volume-20:before {
  content: "\f0fb";
}

.v1b-Volume-21:before {
  content: "\f0fc";
}

.v1b-Volume-22:before {
  content: "\f0fd";
}

.v1b-Volume-23:before {
  content: "\f0fe";
}

.v1b-Volume-2Down:before {
  content: "\f0ff";
}

.v1b-Volume-2Mute:before {
  content: "\f100";
}

.v1b-Volume-2Up:before {
  content: "\f101";
}

.v1b-Volume-controler:before {
  content: "\f102";
}

.v1b-Vomiting:before {
  content: "\f103";
}

.v1b-Vulverine:before {
  content: "\f104";
}

.v1b-Wach-4:before {
  content: "\f105";
}

.v1b-Waiter:before {
  content: "\f106";
}

.v1b-Waitress:before {
  content: "\f107";
}

.v1b-Wall:before {
  content: "\f108";
}

.v1b-Wallet-1:before {
  content: "\f109";
}

.v1b-Wallet-2:before {
  content: "\f10a";
}

.v1b-Wallet-3:before {
  content: "\f10b";
}

.v1b-Watch-1:before {
  content: "\f10c";
}

.v1b-Watch-2:before {
  content: "\f10d";
}

.v1b-Watch-3:before {
  content: "\f10e";
}

.v1b-Water-Tap:before {
  content: "\f10f";
}

.v1b-Watering-can:before {
  content: "\f110";
}

.v1b-Wattpad:before {
  content: "\f111";
}

.v1b-Wav-Doc:before {
  content: "\f112";
}

.v1b-WB-Incandescent:before {
  content: "\f113";
}

.v1b-WB-Irradescent:before {
  content: "\f114";
}

.v1b-WB-Suny:before {
  content: "\f115";
}

.v1b-Wechat:before {
  content: "\f116";
}

.v1b-Weight-Libra:before {
  content: "\f117";
}

.v1b-Weight-Lift:before {
  content: "\f118";
}

.v1b-Weight:before {
  content: "\f119";
}

.v1b-Whatsapp:before {
  content: "\f11a";
}

.v1b-Wheel-Chair:before {
  content: "\f11b";
}

.v1b-Wheel-Swing:before {
  content: "\f11c";
}

.v1b-Whistle:before {
  content: "\f11d";
}

.v1b-Wierd-1:before {
  content: "\f11e";
}

.v1b-Wierd-2:before {
  content: "\f11f";
}

.v1b-Wifi-1:before {
  content: "\f120";
}

.v1b-Wifi-2:before {
  content: "\f121";
}

.v1b-Wifi-3:before {
  content: "\f122";
}

.v1b-Wifi-4:before {
  content: "\f123";
}

.v1b-Wifi-Delay:before {
  content: "\f124";
}

.v1b-Wifi-Error1:before {
  content: "\f125";
}

.v1b-Wifi-Error2:before {
  content: "\f126";
}

.v1b-Wifi-Question:before {
  content: "\f127";
}

.v1b-Wifi-Router:before {
  content: "\f128";
}

.v1b-Wifi-Settings:before {
  content: "\f129";
}

.v1b-Wii-Console:before {
  content: "\f12a";
}

.v1b-Wind-Game:before {
  content: "\f12b";
}

.v1b-Wind-Turbine:before {
  content: "\f12c";
}

.v1b-Windows-MediaPlayer:before {
  content: "\f12d";
}

.v1b-Windows:before {
  content: "\f12e";
}

.v1b-Wine-Bottle:before {
  content: "\f12f";
}

.v1b-Wine-Glass:before {
  content: "\f130";
}

.v1b-Wink:before {
  content: "\f131";
}

.v1b-Wireles-Headset:before {
  content: "\f132";
}

.v1b-Wma-Doc2:before {
  content: "\f133";
}

.v1b-Wma-Doc:before {
  content: "\f134";
}

.v1b-Wondering:before {
  content: "\f135";
}

.v1b-Word:before {
  content: "\f136";
}

.v1b-Wordpress:before {
  content: "\f137";
}

.v1b-Worker:before {
  content: "\f138";
}

.v1b-World-ofWarcraft:before {
  content: "\f139";
}

.v1b-World-WideWeb:before {
  content: "\f13a";
}

.v1b-Worms-Armagedon:before {
  content: "\f13b";
}

.v1b-Wow:before {
  content: "\f13c";
}

.v1b-Wrap-ImageCenter:before {
  content: "\f13d";
}

.v1b-Wrap-ImageLeft:before {
  content: "\f13e";
}

.v1b-Wrap-ImageRight:before {
  content: "\f13f";
}

.v1b-Wrench-1:before {
  content: "\f140";
}

.v1b-Wrench-2:before {
  content: "\f141";
}

.v1b-Wrench-3:before {
  content: "\f142";
}

.v1b-Wrench-4:before {
  content: "\f143";
}

.v1b-X-Eye:before {
  content: "\f144";
}

.v1b-X-Mouth:before {
  content: "\f145";
}

.v1b-Xilophone:before {
  content: "\f146";
}

.v1b-Xing:before {
  content: "\f147";
}

.v1b-Yagermeister:before {
  content: "\f148";
}

.v1b-Yahoo-Messenger:before {
  content: "\f149";
}

.v1b-Yahoo:before {
  content: "\f14a";
}

.v1b-Yammer:before {
  content: "\f14b";
}

.v1b-Yelling:before {
  content: "\f14c";
}

.v1b-You-Rock:before {
  content: "\f14d";
}

.v1b-Youtube-1:before {
  content: "\f14e";
}

.v1b-Youtube-2:before {
  content: "\f14f";
}

.v1b-Zip-File:before {
  content: "\f150";
}

.v1b-Zip-Folder:before {
  content: "\f151";
}

.v1b-Zipper:before {
  content: "\f152";
}

.v1b-Zombie:before {
  content: "\f153";
}

.v1b-Zoom-In2:before {
  content: "\f154";
}

.v1b-Zoom-In3:before {
  content: "\f155";
}

.v1b-Zoom-In:before {
  content: "\f156";
}

.v1b-Zoom-Out2:before {
  content: "\f157";
}

.v1b-Zoom-Out3:before {
  content: "\f158";
}

.v1b-Zoom-Out:before {
  content: "\f159";
}

.v1b-Zootool:before {
  content: "\f15a";
}

