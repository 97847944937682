.text-pre {
  white-space: pre-line;
}

.text-end {
  text-align: end;
}

.text-bold {
  font-weight: 600 !important;
}

//kdh add

.text-center {
  text-align: center;
}

.mat-card-title {
  font-size: 13px!important;
}
.mat-card-header-text {
  margin: 0 5px!important;
}
.mat-stroked-button {
  border: 1px solid currentColor;
  padding: 0 15px;
  line-height: 28px;
}
.mat-rised-button {
  line-height: 28px;
}

.button-md {
  border: 1px solid currentColor;
  padding: 0 12px;
  line-height: 20px;
}

.f-14 {
  font-size: 14px!important;
}
.f-16 {
  font-size: 16px!important;
}
