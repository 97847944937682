@font-face {
  font-family: 'v1';
  src: url('fonts/v1.woff?uf6sbu') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v1-"], [class*=" v1-"] {
  font-family: 'v1' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v1-Add-Bag:before {
  content: "\e900";
}

.v1-Add-Cart2:before {
  content: "\e901";
}

.v1-Add-Chat1:before {
  content: "\e902";
}

.v1-Add-Chat2:before {
  content: "\e903";
}

.v1-Add-Chat3:before {
  content: "\e904";
}

.v1-Bitcoin-Bag:before {
  content: "\e905";
}

.v1-Bitcoin-Cart:before {
  content: "\e906";
}

.v1-Cart:before {
  content: "\e907";
}

.v1-Char-Search2:before {
  content: "\e908";
}

.v1-Chat-1:before {
  content: "\e909";
}

.v1-Chat-2:before {
  content: "\e90a";
}

.v1-Chat-3:before {
  content: "\e90b";
}

.v1-Chat-Bubble1:before {
  content: "\e90c";
}

.v1-Chat-Bubble2:before {
  content: "\e90d";
}

.v1-Chat-Bubble4:before {
  content: "\e90e";
}

.v1-Chat-Bubble5:before {
  content: "\e90f";
}

.v1-Chat-Buble3:before {
  content: "\e910";
}

.v1-Chat-Delete1:before {
  content: "\e911";
}

.v1-Chat-Delete2:before {
  content: "\e912";
}

.v1-Chat-Delete3:before {
  content: "\e913";
}

.v1-Chat-Error1:before {
  content: "\e914";
}

.v1-Chat-Error2:before {
  content: "\e915";
}

.v1-Chat-Error3:before {
  content: "\e916";
}

.v1-Chat-Help1:before {
  content: "\e917";
}

.v1-Chat-Help2:before {
  content: "\e918";
}

.v1-Chat-Help3:before {
  content: "\e919";
}

.v1-Chat-Remouve1:before {
  content: "\e91a";
}

.v1-Chat-Remouve2:before {
  content: "\e91b";
}

.v1-Chat-Remouve3:before {
  content: "\e91c";
}

.v1-Chat-Search1:before {
  content: "\e91d";
}

.v1-Chat-Search3:before {
  content: "\e91e";
}

.v1-Chat-Settings1:before {
  content: "\e91f";
}

.v1-Chat-Settings2:before {
  content: "\e920";
}

.v1-Chat-settings3:before {
  content: "\e921";
}

.v1-Chat-User1:before {
  content: "\e922";
}

.v1-Chat-User2:before {
  content: "\e923";
}

.v1-Chat-User3:before {
  content: "\e924";
}

.v1-Chat-User4:before {
  content: "\e925";
}

.v1-Check-Out:before {
  content: "\e926";
}

.v1-Clear-Bag:before {
  content: "\e927";
}

.v1-Clear-Cart2:before {
  content: "\e928";
}

.v1-Conference-Chat:before {
  content: "\e929";
}

.v1-Conference-Speach:before {
  content: "\e92a";
}

.v1-Credit-Card:before {
  content: "\e92b";
}

.v1-Cuppon:before {
  content: "\e92c";
}

.v1-Dismiss-Bag:before {
  content: "\e92d";
}

.v1-Dismiss-Cart:before {
  content: "\e92e";
}

.v1-Dismiss-Chat1:before {
  content: "\e92f";
}

.v1-Dismiss-Chat2:before {
  content: "\e930";
}

.v1-Dismiss-Chat3:before {
  content: "\e931";
}

.v1-Dollar-Bag:before {
  content: "\e932";
}

.v1-Dollar-Cart:before {
  content: "\e933";
}

.v1-Done-Bag:before {
  content: "\e934";
}

.v1-Done-Cat:before {
  content: "\e935";
}

.v1-Dream-Bubble1:before {
  content: "\e936";
}

.v1-Dream-Bubble2:before {
  content: "\e937";
}

.v1-Dreamimg-2:before {
  content: "\e938";
}

.v1-Dreaming-1:before {
  content: "\e939";
}

.v1-Euro-Bag:before {
  content: "\e93a";
}

.v1-Euro-Cart:before {
  content: "\e93b";
}

.v1-Favorite-Bag:before {
  content: "\e93c";
}

.v1-Favorite-Cart:before {
  content: "\e93d";
}

.v1-Favorite-Chat1:before {
  content: "\e93e";
}

.v1-Favorite-Chat2:before {
  content: "\e93f";
}

.v1-Favorite-Chat3:before {
  content: "\e940";
}

.v1-Full-Cart2:before {
  content: "\e941";
}

.v1-Gift-Box2:before {
  content: "\e942";
}

.v1-Gift-Card2:before {
  content: "\e943";
}

.v1-Home-Bag:before {
  content: "\e944";
}

.v1-Hot-Offer:before {
  content: "\e945";
}

.v1-Mobile-Shopping1:before {
  content: "\e946";
}

.v1-Mobile-Shopping2:before {
  content: "\e947";
}

.v1-On-Sale2:before {
  content: "\e948";
}

.v1-On-Sale:before {
  content: "\e949";
}

.v1-Online-shopping1:before {
  content: "\e94a";
}

.v1-Online-Store:before {
  content: "\e94b";
}

.v1-Open-box:before {
  content: "\e94c";
}

.v1-Pound-Bag:before {
  content: "\e94d";
}

.v1-Pound-Cart:before {
  content: "\e94e";
}

.v1-Remouve-Bag:before {
  content: "\e94f";
}

.v1-Remouve-Cart2:before {
  content: "\e950";
}

.v1-Rotate-2:before {
  content: "\e951";
}

.v1-Search-Bag:before {
  content: "\e952";
}

.v1-Search-Cart:before {
  content: "\e953";
}

.v1-Secure-Shopping3:before {
  content: "\e954";
}

.v1-Secure-Shopping4:before {
  content: "\e955";
}

.v1-Secure-Shopping5:before {
  content: "\e956";
}

.v1-Share-Conversation1:before {
  content: "\e957";
}

.v1-Share-Conversation2:before {
  content: "\e958";
}

.v1-Share-Conversation3:before {
  content: "\e959";
}

.v1-Shipping-box:before {
  content: "\e95a";
}

.v1-Shipping-Scedule:before {
  content: "\e95b";
}

.v1-Shipping:before {
  content: "\e95c";
}

.v1-Shopping-bag:before {
  content: "\e95d";
}

.v1-Video-Message:before {
  content: "\e95e";
}

.v1-Voice-Message:before {
  content: "\e95f";
}

.v1-Web-shop1:before {
  content: "\e960";
}

.v1-Web-shop2:before {
  content: "\e961";
}

.v1-Web-shop3:before {
  content: "\e962";
}

.v1-D-Blockchart1:before {
  content: "\e963";
}

.v1-112:before {
  content: "\e964";
}

.v1-911:before {
  content: "\e965";
}

.v1-Add-Call:before {
  content: "\e966";
}

.v1-Balloons:before {
  content: "\e967";
}

.v1-Baseball-Cap:before {
  content: "\e968";
}

.v1-Bat-Man:before {
  content: "\e969";
}

.v1-Block-Call:before {
  content: "\e96a";
}

.v1-Block-Chart1:before {
  content: "\e96b";
}

.v1-Block-Chart2:before {
  content: "\e96c";
}

.v1-Block-Chart3:before {
  content: "\e96d";
}

.v1-Bluetuth-PhoneSpeaker:before {
  content: "\e96e";
}

.v1-Call-24h:before {
  content: "\e96f";
}

.v1-Call-Center24h:before {
  content: "\e970";
}

.v1-Call-Forward:before {
  content: "\e971";
}

.v1-Call-Made:before {
  content: "\e972";
}

.v1-Call-Recieved:before {
  content: "\e973";
}

.v1-Call-Reservation:before {
  content: "\e974";
}

.v1-Clear-call:before {
  content: "\e975";
}

.v1-Crain-Hook:before {
  content: "\e976";
}

.v1-Decreasing-Chart1:before {
  content: "\e977";
}

.v1-Decreasing-Chart2:before {
  content: "\e978";
}

.v1-Delayed-Call:before {
  content: "\e979";
}

.v1-Diagram-1:before {
  content: "\e97a";
}

.v1-Diagram-2:before {
  content: "\e97b";
}

.v1-Diagram-3:before {
  content: "\e97c";
}

.v1-Diagram-4:before {
  content: "\e97d";
}

.v1-Dial-Pad2:before {
  content: "\e97e";
}

.v1-Dot-Chart1:before {
  content: "\e97f";
}

.v1-Dot-Chart2:before {
  content: "\e980";
}

.v1-Dot-Chart3:before {
  content: "\e981";
}

.v1-Enter-1:before {
  content: "\e982";
}

.v1-Enter-2:before {
  content: "\e983";
}

.v1-Exit-1:before {
  content: "\e984";
}

.v1-Exit-2:before {
  content: "\e985";
}

.v1-Fax-Phone:before {
  content: "\e986";
}

.v1-Fragment-Chart:before {
  content: "\e987";
}

.v1-Hold-TheLine:before {
  content: "\e988";
}

.v1-ID-Tag:before {
  content: "\e989";
}

.v1-Increasing-Chart1:before {
  content: "\e98a";
}

.v1-Increasing-Chart2:before {
  content: "\e98b";
}

.v1-Key-2:before {
  content: "\e98c";
}

.v1-Key-Hole1:before {
  content: "\e98d";
}

.v1-Key-Hole2:before {
  content: "\e98e";
}

.v1-Landscape-Chart:before {
  content: "\e98f";
}

.v1-Line-Chart1:before {
  content: "\e990";
}

.v1-Line-Chart2:before {
  content: "\e991";
}

.v1-Line-Chart3:before {
  content: "\e992";
}

.v1-Lock-1:before {
  content: "\e993";
}

.v1-Lock-Call:before {
  content: "\e994";
}

.v1-Lock-User1:before {
  content: "\e995";
}

.v1-Lock-User2:before {
  content: "\e996";
}

.v1-Login-Form1:before {
  content: "\e997";
}

.v1-Login-Form2:before {
  content: "\e998";
}

.v1-Marge-Call:before {
  content: "\e999";
}

.v1-Missed-Call:before {
  content: "\e99a";
}

.v1-Name-Tag:before {
  content: "\e99b";
}

.v1-Open-Lock:before {
  content: "\e99c";
}

.v1-Pause-Call:before {
  content: "\e99d";
}

.v1-Phone-1:before {
  content: "\e99e";
}

.v1-Phone-2:before {
  content: "\e99f";
}

.v1-Phone-3:before {
  content: "\e9a0";
}

.v1-Phone-4:before {
  content: "\e9a1";
}

.v1-Phone-Book:before {
  content: "\e9a2";
}

.v1-Phone-Box:before {
  content: "\e9a3";
}

.v1-Phone-InTalk:before {
  content: "\e9a4";
}

.v1-Phone-Ring:before {
  content: "\e9a5";
}

.v1-Phone-Shopping:before {
  content: "\e9a6";
}

.v1-Pie-Chart1:before {
  content: "\e9a7";
}

.v1-Pie-chart2:before {
  content: "\e9a8";
}

.v1-Pie-Chart3:before {
  content: "\e9a9";
}

.v1-Pin-Code:before {
  content: "\e9aa";
}

.v1-Public-Phone:before {
  content: "\e9ab";
}

.v1-Recall:before {
  content: "\e9ac";
}

.v1-Record-call:before {
  content: "\e9ad";
}

.v1-Remouve-Call:before {
  content: "\e9ae";
}

.v1-Ring-Chart1:before {
  content: "\e9af";
}

.v1-Ring-Chart2:before {
  content: "\e9b0";
}

.v1-Signal-0:before {
  content: "\e9b1";
}

.v1-Signal-1:before {
  content: "\e9b2";
}

.v1-Signal-2:before {
  content: "\e9b3";
}

.v1-Signal-3:before {
  content: "\e9b4";
}

.v1-Signal-4:before {
  content: "\e9b5";
}

.v1-Signal-5:before {
  content: "\e9b6";
}

.v1-Sinus:before {
  content: "\e9b7";
}

.v1-Split-Call:before {
  content: "\e9b8";
}

.v1-Success-Chart:before {
  content: "\e9b9";
}

.v1-Table-Chart1:before {
  content: "\e9ba";
}

.v1-Table-Chart2:before {
  content: "\e9bb";
}

.v1-Tangent:before {
  content: "\e9bc";
}

.v1-BedRoom:before {
  content: "\e9bd";
}

.v1-FingerTouch:before {
  content: "\e9be";
}

.v1-Hour:before {
  content: "\e9bf";
}

.v1-BedRoom2:before {
  content: "\e9c0";
}

.v1-Files:before {
  content: "\e9c1";
}

.v1-FingerTouch2:before {
  content: "\e9c2";
}

.v1-Files2:before {
  content: "\e9c3";
}

.v1-FingerTouch3:before {
  content: "\e9c4";
}

.v1-StarBed:before {
  content: "\e9c5";
}

.v1-G-Network:before {
  content: "\e9c6";
}

.v1-gp-Doc:before {
  content: "\e9c7";
}

.v1-FingerRoatate:before {
  content: "\e9c8";
}

.v1-FingerTouch4:before {
  content: "\e9c9";
}

.v1-G-Network2:before {
  content: "\e9ca";
}

.v1-StarHotel1:before {
  content: "\e9cb";
}

.v1-Starhotel2:before {
  content: "\e9cc";
}

.v1-Ball:before {
  content: "\e9cd";
}

.v1-Min:before {
  content: "\e9ce";
}

.v1-by7:before {
  content: "\e9cf";
}

.v1-Min2:before {
  content: "\e9d0";
}

.v1-Min3:before {
  content: "\e9d1";
}

.v1-Abascus-Calculator:before {
  content: "\e9d2";
}

.v1-Accordion:before {
  content: "\e9d3";
}

.v1-Acrobat-File:before {
  content: "\e9d4";
}

.v1-Add-Basket:before {
  content: "\e9d5";
}

.v1-Add-Bookmark:before {
  content: "\e9d6";
}

.v1-Add-Card:before {
  content: "\e9d7";
}

.v1-Add-Cart:before {
  content: "\e9d8";
}

.v1-Add-Cloud:before {
  content: "\e9d9";
}

.v1-Add-Doc:before {
  content: "\e9da";
}

.v1-Add-File:before {
  content: "\e9db";
}

.v1-Add-Folder:before {
  content: "\e9dc";
}

.v1-Add-Location:before {
  content: "\e9dd";
}

.v1-Add-Mail:before {
  content: "\e9de";
}

.v1-Add-Wifi:before {
  content: "\e9df";
}

.v1-Addon-Setting:before {
  content: "\e9e0";
}

.v1-Addvertise:before {
  content: "\e9e1";
}

.v1-Adobe-FlashPlayer:before {
  content: "\e9e2";
}

.v1-Adobe:before {
  content: "\e9e3";
}

.v1-Aif-Doc:before {
  content: "\e9e4";
}

.v1-Air-Conditioner2:before {
  content: "\e9e5";
}

.v1-Air-Conditioner:before {
  content: "\e9e6";
}

.v1-Air-Conditioner1:before {
  content: "\e9e7";
}

.v1-Airbnb:before {
  content: "\e9e8";
}

.v1-Alambic:before {
  content: "\e9e9";
}

.v1-Alarm-Clock1:before {
  content: "\e9ea";
}

.v1-Alarm-Clock2:before {
  content: "\e9eb";
}

.v1-Alarm-Clock3:before {
  content: "\e9ec";
}

.v1-Alarm-Sound:before {
  content: "\e9ed";
}

.v1-Align-Center:before {
  content: "\e9ee";
}

.v1-Align-Left:before {
  content: "\e9ef";
}

.v1-Align-Right:before {
  content: "\e9f0";
}

.v1-All-Directions:before {
  content: "\e9f1";
}

.v1-Allert-Card:before {
  content: "\e9f2";
}

.v1-Alligator:before {
  content: "\e9f3";
}

.v1-Alphabet-List:before {
  content: "\e9f4";
}

.v1-Alu-Recycle:before {
  content: "\e9f5";
}

.v1-Amazon:before {
  content: "\e9f6";
}

.v1-Ambulance-1:before {
  content: "\e9f7";
}

.v1-Ambulance:before {
  content: "\e9f8";
}

.v1-Amd:before {
  content: "\e9f9";
}

.v1-Amex-2:before {
  content: "\e9fa";
}

.v1-AMEX:before {
  content: "\e9fb";
}

.v1-Amplifier-1:before {
  content: "\e9fc";
}

.v1-Amplifier-2:before {
  content: "\e9fd";
}

.v1-Analogue-Antenna:before {
  content: "\e9fe";
}

.v1-Anchor:before {
  content: "\e9ff";
}

.v1-Android:before {
  content: "\ea00";
}

.v1-Angel2:before {
  content: "\ea01";
}

.v1-Angel:before {
  content: "\ea02";
}

.v1-Angry-Birds2:before {
  content: "\ea03";
}

.v1-Angry-Birds:before {
  content: "\ea04";
}

.v1-Anonymous-1:before {
  content: "\ea05";
}

.v1-Anonymous-2:before {
  content: "\ea06";
}

.v1-Ant:before {
  content: "\ea07";
}

.v1-Antilop:before {
  content: "\ea08";
}

.v1-App-Setting:before {
  content: "\ea09";
}

.v1-Apple2:before {
  content: "\ea0a";
}

.v1-Apple:before {
  content: "\ea0b";
}

.v1-Appstore-2:before {
  content: "\ea0c";
}

.v1-Arc-Phisics:before {
  content: "\ea0d";
}

.v1-Arcade:before {
  content: "\ea0e";
}

.v1-Archery-1:before {
  content: "\ea0f";
}

.v1-Archery-2:before {
  content: "\ea10";
}

.v1-Aries:before {
  content: "\ea11";
}

.v1-Army-Solider:before {
  content: "\ea12";
}

.v1-Artboard:before {
  content: "\ea13";
}

.v1-Astronaut:before {
  content: "\ea14";
}

.v1-At-Symbol:before {
  content: "\ea15";
}

.v1-Ati:before {
  content: "\ea16";
}

.v1-Atm-1:before {
  content: "\ea17";
}

.v1-Atm-2:before {
  content: "\ea18";
}

.v1-Atm-3:before {
  content: "\ea19";
}

.v1-Atom:before {
  content: "\ea1a";
}

.v1-Atomic-Reactor:before {
  content: "\ea1b";
}

.v1-Attach-File:before {
  content: "\ea1c";
}

.v1-Attache-Mail:before {
  content: "\ea1d";
}

.v1-Attacher:before {
  content: "\ea1e";
}

.v1-Attention-Cloud:before {
  content: "\ea1f";
}

.v1-Attention1-Doc:before {
  content: "\ea20";
}

.v1-Attention2-Doc:before {
  content: "\ea21";
}

.v1-Auction:before {
  content: "\ea22";
}

.v1-Auto-Flash:before {
  content: "\ea23";
}

.v1-Auto-Gearbox:before {
  content: "\ea24";
}

.v1-Aux-Cabel:before {
  content: "\ea25";
}

.v1-Avi-Doc:before {
  content: "\ea26";
}

.v1-Backward-Button:before {
  content: "\ea27";
}

.v1-Backward:before {
  content: "\ea28";
}

.v1-Bacteria-1:before {
  content: "\ea29";
}

.v1-Bacteria-4:before {
  content: "\ea2a";
}

.v1-Bad-Pig:before {
  content: "\ea2b";
}

.v1-Badminton:before {
  content: "\ea2c";
}

.v1-Bag-1:before {
  content: "\ea2d";
}

.v1-Bag-2:before {
  content: "\ea2e";
}

.v1-Balance-1:before {
  content: "\ea2f";
}

.v1-Balance-2:before {
  content: "\ea30";
}

.v1-Bald-Male:before {
  content: "\ea31";
}

.v1-Ball-Pen:before {
  content: "\ea32";
}

.v1-Baloon:before {
  content: "\ea33";
}

.v1-Banana:before {
  content: "\ea34";
}

.v1-Banch:before {
  content: "\ea35";
}

.v1-Bank-1:before {
  content: "\ea36";
}

.v1-Bank-2:before {
  content: "\ea37";
}

.v1-Bank3:before {
  content: "\ea38";
}

.v1-Barbeque:before {
  content: "\ea39";
}

.v1-Barcode-Scanner:before {
  content: "\ea3a";
}

.v1-Barcode:before {
  content: "\ea3b";
}

.v1-Barrow:before {
  content: "\ea3c";
}

.v1-Baseball-2:before {
  content: "\ea3d";
}

.v1-Baseball:before {
  content: "\ea3e";
}

.v1-Basket-1:before {
  content: "\ea3f";
}

.v1-Basket-2:before {
  content: "\ea40";
}

.v1-Basket-Ball:before {
  content: "\ea41";
}

.v1-Basket:before {
  content: "\ea42";
}

.v1-Bass-key:before {
  content: "\ea43";
}

.v1-Bat:before {
  content: "\ea44";
}

.v1-Bath-Robe:before {
  content: "\ea45";
}

.v1-Battery2:before {
  content: "\ea46";
}

.v1-Battery-0:before {
  content: "\ea47";
}

.v1-Battery-1:before {
  content: "\ea48";
}

.v1-Battery-2:before {
  content: "\ea49";
}

.v1-Battery-3:before {
  content: "\ea4a";
}

.v1-Battery-4:before {
  content: "\ea4b";
}

.v1-Battery:before {
  content: "\ea4c";
}

.v1-Battrey-Charge:before {
  content: "\ea4d";
}

.v1-Beach-Ball:before {
  content: "\ea4e";
}

.v1-Beanie-Hat:before {
  content: "\ea4f";
}

.v1-Bear:before {
  content: "\ea50";
}

.v1-Beard-Man:before {
  content: "\ea51";
}

.v1-Beatle:before {
  content: "\ea52";
}

.v1-Beats:before {
  content: "\ea53";
}

.v1-Bee:before {
  content: "\ea54";
}

.v1-Beer-Mug:before {
  content: "\ea55";
}

.v1-Behance:before {
  content: "\ea56";
}

.v1-Bell2:before {
  content: "\ea57";
}

.v1-Bell:before {
  content: "\ea58";
}

.v1-Bellboy:before {
  content: "\ea59";
}

.v1-Bellhop:before {
  content: "\ea5a";
}

.v1-Belt:before {
  content: "\ea5b";
}

.v1-Benjo:before {
  content: "\ea5c";
}

.v1-Bezier-1:before {
  content: "\ea5d";
}

.v1-Bezier-2:before {
  content: "\ea5e";
}

.v1-Big-Eye:before {
  content: "\ea5f";
}

.v1-Big-Jack:before {
  content: "\ea60";
}

.v1-Big-Shopping:before {
  content: "\ea61";
}

.v1-Big-Smile:before {
  content: "\ea62";
}

.v1-Bike-1:before {
  content: "\ea63";
}

.v1-Bike-2:before {
  content: "\ea64";
}

.v1-Bike-3:before {
  content: "\ea65";
}

.v1-Bike:before {
  content: "\ea66";
}

.v1-Bikini:before {
  content: "\ea67";
}

.v1-Binders:before {
  content: "\ea68";
}

.v1-Bing:before {
  content: "\ea69";
}

.v1-Binocular:before {
  content: "\ea6a";
}

.v1-Bio-1:before {
  content: "\ea6b";
}

.v1-Bio-2:before {
  content: "\ea6c";
}

.v1-Bio-Ennergy:before {
  content: "\ea6d";
}

.v1-Bio-Gas:before {
  content: "\ea6e";
}

.v1-Biohazzard:before {
  content: "\ea6f";
}

.v1-Bitcoin-2:before {
  content: "\ea70";
}

.v1-Bitcoin-3:before {
  content: "\ea71";
}

.v1-Bitcoin-Cloud:before {
  content: "\ea72";
}

.v1-Bitcoin-Doc:before {
  content: "\ea73";
}

.v1-Black-Friday:before {
  content: "\ea74";
}

.v1-Blank-File:before {
  content: "\ea75";
}

.v1-Blend-Tool:before {
  content: "\ea76";
}

.v1-Blogger:before {
  content: "\ea77";
}

.v1-Blood-Drop:before {
  content: "\ea78";
}

.v1-Bluetuth:before {
  content: "\ea79";
}

.v1-Blututh-Headset:before {
  content: "\ea7a";
}

.v1-Boiled-Egg:before {
  content: "\ea7b";
}

.v1-Bold:before {
  content: "\ea7c";
}

.v1-Bomb:before {
  content: "\ea7d";
}

.v1-Bomber-Man:before {
  content: "\ea7e";
}

.v1-Bookmark-1:before {
  content: "\ea7f";
}

.v1-Bookmark-2:before {
  content: "\ea80";
}

.v1-Bookmark-3:before {
  content: "\ea81";
}

.v1-Bookmark-4:before {
  content: "\ea82";
}

.v1-Bookmark-Settings:before {
  content: "\ea83";
}

.v1-Bookmark-Site2:before {
  content: "\ea84";
}

.v1-Bookmark-Site:before {
  content: "\ea85";
}

.v1-Bookmarked-File:before {
  content: "\ea86";
}

.v1-Boot:before {
  content: "\ea87";
}

.v1-Botcoin-1:before {
  content: "\ea88";
}

.v1-Bow-Tie:before {
  content: "\ea89";
}

.v1-Bow:before {
  content: "\ea8a";
}

.v1-Bowler-Hat:before {
  content: "\ea8b";
}

.v1-Bowling-Ball:before {
  content: "\ea8c";
}

.v1-Bowling:before {
  content: "\ea8d";
}

.v1-Boxing-Bag:before {
  content: "\ea8e";
}

.v1-Boxing-Glov:before {
  content: "\ea8f";
}

.v1-Boxing-Helmet:before {
  content: "\ea90";
}

.v1-Boxing-Ring:before {
  content: "\ea91";
}

.v1-Bra:before {
  content: "\ea92";
}

.v1-Brain:before {
  content: "\ea93";
}

.v1-Bread:before {
  content: "\ea94";
}

.v1-Breakout:before {
  content: "\ea95";
}

.v1-Bridge:before {
  content: "\ea96";
}

.v1-Briefcase-1:before {
  content: "\ea97";
}

.v1-Briefcase-2:before {
  content: "\ea98";
}

.v1-Brightness-2:before {
  content: "\ea99";
}

.v1-Brightness-3:before {
  content: "\ea9a";
}

.v1-Brigthness-1:before {
  content: "\ea9b";
}

.v1-Broken-Glas:before {
  content: "\ea9c";
}

.v1-Broken-Heart:before {
  content: "\ea9d";
}

.v1-Broken-Link:before {
  content: "\ea9e";
}

.v1-Brush-1:before {
  content: "\ea9f";
}

.v1-Brush-2:before {
  content: "\eaa0";
}

.v1-Bucket:before {
  content: "\eaa1";
}

.v1-Buddybuilding:before {
  content: "\eaa2";
}

.v1-Bug-Protect:before {
  content: "\eaa3";
}

.v1-Bullet-List:before {
  content: "\eaa4";
}

.v1-Bulleted-List:before {
  content: "\eaa5";
}

.v1-Bus-1:before {
  content: "\eaa6";
}

.v1-Bus-2:before {
  content: "\eaa7";
}

.v1-Bus-3:before {
  content: "\eaa8";
}

.v1-Busi-2:before {
  content: "\eaa9";
}

.v1-Business-Man:before {
  content: "\eaaa";
}

.v1-Business-Woman:before {
  content: "\eaab";
}

.v1-Busy-1:before {
  content: "\eaac";
}

.v1-Butterfly:before {
  content: "\eaad";
}

.v1-Button:before {
  content: "\eaae";
}

.v1-Cab:before {
  content: "\eaaf";
}

.v1-Cabine-Lift:before {
  content: "\eab0";
}

.v1-Cactus:before {
  content: "\eab1";
}

.v1-Caffe-Bean:before {
  content: "\eab2";
}

.v1-Caffe-Mug:before {
  content: "\eab3";
}

.v1-Calculator-1:before {
  content: "\eab4";
}

.v1-Calculator-2:before {
  content: "\eab5";
}

.v1-Calculator-3:before {
  content: "\eab6";
}

.v1-Calculator-4:before {
  content: "\eab7";
}

.v1-Calendar-Clock:before {
  content: "\eab8";
}

.v1-Calendar:before {
  content: "\eab9";
}

.v1-Call:before {
  content: "\eaba";
}

.v1-Camel:before {
  content: "\eabb";
}

.v1-Camera-12:before {
  content: "\eabc";
}

.v1-Camera-1:before {
  content: "\eabd";
}

.v1-Camera-22:before {
  content: "\eabe";
}

.v1-Camera-2:before {
  content: "\eabf";
}

.v1-Camera-3:before {
  content: "\eac0";
}

.v1-Camera-42:before {
  content: "\eac1";
}

.v1-Camera-4:before {
  content: "\eac2";
}

.v1-Camera-5:before {
  content: "\eac3";
}

.v1-Camera-6:before {
  content: "\eac4";
}

.v1-Camera-7:before {
  content: "\eac5";
}

.v1-Camera-8:before {
  content: "\eac6";
}

.v1-Camera-9:before {
  content: "\eac7";
}

.v1-Camera-Rear:before {
  content: "\eac8";
}

.v1-Camera-roll:before {
  content: "\eac9";
}

.v1-Camp-Bag:before {
  content: "\eaca";
}

.v1-Camp-Fire2:before {
  content: "\eacb";
}

.v1-Camp-Fire:before {
  content: "\eacc";
}

.v1-Camping-Knief:before {
  content: "\eacd";
}

.v1-Candell:before {
  content: "\eace";
}

.v1-Candy-Stick:before {
  content: "\eacf";
}

.v1-Candy:before {
  content: "\ead0";
}

.v1-Captain-America:before {
  content: "\ead1";
}

.v1-Car-1:before {
  content: "\ead2";
}

.v1-Car-2:before {
  content: "\ead3";
}

.v1-Car-3:before {
  content: "\ead4";
}

.v1-Car-AirPump:before {
  content: "\ead5";
}

.v1-Car-Secure:before {
  content: "\ead6";
}

.v1-Car-Service:before {
  content: "\ead7";
}

.v1-Car-Wash:before {
  content: "\ead8";
}

.v1-Card-Pay:before {
  content: "\ead9";
}

.v1-Card-Validity:before {
  content: "\eada";
}

.v1-Cargo-Ship:before {
  content: "\eadb";
}

.v1-Carnaval:before {
  content: "\eadc";
}

.v1-Carrot:before {
  content: "\eadd";
}

.v1-Cart-1:before {
  content: "\eade";
}

.v1-Cart-2:before {
  content: "\eadf";
}

.v1-Cart-Done:before {
  content: "\eae0";
}

.v1-Cash-Pay:before {
  content: "\eae1";
}

.v1-Cash-Payment:before {
  content: "\eae2";
}

.v1-Cassette:before {
  content: "\eae3";
}

.v1-Cat:before {
  content: "\eae4";
}

.v1-CD-case:before {
  content: "\eae5";
}

.v1-Cello:before {
  content: "\eae6";
}

.v1-Celsius:before {
  content: "\eae7";
}

.v1-Chaplin:before {
  content: "\eae8";
}

.v1-Character-Spacing:before {
  content: "\eae9";
}

.v1-Check-File:before {
  content: "\eaea";
}

.v1-Check:before {
  content: "\eaeb";
}

.v1-Checkout-Bitcoin1:before {
  content: "\eaec";
}

.v1-Checkout-Bitcoin2:before {
  content: "\eaed";
}

.v1-Checkout-Dollar1:before {
  content: "\eaee";
}

.v1-Checkout-Dollar2:before {
  content: "\eaef";
}

.v1-Checkout-Euro1:before {
  content: "\eaf0";
}

.v1-Checkout-Euro2:before {
  content: "\eaf1";
}

.v1-Checkout-Pound1:before {
  content: "\eaf2";
}

.v1-Checout-Pound2:before {
  content: "\eaf3";
}

.v1-Cheese:before {
  content: "\eaf4";
}

.v1-Cheetah:before {
  content: "\eaf5";
}

.v1-Chef:before {
  content: "\eaf6";
}

.v1-Cherry:before {
  content: "\eaf7";
}

.v1-Chess:before {
  content: "\eaf8";
}

.v1-Chicken-leg:before {
  content: "\eaf9";
}

.v1-Chicken:before {
  content: "\eafa";
}

.v1-Chilly:before {
  content: "\eafb";
}

.v1-Chimney:before {
  content: "\eafc";
}

.v1-Chip:before {
  content: "\eafd";
}

.v1-Christmas-Decoration:before {
  content: "\eafe";
}

.v1-Christmas-Light:before {
  content: "\eaff";
}

.v1-Christmas-Star:before {
  content: "\eb00";
}

.v1-Christmas-Tree:before {
  content: "\eb01";
}

.v1-Chrome:before {
  content: "\eb02";
}

.v1-Circus:before {
  content: "\eb03";
}

.v1-Clear-Basket:before {
  content: "\eb04";
}

.v1-Clear-Bookmark:before {
  content: "\eb05";
}

.v1-Clear-Cart:before {
  content: "\eb06";
}

.v1-Clear-File:before {
  content: "\eb07";
}

.v1-Clear-Folder:before {
  content: "\eb08";
}

.v1-Clear-Format:before {
  content: "\eb09";
}

.v1-Clear-Formatting:before {
  content: "\eb0a";
}

.v1-Clear-Location:before {
  content: "\eb0b";
}

.v1-Clear-Network:before {
  content: "\eb0c";
}

.v1-Click-1:before {
  content: "\eb0d";
}

.v1-Click-2:before {
  content: "\eb0e";
}

.v1-Clock-1:before {
  content: "\eb0f";
}

.v1-Clock-2:before {
  content: "\eb10";
}

.v1-Clone-Cloud:before {
  content: "\eb11";
}

.v1-Close-2:before {
  content: "\eb12";
}

.v1-Closr-1:before {
  content: "\eb13";
}

.v1-Cloud2:before {
  content: "\eb14";
}

.v1-Cloud-Download:before {
  content: "\eb15";
}

.v1-Cloud-Drive:before {
  content: "\eb16";
}

.v1-Cloud-Folder2:before {
  content: "\eb17";
}

.v1-Cloud-Folder:before {
  content: "\eb18";
}

.v1-Cloud-Help:before {
  content: "\eb19";
}

.v1-Cloud-List:before {
  content: "\eb1a";
}

.v1-Cloud-Network:before {
  content: "\eb1b";
}

.v1-Cloud-Server:before {
  content: "\eb1c";
}

.v1-Cloud-Setting:before {
  content: "\eb1d";
}

.v1-Cloud-Upload:before {
  content: "\eb1e";
}

.v1-Cloud:before {
  content: "\eb1f";
}

.v1-Cloudy-Day:before {
  content: "\eb20";
}

.v1-Cloudy-Fog:before {
  content: "\eb21";
}

.v1-Cloudy-Night:before {
  content: "\eb22";
}

.v1-Cloudy:before {
  content: "\eb23";
}

.v1-Clown:before {
  content: "\eb24";
}

.v1-Clubs-AceCard:before {
  content: "\eb25";
}

.v1-Cmera-3:before {
  content: "\eb26";
}

.v1-CMYK:before {
  content: "\eb27";
}

.v1-Coal-Railcar:before {
  content: "\eb28";
}

.v1-Coat-Rank:before {
  content: "\eb29";
}

.v1-Coat:before {
  content: "\eb2a";
}

.v1-Coce-withGlass:before {
  content: "\eb2b";
}

.v1-Cockroach:before {
  content: "\eb2c";
}

.v1-Coctail-Glass1:before {
  content: "\eb2d";
}

.v1-Coctail-Glass2:before {
  content: "\eb2e";
}

.v1-Coding:before {
  content: "\eb2f";
}

.v1-Coffe-ToGo:before {
  content: "\eb30";
}

.v1-Coffe:before {
  content: "\eb31";
}

.v1-Coin-andCash:before {
  content: "\eb32";
}

.v1-Coins-1:before {
  content: "\eb33";
}

.v1-Coins-2:before {
  content: "\eb34";
}

.v1-Coins-3:before {
  content: "\eb35";
}

.v1-Combine-File:before {
  content: "\eb36";
}

.v1-Compact-Disc:before {
  content: "\eb37";
}

.v1-Compas-Rose:before {
  content: "\eb38";
}

.v1-Compass-1:before {
  content: "\eb39";
}

.v1-Compass-2:before {
  content: "\eb3a";
}

.v1-Compose-Mail1:before {
  content: "\eb3b";
}

.v1-Compose-Mail2:before {
  content: "\eb3c";
}

.v1-Computer-Download:before {
  content: "\eb3d";
}

.v1-Computer-Network1:before {
  content: "\eb3e";
}

.v1-Computer-Network2:before {
  content: "\eb3f";
}

.v1-Computer-Upload:before {
  content: "\eb40";
}

.v1-Concert-Lighting:before {
  content: "\eb41";
}

.v1-Concrete-Truck:before {
  content: "\eb42";
}

.v1-Conga-1:before {
  content: "\eb43";
}

.v1-Conga-2:before {
  content: "\eb44";
}

.v1-Contact-Book1:before {
  content: "\eb45";
}

.v1-Contact-Book2:before {
  content: "\eb46";
}

.v1-Contact-Folder:before {
  content: "\eb47";
}

.v1-Contact-Info:before {
  content: "\eb48";
}

.v1-Container-Railcar:before {
  content: "\eb49";
}

.v1-Contract-1:before {
  content: "\eb4a";
}

.v1-Contract-2:before {
  content: "\eb4b";
}

.v1-Converse:before {
  content: "\eb4c";
}

.v1-Convert-Bitcoin:before {
  content: "\eb4d";
}

.v1-Convert-Curency:before {
  content: "\eb4e";
}

.v1-Cookie-Man:before {
  content: "\eb4f";
}

.v1-Copy-Machine:before {
  content: "\eb50";
}

.v1-Corn:before {
  content: "\eb51";
}

.v1-Coroflot:before {
  content: "\eb52";
}

.v1-Corrector:before {
  content: "\eb53";
}

.v1-Countdown-1:before {
  content: "\eb54";
}

.v1-Countdown-2:before {
  content: "\eb55";
}

.v1-Countdown-3:before {
  content: "\eb56";
}

.v1-Countdown-4:before {
  content: "\eb57";
}

.v1-Countdown-5:before {
  content: "\eb58";
}

.v1-Cow:before {
  content: "\eb59";
}

.v1-Crain-Truck2:before {
  content: "\eb5a";
}

.v1-Crain-Truck:before {
  content: "\eb5b";
}

.v1-Crain:before {
  content: "\eb5c";
}

.v1-Credit-Card1:before {
  content: "\eb5d";
}

.v1-Credit-Card2:before {
  content: "\eb5e";
}

.v1-Credit-Card3:before {
  content: "\eb5f";
}

.v1-Criminal:before {
  content: "\eb60";
}

.v1-Croissant:before {
  content: "\eb61";
}

.v1-Cronometer:before {
  content: "\eb62";
}

.v1-Crop-Image:before {
  content: "\eb63";
}

.v1-Crop-Tool:before {
  content: "\eb64";
}

.v1-Crown-1:before {
  content: "\eb65";
}

.v1-Crown-2:before {
  content: "\eb66";
}

.v1-Crunchyroll:before {
  content: "\eb67";
}

.v1-Cry-Hard:before {
  content: "\eb68";
}

.v1-Cry:before {
  content: "\eb69";
}

.v1-CSS-3:before {
  content: "\eb6a";
}

.v1-Cup-1:before {
  content: "\eb6b";
}

.v1-Cup-2:before {
  content: "\eb6c";
}

.v1-Cup-3:before {
  content: "\eb6d";
}

.v1-Cursor-Select1:before {
  content: "\eb6e";
}

.v1-Cusror-Select2:before {
  content: "\eb6f";
}

.v1-Cuter:before {
  content: "\eb70";
}

.v1-Cylinder-Hat:before {
  content: "\eb71";
}

.v1-Cymbal:before {
  content: "\eb72";
}

.v1-Cystern-Railcar:before {
  content: "\eb73";
}

.v1-Cystern-Truck:before {
  content: "\eb74";
}

.v1-Dailybooth:before {
  content: "\eb75";
}

.v1-Darth-Vader:before {
  content: "\eb76";
}

.v1-Darts:before {
  content: "\eb77";
}

.v1-Decode-File:before {
  content: "\eb78";
}

.v1-Decrease-Indent:before {
  content: "\eb79";
}

.v1-Decrease-Margin:before {
  content: "\eb7a";
}

.v1-Deer:before {
  content: "\eb7b";
}

.v1-Delete-Mail:before {
  content: "\eb7c";
}

.v1-Delicious:before {
  content: "\eb7d";
}

.v1-Desert:before {
  content: "\eb7e";
}

.v1-Design-Software:before {
  content: "\eb7f";
}

.v1-Designfloat:before {
  content: "\eb80";
}

.v1-Designmoo:before {
  content: "\eb81";
}

.v1-Desktop-Security:before {
  content: "\eb82";
}

.v1-Deviant-Art:before {
  content: "\eb83";
}

.v1-Devil:before {
  content: "\eb84";
}

.v1-Dial-Pad:before {
  content: "\eb85";
}

.v1-Diamond-AceCard:before {
  content: "\eb86";
}

.v1-Diamond:before {
  content: "\eb87";
}

.v1-Dice2:before {
  content: "\eb88";
}

.v1-Dice:before {
  content: "\eb89";
}

.v1-Digg:before {
  content: "\eb8a";
}

.v1-Digital-AlarmClock:before {
  content: "\eb8b";
}

.v1-Digital-Design:before {
  content: "\eb8c";
}

.v1-Diigo:before {
  content: "\eb8d";
}

.v1-Direction-Control1:before {
  content: "\eb8e";
}

.v1-Direction-Control2:before {
  content: "\eb8f";
}

.v1-Direction-Select1:before {
  content: "\eb90";
}

.v1-Direction-Select2:before {
  content: "\eb91";
}

.v1-Directions-2:before {
  content: "\eb92";
}

.v1-Directions:before {
  content: "\eb93";
}

.v1-Disc-Doc:before {
  content: "\eb94";
}

.v1-Discount-Bitcoin:before {
  content: "\eb95";
}

.v1-Discount-Coupon:before {
  content: "\eb96";
}

.v1-Discount-Dollar:before {
  content: "\eb97";
}

.v1-Discount-Euro:before {
  content: "\eb98";
}

.v1-Discount-Pound:before {
  content: "\eb99";
}

.v1-Disk-Cutter:before {
  content: "\eb9a";
}

.v1-Dismiss-Bookmark:before {
  content: "\eb9b";
}

.v1-Dismiss-Card:before {
  content: "\eb9c";
}

.v1-Dismiss-Cloud:before {
  content: "\eb9d";
}

.v1-Dismiss-Doc:before {
  content: "\eb9e";
}

.v1-Dismiss-File:before {
  content: "\eb9f";
}

.v1-Dismiss-Firewall:before {
  content: "\eba0";
}

.v1-Dismiss-Folder:before {
  content: "\eba1";
}

.v1-Dismiss-Mail:before {
  content: "\eba2";
}

.v1-Dismiss-Network:before {
  content: "\eba3";
}

.v1-Dismiss-Settings:before {
  content: "\eba4";
}

.v1-Dispacher-1:before {
  content: "\eba5";
}

.v1-Dispacher-2:before {
  content: "\eba6";
}

.v1-Distance-1:before {
  content: "\eba7";
}

.v1-Distance-2:before {
  content: "\eba8";
}

.v1-Diving-Mask:before {
  content: "\eba9";
}

.v1-Dj-mixer:before {
  content: "\ebaa";
}

.v1-DNA:before {
  content: "\ebab";
}

.v1-Do-NotDisturbe:before {
  content: "\ebac";
}

.v1-Document-Cutter:before {
  content: "\ebad";
}

.v1-Document-File:before {
  content: "\ebae";
}

.v1-Documents:before {
  content: "\ebaf";
}

.v1-Dodgem:before {
  content: "\ebb0";
}

.v1-Dog:before {
  content: "\ebb1";
}

.v1-Dollar-1:before {
  content: "\ebb2";
}

.v1-Dollar-2:before {
  content: "\ebb3";
}

.v1-Dollar-3:before {
  content: "\ebb4";
}

.v1-Dollar-Card:before {
  content: "\ebb5";
}

.v1-Dollar-Cloud:before {
  content: "\ebb6";
}

.v1-Dollar-Doc:before {
  content: "\ebb7";
}

.v1-Dollar-Fall:before {
  content: "\ebb8";
}

.v1-Dollar-Rise:before {
  content: "\ebb9";
}

.v1-Dolphin:before {
  content: "\ebba";
}

.v1-Domino:before {
  content: "\ebbb";
}

.v1-Donate-Blood:before {
  content: "\ebbc";
}

.v1-Donate:before {
  content: "\ebbd";
}

.v1-Done-Basket:before {
  content: "\ebbe";
}

.v1-Done-Bookmark:before {
  content: "\ebbf";
}

.v1-Done-Card:before {
  content: "\ebc0";
}

.v1-Done-Cloud:before {
  content: "\ebc1";
}

.v1-Done-Doc:before {
  content: "\ebc2";
}

.v1-Done-Folder:before {
  content: "\ebc3";
}

.v1-Done-Location:before {
  content: "\ebc4";
}

.v1-Done-Mail:before {
  content: "\ebc5";
}

.v1-Donkey:before {
  content: "\ebc6";
}

.v1-Dont-TouchRound:before {
  content: "\ebc7";
}

.v1-Dont-Touch:before {
  content: "\ebc8";
}

.v1-Donut:before {
  content: "\ebc9";
}

.v1-Door-Hanger:before {
  content: "\ebca";
}

.v1-Double-Click1:before {
  content: "\ebcb";
}

.v1-Double-Click2:before {
  content: "\ebcc";
}

.v1-Double-Tap:before {
  content: "\ebcd";
}

.v1-Dove:before {
  content: "\ebce";
}

.v1-Down-1:before {
  content: "\ebcf";
}

.v1-Down-2:before {
  content: "\ebd0";
}

.v1-Down-3:before {
  content: "\ebd1";
}

.v1-Down-4:before {
  content: "\ebd2";
}

.v1-Down-5:before {
  content: "\ebd3";
}

.v1-Down-6:before {
  content: "\ebd4";
}

.v1-Down-7:before {
  content: "\ebd5";
}

.v1-Down-8:before {
  content: "\ebd6";
}

.v1-Down-9:before {
  content: "\ebd7";
}

.v1-Down-10:before {
  content: "\ebd8";
}

.v1-Down-11:before {
  content: "\ebd9";
}

.v1-Down-12:before {
  content: "\ebda";
}

.v1-Down-Left1:before {
  content: "\ebdb";
}

.v1-Down-Right1:before {
  content: "\ebdc";
}

.v1-Download-Bookmark:before {
  content: "\ebdd";
}

.v1-Download-Doc:before {
  content: "\ebde";
}

.v1-Download-File:before {
  content: "\ebdf";
}

.v1-Download-Folder:before {
  content: "\ebe0";
}

.v1-Download:before {
  content: "\ebe1";
}

.v1-Drag-Drop:before {
  content: "\ebe2";
}

.v1-Drag-Down:before {
  content: "\ebe3";
}

.v1-Drag-Hand1:before {
  content: "\ebe4";
}

.v1-Drag-Hand2:before {
  content: "\ebe5";
}

.v1-Drag-Location:before {
  content: "\ebe6";
}

.v1-Drag-Up:before {
  content: "\ebe7";
}

.v1-Dress-1:before {
  content: "\ebe8";
}

.v1-Dress-2:before {
  content: "\ebe9";
}

.v1-Dribbble:before {
  content: "\ebea";
}

.v1-Driller:before {
  content: "\ebeb";
}

.v1-Drive-File:before {
  content: "\ebec";
}

.v1-Drive-Folder:before {
  content: "\ebed";
}

.v1-Drool:before {
  content: "\ebee";
}

.v1-Dropbox-File:before {
  content: "\ebef";
}

.v1-Dropbox-Folder:before {
  content: "\ebf0";
}

.v1-Dropbox:before {
  content: "\ebf1";
}

.v1-Drowing:before {
  content: "\ebf2";
}

.v1-Drum-1:before {
  content: "\ebf3";
}

.v1-Drum-2:before {
  content: "\ebf4";
}

.v1-Drum-sticks:before {
  content: "\ebf5";
}

.v1-Drupal:before {
  content: "\ebf6";
}

.v1-Duck:before {
  content: "\ebf7";
}

.v1-Dumbbell:before {
  content: "\ebf8";
}

.v1-Dumper-Truck:before {
  content: "\ebf9";
}

.v1-DVD-Case:before {
  content: "\ebfa";
}

.v1-DVD-Disc:before {
  content: "\ebfb";
}

.v1-DVD-Sign:before {
  content: "\ebfc";
}

.v1-Dzone:before {
  content: "\ebfd";
}

.v1-Eagle-1:before {
  content: "\ebfe";
}

.v1-Eagle-2:before {
  content: "\ebff";
}

.v1-Earphone-1:before {
  content: "\ec00";
}

.v1-Earphone-2:before {
  content: "\ec01";
}

.v1-Eatrh-Support:before {
  content: "\ec02";
}

.v1-Ebay:before {
  content: "\ec03";
}

.v1-eBooks-Folder:before {
  content: "\ec04";
}

.v1-Eco-Badge:before {
  content: "\ec05";
}

.v1-Eco-Bulb1:before {
  content: "\ec06";
}

.v1-Eco-Bulb2:before {
  content: "\ec07";
}

.v1-Eco-Earth:before {
  content: "\ec08";
}

.v1-Ecuation:before {
  content: "\ec09";
}

.v1-Edge:before {
  content: "\ec0a";
}

.v1-Edit-Doc:before {
  content: "\ec0b";
}

.v1-Edit-Wifi:before {
  content: "\ec0c";
}

.v1-Egg-Holder:before {
  content: "\ec0d";
}

.v1-Einstein:before {
  content: "\ec0e";
}

.v1-Electric-Guitar1:before {
  content: "\ec0f";
}

.v1-Electric-Guitar2:before {
  content: "\ec10";
}

.v1-Electric-Plug:before {
  content: "\ec11";
}

.v1-Elephant:before {
  content: "\ec12";
}

.v1-Elevator:before {
  content: "\ec13";
}

.v1-Elf:before {
  content: "\ec14";
}

.v1-Elvis:before {
  content: "\ec15";
}

.v1-Email-File:before {
  content: "\ec16";
}

.v1-Email-Folder:before {
  content: "\ec17";
}

.v1-Encode-File:before {
  content: "\ec18";
}

.v1-End-Call:before {
  content: "\ec19";
}

.v1-Energy-Drink:before {
  content: "\ec1a";
}

.v1-Enter-Pin2:before {
  content: "\ec1b";
}

.v1-Enter-Pin:before {
  content: "\ec1c";
}

.v1-Envato:before {
  content: "\ec1d";
}

.v1-Eraser-Tool:before {
  content: "\ec1e";
}

.v1-Eroor-Folder:before {
  content: "\ec1f";
}

.v1-Error-Bookmark:before {
  content: "\ec20";
}

.v1-Error-Card:before {
  content: "\ec21";
}

.v1-Error-Cloud:before {
  content: "\ec22";
}

.v1-Error-Doc:before {
  content: "\ec23";
}

.v1-Espresso:before {
  content: "\ec24";
}

.v1-Ethernet:before {
  content: "\ec25";
}

.v1-Euro-1:before {
  content: "\ec26";
}

.v1-Euro-2:before {
  content: "\ec27";
}

.v1-Euro-3:before {
  content: "\ec28";
}

.v1-Euro-Card:before {
  content: "\ec29";
}

.v1-Euro-Cloud:before {
  content: "\ec2a";
}

.v1-Euro-Doc:before {
  content: "\ec2b";
}

.v1-Euro-Fall:before {
  content: "\ec2c";
}

.v1-Euro-Rise:before {
  content: "\ec2d";
}

.v1-Evernote:before {
  content: "\ec2e";
}

.v1-Evil:before {
  content: "\ec2f";
}

.v1-Excavator-1:before {
  content: "\ec30";
}

.v1-Excavator-2:before {
  content: "\ec31";
}

.v1-Excel:before {
  content: "\ec32";
}

.v1-Exit-Sign:before {
  content: "\ec33";
}

.v1-Expensive:before {
  content: "\ec34";
}

.v1-Expisior:before {
  content: "\ec35";
}

.v1-Eyedropper:before {
  content: "\ec36";
}

.v1-Facebook-1:before {
  content: "\ec37";
}

.v1-Facebook-2:before {
  content: "\ec38";
}

.v1-Facebook-Messenger:before {
  content: "\ec39";
}

.v1-Factory-1:before {
  content: "\ec3a";
}

.v1-Factory-2:before {
  content: "\ec3b";
}

.v1-Factory-3:before {
  content: "\ec3c";
}

.v1-Factory-Chimneys:before {
  content: "\ec3d";
}

.v1-Factory-Line:before {
  content: "\ec3e";
}

.v1-Fahrenheit:before {
  content: "\ec3f";
}

.v1-Fan:before {
  content: "\ec40";
}

.v1-Favorit-File:before {
  content: "\ec41";
}

.v1-Favorit-Location:before {
  content: "\ec42";
}

.v1-Favorit-Network:before {
  content: "\ec43";
}

.v1-Favorite-Card:before {
  content: "\ec44";
}

.v1-Favorite-Cloud:before {
  content: "\ec45";
}

.v1-Favorite-Doc:before {
  content: "\ec46";
}

.v1-Favorite-Folder:before {
  content: "\ec47";
}

.v1-Favorite-Mail:before {
  content: "\ec48";
}

.v1-Favorite-Store:before {
  content: "\ec49";
}

.v1-Favorite-Wifi:before {
  content: "\ec4a";
}

.v1-Favorite:before {
  content: "\ec4b";
}

.v1-Feather:before {
  content: "\ec4c";
}

.v1-Feedburner:before {
  content: "\ec4d";
}

.v1-Feeling-Sick:before {
  content: "\ec4e";
}

.v1-Female-1:before {
  content: "\ec4f";
}

.v1-Female-2:before {
  content: "\ec50";
}

.v1-Female-User:before {
  content: "\ec51";
}

.v1-Fever:before {
  content: "\ec52";
}

.v1-File-Error:before {
  content: "\ec53";
}

.v1-File-Settings:before {
  content: "\ec54";
}

.v1-Film-Clapper:before {
  content: "\ec55";
}

.v1-Film-Roll:before {
  content: "\ec56";
}

.v1-Film-Stripe:before {
  content: "\ec57";
}

.v1-Filter:before {
  content: "\ec58";
}

.v1-Financial-Care1:before {
  content: "\ec59";
}

.v1-Financial-Care2:before {
  content: "\ec5a";
}

.v1-Financial-Care3:before {
  content: "\ec5b";
}

.v1-Finder:before {
  content: "\ec5c";
}

.v1-Finger-Print:before {
  content: "\ec5d";
}

.v1-Fire-Alarm:before {
  content: "\ec5e";
}

.v1-Fire-Extinguisher:before {
  content: "\ec5f";
}

.v1-Fire-Fighjter:before {
  content: "\ec60";
}

.v1-Firefox:before {
  content: "\ec61";
}

.v1-Firewall-Attention:before {
  content: "\ec62";
}

.v1-Firewall-Error:before {
  content: "\ec63";
}

.v1-Firewall-Off:before {
  content: "\ec64";
}

.v1-Firewall-Ok:before {
  content: "\ec65";
}

.v1-Firewall-On:before {
  content: "\ec66";
}

.v1-Firewall-Settings:before {
  content: "\ec67";
}

.v1-Firewall:before {
  content: "\ec68";
}

.v1-Fireworks-1:before {
  content: "\ec69";
}

.v1-Fireworks-2:before {
  content: "\ec6a";
}

.v1-Fish:before {
  content: "\ec6b";
}

.v1-Fishing:before {
  content: "\ec6c";
}

.v1-Flamingo:before {
  content: "\ec6d";
}

.v1-Flash-1:before {
  content: "\ec6e";
}

.v1-Flash-Light:before {
  content: "\ec6f";
}

.v1-Flash-Off:before {
  content: "\ec70";
}

.v1-Flash-On:before {
  content: "\ec71";
}

.v1-Flash-Video:before {
  content: "\ec72";
}

.v1-Flickr-2:before {
  content: "\ec73";
}

.v1-Flickr:before {
  content: "\ec74";
}

.v1-Flower:before {
  content: "\ec75";
}

.v1-Flusk-Holder:before {
  content: "\ec76";
}

.v1-Flute2:before {
  content: "\ec77";
}

.v1-Flute:before {
  content: "\ec78";
}

.v1-Flv-Doc:before {
  content: "\ec79";
}

.v1-Fly:before {
  content: "\ec7a";
}

.v1-Focus-Auto:before {
  content: "\ec7b";
}

.v1-Focus-Center:before {
  content: "\ec7c";
}

.v1-Fog-day:before {
  content: "\ec7d";
}

.v1-Fog-Night:before {
  content: "\ec7e";
}

.v1-Fog:before {
  content: "\ec7f";
}

.v1-Folder-1:before {
  content: "\ec80";
}

.v1-Folder-Tree:before {
  content: "\ec81";
}

.v1-Folder-withDoc:before {
  content: "\ec82";
}

.v1-Font-Szie:before {
  content: "\ec83";
}

.v1-Foodspotting:before {
  content: "\ec84";
}

.v1-Football:before {
  content: "\ec85";
}

.v1-Fork-Knife:before {
  content: "\ec86";
}

.v1-Fork-Lifter:before {
  content: "\ec87";
}

.v1-Formal-Coat:before {
  content: "\ec88";
}

.v1-Formal-Pants:before {
  content: "\ec89";
}

.v1-Forrest:before {
  content: "\ec8a";
}

.v1-Forrst:before {
  content: "\ec8b";
}

.v1-Forward-AllMail:before {
  content: "\ec8c";
}

.v1-Forward-Button:before {
  content: "\ec8d";
}

.v1-Forward-Mail:before {
  content: "\ec8e";
}

.v1-Forward:before {
  content: "\ec8f";
}

.v1-Foursquare:before {
  content: "\ec90";
}

.v1-Fox:before {
  content: "\ec91";
}

.v1-Free-Tag1:before {
  content: "\ec92";
}

.v1-Free-Tag2:before {
  content: "\ec93";
}

.v1-French-Fries:before {
  content: "\ec94";
}

.v1-Fried-Egg:before {
  content: "\ec95";
}

.v1-Frog:before {
  content: "\ec96";
}

.v1-Front-Camera:before {
  content: "\ec97";
}

.v1-Full-Cart:before {
  content: "\ec98";
}

.v1-Full-HD:before {
  content: "\ec99";
}

.v1-Full-Moon:before {
  content: "\ec9a";
}

.v1-Gallery-1:before {
  content: "\ec9b";
}

.v1-Gallery-2:before {
  content: "\ec9c";
}

.v1-Game-Console1:before {
  content: "\ec9d";
}

.v1-Game-Console2:before {
  content: "\ec9e";
}

.v1-Game-Console3:before {
  content: "\ec9f";
}

.v1-Game-Console:before {
  content: "\eca0";
}

.v1-Gameboy-1:before {
  content: "\eca1";
}

.v1-Gameboy-2:before {
  content: "\eca2";
}

.v1-Games-Folder:before {
  content: "\eca3";
}

.v1-Gas-Can:before {
  content: "\eca4";
}

.v1-Gas-Container:before {
  content: "\eca5";
}

.v1-Gas-Pump2:before {
  content: "\eca6";
}

.v1-Gas-Pump:before {
  content: "\eca7";
}

.v1-Gdgt:before {
  content: "\eca8";
}

.v1-Gear-12:before {
  content: "\eca9";
}

.v1-Gear-1:before {
  content: "\ecaa";
}

.v1-Gear-2:before {
  content: "\ecab";
}

.v1-Gear-3:before {
  content: "\ecac";
}

.v1-Gear-4:before {
  content: "\ecad";
}

.v1-Gear-Box:before {
  content: "\ecae";
}

.v1-Geek-1:before {
  content: "\ecaf";
}

.v1-Geek-2:before {
  content: "\ecb0";
}

.v1-Gem:before {
  content: "\ecb1";
}

.v1-Geooveshark:before {
  content: "\ecb2";
}

.v1-Gift-Box:before {
  content: "\ecb3";
}

.v1-Gift-Card:before {
  content: "\ecb4";
}

.v1-Giraffe:before {
  content: "\ecb5";
}

.v1-Github:before {
  content: "\ecb6";
}

.v1-Glases-1:before {
  content: "\ecb7";
}

.v1-Glases-2:before {
  content: "\ecb8";
}

.v1-Glitter-Eye:before {
  content: "\ecb9";
}

.v1-Global-Network1:before {
  content: "\ecba";
}

.v1-Global-Network2:before {
  content: "\ecbb";
}

.v1-Global-Position:before {
  content: "\ecbc";
}

.v1-Globe-1:before {
  content: "\ecbd";
}

.v1-Globe-2:before {
  content: "\ecbe";
}

.v1-Globe-4:before {
  content: "\ecbf";
}

.v1-Globe:before {
  content: "\ecc0";
}

.v1-Glue:before {
  content: "\ecc1";
}

.v1-Gmail:before {
  content: "\ecc2";
}

.v1-Gold-Bars1:before {
  content: "\ecc3";
}

.v1-Gold-Bars2:before {
  content: "\ecc4";
}

.v1-Golf-Ball:before {
  content: "\ecc5";
}

.v1-Golf:before {
  content: "\ecc6";
}

.v1-Gong:before {
  content: "\ecc7";
}

.v1-Google-:before {
  content: "\ecc8";
}

.v1-Google-1:before {
  content: "\ecc9";
}

.v1-Google-2:before {
  content: "\ecca";
}

.v1-Google-Camera:before {
  content: "\eccb";
}

.v1-Google-Drive:before {
  content: "\eccc";
}

.v1-Google-Earth:before {
  content: "\eccd";
}

.v1-Google-Maps:before {
  content: "\ecce";
}

.v1-Google-Play:before {
  content: "\eccf";
}

.v1-Google-Walet:before {
  content: "\ecd0";
}

.v1-Gorilla:before {
  content: "\ecd1";
}

.v1-Gowala:before {
  content: "\ecd2";
}

.v1-Gps-Fixed:before {
  content: "\ecd3";
}

.v1-Gps-NotFixed:before {
  content: "\ecd4";
}

.v1-Gps-Off:before {
  content: "\ecd5";
}

.v1-Grab-Hand:before {
  content: "\ecd6";
}

.v1-Gradient-Tool:before {
  content: "\ecd7";
}

.v1-Grid-Tool:before {
  content: "\ecd8";
}

.v1-Grill:before {
  content: "\ecd9";
}

.v1-Guitar-Amplifier:before {
  content: "\ecda";
}

.v1-Guitar-Head1:before {
  content: "\ecdb";
}

.v1-Guitar-Head2:before {
  content: "\ecdc";
}

.v1-Guitar:before {
  content: "\ecdd";
}

.v1-Hailstorm-Day:before {
  content: "\ecde";
}

.v1-Hailstorm-Night:before {
  content: "\ecdf";
}

.v1-Hailstorm:before {
  content: "\ece0";
}

.v1-Hairdryer:before {
  content: "\ece1";
}

.v1-Half-Life:before {
  content: "\ece2";
}

.v1-Ham:before {
  content: "\ece3";
}

.v1-Hamburger:before {
  content: "\ece4";
}

.v1-Hammer2:before {
  content: "\ece5";
}

.v1-Hammer:before {
  content: "\ece6";
}

.v1-Hamster:before {
  content: "\ece7";
}

.v1-Handshake:before {
  content: "\ece8";
}

.v1-Hanger:before {
  content: "\ece9";
}

.v1-Hangout:before {
  content: "\ecea";
}

.v1-Happy-Wink:before {
  content: "\eceb";
}

.v1-Happy:before {
  content: "\ecec";
}

.v1-Harph-1:before {
  content: "\eced";
}

.v1-Harph-2:before {
  content: "\ecee";
}

.v1-Harry-Potter:before {
  content: "\ecef";
}

.v1-Hash-Tag:before {
  content: "\ecf0";
}

.v1-Hat:before {
  content: "\ecf1";
}

.v1-Hawk:before {
  content: "\ecf2";
}

.v1-Hdmi:before {
  content: "\ecf3";
}

.v1-HDR-Off:before {
  content: "\ecf4";
}

.v1-HDR-On:before {
  content: "\ecf5";
}

.v1-Headache:before {
  content: "\ecf6";
}

.v1-Headset-1:before {
  content: "\ecf7";
}

.v1-Headset-2:before {
  content: "\ecf8";
}

.v1-Heart2:before {
  content: "\ecf9";
}

.v1-Heart-AceCard:before {
  content: "\ecfa";
}

.v1-Heart-Beat:before {
  content: "\ecfb";
}

.v1-Heart:before {
  content: "\ecfc";
}

.v1-Heat-Balloon:before {
  content: "\ecfd";
}

.v1-Heater:before {
  content: "\ecfe";
}

.v1-Heels:before {
  content: "\ecff";
}

.v1-Helicopter:before {
  content: "\ed00";
}

.v1-Helmet-1:before {
  content: "\ed01";
}

.v1-Helmet-2:before {
  content: "\ed02";
}

.v1-Helmet-3:before {
  content: "\ed03";
}

.v1-Hide-File:before {
  content: "\ed04";
}

.v1-Hiden-File:before {
  content: "\ed05";
}

.v1-Hiden-Folder:before {
  content: "\ed06";
}

.v1-Hidrant:before {
  content: "\ed07";
}

.v1-Hipo:before {
  content: "\ed08";
}

.v1-Hokey-Disc:before {
  content: "\ed09";
}

.v1-Hokey-Skate:before {
  content: "\ed0a";
}

.v1-Hokey:before {
  content: "\ed0b";
}

.v1-Hold:before {
  content: "\ed0c";
}

.v1-Hole-Puncher:before {
  content: "\ed0d";
}

.v1-Home-Location:before {
  content: "\ed0e";
}

.v1-Home-Security:before {
  content: "\ed0f";
}

.v1-Home-Wifi:before {
  content: "\ed10";
}

.v1-Hoodie:before {
  content: "\ed11";
}

.v1-Horn-Trompet:before {
  content: "\ed12";
}

.v1-Horn:before {
  content: "\ed13";
}

.v1-Horse-Shoe:before {
  content: "\ed14";
}

.v1-Horse:before {
  content: "\ed15";
}

.v1-Hospital-1:before {
  content: "\ed16";
}

.v1-Hospital-Bed:before {
  content: "\ed17";
}

.v1-Hot-Dog1:before {
  content: "\ed18";
}

.v1-Hotdog-2:before {
  content: "\ed19";
}

.v1-Hotel-Bell:before {
  content: "\ed1a";
}

.v1-Hotel-Sign1:before {
  content: "\ed1b";
}

.v1-Hotel:before {
  content: "\ed1c";
}

.v1-Hotspot-Mobile:before {
  content: "\ed1d";
}

.v1-HTML-5:before {
  content: "\ed1e";
}

.v1-Hypnotized:before {
  content: "\ed1f";
}

.v1-Hypster:before {
  content: "\ed20";
}

.v1-Icecream:before {
  content: "\ed21";
}

.v1-iCloud:before {
  content: "\ed22";
}

.v1-ICQ:before {
  content: "\ed23";
}

.v1-Illustrator:before {
  content: "\ed24";
}

.v1-Image-file:before {
  content: "\ed25";
}

.v1-IMDb:before {
  content: "\ed26";
}

.v1-In-LineImage:before {
  content: "\ed27";
}

.v1-In-Love:before {
  content: "\ed28";
}

.v1-Inbox-Google:before {
  content: "\ed29";
}

.v1-Inbox-In:before {
  content: "\ed2a";
}

.v1-Inbox-Letter:before {
  content: "\ed2b";
}

.v1-Inbox-Out:before {
  content: "\ed2c";
}

.v1-Inbox:before {
  content: "\ed2d";
}

.v1-Increase-Indent:before {
  content: "\ed2e";
}

.v1-Increase-Margin:before {
  content: "\ed2f";
}

.v1-Info-Point:before {
  content: "\ed30";
}

.v1-Infuzion:before {
  content: "\ed31";
}

.v1-Initial:before {
  content: "\ed32";
}

.v1-Insert-Image:before {
  content: "\ed33";
}

.v1-Inshurance-2:before {
  content: "\ed34";
}

.v1-Inshurance:before {
  content: "\ed35";
}

.v1-Instagram:before {
  content: "\ed36";
}

.v1-Intel:before {
  content: "\ed37";
}

.v1-Internet-Explorer:before {
  content: "\ed38";
}

.v1-Iron-Man:before {
  content: "\ed39";
}

.v1-Isert-Tabel:before {
  content: "\ed3a";
}

.v1-Italic:before {
  content: "\ed3b";
}

.v1-Jack-Hammer:before {
  content: "\ed3c";
}

.v1-Jack-Sparrow:before {
  content: "\ed3d";
}

.v1-Jacket:before {
  content: "\ed3e";
}

.v1-Jason:before {
  content: "\ed3f";
}

.v1-Jeans:before {
  content: "\ed40";
}

.v1-Jeep:before {
  content: "\ed41";
}

.v1-Jetplnade:before {
  content: "\ed42";
}

.v1-Joy-Stick:before {
  content: "\ed43";
}

.v1-Juice:before {
  content: "\ed44";
}

.v1-Justify-Center:before {
  content: "\ed45";
}

.v1-Justify-Left:before {
  content: "\ed46";
}

.v1-Justify-Right:before {
  content: "\ed47";
}

.v1-Karate:before {
  content: "\ed48";
}

.v1-Keep-Out:before {
  content: "\ed49";
}

.v1-Kerneling:before {
  content: "\ed4a";
}

.v1-Key-1:before {
  content: "\ed4b";
}

.v1-Key-22:before {
  content: "\ed4c";
}

.v1-Key-3:before {
  content: "\ed4d";
}

.v1-Key-ToSuccess:before {
  content: "\ed4e";
}

.v1-Kickstarter:before {
  content: "\ed4f";
}

.v1-Kidneys:before {
  content: "\ed50";
}

.v1-Kiss:before {
  content: "\ed51";
}

.v1-Kite:before {
  content: "\ed52";
}

.v1-Koala:before {
  content: "\ed53";
}

.v1-Ladys-T-Shirt:before {
  content: "\ed54";
}

.v1-Ladys-Underwear:before {
  content: "\ed55";
}

.v1-Lama:before {
  content: "\ed56";
}

.v1-Lamp-1:before {
  content: "\ed57";
}

.v1-Lamp-2:before {
  content: "\ed58";
}

.v1-Lamp-3:before {
  content: "\ed59";
}

.v1-Landing-Plane:before {
  content: "\ed5a";
}

.v1-Lasso-Tool:before {
  content: "\ed5b";
}

.v1-Last-Fm:before {
  content: "\ed5c";
}

.v1-Laugh-Hard:before {
  content: "\ed5d";
}

.v1-Layer-2:before {
  content: "\ed5e";
}

.v1-Layer-4:before {
  content: "\ed5f";
}

.v1-Layer-30:before {
  content: "\ed60";
}

.v1-Layer-56:before {
  content: "\ed61";
}

.v1-Leaf-1:before {
  content: "\ed62";
}

.v1-Left-1:before {
  content: "\ed63";
}

.v1-Left-2:before {
  content: "\ed64";
}

.v1-Left-3:before {
  content: "\ed65";
}

.v1-Left-4:before {
  content: "\ed66";
}

.v1-Left-5:before {
  content: "\ed67";
}

.v1-Left-6:before {
  content: "\ed68";
}

.v1-Left-7:before {
  content: "\ed69";
}

.v1-Left-8:before {
  content: "\ed6a";
}

.v1-Left-9:before {
  content: "\ed6b";
}

.v1-Left-10:before {
  content: "\ed6c";
}

.v1-Left-11:before {
  content: "\ed6d";
}

.v1-Left-12:before {
  content: "\ed6e";
}

.v1-Left-13:before {
  content: "\ed6f";
}

.v1-Left-14:before {
  content: "\ed70";
}

.v1-Lego-Brick:before {
  content: "\ed71";
}

.v1-Lego-Head:before {
  content: "\ed72";
}

.v1-Lens-1:before {
  content: "\ed73";
}

.v1-Lens-2:before {
  content: "\ed74";
}

.v1-Lifter:before {
  content: "\ed75";
}

.v1-Lifting-Phisics:before {
  content: "\ed76";
}

.v1-Ligatures2:before {
  content: "\ed77";
}

.v1-Ligatures:before {
  content: "\ed78";
}

.v1-Light-1:before {
  content: "\ed79";
}

.v1-Light-2:before {
  content: "\ed7a";
}

.v1-Light-Bulb:before {
  content: "\ed7b";
}

.v1-Light-Switch:before {
  content: "\ed7c";
}

.v1-Lightning-day:before {
  content: "\ed7d";
}

.v1-Lightning-night:before {
  content: "\ed7e";
}

.v1-Lightning:before {
  content: "\ed7f";
}

.v1-Line-Spacing2:before {
  content: "\ed80";
}

.v1-Line-Spacing:before {
  content: "\ed81";
}

.v1-Line-Tool:before {
  content: "\ed82";
}

.v1-Link-Select1:before {
  content: "\ed83";
}

.v1-Link-Select2:before {
  content: "\ed84";
}

.v1-Link:before {
  content: "\ed85";
}

.v1-Linkedin:before {
  content: "\ed86";
}

.v1-Lion:before {
  content: "\ed87";
}

.v1-List-Doc:before {
  content: "\ed88";
}

.v1-List-Folder:before {
  content: "\ed89";
}

.v1-Litter:before {
  content: "\ed8a";
}

.v1-Liver:before {
  content: "\ed8b";
}

.v1-Load-Button:before {
  content: "\ed8c";
}

.v1-Load-Cloud1:before {
  content: "\ed8d";
}

.v1-Load-Cloud2:before {
  content: "\ed8e";
}

.v1-Load-Cloud3:before {
  content: "\ed8f";
}

.v1-Load-Doc:before {
  content: "\ed90";
}

.v1-Load-File:before {
  content: "\ed91";
}

.v1-Load-Folder:before {
  content: "\ed92";
}

.v1-Local-Airport:before {
  content: "\ed93";
}

.v1-Local-Bank:before {
  content: "\ed94";
}

.v1-Local-GasStation:before {
  content: "\ed95";
}

.v1-Local-Hospital:before {
  content: "\ed96";
}

.v1-Local-Library:before {
  content: "\ed97";
}

.v1-Local-Monument:before {
  content: "\ed98";
}

.v1-Local-Parking:before {
  content: "\ed99";
}

.v1-Local-Port:before {
  content: "\ed9a";
}

.v1-Local-Pub:before {
  content: "\ed9b";
}

.v1-Local-Restaurant:before {
  content: "\ed9c";
}

.v1-Local-Transport:before {
  content: "\ed9d";
}

.v1-Location-1:before {
  content: "\ed9e";
}

.v1-Location-3:before {
  content: "\ed9f";
}

.v1-Location-4:before {
  content: "\eda0";
}

.v1-Location-A:before {
  content: "\eda1";
}

.v1-Location-B:before {
  content: "\eda2";
}

.v1-Locatoin-2:before {
  content: "\eda3";
}

.v1-Lock-12:before {
  content: "\eda4";
}

.v1-Lock-2:before {
  content: "\eda5";
}

.v1-Lock-Mail:before {
  content: "\eda6";
}

.v1-Lock-ScreenRotation:before {
  content: "\eda7";
}

.v1-Lock-Wifi:before {
  content: "\eda8";
}

.v1-Locked-Parking:before {
  content: "\eda9";
}

.v1-Locl-Shop:before {
  content: "\edaa";
}

.v1-Locomotive:before {
  content: "\edab";
}

.v1-LOL:before {
  content: "\edac";
}

.v1-Loudspeaker:before {
  content: "\edad";
}

.v1-Love-Mail:before {
  content: "\edae";
}

.v1-Loyalty-Card2:before {
  content: "\edaf";
}

.v1-Loyalty-Card:before {
  content: "\edb0";
}

.v1-Luggage:before {
  content: "\edb1";
}

.v1-Lungs:before {
  content: "\edb2";
}

.v1-Macro:before {
  content: "\edb3";
}

.v1-Magic-Hat:before {
  content: "\edb4";
}

.v1-Magic-Wand:before {
  content: "\edb5";
}

.v1-Magnet:before {
  content: "\edb6";
}

.v1-Magnetic-Field:before {
  content: "\edb7";
}

.v1-Maid:before {
  content: "\edb8";
}

.v1-Mail-At:before {
  content: "\edb9";
}

.v1-Mail-Box1:before {
  content: "\edba";
}

.v1-Mail-Box2:before {
  content: "\edbb";
}

.v1-Mail-Box3:before {
  content: "\edbc";
}

.v1-Mail-Error:before {
  content: "\edbd";
}

.v1-Mail-Help:before {
  content: "\edbe";
}

.v1-Mail-Inbox:before {
  content: "\edbf";
}

.v1-Mail-Notification:before {
  content: "\edc0";
}

.v1-Mail-Outbox:before {
  content: "\edc1";
}

.v1-Mail-Settings:before {
  content: "\edc2";
}

.v1-Mail:before {
  content: "\edc3";
}

.v1-Mailing-List:before {
  content: "\edc4";
}

.v1-Male-1:before {
  content: "\edc5";
}

.v1-Male-2:before {
  content: "\edc6";
}

.v1-Mans-Shoe:before {
  content: "\edc7";
}

.v1-Mans-Uderweare:before {
  content: "\edc8";
}

.v1-Map-1:before {
  content: "\edc9";
}

.v1-Map-2:before {
  content: "\edca";
}

.v1-Map-3:before {
  content: "\edcb";
}

.v1-Map-Pin1:before {
  content: "\edcc";
}

.v1-Map-Pin2:before {
  content: "\edcd";
}

.v1-Map-Pin3:before {
  content: "\edce";
}

.v1-Map-Pin4:before {
  content: "\edcf";
}

.v1-Map-pin5:before {
  content: "\edd0";
}

.v1-Map-pin6:before {
  content: "\edd1";
}

.v1-Map-Screen:before {
  content: "\edd2";
}

.v1-Mario-Mushroom:before {
  content: "\edd3";
}

.v1-Marker:before {
  content: "\edd4";
}

.v1-Marry-GoRound:before {
  content: "\edd5";
}

.v1-Martini-Glass:before {
  content: "\edd6";
}

.v1-Master-Card2:before {
  content: "\edd7";
}

.v1-Master-Card:before {
  content: "\edd8";
}

.v1-Master-Yoda:before {
  content: "\edd9";
}

.v1-Medal-1:before {
  content: "\edda";
}

.v1-Medal-2:before {
  content: "\eddb";
}

.v1-Medal-3:before {
  content: "\eddc";
}

.v1-Medic:before {
  content: "\eddd";
}

.v1-Medical-Bag:before {
  content: "\edde";
}

.v1-Medical-Symbol:before {
  content: "\eddf";
}

.v1-Medicine-Mixing:before {
  content: "\ede0";
}

.v1-Medicine:before {
  content: "\ede1";
}

.v1-Metro:before {
  content: "\ede2";
}

.v1-Metronome:before {
  content: "\ede3";
}

.v1-Microphone-1:before {
  content: "\ede4";
}

.v1-Microphone-2:before {
  content: "\ede5";
}

.v1-Microphone-3:before {
  content: "\ede6";
}

.v1-Microscope:before {
  content: "\ede7";
}

.v1-Microsoft-Store:before {
  content: "\ede8";
}

.v1-Midi-Doc:before {
  content: "\ede9";
}

.v1-Milk:before {
  content: "\edea";
}

.v1-Mini-Bar:before {
  content: "\edeb";
}

.v1-Mini-bus:before {
  content: "\edec";
}

.v1-Mini-Truck2:before {
  content: "\eded";
}

.v1-Mini-Truck:before {
  content: "\edee";
}

.v1-Mini-Van:before {
  content: "\edef";
}

.v1-Mining-Helmet:before {
  content: "\edf0";
}

.v1-Mining-Pick:before {
  content: "\edf1";
}

.v1-Mining-Railcar:before {
  content: "\edf2";
}

.v1-Minion-1:before {
  content: "\edf3";
}

.v1-Minion-2:before {
  content: "\edf4";
}

.v1-mirc:before {
  content: "\edf5";
}

.v1-Mirror2:before {
  content: "\edf6";
}

.v1-Mirror:before {
  content: "\edf7";
}

.v1-Mistletoe:before {
  content: "\edf8";
}

.v1-Mixer-1:before {
  content: "\edf9";
}

.v1-Mixer-2:before {
  content: "\edfa";
}

.v1-Mobile-1:before {
  content: "\edfb";
}

.v1-Mobile-2:before {
  content: "\edfc";
}

.v1-Mobile-3:before {
  content: "\edfd";
}

.v1-Mobile-4:before {
  content: "\edfe";
}

.v1-Mobile-5:before {
  content: "\edff";
}

.v1-Mobile-Map:before {
  content: "\ee00";
}

.v1-Mobile-Network:before {
  content: "\ee01";
}

.v1-Mobile-Security:before {
  content: "\ee02";
}

.v1-Mobile-Touch:before {
  content: "\ee03";
}

.v1-Molecule-1:before {
  content: "\ee04";
}

.v1-Molecule-2:before {
  content: "\ee05";
}

.v1-Money-Bag2:before {
  content: "\ee06";
}

.v1-Money-Bag:before {
  content: "\ee07";
}

.v1-Money-Eye:before {
  content: "\ee08";
}

.v1-Money-Network:before {
  content: "\ee09";
}

.v1-Money-Protect:before {
  content: "\ee0a";
}

.v1-Monkey:before {
  content: "\ee0b";
}

.v1-Moon-2:before {
  content: "\ee0c";
}

.v1-Moon-3:before {
  content: "\ee0d";
}

.v1-Mosquito:before {
  content: "\ee0e";
}

.v1-Motorcycle:before {
  content: "\ee0f";
}

.v1-Mountain:before {
  content: "\ee10";
}

.v1-Mouse-Pointer1:before {
  content: "\ee11";
}

.v1-Mouse-Pointer2:before {
  content: "\ee12";
}

.v1-Mouse:before {
  content: "\ee13";
}

.v1-Mov-Doc:before {
  content: "\ee14";
}

.v1-Mp3-Doc:before {
  content: "\ee15";
}

.v1-Mp3-Player1:before {
  content: "\ee16";
}

.v1-Mp3-Player2:before {
  content: "\ee17";
}

.v1-Mp4-Doc:before {
  content: "\ee18";
}

.v1-Mpg-Doc:before {
  content: "\ee19";
}

.v1-Mpu-Doc:before {
  content: "\ee1a";
}

.v1-Multimeter:before {
  content: "\ee1b";
}

.v1-Mushroom-Cloud:before {
  content: "\ee1c";
}

.v1-Music-Doc:before {
  content: "\ee1d";
}

.v1-Music-Folder2:before {
  content: "\ee1e";
}

.v1-Music-Folder:before {
  content: "\ee1f";
}

.v1-Music-Note1:before {
  content: "\ee20";
}

.v1-Music-Note2:before {
  content: "\ee21";
}

.v1-Music-Note3:before {
  content: "\ee22";
}

.v1-Music-Note4:before {
  content: "\ee23";
}

.v1-Music-Note5:before {
  content: "\ee24";
}

.v1-Music-Note6:before {
  content: "\ee25";
}

.v1-Music-Note7:before {
  content: "\ee26";
}

.v1-Mute-Headset:before {
  content: "\ee27";
}

.v1-Mute-Microphone:before {
  content: "\ee28";
}

.v1-My-Space:before {
  content: "\ee29";
}

.v1-N-W-8:before {
  content: "\ee2a";
}

.v1-N-W-9:before {
  content: "\ee2b";
}

.v1-Navigation-1:before {
  content: "\ee2c";
}

.v1-Navigation-2:before {
  content: "\ee2d";
}

.v1-Needle:before {
  content: "\ee2e";
}

.v1-Negative-Temperature:before {
  content: "\ee2f";
}

.v1-Nest:before {
  content: "\ee30";
}

.v1-Network-Add:before {
  content: "\ee31";
}

.v1-Network-Cable:before {
  content: "\ee32";
}

.v1-Network-Error:before {
  content: "\ee33";
}

.v1-Network-File:before {
  content: "\ee34";
}

.v1-Network-Folder:before {
  content: "\ee35";
}

.v1-Network-Plug:before {
  content: "\ee36";
}

.v1-Network-Question:before {
  content: "\ee37";
}

.v1-Network-Settings:before {
  content: "\ee38";
}

.v1-Networking-1:before {
  content: "\ee39";
}

.v1-Networking-2:before {
  content: "\ee3a";
}

.v1-New-Mail:before {
  content: "\ee3b";
}

.v1-New-Tag1:before {
  content: "\ee3c";
}

.v1-New-Tag2:before {
  content: "\ee3d";
}

.v1-New-Tag3:before {
  content: "\ee3e";
}

.v1-New-Tag5:before {
  content: "\ee3f";
}

.v1-New-Tga4:before {
  content: "\ee40";
}

.v1-Newtons-Cradle:before {
  content: "\ee41";
}

.v1-Next-Button:before {
  content: "\ee42";
}

.v1-Night-Mode:before {
  content: "\ee43";
}

.v1-Ninja:before {
  content: "\ee44";
}

.v1-Nird:before {
  content: "\ee45";
}

.v1-No-Batery:before {
  content: "\ee46";
}

.v1-No-Sim:before {
  content: "\ee47";
}

.v1-No-Television:before {
  content: "\ee48";
}

.v1-No-Wifi2:before {
  content: "\ee49";
}

.v1-No-Wifi:before {
  content: "\ee4a";
}

.v1-Nuclear-Symbol2:before {
  content: "\ee4b";
}

.v1-Nuclear-Symbol:before {
  content: "\ee4c";
}

.v1-Numbered-List2:before {
  content: "\ee4d";
}

.v1-Numbered-List:before {
  content: "\ee4e";
}

.v1-Nurse:before {
  content: "\ee4f";
}

.v1-Nvidia:before {
  content: "\ee50";
}

.v1-Observatory:before {
  content: "\ee51";
}

.v1-Odnoklassniki:before {
  content: "\ee52";
}

.v1-Office-Chair:before {
  content: "\ee53";
}

.v1-Office:before {
  content: "\ee54";
}

.v1-Oil-Extractor:before {
  content: "\ee55";
}

.v1-Oil-Tower:before {
  content: "\ee56";
}

.v1-Old-Clock:before {
  content: "\ee57";
}

.v1-Old-Man:before {
  content: "\ee58";
}

.v1-Omega:before {
  content: "\ee59";
}

.v1-On-air1:before {
  content: "\ee5a";
}

.v1-On-air2:before {
  content: "\ee5b";
}

.v1-One-Click1:before {
  content: "\ee5c";
}

.v1-One-Click2:before {
  content: "\ee5d";
}

.v1-Open-1:before {
  content: "\ee5e";
}

.v1-Open-2:before {
  content: "\ee5f";
}

.v1-Open-Bookmark:before {
  content: "\ee60";
}

.v1-Open-Folder:before {
  content: "\ee61";
}

.v1-Open-Hand1:before {
  content: "\ee62";
}

.v1-Open-Hand2:before {
  content: "\ee63";
}

.v1-Open-Hand:before {
  content: "\ee64";
}

.v1-Open-Mail2:before {
  content: "\ee65";
}

.v1-Open-Mail:before {
  content: "\ee66";
}

.v1-Open-SourceIniciative:before {
  content: "\ee67";
}

.v1-Opera:before {
  content: "\ee68";
}

.v1-Ornament:before {
  content: "\ee69";
}

.v1-Ornamnt-2:before {
  content: "\ee6a";
}

.v1-Owl:before {
  content: "\ee6b";
}

.v1-Packman:before {
  content: "\ee6c";
}

.v1-Pacman-Ghost:before {
  content: "\ee6d";
}

.v1-Page-Break:before {
  content: "\ee6e";
}

.v1-Page-Size:before {
  content: "\ee6f";
}

.v1-Paint-Bucket:before {
  content: "\ee70";
}

.v1-Paint-Format:before {
  content: "\ee71";
}

.v1-Paint-Roller:before {
  content: "\ee72";
}

.v1-Painting-Stand:before {
  content: "\ee73";
}

.v1-Palm-Tree:before {
  content: "\ee74";
}

.v1-Palm:before {
  content: "\ee75";
}

.v1-Panda:before {
  content: "\ee76";
}

.v1-Panorama:before {
  content: "\ee77";
}

.v1-Paper-Clip1:before {
  content: "\ee78";
}

.v1-Paper-Clip2:before {
  content: "\ee79";
}

.v1-Paper-Clip3:before {
  content: "\ee7a";
}

.v1-Paper-Roll:before {
  content: "\ee7b";
}

.v1-Paprika:before {
  content: "\ee7c";
}

.v1-Parabolic-Antena:before {
  content: "\ee7d";
}

.v1-Paragraph-Tool:before {
  content: "\ee7e";
}

.v1-Park:before {
  content: "\ee7f";
}

.v1-Parrot:before {
  content: "\ee80";
}

.v1-Party-Hat:before {
  content: "\ee81";
}

.v1-Party-Ribbon:before {
  content: "\ee82";
}

.v1-Paste-Here:before {
  content: "\ee83";
}

.v1-Path:before {
  content: "\ee84";
}

.v1-Pause-Button:before {
  content: "\ee85";
}

.v1-Pause:before {
  content: "\ee86";
}

.v1-Paypal-1:before {
  content: "\ee87";
}

.v1-Paypal-2:before {
  content: "\ee88";
}

.v1-Peace:before {
  content: "\ee89";
}

.v1-Pear:before {
  content: "\ee8a";
}

.v1-Pelican:before {
  content: "\ee8b";
}

.v1-Pen-Holder:before {
  content: "\ee8c";
}

.v1-Pen-Tool:before {
  content: "\ee8d";
}

.v1-Pencil-Sharpener:before {
  content: "\ee8e";
}

.v1-Pencil-tool:before {
  content: "\ee8f";
}

.v1-Penguin:before {
  content: "\ee90";
}

.v1-Pet-Bottle:before {
  content: "\ee91";
}

.v1-Pet-Recycle:before {
  content: "\ee92";
}

.v1-Pharmaceutical-Symbol:before {
  content: "\ee93";
}

.v1-Phone-Download:before {
  content: "\ee94";
}

.v1-Phone-Upload:before {
  content: "\ee95";
}

.v1-Photo-Library:before {
  content: "\ee96";
}

.v1-Photoshop:before {
  content: "\ee97";
}

.v1-Piano-Keyboard:before {
  content: "\ee98";
}

.v1-Piano:before {
  content: "\ee99";
}

.v1-Picasa:before {
  content: "\ee9a";
}

.v1-Pickup-1:before {
  content: "\ee9b";
}

.v1-Pickup-2:before {
  content: "\ee9c";
}

.v1-Pickup-3:before {
  content: "\ee9d";
}

.v1-Pickup:before {
  content: "\ee9e";
}

.v1-Picture-1:before {
  content: "\ee9f";
}

.v1-Picture-2:before {
  content: "\eea0";
}

.v1-Picture-3:before {
  content: "\eea1";
}

.v1-Picture-Folder:before {
  content: "\eea2";
}

.v1-Pie:before {
  content: "\eea3";
}

.v1-Pikachu:before {
  content: "\eea4";
}

.v1-Pills:before {
  content: "\eea5";
}

.v1-Pin:before {
  content: "\eea6";
}

.v1-Pine-Tree:before {
  content: "\eea7";
}

.v1-Pinterest:before {
  content: "\eea8";
}

.v1-Pirate:before {
  content: "\eea9";
}

.v1-Pizza-Slice:before {
  content: "\eeaa";
}

.v1-Pizza:before {
  content: "\eeab";
}

.v1-Plane-1:before {
  content: "\eeac";
}

.v1-Plane-2:before {
  content: "\eead";
}

.v1-Plane-3:before {
  content: "\eeae";
}

.v1-Plane-4:before {
  content: "\eeaf";
}

.v1-Plane-Front:before {
  content: "\eeb0";
}

.v1-Planet:before {
  content: "\eeb1";
}

.v1-Plant-2:before {
  content: "\eeb2";
}

.v1-Plant-Care:before {
  content: "\eeb3";
}

.v1-Plant:before {
  content: "\eeb4";
}

.v1-Play-Button:before {
  content: "\eeb5";
}

.v1-Play-Stor:before {
  content: "\eeb6";
}

.v1-Play-Store2:before {
  content: "\eeb7";
}

.v1-Play:before {
  content: "\eeb8";
}

.v1-Player-1:before {
  content: "\eeb9";
}

.v1-Player-2:before {
  content: "\eeba";
}

.v1-Playground:before {
  content: "\eebb";
}

.v1-Playing:before {
  content: "\eebc";
}

.v1-Plurk:before {
  content: "\eebd";
}

.v1-Podium:before {
  content: "\eebe";
}

.v1-Pointer-Help1:before {
  content: "\eebf";
}

.v1-Pointer-Help2:before {
  content: "\eec0";
}

.v1-Pointer-Working1:before {
  content: "\eec1";
}

.v1-Pointer-Working2:before {
  content: "\eec2";
}

.v1-Poke:before {
  content: "\eec3";
}

.v1-Pokemon:before {
  content: "\eec4";
}

.v1-Police-car:before {
  content: "\eec5";
}

.v1-Police:before {
  content: "\eec6";
}

.v1-Pong:before {
  content: "\eec7";
}

.v1-Pool2:before {
  content: "\eec8";
}

.v1-Pool:before {
  content: "\eec9";
}

.v1-Postit:before {
  content: "\eeca";
}

.v1-Pound-1:before {
  content: "\eecb";
}

.v1-Pound-2:before {
  content: "\eecc";
}

.v1-Pound-3:before {
  content: "\eecd";
}

.v1-Pound-Card:before {
  content: "\eece";
}

.v1-Pound-Cloud:before {
  content: "\eecf";
}

.v1-Pound-Doc:before {
  content: "\eed0";
}

.v1-Pound-Fall:before {
  content: "\eed1";
}

.v1-Pound-Rise:before {
  content: "\eed2";
}

.v1-Power-Socket1:before {
  content: "\eed3";
}

.v1-Power-Socket2:before {
  content: "\eed4";
}

.v1-Powerpoint:before {
  content: "\eed5";
}

.v1-Pozitive-Temperature:before {
  content: "\eed6";
}

.v1-Precision-Seledt1:before {
  content: "\eed7";
}

.v1-Precision-Seledt2:before {
  content: "\eed8";
}

.v1-Precision-Seledt3:before {
  content: "\eed9";
}

.v1-Predator:before {
  content: "\eeda";
}

.v1-Present-Box:before {
  content: "\eedb";
}

.v1-Presentation-2:before {
  content: "\eedc";
}

.v1-Presentation-3:before {
  content: "\eedd";
}

.v1-Presentation-File:before {
  content: "\eede";
}

.v1-Pressure-Checker:before {
  content: "\eedf";
}

.v1-Prev-Button:before {
  content: "\eee0";
}

.v1-Prezentation-1:before {
  content: "\eee1";
}

.v1-Price-Tag1:before {
  content: "\eee2";
}

.v1-Price-Tag2:before {
  content: "\eee3";
}

.v1-Print:before {
  content: "\eee4";
}

.v1-Prisoner:before {
  content: "\eee5";
}

.v1-Projector:before {
  content: "\eee6";
}

.v1-Protect-Wifi:before {
  content: "\eee7";
}

.v1-Protection-Helmet:before {
  content: "\eee8";
}

.v1-Public-Wifi:before {
  content: "\eee9";
}

.v1-Pulley-Phisics:before {
  content: "\eeea";
}

.v1-Punk:before {
  content: "\eeeb";
}

.v1-Puzzle-Piece:before {
  content: "\eeec";
}

.v1-QR-Code:before {
  content: "\eeed";
}

.v1-Question-Bookmark:before {
  content: "\eeee";
}

.v1-Question-Doc:before {
  content: "\eeef";
}

.v1-Question-File:before {
  content: "\eef0";
}

.v1-Question-Folder:before {
  content: "\eef1";
}

.v1-Quicktime-Doc:before {
  content: "\eef2";
}

.v1-Quicktime-Player:before {
  content: "\eef3";
}

.v1-Quiet:before {
  content: "\eef4";
}

.v1-Quote-1:before {
  content: "\eef5";
}

.v1-Quote-2:before {
  content: "\eef6";
}

.v1-Rabit:before {
  content: "\eef7";
}

.v1-Raccoon:before {
  content: "\eef8";
}

.v1-Radio-1:before {
  content: "\eef9";
}

.v1-Radio-2:before {
  content: "\eefa";
}

.v1-Radio-3:before {
  content: "\eefb";
}

.v1-Rain-Day:before {
  content: "\eefc";
}

.v1-Rain-Night:before {
  content: "\eefd";
}

.v1-Rain-Storm:before {
  content: "\eefe";
}

.v1-Raining:before {
  content: "\eeff";
}

.v1-Rattles-1:before {
  content: "\ef00";
}

.v1-Rattles-2:before {
  content: "\ef01";
}

.v1-Razor-Blade:before {
  content: "\ef02";
}

.v1-Rec-Button:before {
  content: "\ef03";
}

.v1-Reception:before {
  content: "\ef04";
}

.v1-Recieve-Mail:before {
  content: "\ef05";
}

.v1-Recycle-1:before {
  content: "\ef06";
}

.v1-Recycle-2:before {
  content: "\ef07";
}

.v1-Recycle-BinFile:before {
  content: "\ef08";
}

.v1-Recycle-Container:before {
  content: "\ef09";
}

.v1-Recycle-Water:before {
  content: "\ef0a";
}

.v1-Reddit:before {
  content: "\ef0b";
}

.v1-Redo:before {
  content: "\ef0c";
}

.v1-Reflection-Tool:before {
  content: "\ef0d";
}

.v1-Refresh-Folder:before {
  content: "\ef0e";
}

.v1-Refresh-Mail:before {
  content: "\ef0f";
}

.v1-Refresh-Network:before {
  content: "\ef10";
}

.v1-Refresh-Wifi:before {
  content: "\ef11";
}

.v1-Registry-1:before {
  content: "\ef12";
}

.v1-Registry-2:before {
  content: "\ef13";
}

.v1-Registry-3:before {
  content: "\ef14";
}

.v1-Registry-List:before {
  content: "\ef15";
}

.v1-Relativity:before {
  content: "\ef16";
}

.v1-Reload-Mail:before {
  content: "\ef17";
}

.v1-Remote-Control:before {
  content: "\ef18";
}

.v1-Remouve-Basket:before {
  content: "\ef19";
}

.v1-Remouve-Bookmark:before {
  content: "\ef1a";
}

.v1-Remouve-Card:before {
  content: "\ef1b";
}

.v1-Remouve-Cart:before {
  content: "\ef1c";
}

.v1-Remouve-Cloud:before {
  content: "\ef1d";
}

.v1-Remouve-Doc:before {
  content: "\ef1e";
}

.v1-Remouve-File:before {
  content: "\ef1f";
}

.v1-Remouve-Folder:before {
  content: "\ef20";
}

.v1-Remouve-Location:before {
  content: "\ef21";
}

.v1-Remouve-Network:before {
  content: "\ef22";
}

.v1-Remouve-Wifi:before {
  content: "\ef23";
}

.v1-Renew-Card:before {
  content: "\ef24";
}

.v1-Repeat-Doc:before {
  content: "\ef25";
}

.v1-Replay-Doc:before {
  content: "\ef26";
}

.v1-Reply-All:before {
  content: "\ef27";
}

.v1-Reply:before {
  content: "\ef28";
}

.v1-Reset-Settings:before {
  content: "\ef29";
}

.v1-Resize-Corner:before {
  content: "\ef2a";
}

.v1-Responsive-Design:before {
  content: "\ef2b";
}

.v1-Restaurant:before {
  content: "\ef2c";
}

.v1-Retina-Scan:before {
  content: "\ef2d";
}

.v1-RGB:before {
  content: "\ef2e";
}

.v1-Rhyno:before {
  content: "\ef2f";
}

.v1-Right-1:before {
  content: "\ef30";
}

.v1-Right-2:before {
  content: "\ef31";
}

.v1-Right-3:before {
  content: "\ef32";
}

.v1-Right-4:before {
  content: "\ef33";
}

.v1-Right-5:before {
  content: "\ef34";
}

.v1-Right-6:before {
  content: "\ef35";
}

.v1-Right-7:before {
  content: "\ef36";
}

.v1-Right-8:before {
  content: "\ef37";
}

.v1-Right-9:before {
  content: "\ef38";
}

.v1-Right-10:before {
  content: "\ef39";
}

.v1-Right-11:before {
  content: "\ef3a";
}

.v1-Right-12:before {
  content: "\ef3b";
}

.v1-Right-13:before {
  content: "\ef3c";
}

.v1-Right-14:before {
  content: "\ef3d";
}

.v1-Roadster:before {
  content: "\ef3e";
}

.v1-Roasted-Chicken:before {
  content: "\ef3f";
}

.v1-Rocket-2:before {
  content: "\ef40";
}

.v1-Rocket:before {
  content: "\ef41";
}

.v1-Roller-Coaster:before {
  content: "\ef42";
}

.v1-Rolling-Skate:before {
  content: "\ef43";
}

.v1-Room-Key:before {
  content: "\ef44";
}

.v1-Room-Service2:before {
  content: "\ef45";
}

.v1-Room-Service:before {
  content: "\ef46";
}

.v1-Rotate-Camera:before {
  content: "\ef47";
}

.v1-Rotate-Left:before {
  content: "\ef48";
}

.v1-Rotate-Right:before {
  content: "\ef49";
}

.v1-Rotate:before {
  content: "\ef4a";
}

.v1-Rotation-Tool:before {
  content: "\ef4b";
}

.v1-Round-Swipe:before {
  content: "\ef4c";
}

.v1-Rss:before {
  content: "\ef4d";
}

.v1-Rubber:before {
  content: "\ef4e";
}

.v1-Rudolf-Deer:before {
  content: "\ef4f";
}

.v1-Ruler-1:before {
  content: "\ef50";
}

.v1-Ruler-2:before {
  content: "\ef51";
}

.v1-Ruler-Tool:before {
  content: "\ef52";
}

.v1-S-E-1:before {
  content: "\ef53";
}

.v1-S-E-4:before {
  content: "\ef54";
}

.v1-S-E-5:before {
  content: "\ef55";
}

.v1-S-E-7:before {
  content: "\ef56";
}

.v1-S-E-11:before {
  content: "\ef57";
}

.v1-S-E-12:before {
  content: "\ef58";
}

.v1-S-W-1:before {
  content: "\ef59";
}

.v1-S-W-4:before {
  content: "\ef5a";
}

.v1-S-W-5:before {
  content: "\ef5b";
}

.v1-S-W-7:before {
  content: "\ef5c";
}

.v1-S-W-8:before {
  content: "\ef5d";
}

.v1-S-W-9:before {
  content: "\ef5e";
}

.v1-S-W-11:before {
  content: "\ef5f";
}

.v1-S-W-12:before {
  content: "\ef60";
}

.v1-Safari:before {
  content: "\ef61";
}

.v1-Safe-Box1:before {
  content: "\ef62";
}

.v1-Safe-Box2:before {
  content: "\ef63";
}

.v1-Safty-Pin1:before {
  content: "\ef64";
}

.v1-Safty-Pin2:before {
  content: "\ef65";
}

.v1-Sale-1:before {
  content: "\ef66";
}

.v1-Sale-2:before {
  content: "\ef67";
}

.v1-Sale-Tag1:before {
  content: "\ef68";
}

.v1-Salt-Pepper:before {
  content: "\ef69";
}

.v1-Sand-Clock:before {
  content: "\ef6a";
}

.v1-Sandwich:before {
  content: "\ef6b";
}

.v1-Santa-bag:before {
  content: "\ef6c";
}

.v1-Santa-Claus2:before {
  content: "\ef6d";
}

.v1-Santa-Claus:before {
  content: "\ef6e";
}

.v1-Santa-Hat1:before {
  content: "\ef6f";
}

.v1-Santa-Hat2:before {
  content: "\ef70";
}

.v1-Santa-Slide:before {
  content: "\ef71";
}

.v1-Satellite:before {
  content: "\ef72";
}

.v1-Save-fromCloud:before {
  content: "\ef73";
}

.v1-Save-Settings:before {
  content: "\ef74";
}

.v1-Save-toCloud:before {
  content: "\ef75";
}

.v1-Saxophone:before {
  content: "\ef76";
}

.v1-Scale-Tool:before {
  content: "\ef77";
}

.v1-School-Bus:before {
  content: "\ef78";
}

.v1-Scooter-2:before {
  content: "\ef79";
}

.v1-Scooter:before {
  content: "\ef7a";
}

.v1-Scorll-LeftRight1:before {
  content: "\ef7b";
}

.v1-Scorll-LeftRight2:before {
  content: "\ef7c";
}

.v1-Scream:before {
  content: "\ef7d";
}

.v1-Screen-Lock:before {
  content: "\ef7e";
}

.v1-Screen-Rotation:before {
  content: "\ef7f";
}

.v1-Screw:before {
  content: "\ef80";
}

.v1-Screwdriver2:before {
  content: "\ef81";
}

.v1-Screwdriver:before {
  content: "\ef82";
}

.v1-Scroll-AllDirection1:before {
  content: "\ef83";
}

.v1-Scroll-AllDirection2:before {
  content: "\ef84";
}

.v1-Scroll-Down:before {
  content: "\ef85";
}

.v1-Scroll-Up:before {
  content: "\ef86";
}

.v1-Seadog:before {
  content: "\ef87";
}

.v1-Search-Cloud:before {
  content: "\ef88";
}

.v1-Search-Doc:before {
  content: "\ef89";
}

.v1-Search-File:before {
  content: "\ef8a";
}

.v1-Search-Folder:before {
  content: "\ef8b";
}

.v1-Search-Job:before {
  content: "\ef8c";
}

.v1-Search-Mail:before {
  content: "\ef8d";
}

.v1-Search-Money:before {
  content: "\ef8e";
}

.v1-Search-Network:before {
  content: "\ef8f";
}

.v1-Search-Wifi:before {
  content: "\ef90";
}

.v1-Seatbelt:before {
  content: "\ef91";
}

.v1-Secure-Card:before {
  content: "\ef92";
}

.v1-Secure-File:before {
  content: "\ef93";
}

.v1-Secure-Folder:before {
  content: "\ef94";
}

.v1-Secure-Shopping1:before {
  content: "\ef95";
}

.v1-Secure-Shopping2:before {
  content: "\ef96";
}

.v1-Security-Camera1:before {
  content: "\ef97";
}

.v1-Security-Camera2:before {
  content: "\ef98";
}

.v1-Security-Camera3:before {
  content: "\ef99";
}

.v1-Sedan:before {
  content: "\ef9a";
}

.v1-Select-1:before {
  content: "\ef9b";
}

.v1-Select-2:before {
  content: "\ef9c";
}

.v1-Selection-Tool:before {
  content: "\ef9d";
}

.v1-Send-Mail1:before {
  content: "\ef9e";
}

.v1-Send-Mail2:before {
  content: "\ef9f";
}

.v1-Send-Mail:before {
  content: "\efa0";
}

.v1-Send-Money:before {
  content: "\efa1";
}

.v1-Settings-Attention:before {
  content: "\efa2";
}

.v1-Settings-Doc:before {
  content: "\efa3";
}

.v1-Settings-Done:before {
  content: "\efa4";
}

.v1-Settings-Error:before {
  content: "\efa5";
}

.v1-Settings-Folder:before {
  content: "\efa6";
}

.v1-Settings-Help:before {
  content: "\efa7";
}

.v1-Settings-Window:before {
  content: "\efa8";
}

.v1-Sextant:before {
  content: "\efa9";
}

.v1-Shake:before {
  content: "\efaa";
}

.v1-Share-Cloud:before {
  content: "\efab";
}

.v1-Share-File1:before {
  content: "\efac";
}

.v1-Share-File2:before {
  content: "\efad";
}

.v1-Share-Folder:before {
  content: "\efae";
}

.v1-Shark:before {
  content: "\efaf";
}

.v1-Shear-Tool:before {
  content: "\efb0";
}

.v1-Sheep:before {
  content: "\efb1";
}

.v1-Sheet-File:before {
  content: "\efb2";
}

.v1-Ship-1:before {
  content: "\efb3";
}

.v1-Ship-2:before {
  content: "\efb4";
}

.v1-Shirt-1:before {
  content: "\efb5";
}

.v1-Shirt-2:before {
  content: "\efb6";
}

.v1-Shopping-list:before {
  content: "\efb7";
}

.v1-Shorts:before {
  content: "\efb8";
}

.v1-Shower-1:before {
  content: "\efb9";
}

.v1-Shower-2:before {
  content: "\efba";
}

.v1-Shuffle-Doc:before {
  content: "\efbb";
}

.v1-Shufle-Folder:before {
  content: "\efbc";
}

.v1-Shy:before {
  content: "\efbd";
}

.v1-Sign-Contract:before {
  content: "\efbe";
}

.v1-Sign-Post:before {
  content: "\efbf";
}

.v1-Silo-Storage:before {
  content: "\efc0";
}

.v1-Sim-Card:before {
  content: "\efc1";
}

.v1-Sims:before {
  content: "\efc2";
}

.v1-Simulator:before {
  content: "\efc3";
}

.v1-Siutecase:before {
  content: "\efc4";
}

.v1-Skate-Board:before {
  content: "\efc5";
}

.v1-Skeleton:before {
  content: "\efc6";
}

.v1-Skirt:before {
  content: "\efc7";
}

.v1-Skrill:before {
  content: "\efc8";
}

.v1-Skull2:before {
  content: "\efc9";
}

.v1-Skull:before {
  content: "\efca";
}

.v1-Skype:before {
  content: "\efcb";
}

.v1-Sleeping:before {
  content: "\efcc";
}

.v1-Slide-LeftRight:before {
  content: "\efcd";
}

.v1-Slippers:before {
  content: "\efce";
}

.v1-Slot-7:before {
  content: "\efcf";
}

.v1-Slot-Machine:before {
  content: "\efd0";
}

.v1-Small-Jack:before {
  content: "\efd1";
}

.v1-Smile:before {
  content: "\efd2";
}

.v1-Snail:before {
  content: "\efd3";
}

.v1-Snake:before {
  content: "\efd4";
}

.v1-Snooze:before {
  content: "\efd5";
}

.v1-Snow-Day:before {
  content: "\efd6";
}

.v1-Snow-Flake:before {
  content: "\efd7";
}

.v1-Snow-man:before {
  content: "\efd8";
}

.v1-Snow-Night:before {
  content: "\efd9";
}

.v1-Snow-RainDay:before {
  content: "\efda";
}

.v1-Snow-RainNight:before {
  content: "\efdb";
}

.v1-Snow-Rain:before {
  content: "\efdc";
}

.v1-Snow:before {
  content: "\efdd";
}

.v1-Snowbulb:before {
  content: "\efde";
}

.v1-Snowing:before {
  content: "\efdf";
}

.v1-Soccer-Ball:before {
  content: "\efe0";
}

.v1-Soda-Can:before {
  content: "\efe1";
}

.v1-Solar-Panel:before {
  content: "\efe2";
}

.v1-Solar-System:before {
  content: "\efe3";
}

.v1-Solider:before {
  content: "\efe4";
}

.v1-Sonic:before {
  content: "\efe5";
}

.v1-Sonny-See:before {
  content: "\efe6";
}

.v1-Soop:before {
  content: "\efe7";
}

.v1-Sound-Cloud:before {
  content: "\efe8";
}

.v1-Sound-Off:before {
  content: "\efe9";
}

.v1-Sound-wave1:before {
  content: "\efea";
}

.v1-Sound-wave2:before {
  content: "\efeb";
}

.v1-Sound-wave3:before {
  content: "\efec";
}

.v1-Spa-Towel:before {
  content: "\efed";
}

.v1-Space-Invaders:before {
  content: "\efee";
}

.v1-Spade-AceCard:before {
  content: "\efef";
}

.v1-Speaker-1:before {
  content: "\eff0";
}

.v1-Speaker-2:before {
  content: "\eff1";
}

.v1-Speaker-3:before {
  content: "\eff2";
}

.v1-Speaker-4:before {
  content: "\eff3";
}

.v1-Speakers-1:before {
  content: "\eff4";
}

.v1-Speakers-Phone:before {
  content: "\eff5";
}

.v1-Speed-Meter:before {
  content: "\eff6";
}

.v1-Spider:before {
  content: "\eff7";
}

.v1-Split-File:before {
  content: "\eff8";
}

.v1-Split:before {
  content: "\eff9";
}

.v1-Sport-Car1:before {
  content: "\effa";
}

.v1-Sport-Flusk:before {
  content: "\effb";
}

.v1-Sport-Shirt:before {
  content: "\effc";
}

.v1-Sport-Shoe:before {
  content: "\effd";
}

.v1-Spotifi:before {
  content: "\effe";
}

.v1-Sprayer-Tool:before {
  content: "\efff";
}

.v1-Sputnic:before {
  content: "\f000";
}

.v1-Spy:before {
  content: "\f001";
}

.v1-Squirel:before {
  content: "\f002";
}

.v1-Sroll-UpDown1:before {
  content: "\f003";
}

.v1-Sroll-UpDown2:before {
  content: "\f004";
}

.v1-Stamp-1:before {
  content: "\f005";
}

.v1-Stamp-2:before {
  content: "\f006";
}

.v1-Stamp:before {
  content: "\f007";
}

.v1-Star-Night:before {
  content: "\f008";
}

.v1-Star:before {
  content: "\f009";
}

.v1-Starfish:before {
  content: "\f00a";
}

.v1-Steak:before {
  content: "\f00b";
}

.v1-Steam:before {
  content: "\f00c";
}

.v1-Stetoscope:before {
  content: "\f00d";
}

.v1-Steve-Jobs:before {
  content: "\f00e";
}

.v1-Stock-ofMoney:before {
  content: "\f00f";
}

.v1-Stomach:before {
  content: "\f010";
}

.v1-Stop-Button:before {
  content: "\f011";
}

.v1-Stop-Watch1:before {
  content: "\f012";
}

.v1-Stop-Watch2:before {
  content: "\f013";
}

.v1-Store:before {
  content: "\f014";
}

.v1-Storm-day:before {
  content: "\f015";
}

.v1-Storm-night:before {
  content: "\f016";
}

.v1-Stormtrooper:before {
  content: "\f017";
}

.v1-Strait:before {
  content: "\f018";
}

.v1-Strategy-1:before {
  content: "\f019";
}

.v1-Street-View:before {
  content: "\f01a";
}

.v1-Striketrough:before {
  content: "\f01b";
}

.v1-Student:before {
  content: "\f01c";
}

.v1-Stumbleupon:before {
  content: "\f01d";
}

.v1-Subscript:before {
  content: "\f01e";
}

.v1-Sum:before {
  content: "\f01f";
}

.v1-Sun-Glases:before {
  content: "\f020";
}

.v1-Sun:before {
  content: "\f021";
}

.v1-Sunglass:before {
  content: "\f022";
}

.v1-Sunny-Fields:before {
  content: "\f023";
}

.v1-Super-Mario:before {
  content: "\f024";
}

.v1-Superscript:before {
  content: "\f025";
}

.v1-Sushi:before {
  content: "\f026";
}

.v1-Swan:before {
  content: "\f027";
}

.v1-Swarm:before {
  content: "\f028";
}

.v1-Sweating:before {
  content: "\f029";
}

.v1-Swiming-Short:before {
  content: "\f02a";
}

.v1-Swimwear:before {
  content: "\f02b";
}

.v1-Swipe-LeftRight:before {
  content: "\f02c";
}

.v1-Swipe-Left:before {
  content: "\f02d";
}

.v1-Swipe-Right:before {
  content: "\f02e";
}

.v1-Switch-1:before {
  content: "\f02f";
}

.v1-Switch-2:before {
  content: "\f030";
}

.v1-Switch-3:before {
  content: "\f031";
}

.v1-Switch-4:before {
  content: "\f032";
}

.v1-Switch-5:before {
  content: "\f033";
}

.v1-Sync-Cloud:before {
  content: "\f034";
}

.v1-Syncronize-Files:before {
  content: "\f035";
}

.v1-Syncronize-Folder2:before {
  content: "\f036";
}

.v1-Syncronize-Folders:before {
  content: "\f037";
}

.v1-Synthesizer:before {
  content: "\f038";
}

.v1-Syringe:before {
  content: "\f039";
}

.v1-T-Shirt-2:before {
  content: "\f03a";
}

.v1-T-Shirt:before {
  content: "\f03b";
}

.v1-Table-Tenis:before {
  content: "\f03c";
}

.v1-Tablet-Download:before {
  content: "\f03d";
}

.v1-Tablet-Security:before {
  content: "\f03e";
}

.v1-Tablet-Touch:before {
  content: "\f03f";
}

.v1-Tablet-Upload:before {
  content: "\f040";
}

.v1-Tag-1:before {
  content: "\f041";
}

.v1-Tag-2:before {
  content: "\f042";
}

.v1-Take-offPlane:before {
  content: "\f043";
}

.v1-Tap:before {
  content: "\f044";
}

.v1-Tape-1:before {
  content: "\f045";
}

.v1-Tape-2:before {
  content: "\f046";
}

.v1-Tape-Meter:before {
  content: "\f047";
}

.v1-Target-Group:before {
  content: "\f048";
}

.v1-Target-Money:before {
  content: "\f049";
}

.v1-Target:before {
  content: "\f04a";
}

.v1-Taxi:before {
  content: "\f04b";
}

.v1-Tea-Mug:before {
  content: "\f04c";
}

.v1-Teamviewer:before {
  content: "\f04d";
}

.v1-Tect-Cursor:before {
  content: "\f04e";
}

.v1-Telephone:before {
  content: "\f04f";
}

.v1-Telescope:before {
  content: "\f050";
}

.v1-Television:before {
  content: "\f051";
}

.v1-Temperatur-night:before {
  content: "\f052";
}

.v1-Temperature-Day:before {
  content: "\f053";
}

.v1-Tempometer:before {
  content: "\f054";
}

.v1-Tenis-Ball:before {
  content: "\f055";
}

.v1-Tenis:before {
  content: "\f056";
}

.v1-Tent:before {
  content: "\f057";
}

.v1-Termometer2:before {
  content: "\f058";
}

.v1-Termometer:before {
  content: "\f059";
}

.v1-Test-Flusk1:before {
  content: "\f05a";
}

.v1-Test-Flusk2:before {
  content: "\f05b";
}

.v1-Test-Tube:before {
  content: "\f05c";
}

.v1-Tetris:before {
  content: "\f05d";
}

.v1-Text-Box2:before {
  content: "\f05e";
}

.v1-Text-box:before {
  content: "\f05f";
}

.v1-Text-Color:before {
  content: "\f060";
}

.v1-Text-Select1:before {
  content: "\f061";
}

.v1-Text-Select2:before {
  content: "\f062";
}

.v1-Text-Size:before {
  content: "\f063";
}

.v1-Theatre-Scene:before {
  content: "\f064";
}

.v1-Theatre:before {
  content: "\f065";
}

.v1-Thin-Pen:before {
  content: "\f066";
}

.v1-Thoung-Out:before {
  content: "\f067";
}

.v1-Thread:before {
  content: "\f068";
}

.v1-Thumbs-Down:before {
  content: "\f069";
}

.v1-Thumbs-Up:before {
  content: "\f06a";
}

.v1-Thunder-StormDay:before {
  content: "\f06b";
}

.v1-Thunder-StormNight:before {
  content: "\f06c";
}

.v1-Thunderstorm:before {
  content: "\f06d";
}

.v1-Tic-TacToe:before {
  content: "\f06e";
}

.v1-Ticket:before {
  content: "\f06f";
}

.v1-Tie:before {
  content: "\f070";
}

.v1-Tiger:before {
  content: "\f071";
}

.v1-Time-Doc:before {
  content: "\f072";
}

.v1-Time-Folder:before {
  content: "\f073";
}

.v1-Timemoney:before {
  content: "\f074";
}

.v1-Timer-Egg:before {
  content: "\f075";
}

.v1-Toast:before {
  content: "\f076";
}

.v1-Toilet-Paper:before {
  content: "\f077";
}

.v1-Toilet-Pump:before {
  content: "\f078";
}

.v1-Toilet:before {
  content: "\f079";
}

.v1-Tomato:before {
  content: "\f07a";
}

.v1-Tools:before {
  content: "\f07b";
}

.v1-Tooth:before {
  content: "\f07c";
}

.v1-Tornado:before {
  content: "\f07d";
}

.v1-Total-Commander:before {
  content: "\f07e";
}

.v1-Touch-Lock:before {
  content: "\f07f";
}

.v1-Towel:before {
  content: "\f080";
}

.v1-Traffic-Cone:before {
  content: "\f081";
}

.v1-Trafic-light1:before {
  content: "\f082";
}

.v1-Trafic-Light2:before {
  content: "\f083";
}

.v1-Train-1:before {
  content: "\f084";
}

.v1-Train-2:before {
  content: "\f085";
}

.v1-Train-3:before {
  content: "\f086";
}

.v1-Tram:before {
  content: "\f087";
}

.v1-Translate2:before {
  content: "\f088";
}

.v1-Translate:before {
  content: "\f089";
}

.v1-Transmition-Tower:before {
  content: "\f08a";
}

.v1-Trash-Protection:before {
  content: "\f08b";
}

.v1-Trashcan:before {
  content: "\f08c";
}

.v1-Tree-1:before {
  content: "\f08d";
}

.v1-Treehouse:before {
  content: "\f08e";
}

.v1-Tresure-Chest:before {
  content: "\f08f";
}

.v1-Tripit:before {
  content: "\f090";
}

.v1-Troleybus:before {
  content: "\f091";
}

.v1-Trompet-1:before {
  content: "\f092";
}

.v1-Trompet-2:before {
  content: "\f093";
}

.v1-Truck-1:before {
  content: "\f094";
}

.v1-Truck-2:before {
  content: "\f095";
}

.v1-Truck:before {
  content: "\f096";
}

.v1-Tub:before {
  content: "\f097";
}

.v1-Tuenti:before {
  content: "\f098";
}

.v1-Tumblr:before {
  content: "\f099";
}

.v1-Turn-Page:before {
  content: "\f09a";
}

.v1-Turtle:before {
  content: "\f09b";
}

.v1-Twitch:before {
  content: "\f09c";
}

.v1-Twitter-1:before {
  content: "\f09d";
}

.v1-Twitter-2:before {
  content: "\f09e";
}

.v1-Type-Tool:before {
  content: "\f09f";
}

.v1-Ubuntu:before {
  content: "\f0a0";
}

.v1-Ufo:before {
  content: "\f0a1";
}

.v1-Umbrella:before {
  content: "\f0a2";
}

.v1-Unavailable-1:before {
  content: "\f0a3";
}

.v1-Unavailable-2:before {
  content: "\f0a4";
}

.v1-Unclean-Water:before {
  content: "\f0a5";
}

.v1-Underline:before {
  content: "\f0a6";
}

.v1-Undo:before {
  content: "\f0a7";
}

.v1-Unlock:before {
  content: "\f0a8";
}

.v1-Up-1:before {
  content: "\f0a9";
}

.v1-Up-2:before {
  content: "\f0aa";
}

.v1-Up-3:before {
  content: "\f0ab";
}

.v1-Up-4:before {
  content: "\f0ac";
}

.v1-Up-5:before {
  content: "\f0ad";
}

.v1-Up-6:before {
  content: "\f0ae";
}

.v1-Up-7:before {
  content: "\f0af";
}

.v1-Up-8:before {
  content: "\f0b0";
}

.v1-Up-9:before {
  content: "\f0b1";
}

.v1-Up-10:before {
  content: "\f0b2";
}

.v1-Up-11:before {
  content: "\f0b3";
}

.v1-Up-12:before {
  content: "\f0b4";
}

.v1-Update-Time:before {
  content: "\f0b5";
}

.v1-Upload-Bookmark:before {
  content: "\f0b6";
}

.v1-Upload-Doc:before {
  content: "\f0b7";
}

.v1-Upload-File:before {
  content: "\f0b8";
}

.v1-Upload-Folder:before {
  content: "\f0b9";
}

.v1-Upload-Fond:before {
  content: "\f0ba";
}

.v1-Upload:before {
  content: "\f0bb";
}

.v1-Usb-Modem:before {
  content: "\f0bc";
}

.v1-User-Security1:before {
  content: "\f0bd";
}

.v1-User-Security2:before {
  content: "\f0be";
}

.v1-User:before {
  content: "\f0bf";
}

.v1-Vase:before {
  content: "\f0c0";
}

.v1-Vector-File:before {
  content: "\f0c1";
}

.v1-Vest:before {
  content: "\f0c2";
}

.v1-VHS-Cassette:before {
  content: "\f0c3";
}

.v1-Viddler:before {
  content: "\f0c4";
}

.v1-Video-Disc:before {
  content: "\f0c5";
}

.v1-Video-File:before {
  content: "\f0c6";
}

.v1-Video-Folder2:before {
  content: "\f0c7";
}

.v1-Video-Folder:before {
  content: "\f0c8";
}

.v1-Vimeo:before {
  content: "\f0c9";
}

.v1-Violine-Key:before {
  content: "\f0ca";
}

.v1-Violine:before {
  content: "\f0cb";
}

.v1-Virus-1:before {
  content: "\f0cc";
}

.v1-Virus-2:before {
  content: "\f0cd";
}

.v1-Visa-2:before {
  content: "\f0ce";
}

.v1-Visa:before {
  content: "\f0cf";
}

.v1-VK:before {
  content: "\f0d0";
}

.v1-Vlc-Player:before {
  content: "\f0d1";
}

.v1-Voice-Mail:before {
  content: "\f0d2";
}

.v1-Voley-Ball:before {
  content: "\f0d3";
}

.v1-Volume-10:before {
  content: "\f0d4";
}

.v1-Volume-11:before {
  content: "\f0d5";
}

.v1-Volume-12:before {
  content: "\f0d6";
}

.v1-Volume-13:before {
  content: "\f0d7";
}

.v1-Volume-1Down:before {
  content: "\f0d8";
}

.v1-Volume-1Mute:before {
  content: "\f0d9";
}

.v1-Volume-1Up:before {
  content: "\f0da";
}

.v1-Volume-20:before {
  content: "\f0db";
}

.v1-Volume-21:before {
  content: "\f0dc";
}

.v1-Volume-22:before {
  content: "\f0dd";
}

.v1-Volume-23:before {
  content: "\f0de";
}

.v1-Volume-2Down:before {
  content: "\f0df";
}

.v1-Volume-2Mute:before {
  content: "\f0e0";
}

.v1-Volume-2Up:before {
  content: "\f0e1";
}

.v1-Volume-controler:before {
  content: "\f0e2";
}

.v1-Vomiting:before {
  content: "\f0e3";
}

.v1-Vulverine:before {
  content: "\f0e4";
}

.v1-VW-Bug:before {
  content: "\f0e5";
}

.v1-VW-T1:before {
  content: "\f0e6";
}

.v1-Wach-4:before {
  content: "\f0e7";
}

.v1-Waiter:before {
  content: "\f0e8";
}

.v1-Waitress:before {
  content: "\f0e9";
}

.v1-Wall:before {
  content: "\f0ea";
}

.v1-Wallet-1:before {
  content: "\f0eb";
}

.v1-Wallet-2:before {
  content: "\f0ec";
}

.v1-Wallet-3:before {
  content: "\f0ed";
}

.v1-Watch-1:before {
  content: "\f0ee";
}

.v1-Watch-2:before {
  content: "\f0ef";
}

.v1-Watch-3:before {
  content: "\f0f0";
}

.v1-Water-Tap:before {
  content: "\f0f1";
}

.v1-Watering-can:before {
  content: "\f0f2";
}

.v1-Wattpad:before {
  content: "\f0f3";
}

.v1-Wav-Doc:before {
  content: "\f0f4";
}

.v1-WB-Incandescent:before {
  content: "\f0f5";
}

.v1-WB-Irradescent:before {
  content: "\f0f6";
}

.v1-WB-Suny:before {
  content: "\f0f7";
}

.v1-Wechat:before {
  content: "\f0f8";
}

.v1-Weight-Libra:before {
  content: "\f0f9";
}

.v1-Weight-Lift:before {
  content: "\f0fa";
}

.v1-Weight:before {
  content: "\f0fb";
}

.v1-Whatsapp:before {
  content: "\f0fc";
}

.v1-Wheel-Chair:before {
  content: "\f0fd";
}

.v1-Wheel-Swing:before {
  content: "\f0fe";
}

.v1-Whistle:before {
  content: "\f0ff";
}

.v1-Wierd-1:before {
  content: "\f100";
}

.v1-Wierd-2:before {
  content: "\f101";
}

.v1-Wifi-1:before {
  content: "\f102";
}

.v1-Wifi-2:before {
  content: "\f103";
}

.v1-Wifi-3:before {
  content: "\f104";
}

.v1-Wifi-4:before {
  content: "\f105";
}

.v1-Wifi-Cloud:before {
  content: "\f106";
}

.v1-Wifi-Delay:before {
  content: "\f107";
}

.v1-Wifi-Error1:before {
  content: "\f108";
}

.v1-Wifi-Error2:before {
  content: "\f109";
}

.v1-Wifi-Question:before {
  content: "\f10a";
}

.v1-Wifi-Router:before {
  content: "\f10b";
}

.v1-Wifi-Settings:before {
  content: "\f10c";
}

.v1-Wii-Console:before {
  content: "\f10d";
}

.v1-Wind-Game:before {
  content: "\f10e";
}

.v1-Wind-Turbine:before {
  content: "\f10f";
}

.v1-Wind:before {
  content: "\f110";
}

.v1-Window:before {
  content: "\f111";
}

.v1-Windows-MediaPlayer:before {
  content: "\f112";
}

.v1-Windows:before {
  content: "\f113";
}

.v1-Windshield-Cleaner:before {
  content: "\f114";
}

.v1-Wine-Bottle:before {
  content: "\f115";
}

.v1-Wine-Glass:before {
  content: "\f116";
}

.v1-Wink:before {
  content: "\f117";
}

.v1-Winter-Temperature:before {
  content: "\f118";
}

.v1-Wireles-Headset:before {
  content: "\f119";
}

.v1-Withrow-Fond:before {
  content: "\f11a";
}

.v1-Wma-Doc2:before {
  content: "\f11b";
}

.v1-Wma-Doc:before {
  content: "\f11c";
}

.v1-Wolf:before {
  content: "\f11d";
}

.v1-Wondering:before {
  content: "\f11e";
}

.v1-Word:before {
  content: "\f11f";
}

.v1-Wordpress:before {
  content: "\f120";
}

.v1-Worker:before {
  content: "\f121";
}

.v1-World-ofWarcraft:before {
  content: "\f122";
}

.v1-World-WideWeb:before {
  content: "\f123";
}

.v1-Worms-Armagedon:before {
  content: "\f124";
}

.v1-Wow:before {
  content: "\f125";
}

.v1-Wrap-ImageCenter:before {
  content: "\f126";
}

.v1-Wrap-ImageLeft:before {
  content: "\f127";
}

.v1-Wrap-ImageRight:before {
  content: "\f128";
}

.v1-Wrench-1:before {
  content: "\f129";
}

.v1-Wrench-2:before {
  content: "\f12a";
}

.v1-Wrench-3:before {
  content: "\f12b";
}

.v1-Wrench-4:before {
  content: "\f12c";
}

.v1-X-Eye:before {
  content: "\f12d";
}

.v1-X-Mouth:before {
  content: "\f12e";
}

.v1-Xilophone:before {
  content: "\f12f";
}

.v1-Xing:before {
  content: "\f130";
}

.v1-Xmas-Soks:before {
  content: "\f131";
}

.v1-Xmas-Tag:before {
  content: "\f132";
}

.v1-Yagermeister:before {
  content: "\f133";
}

.v1-Yahoo-Messenger:before {
  content: "\f134";
}

.v1-Yahoo:before {
  content: "\f135";
}

.v1-Yammer:before {
  content: "\f136";
}

.v1-Yelling:before {
  content: "\f137";
}

.v1-You-Rock:before {
  content: "\f138";
}

.v1-Youtube-1:before {
  content: "\f139";
}

.v1-Youtube-2:before {
  content: "\f13a";
}

.v1-Zip-File:before {
  content: "\f13b";
}

.v1-Zip-Folder:before {
  content: "\f13c";
}

.v1-Zipper:before {
  content: "\f13d";
}

.v1-Zombie:before {
  content: "\f13e";
}

.v1-Zoom-In2:before {
  content: "\f13f";
}

.v1-Zoom-In3:before {
  content: "\f140";
}

.v1-Zoom-In:before {
  content: "\f141";
}

.v1-Zoom-Out2:before {
  content: "\f142";
}

.v1-Zoom-Out3:before {
  content: "\f143";
}

.v1-Zoom-Out:before {
  content: "\f144";
}

.v1-Zootool:before {
  content: "\f145";
}

