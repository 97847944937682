@import './variable';

.bg-smoke {
  background: whitesmoke !important;
}

.bg-gray-light {
  background: $gray-light!important;
}

.bg-primary {
  background: $primary !important;
  color: white !important;
}
.bg-primary-light {
  background: $primary-light !important;
  color: white !important;
}
.c-primary-light {
  color: $primary-light !important;
}

.c-primary {
  color: $primary !important;
}

.c-primary-darker {
  color: $primary-darker !important;
}

.c-warn-light {
  color: $warn-light !important;
}

.c-warn {
  color: $warn !important;
}
