@font-face {
  font-family: 'v2';
  src: url('fonts/v2.woff?3tvhdh') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v2-"], [class*=" v2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v2-Israel:before {
  content: "\e900";
}

.v2-year-aniversary-23:before {
  content: "\e901";
}

.v2-year-aniversary3:before {
  content: "\e902";
}

.v2-st-Place:before {
  content: "\e903";
}

.v2-nd-Place:before {
  content: "\e904";
}

.v2-rd-Place:before {
  content: "\e905";
}

.v2-Stars:before {
  content: "\e906";
}

.v2-year-aniversary-2:before {
  content: "\e907";
}

.v2-year-aniversary:before {
  content: "\e908";
}

.v2-year-aniversary-22:before {
  content: "\e909";
}

.v2-year-aniversary2:before {
  content: "\e90a";
}

.v2-Accessibility:before {
  content: "\e90b";
}

.v2-Acorn:before {
  content: "\e90c";
}

.v2-Add-App:before {
  content: "\e90d";
}

.v2-Add-Award-Badge:before {
  content: "\e90e";
}

.v2-Add-Heart:before {
  content: "\e90f";
}

.v2-Add-Rating:before {
  content: "\e910";
}

.v2-Addon:before {
  content: "\e911";
}

.v2-Air-balloon:before {
  content: "\e912";
}

.v2-Air-condition:before {
  content: "\e913";
}

.v2-Airplane:before {
  content: "\e914";
}

.v2-Airport:before {
  content: "\e915";
}

.v2-Allert-app:before {
  content: "\e916";
}

.v2-Allow-Copying:before {
  content: "\e917";
}

.v2-Allow-Redistribution:before {
  content: "\e918";
}

.v2-Almond:before {
  content: "\e919";
}

.v2-Analitics:before {
  content: "\e91a";
}

.v2-Ananas:before {
  content: "\e91b";
}

.v2-Anchor:before {
  content: "\e91c";
}

.v2-Angle-Ruler:before {
  content: "\e91d";
}

.v2-App-1:before {
  content: "\e91e";
}

.v2-APP-2:before {
  content: "\e91f";
}

.v2-App-Error:before {
  content: "\e920";
}

.v2-App-Settings:before {
  content: "\e921";
}

.v2-App-Tile-Task:before {
  content: "\e922";
}

.v2-App-Window:before {
  content: "\e923";
}

.v2-Apple:before {
  content: "\e924";
}

.v2-Application-User:before {
  content: "\e925";
}

.v2-Application:before {
  content: "\e926";
}

.v2-Arc-de-Triomphe:before {
  content: "\e927";
}

.v2-Architecture:before {
  content: "\e928";
}

.v2-Arrived:before {
  content: "\e929";
}

.v2-Arriving:before {
  content: "\e92a";
}

.v2-Artichoke:before {
  content: "\e92b";
}

.v2-Asparagus:before {
  content: "\e92c";
}

.v2-Attach-App:before {
  content: "\e92d";
}

.v2-Attention:before {
  content: "\e92e";
}

.v2-Avocado:before {
  content: "\e92f";
}

.v2-Award-1:before {
  content: "\e930";
}

.v2-Award-2:before {
  content: "\e931";
}

.v2-Award-Badge-Shopping:before {
  content: "\e932";
}

.v2-Award-Stamp-2:before {
  content: "\e933";
}

.v2-Award-Stamp:before {
  content: "\e934";
}

.v2-Axe:before {
  content: "\e935";
}

.v2-Badge-Star:before {
  content: "\e936";
}

.v2-Bag-conveyer:before {
  content: "\e937";
}

.v2-Bag-weight:before {
  content: "\e938";
}

.v2-Bag:before {
  content: "\e939";
}

.v2-Bakelite:before {
  content: "\e93a";
}

.v2-Ball:before {
  content: "\e93b";
}

.v2-Banana:before {
  content: "\e93c";
}

.v2-Bank-1:before {
  content: "\e93d";
}

.v2-Bank-2:before {
  content: "\e93e";
}

.v2-Barber-Shop:before {
  content: "\e93f";
}

/*.v2-Barber's-Razor:before {*/
/*content: "\e940";*/
/*}*/
.v2-Barcode-App:before {
  content: "\e941";
}

.v2-Barn:before {
  content: "\e942";
}

.v2-Barrow:before {
  content: "\e943";
}

.v2-Basketball-Cup:before {
  content: "\e944";
}

.v2-Beard-1:before {
  content: "\e945";
}

.v2-Beard-2:before {
  content: "\e946";
}

.v2-Beard-3:before {
  content: "\e947";
}

.v2-Beard-4:before {
  content: "\e948";
}

.v2-Berlin-TV-Tower:before {
  content: "\e949";
}

.v2-Bicycle-1:before {
  content: "\e94a";
}

.v2-Bicycle-2:before {
  content: "\e94b";
}

.v2-Big-Ben:before {
  content: "\e94c";
}

.v2-Binar-Code-2:before {
  content: "\e94d";
}

.v2-Binar-Code:before {
  content: "\e94e";
}

.v2-Bishop-1:before {
  content: "\e94f";
}

.v2-Bishop-2:before {
  content: "\e950";
}

.v2-Bitcoin-App:before {
  content: "\e951";
}

.v2-Blueberries:before {
  content: "\e952";
}

.v2-Bologna:before {
  content: "\e953";
}

.v2-Booklet-Female-1:before {
  content: "\e954";
}

.v2-Booklet-Female-2:before {
  content: "\e955";
}

.v2-Booklet-Male-1:before {
  content: "\e956";
}

.v2-Booklet-Male-2:before {
  content: "\e957";
}

.v2-Bookmark-App:before {
  content: "\e958";
}

.v2-Bow-1:before {
  content: "\e959";
}

.v2-Bow-2:before {
  content: "\e95a";
}

.v2-Brick:before {
  content: "\e95b";
}

.v2-Bridge-1:before {
  content: "\e95c";
}

.v2-Bridge-2:before {
  content: "\e95d";
}

.v2-Broccoli:before {
  content: "\e95e";
}

.v2-Bucket:before {
  content: "\e95f";
}

.v2-Bug-Fix:before {
  content: "\e960";
}

.v2-Bug:before {
  content: "\e961";
}

.v2-BullDozer:before {
  content: "\e962";
}

.v2-Burj-Khalifa-Dubai:before {
  content: "\e963";
}

.v2-Bus-Station:before {
  content: "\e964";
}

.v2-C-plus-plus-1:before {
  content: "\e965";
}

.v2-C-plus-plus-2:before {
  content: "\e966";
}

.v2-Cabbage:before {
  content: "\e967";
}

.v2-Calippers:before {
  content: "\e968";
}

.v2-Camera:before {
  content: "\e969";
}

.v2-Caravan:before {
  content: "\e96a";
}

.v2-Carrot:before {
  content: "\e96b";
}

.v2-Cashew:before {
  content: "\e96c";
}

.v2-Castle:before {
  content: "\e96d";
}

.v2-Castte:before {
  content: "\e96e";
}

.v2-Cement-Trovel:before {
  content: "\e96f";
}

.v2-Cemetery:before {
  content: "\e970";
}

.v2-Central-Television-Beijing:before {
  content: "\e971";
}

.v2-Chain-Sawe:before {
  content: "\e972";
}

.v2-Chat-App:before {
  content: "\e973";
}

.v2-Cherry-Tomato:before {
  content: "\e974";
}

.v2-Cherry:before {
  content: "\e975";
}

.v2-Chess-Board:before {
  content: "\e976";
}

.v2-Chess-Clock:before {
  content: "\e977";
}

.v2-Chili:before {
  content: "\e978";
}

.v2-Chinese-Lettuce:before {
  content: "\e979";
}

.v2-Church-1:before {
  content: "\e97a";
}

.v2-Church-2:before {
  content: "\e97b";
}

.v2-Circle-Star:before {
  content: "\e97c";
}

.v2-Clean-Code:before {
  content: "\e97d";
}

.v2-Clear-Rating:before {
  content: "\e97e";
}

.v2-Clipboard:before {
  content: "\e97f";
}

.v2-Cloud-App:before {
  content: "\e980";
}

.v2-Clove:before {
  content: "\e981";
}

.v2-Cocktail:before {
  content: "\e982";
}

.v2-Cocoa-Pod:before {
  content: "\e983";
}

.v2-Coconut:before {
  content: "\e984";
}

.v2-Code-1:before {
  content: "\e985";
}

.v2-Code-2:before {
  content: "\e986";
}

.v2-Code-Doc:before {
  content: "\e987";
}

.v2-Colosseum:before {
  content: "\e988";
}

.v2-Comb-1:before {
  content: "\e989";
}

.v2-Comb-2:before {
  content: "\e98a";
}

.v2-Compass:before {
  content: "\e98b";
}

.v2-Computer-App:before {
  content: "\e98c";
}

.v2-Computer-User:before {
  content: "\e98d";
}

.v2-Connecting-Users:before {
  content: "\e98e";
}

.v2-Content-View:before {
  content: "\e98f";
}

.v2-Cop-Badge:before {
  content: "\e990";
}

.v2-Copyright-App:before {
  content: "\e991";
}

.v2-Copyright-Doc:before {
  content: "\e992";
}

.v2-Copyright:before {
  content: "\e993";
}

.v2-Crain-Hook:before {
  content: "\e994";
}

.v2-Crain:before {
  content: "\e995";
}

.v2-Creative-Commun-doc:before {
  content: "\e996";
}

.v2-Creative-Commun:before {
  content: "\e997";
}

.v2-Css-2:before {
  content: "\e998";
}

.v2-css-3:before {
  content: "\e999";
}

.v2-Css1:before {
  content: "\e99a";
}

.v2-Cup-2:before {
  content: "\e99b";
}

.v2-Cup-3:before {
  content: "\e99c";
}

.v2-Cut:before {
  content: "\e99d";
}

.v2-Cutlery:before {
  content: "\e99e";
}

.v2-Cutter:before {
  content: "\e99f";
}

.v2-Database:before {
  content: "\e9a0";
}

.v2-Deer:before {
  content: "\e9a1";
}

.v2-Departed:before {
  content: "\e9a2";
}

.v2-Departing:before {
  content: "\e9a3";
}

.v2-Detail-View:before {
  content: "\e9a4";
}

.v2-Detailes-Pane:before {
  content: "\e9a5";
}

.v2-Diploma:before {
  content: "\e9a6";
}

.v2-Disabled-man:before {
  content: "\e9a7";
}

.v2-Disc-Cutter:before {
  content: "\e9a8";
}

.v2-Discount-Sticker:before {
  content: "\e9a9";
}

.v2-Dislike:before {
  content: "\e9aa";
}

.v2-Dismiss-App:before {
  content: "\e9ab";
}

.v2-Dismiss-Award-Badge:before {
  content: "\e9ac";
}

.v2-Dismiss-Heart:before {
  content: "\e9ad";
}

.v2-Dismiss-Rating:before {
  content: "\e9ae";
}

.v2-Diving-goggles:before {
  content: "\e9af";
}

.v2-Dog-Tag:before {
  content: "\e9b0";
}

.v2-Dollar-App:before {
  content: "\e9b1";
}

.v2-Done-App:before {
  content: "\e9b2";
}

.v2-Done-Award-Badge:before {
  content: "\e9b3";
}

.v2-Done-Heart:before {
  content: "\e9b4";
}

.v2-Done-Rating:before {
  content: "\e9b5";
}

.v2-Door:before {
  content: "\e9b6";
}

.v2-Download-App:before {
  content: "\e9b7";
}

.v2-Download:before {
  content: "\e9b8";
}

.v2-Drag-and-Drop:before {
  content: "\e9b9";
}

.v2-Drilling-Machine-2:before {
  content: "\e9ba";
}

.v2-Drilling-Machine:before {
  content: "\e9bb";
}

.v2-Drive-App:before {
  content: "\e9bc";
}

.v2-Driver-Licence-Female:before {
  content: "\e9bd";
}

.v2-Driver-License-Male:before {
  content: "\e9be";
}

.v2-Dropbox-App:before {
  content: "\e9bf";
}

.v2-Dubai:before {
  content: "\e9c0";
}

.v2-Durian:before {
  content: "\e9c1";
}

.v2-Earth-Map:before {
  content: "\e9c2";
}

.v2-Easter-Island:before {
  content: "\e9c3";
}

.v2-Eat-Heart:before {
  content: "\e9c4";
}

.v2-Eating-Award-Badge:before {
  content: "\e9c5";
}

.v2-Edit-App:before {
  content: "\e9c6";
}

.v2-Eggplant:before {
  content: "\e9c7";
}

.v2-Egypt-Pyramid:before {
  content: "\e9c8";
}

.v2-Eiffel-Tower:before {
  content: "\e9c9";
}

.v2-Elevator:before {
  content: "\e9ca";
}

.v2-Email-App-1:before {
  content: "\e9cb";
}

.v2-Email-App-2:before {
  content: "\e9cc";
}

.v2-Empire-State-Building:before {
  content: "\e9cd";
}

.v2-Empty-Star:before {
  content: "\e9ce";
}

.v2-Equalizer:before {
  content: "\e9cf";
}

.v2-Escalator-down:before {
  content: "\e9d0";
}

.v2-Escalator-up:before {
  content: "\e9d1";
}

.v2-Euro-App:before {
  content: "\e9d2";
}

.v2-Exhibition:before {
  content: "\e9d3";
}

.v2-Exit-Full-Screen:before {
  content: "\e9d4";
}

.v2-Exit:before {
  content: "\e9d5";
}

.v2-Factory:before {
  content: "\e9d6";
}

.v2-Favorite-App-1:before {
  content: "\e9d7";
}

.v2-Favorite-App:before {
  content: "\e9d8";
}

.v2-Favorite-Restaurant:before {
  content: "\e9d9";
}

.v2-Female-Circle-1:before {
  content: "\e9da";
}

.v2-Female-Circle-2:before {
  content: "\e9db";
}

.v2-Female-Group-1:before {
  content: "\e9dc";
}

.v2-Female-Group-2:before {
  content: "\e9dd";
}

.v2-Fennel:before {
  content: "\e9de";
}

.v2-Ferris-Wheel:before {
  content: "\e9df";
}

.v2-Figs:before {
  content: "\e9e0";
}

.v2-Fire-Station:before {
  content: "\e9e1";
}

.v2-Firewall:before {
  content: "\e9e2";
}

.v2-First-ad:before {
  content: "\e9e3";
}

.v2-Flashlight:before {
  content: "\e9e4";
}

.v2-Flippers:before {
  content: "\e9e5";
}

.v2-Flow-Chart-4:before {
  content: "\e9e6";
}

.v2-Flow-Chat-1:before {
  content: "\e9e7";
}

.v2-Flow-Chat-2:before {
  content: "\e9e8";
}

.v2-Folder-Female:before {
  content: "\e9e9";
}

.v2-Folder-Male:before {
  content: "\e9ea";
}

.v2-Footer:before {
  content: "\e9eb";
}

.v2-Fork-Lift:before {
  content: "\e9ec";
}

.v2-Fountain:before {
  content: "\e9ed";
}

.v2-Fow-Chat-3:before {
  content: "\e9ee";
}

.v2-Fragile:before {
  content: "\e9ef";
}

.v2-Free-Sticker:before {
  content: "\e9f0";
}

.v2-Fuel-Station:before {
  content: "\e9f1";
}

.v2-Full-Screen:before {
  content: "\e9f2";
}

.v2-Full-Star:before {
  content: "\e9f3";
}

.v2-Gallery-App:before {
  content: "\e9f4";
}

.v2-Gallery-View:before {
  content: "\e9f5";
}

.v2-Game-App:before {
  content: "\e9f6";
}

.v2-Garlic:before {
  content: "\e9f7";
}

.v2-Ginger:before {
  content: "\e9f8";
}

.v2-Glasses-1:before {
  content: "\e9f9";
}

.v2-Glasses-2:before {
  content: "\e9fa";
}

.v2-Glasses-3:before {
  content: "\e9fb";
}

.v2-Glasses-4:before {
  content: "\e9fc";
}

.v2-Glasses-5:before {
  content: "\e9fd";
}

.v2-Global-Award-Badge:before {
  content: "\e9fe";
}

.v2-Global-Heart:before {
  content: "\e9ff";
}

.v2-Global-Rating:before {
  content: "\ea00";
}

.v2-Global-User:before {
  content: "\ea01";
}

.v2-Global:before {
  content: "\ea02";
}

.v2-Golden-Gate-Bridge:before {
  content: "\ea03";
}

.v2-Golden-Globe:before {
  content: "\ea04";
}

.v2-Grain:before {
  content: "\ea05";
}

.v2-Grape:before {
  content: "\ea06";
}

.v2-Great-Wall:before {
  content: "\ea07";
}

.v2-Grid-1:before {
  content: "\ea08";
}

.v2-Grid-2:before {
  content: "\ea09";
}

.v2-Group-1:before {
  content: "\ea0a";
}

.v2-Group-2:before {
  content: "\ea0b";
}

.v2-Group-Defense:before {
  content: "\ea0c";
}

.v2-Group-Download:before {
  content: "\ea0d";
}

.v2-Group-Shopping:before {
  content: "\ea0e";
}

.v2-Group-Statistics:before {
  content: "\ea0f";
}

.v2-Group-Upload:before {
  content: "\ea10";
}

.v2-Hair-Band:before {
  content: "\ea11";
}

.v2-Half-Star:before {
  content: "\ea12";
}

.v2-Hammer:before {
  content: "\ea13";
}

.v2-Hand-washing:before {
  content: "\ea14";
}

.v2-Hanger:before {
  content: "\ea15";
}

.v2-Haricot:before {
  content: "\ea16";
}

.v2-Hat-1:before {
  content: "\ea17";
}

.v2-Hat-2:before {
  content: "\ea18";
}

.v2-Hat-3:before {
  content: "\ea19";
}

.v2-Hat-4:before {
  content: "\ea1a";
}

.v2-Hat-Portrait-Frame:before {
  content: "\ea1b";
}

.v2-Hazelnut:before {
  content: "\ea1c";
}

.v2-Header:before {
  content: "\ea1d";
}

.v2-Headset:before {
  content: "\ea1e";
}

.v2-Heart-Flag:before {
  content: "\ea1f";
}

.v2-Heart-Full:before {
  content: "\ea20";
}

.v2-Heart-Half:before {
  content: "\ea21";
}

.v2-Heart-Shopping:before {
  content: "\ea22";
}

.v2-Heart-Waiting:before {
  content: "\ea23";
}

.v2-Hearts-Empty:before {
  content: "\ea24";
}

.v2-Heavy:before {
  content: "\ea25";
}

.v2-Helmet:before {
  content: "\ea26";
}

.v2-High-voltage:before {
  content: "\ea27";
}

.v2-Hoist:before {
  content: "\ea28";
}

.v2-Hoist2:before {
  content: "\ea29";
}

.v2-Hold-Heart:before {
  content: "\ea2a";
}

.v2-Hold-Male:before {
  content: "\ea2b";
}

.v2-Hold-Star:before {
  content: "\ea2c";
}

.v2-Home-App:before {
  content: "\ea2d";
}

.v2-Home:before {
  content: "\ea2e";
}

.v2-Honeymelon:before {
  content: "\ea2f";
}

.v2-Hospital:before {
  content: "\ea30";
}

.v2-Hot-drink:before {
  content: "\ea31";
}

.v2-Hot-Sticker:before {
  content: "\ea32";
}

.v2-Hotel-1:before {
  content: "\ea33";
}

.v2-Hotel-2:before {
  content: "\ea34";
}

.v2-Hotel-3:before {
  content: "\ea35";
}

.v2-Hotel-4:before {
  content: "\ea36";
}

.v2-House-1:before {
  content: "\ea37";
}

.v2-House-2:before {
  content: "\ea38";
}

.v2-House-3:before {
  content: "\ea39";
}

.v2-House-4:before {
  content: "\ea3a";
}

.v2-House-5:before {
  content: "\ea3b";
}

.v2-House-6:before {
  content: "\ea3c";
}

.v2-House-7:before {
  content: "\ea3d";
}

.v2-House-8:before {
  content: "\ea3e";
}

.v2-House-9:before {
  content: "\ea3f";
}

.v2-Html-1:before {
  content: "\ea40";
}

.v2-Html-2:before {
  content: "\ea41";
}

.v2-html-5:before {
  content: "\ea42";
}

.v2-http-1:before {
  content: "\ea43";
}

.v2-Http-2:before {
  content: "\ea44";
}

.v2-ID-Female:before {
  content: "\ea45";
}

.v2-ID-Male:before {
  content: "\ea46";
}

.v2-ID-Photo:before {
  content: "\ea47";
}

.v2-Information-booklet:before {
  content: "\ea48";
}

.v2-Information:before {
  content: "\ea49";
}

.v2-Ironing-1:before {
  content: "\ea4a";
}

.v2-Ironing-2:before {
  content: "\ea4b";
}

.v2-Jack-Hammer:before {
  content: "\ea4c";
}

.v2-Java-1:before {
  content: "\ea4d";
}

.v2-Java-2:before {
  content: "\ea4e";
}

.v2-Java-Script:before {
  content: "\ea4f";
}

.v2-Jet-ski:before {
  content: "\ea50";
}

.v2-Jsx-1:before {
  content: "\ea51";
}

.v2-Jsx-2:before {
  content: "\ea52";
}

.v2-Kenu:before {
  content: "\ea53";
}

.v2-Kilimanjaro:before {
  content: "\ea54";
}

.v2-King-1:before {
  content: "\ea55";
}

.v2-King-2:before {
  content: "\ea56";
}

.v2-Kiwi:before {
  content: "\ea57";
}

.v2-Knight-1:before {
  content: "\ea58";
}

.v2-Knight-2:before {
  content: "\ea59";
}

.v2-Ladder:before {
  content: "\ea5a";
}

.v2-Ladies-Bag:before {
  content: "\ea5b";
}

.v2-Large-v2s-View:before {
  content: "\ea5c";
}

.v2-Left-items:before {
  content: "\ea5d";
}

.v2-Left-Sidebar:before {
  content: "\ea5e";
}

.v2-Lemon-1:before {
  content: "\ea5f";
}

.v2-Lemon-2:before {
  content: "\ea60";
}

.v2-Letter:before {
  content: "\ea61";
}

.v2-Lettuce:before {
  content: "\ea62";
}

.v2-Life-jacket:before {
  content: "\ea63";
}

.v2-Lighthouse:before {
  content: "\ea64";
}

.v2-Like:before {
  content: "\ea65";
}

.v2-Lips:before {
  content: "\ea66";
}

.v2-List-View:before {
  content: "\ea67";
}

.v2-Load-App:before {
  content: "\ea68";
}

.v2-Lock-App-2:before {
  content: "\ea69";
}

.v2-Lock-App:before {
  content: "\ea6a";
}

.v2-Lock-Award-Badge:before {
  content: "\ea6b";
}

.v2-Lock-Group:before {
  content: "\ea6c";
}

.v2-Lock-Heart:before {
  content: "\ea6d";
}

.v2-Lock-Rating:before {
  content: "\ea6e";
}

.v2-Lollipop-1:before {
  content: "\ea6f";
}

.v2-Lollipop-2:before {
  content: "\ea70";
}

.v2-Luggage-keeping:before {
  content: "\ea71";
}

.v2-Luggage-weight:before {
  content: "\ea72";
}

.v2-Machu-Picchu:before {
  content: "\ea73";
}

.v2-Mail-App:before {
  content: "\ea74";
}

.v2-Maize:before {
  content: "\ea75";
}

.v2-Male-and-Female-1:before {
  content: "\ea76";
}

.v2-Male-and-Female-2:before {
  content: "\ea77";
}

.v2-Male-Circle-1:before {
  content: "\ea78";
}

.v2-Male-Circle-2:before {
  content: "\ea79";
}

.v2-Male-Group-1:before {
  content: "\ea7a";
}

.v2-Male-Group-2:before {
  content: "\ea7b";
}

.v2-Man-with-Briefcase:before {
  content: "\ea7c";
}

.v2-Man:before {
  content: "\ea7d";
}

.v2-Mango:before {
  content: "\ea7e";
}

.v2-Map:before {
  content: "\ea7f";
}

.v2-Mark-Bubble-1:before {
  content: "\ea80";
}

.v2-Mark-Bubble-2:before {
  content: "\ea81";
}

.v2-Mark-Bubble-3:before {
  content: "\ea82";
}

.v2-Mark-Group:before {
  content: "\ea83";
}

.v2-Maximize:before {
  content: "\ea84";
}

.v2-Medal-1:before {
  content: "\ea85";
}

.v2-Medal-2:before {
  content: "\ea86";
}

.v2-Medal-3:before {
  content: "\ea87";
}

.v2-Medal-4:before {
  content: "\ea88";
}

.v2-Medal-5:before {
  content: "\ea89";
}

.v2-Medium-v2s-View:before {
  content: "\ea8a";
}

.v2-Melon:before {
  content: "\ea8b";
}

.v2-Military-Award-1:before {
  content: "\ea8c";
}

.v2-Military-Award-2:before {
  content: "\ea8d";
}

.v2-Military-Award-3:before {
  content: "\ea8e";
}

.v2-Military-Award-4:before {
  content: "\ea8f";
}

.v2-Militay-Shield:before {
  content: "\ea90";
}

.v2-Minimize-2:before {
  content: "\ea91";
}

.v2-Minimize:before {
  content: "\ea92";
}

.v2-Mosque:before {
  content: "\ea93";
}

.v2-Mountains:before {
  content: "\ea94";
}

.v2-Mouse-App:before {
  content: "\ea95";
}

.v2-Mouve-Piece:before {
  content: "\ea96";
}

.v2-Multy-Task-2:before {
  content: "\ea97";
}

.v2-Multy-Task:before {
  content: "\ea98";
}

.v2-Museum:before {
  content: "\ea99";
}

.v2-Mushroom-1:before {
  content: "\ea9a";
}

.v2-Mushroom-2:before {
  content: "\ea9b";
}

.v2-Music-App:before {
  content: "\ea9c";
}

.v2-Mustache-1:before {
  content: "\ea9d";
}

.v2-MySql-1:before {
  content: "\ea9e";
}

.v2-MySql-2:before {
  content: "\ea9f";
}

.v2-MySQL-Database:before {
  content: "\eaa0";
}

.v2-Name-Plate-Female-1:before {
  content: "\eaa1";
}

.v2-Name-Plate-Female-2:before {
  content: "\eaa2";
}

.v2-Name-Plate-Female-3:before {
  content: "\eaa3";
}

.v2-Name-Plate-Male-1:before {
  content: "\eaa4";
}

.v2-Name-Plate-Male-2:before {
  content: "\eaa5";
}

.v2-Name-Plate-Male-3:before {
  content: "\eaa6";
}

.v2-Nativity-Facade-Barcelona:before {
  content: "\eaa7";
}

.v2-Network-App:before {
  content: "\eaa8";
}

.v2-New-Sticker:before {
  content: "\eaa9";
}

.v2-No-air-condition:before {
  content: "\eaaa";
}

.v2-No-bleaching:before {
  content: "\eaab";
}

.v2-No-Copyright-Doc:before {
  content: "\eaac";
}

.v2-No-Copyright:before {
  content: "\eaad";
}

.v2-No-Creative-Commun:before {
  content: "\eaae";
}

.v2-No-cut:before {
  content: "\eaaf";
}

.v2-No-dogs:before {
  content: "\eab0";
}

.v2-No-fishing:before {
  content: "\eab1";
}

.v2-No-food:before {
  content: "\eab2";
}

.v2-No-hoist:before {
  content: "\eab3";
}

.v2-No-icecream:before {
  content: "\eab4";
}

.v2-No-ironing:before {
  content: "\eab5";
}

.v2-No-pack-up:before {
  content: "\eab6";
}

.v2-No-phone:before {
  content: "\eab7";
}

.v2-No-Resel:before {
  content: "\eab8";
}

.v2-No-resell-Doc:before {
  content: "\eab9";
}

.v2-No-shower:before {
  content: "\eaba";
}

.v2-No-smoking:before {
  content: "\eabb";
}

.v2-No-step:before {
  content: "\eabc";
}

.v2-No-stream:before {
  content: "\eabd";
}

.v2-No-swimming:before {
  content: "\eabe";
}

.v2-No-touch:before {
  content: "\eabf";
}

.v2-No-wet-ironing:before {
  content: "\eac0";
}

.v2-No-wetting:before {
  content: "\eac1";
}

.v2-Nophoto:before {
  content: "\eac2";
}

.v2-Not-Arrived:before {
  content: "\eac3";
}

.v2-Not-Departed:before {
  content: "\eac4";
}

.v2-Not-drinking-water:before {
  content: "\eac5";
}

.v2-Not-loud:before {
  content: "\eac6";
}

.v2-Nuclear-Reactor:before {
  content: "\eac7";
}

.v2-Nuclear:before {
  content: "\eac8";
}

.v2-Obelisc:before {
  content: "\eac9";
}

.v2-Office-1:before {
  content: "\eaca";
}

.v2-Office-2:before {
  content: "\eacb";
}

.v2-Office-3:before {
  content: "\eacc";
}

.v2-Office-4:before {
  content: "\eacd";
}

.v2-Office-5:before {
  content: "\eace";
}

.v2-Office-6:before {
  content: "\eacf";
}

.v2-Old-camera-1:before {
  content: "\ead0";
}

.v2-Old-camera-2:before {
  content: "\ead1";
}

.v2-Old-Sticky-1:before {
  content: "\ead2";
}

.v2-Old-Sticky-2:before {
  content: "\ead3";
}

.v2-Old-Sticky-3:before {
  content: "\ead4";
}

.v2-Old-Telephone:before {
  content: "\ead5";
}

.v2-Olive:before {
  content: "\ead6";
}

.v2-Onion:before {
  content: "\ead7";
}

.v2-Oscar:before {
  content: "\ead8";
}

.v2-Pack-up:before {
  content: "\ead9";
}

.v2-Pagoda-Myanmar:before {
  content: "\eada";
}

.v2-Pagoda:before {
  content: "\eadb";
}

.v2-Paifang-China:before {
  content: "\eadc";
}

.v2-Paint-Brush:before {
  content: "\eadd";
}

.v2-Paint-Bucket:before {
  content: "\eade";
}

.v2-Paint-Roller:before {
  content: "\eadf";
}

.v2-Palm-tree:before {
  content: "\eae0";
}

.v2-Pantheon:before {
  content: "\eae1";
}

.v2-Papaya:before {
  content: "\eae2";
}

.v2-Park:before {
  content: "\eae3";
}

.v2-Party-Glasses:before {
  content: "\eae4";
}

.v2-Passport:before {
  content: "\eae5";
}

.v2-Pawn-1:before {
  content: "\eae6";
}

.v2-Pawn-2:before {
  content: "\eae7";
}

.v2-Pay-Doc:before {
  content: "\eae8";
}

.v2-Pea:before {
  content: "\eae9";
}

.v2-Peach-1:before {
  content: "\eaea";
}

.v2-Peach-2:before {
  content: "\eaeb";
}

.v2-Peanut:before {
  content: "\eaec";
}

.v2-Pear:before {
  content: "\eaed";
}

.v2-Pecan:before {
  content: "\eaee";
}

.v2-Pentagon:before {
  content: "\eaef";
}

.v2-Pepper:before {
  content: "\eaf0";
}

.v2-Personal-Award-Badge:before {
  content: "\eaf1";
}

.v2-Personal-Heart:before {
  content: "\eaf2";
}

.v2-Personal-Use:before {
  content: "\eaf3";
}

.v2-Pesronal-Use-Doc:before {
  content: "\eaf4";
}

.v2-Phone-User:before {
  content: "\eaf5";
}

.v2-php-1:before {
  content: "\eaf6";
}

.v2-PHP-2:before {
  content: "\eaf7";
}

.v2-Pin-App:before {
  content: "\eaf8";
}

.v2-Pipe-1:before {
  content: "\eaf9";
}

.v2-Pipe-2:before {
  content: "\eafa";
}

.v2-Pisa:before {
  content: "\eafb";
}

.v2-Pistachio:before {
  content: "\eafc";
}

.v2-Playground:before {
  content: "\eafd";
}

.v2-Pliers:before {
  content: "\eafe";
}

.v2-Plugin:before {
  content: "\eaff";
}

.v2-Plum:before {
  content: "\eb00";
}

.v2-Pocket-Watch:before {
  content: "\eb01";
}

.v2-Podium:before {
  content: "\eb02";
}

.v2-Police:before {
  content: "\eb03";
}

.v2-Pomegranate:before {
  content: "\eb04";
}

.v2-Port:before {
  content: "\eb05";
}

.v2-Portrait-Frame:before {
  content: "\eb06";
}

.v2-Post-It:before {
  content: "\eb07";
}

.v2-Post-Office:before {
  content: "\eb08";
}

.v2-Potato:before {
  content: "\eb09";
}

.v2-Presentation-1:before {
  content: "\eb0a";
}

.v2-Presentation-2:before {
  content: "\eb0b";
}

.v2-Presentation-3:before {
  content: "\eb0c";
}

.v2-Preview-Pane:before {
  content: "\eb0d";
}

.v2-Profile-App:before {
  content: "\eb0e";
}

.v2-Profile-Cardiograph:before {
  content: "\eb0f";
}

.v2-Profile-Economy:before {
  content: "\eb10";
}

.v2-Profile-Favourites:before {
  content: "\eb11";
}

.v2-Profile-Head:before {
  content: "\eb12";
}

.v2-Profile-History:before {
  content: "\eb13";
}

.v2-Profile-Lightbulb:before {
  content: "\eb14";
}

.v2-Profile-Music:before {
  content: "\eb15";
}

.v2-Profile-Settings:before {
  content: "\eb16";
}

.v2-Profile-Star:before {
  content: "\eb17";
}

.v2-Profile-Voltage:before {
  content: "\eb18";
}

.v2-Profile:before {
  content: "\eb19";
}

.v2-Programing:before {
  content: "\eb1a";
}

.v2-Property-Rights:before {
  content: "\eb1b";
}

.v2-Public-App:before {
  content: "\eb1c";
}

.v2-Public-Domain:before {
  content: "\eb1d";
}

.v2-Pumpkin:before {
  content: "\eb1e";
}

.v2-Puond-App:before {
  content: "\eb1f";
}

.v2-Queen-1:before {
  content: "\eb20";
}

.v2-Queen-2:before {
  content: "\eb21";
}

.v2-Question-App:before {
  content: "\eb22";
}

.v2-Radio:before {
  content: "\eb23";
}

.v2-Radish:before {
  content: "\eb24";
}

.v2-Raspberry:before {
  content: "\eb25";
}

.v2-Razor-Blade:before {
  content: "\eb26";
}

.v2-Razor:before {
  content: "\eb27";
}

.v2-Recording-App:before {
  content: "\eb28";
}

.v2-Rectangle-Star:before {
  content: "\eb29";
}

.v2-Refresh-App:before {
  content: "\eb2a";
}

.v2-Registered-Mark:before {
  content: "\eb2b";
}

.v2-Reload-App:before {
  content: "\eb2c";
}

.v2-Remouve-App:before {
  content: "\eb2d";
}

.v2-Remouve-Rating:before {
  content: "\eb2e";
}

.v2-Remove-Award-Badge:before {
  content: "\eb2f";
}

.v2-Remove-Heart:before {
  content: "\eb30";
}

.v2-Restore:before {
  content: "\eb31";
}

.v2-Retina-Scann-App:before {
  content: "\eb32";
}

.v2-Retro-Perfume:before {
  content: "\eb33";
}

.v2-Rialto-Venice:before {
  content: "\eb34";
}

.v2-Ribbon:before {
  content: "\eb35";
}

.v2-Right-Sidebar:before {
  content: "\eb36";
}

.v2-Rio-de-Jan:before {
  content: "\eb37";
}

.v2-Road-Roller:before {
  content: "\eb38";
}

.v2-Rode-Signe:before {
  content: "\eb39";
}

.v2-Rook-1:before {
  content: "\eb3a";
}

.v2-Rook-2:before {
  content: "\eb3b";
}

.v2-Route-66:before {
  content: "\eb3c";
}

.v2-Rudder:before {
  content: "\eb3d";
}

.v2-Sailboat:before {
  content: "\eb3e";
}

.v2-Sate-Sticker:before {
  content: "\eb3f";
}

.v2-Saw:before {
  content: "\eb40";
}

.v2-School:before {
  content: "\eb41";
}

.v2-Scissors:before {
  content: "\eb42";
}

.v2-Search-App-1:before {
  content: "\eb43";
}

.v2-Search-App-2:before {
  content: "\eb44";
}

.v2-Search-Code:before {
  content: "\eb45";
}

.v2-Search-Female:before {
  content: "\eb46";
}

.v2-Search-Male:before {
  content: "\eb47";
}

.v2-Search-Within-App:before {
  content: "\eb48";
}

.v2-Searching-Group:before {
  content: "\eb49";
}

.v2-Security-gate:before {
  content: "\eb4a";
}

.v2-Sfinx:before {
  content: "\eb4b";
}

.v2-Shanghai-TV-Tower:before {
  content: "\eb4c";
}

.v2-Share-App-1:before {
  content: "\eb4d";
}

.v2-Share-App:before {
  content: "\eb4e";
}

.v2-Share-with-Group:before {
  content: "\eb4f";
}

.v2-Sharring-Rights:before {
  content: "\eb50";
}

.v2-Shaving-Brush:before {
  content: "\eb51";
}

.v2-Sherif-Star:before {
  content: "\eb52";
}

.v2-Shoe-1:before {
  content: "\eb53";
}

.v2-Shoe-2:before {
  content: "\eb54";
}

.v2-Shop-Rating:before {
  content: "\eb55";
}

.v2-Shop:before {
  content: "\eb56";
}

.v2-Shovel:before {
  content: "\eb57";
}

.v2-Signpost:before {
  content: "\eb58";
}

.v2-Sillence:before {
  content: "\eb59";
}

.v2-Single-Female:before {
  content: "\eb5a";
}

.v2-Single-Male:before {
  content: "\eb5b";
}

.v2-Ski:before {
  content: "\eb5c";
}

.v2-Sleeping-bag:before {
  content: "\eb5d";
}

.v2-Small-v2s-View:before {
  content: "\eb5e";
}

.v2-Smoking-area:before {
  content: "\eb5f";
}

.v2-Social-Media:before {
  content: "\eb60";
}

.v2-Space-Needle-Seattle:before {
  content: "\eb61";
}

.v2-Speach-Bubble-1:before {
  content: "\eb62";
}

.v2-Speach-Bubble-2:before {
  content: "\eb63";
}

.v2-Speach-Bubble-3:before {
  content: "\eb64";
}

.v2-Speach-Bubble-4:before {
  content: "\eb65";
}

.v2-Speach-Bubble-5:before {
  content: "\eb66";
}

.v2-Speach-Bubble-6:before {
  content: "\eb67";
}

.v2-Speach-Bubble-7:before {
  content: "\eb68";
}

.v2-Speach-Bubble-8:before {
  content: "\eb69";
}

.v2-Speach-Bubble-9:before {
  content: "\eb6a";
}

.v2-Speach-Bubble-10:before {
  content: "\eb6b";
}

.v2-Speach-Bubble-11:before {
  content: "\eb6c";
}

.v2-Speach-Bubbles-1:before {
  content: "\eb6d";
}

.v2-Speach-Bubbles-2:before {
  content: "\eb6e";
}

.v2-Speach-Bubbles-3:before {
  content: "\eb6f";
}

.v2-Speach-Bubbles-5:before {
  content: "\eb70";
}

.v2-Speach-Bubbles-6:before {
  content: "\eb71";
}

.v2-Speach-Bubbles-7:before {
  content: "\eb72";
}

.v2-Speach-Bubbles-8:before {
  content: "\eb73";
}

.v2-Speach-Bubbles-9:before {
  content: "\eb74";
}

.v2-Speach-Bubbles-10:before {
  content: "\eb75";
}

.v2-Speach-Bubbles-11:before {
  content: "\eb76";
}

.v2-Speach-Bubbles-12:before {
  content: "\eb77";
}

.v2-Speash-Bubbles-4:before {
  content: "\eb78";
}

.v2-Speech:before {
  content: "\eb79";
}

.v2-Spinach:before {
  content: "\eb7a";
}

.v2-St-Petersburg:before {
  content: "\eb7b";
}

.v2-Stadium:before {
  content: "\eb7c";
}

.v2-Stairs-down:before {
  content: "\eb7d";
}

.v2-Stairs-up:before {
  content: "\eb7e";
}

.v2-Standing-position:before {
  content: "\eb7f";
}

.v2-Star-Bookmark:before {
  content: "\eb80";
}

.v2-Star-Flag:before {
  content: "\eb81";
}

.v2-Star-Shield-1:before {
  content: "\eb82";
}

.v2-Star-Shield-2:before {
  content: "\eb83";
}

.v2-Star-User:before {
  content: "\eb84";
}

.v2-Stonehenge:before {
  content: "\eb85";
}

.v2-Storage:before {
  content: "\eb86";
}

.v2-Strawberry:before {
  content: "\eb87";
}

.v2-Suitcase:before {
  content: "\eb88";
}

.v2-Sunflower-Seed:before {
  content: "\eb89";
}

.v2-Sunshade:before {
  content: "\eb8a";
}

.v2-Super-Bowl-Cup:before {
  content: "\eb8b";
}

.v2-Surfboard:before {
  content: "\eb8c";
}

.v2-Surveillance:before {
  content: "\eb8d";
}

.v2-Suspenders:before {
  content: "\eb8e";
}

.v2-Sweet-Potato:before {
  content: "\eb8f";
}

.v2-Switch-App:before {
  content: "\eb90";
}

.v2-Switch-Males:before {
  content: "\eb91";
}

.v2-Sydney:before {
  content: "\eb92";
}

.v2-Synagogue:before {
  content: "\eb93";
}

.v2-Tablet-User:before {
  content: "\eb94";
}

.v2-Taj-Mahal:before {
  content: "\eb95";
}

.v2-Targeted-Head:before {
  content: "\eb96";
}

.v2-Task-Manager:before {
  content: "\eb97";
}

.v2-Telephone:before {
  content: "\eb98";
}

.v2-Tent:before {
  content: "\eb99";
}

.v2-Text-Editor:before {
  content: "\eb9a";
}

.v2-Theater:before {
  content: "\eb9b";
}

.v2-Theme:before {
  content: "\eb9c";
}

.v2-Ticket:before {
  content: "\eb9d";
}

.v2-Tie-1:before {
  content: "\eb9e";
}

.v2-Tie-2:before {
  content: "\eb9f";
}

.v2-Tie-3:before {
  content: "\eba0";
}

.v2-Tie-4:before {
  content: "\eba1";
}

.v2-Tiles-View:before {
  content: "\eba2";
}

.v2-Time-App:before {
  content: "\eba3";
}

.v2-Timed-Rating:before {
  content: "\eba4";
}

.v2-Tomato:before {
  content: "\eba5";
}

.v2-Tower-Bridge:before {
  content: "\eba6";
}

.v2-Trade-Mark:before {
  content: "\eba7";
}

.v2-Trafic-Cone:before {
  content: "\eba8";
}

.v2-Translate:before {
  content: "\eba9";
}

.v2-Trash:before {
  content: "\ebaa";
}

.v2-Tree-Grid:before {
  content: "\ebab";
}

.v2-Tree-View-2:before {
  content: "\ebac";
}

.v2-Tree-View-3:before {
  content: "\ebad";
}

.v2-Tree-View:before {
  content: "\ebae";
}

.v2-Triangle-Ruler:before {
  content: "\ebaf";
}

.v2-Trowel-and-Brick:before {
  content: "\ebb0";
}

.v2-Trowel-and-Wall:before {
  content: "\ebb1";
}

.v2-Truck-1:before {
  content: "\ebb2";
}

.v2-Turin:before {
  content: "\ebb3";
}

.v2-Two-Females-1:before {
  content: "\ebb4";
}

.v2-Two-Males-1:before {
  content: "\ebb5";
}

.v2-Tyle:before {
  content: "\ebb6";
}

.v2-Uefa-Euro-Cup:before {
  content: "\ebb7";
}

.v2-Umbrella:before {
  content: "\ebb8";
}

.v2-Uninstal-App:before {
  content: "\ebb9";
}

.v2-University:before {
  content: "\ebba";
}

.v2-Upload-App:before {
  content: "\ebbb";
}

.v2-Upload:before {
  content: "\ebbc";
}

.v2-User-App:before {
  content: "\ebbd";
}

.v2-User-Budget:before {
  content: "\ebbe";
}

.v2-User-Calendar:before {
  content: "\ebbf";
}

.v2-User-Cloud:before {
  content: "\ebc0";
}

.v2-User-Cup:before {
  content: "\ebc1";
}

.v2-User-Database:before {
  content: "\ebc2";
}

.v2-User-Fingerprint:before {
  content: "\ebc3";
}

.v2-User-Flowchart:before {
  content: "\ebc4";
}

.v2-User-Geometry:before {
  content: "\ebc5";
}

.v2-User-Hierarchy:before {
  content: "\ebc6";
}

.v2-User-Mail:before {
  content: "\ebc7";
}

.v2-User-Password:before {
  content: "\ebc8";
}

.v2-User-Path:before {
  content: "\ebc9";
}

.v2-User-Place:before {
  content: "\ebca";
}

.v2-User-Rating:before {
  content: "\ebcb";
}

.v2-User-Security-Card:before {
  content: "\ebcc";
}

.v2-User-Security-Female:before {
  content: "\ebcd";
}

.v2-User-Security-Male:before {
  content: "\ebce";
}

.v2-User-Settings:before {
  content: "\ebcf";
}

.v2-User-Store:before {
  content: "\ebd0";
}

.v2-User-Target:before {
  content: "\ebd1";
}

.v2-Vector-App:before {
  content: "\ebd2";
}

.v2-Vest:before {
  content: "\ebd3";
}

.v2-Victory:before {
  content: "\ebd4";
}

.v2-Video-App-1:before {
  content: "\ebd5";
}

.v2-Video-App-2:before {
  content: "\ebd6";
}

.v2-VIP-Star:before {
  content: "\ebd7";
}

.v2-Vote-2:before {
  content: "\ebd8";
}

.v2-Vote-3:before {
  content: "\ebd9";
}

.v2-Vote-4:before {
  content: "\ebda";
}

.v2-Vote-Stamp-2:before {
  content: "\ebdb";
}

.v2-Vote-Stamp:before {
  content: "\ebdc";
}

.v2-Vote:before {
  content: "\ebdd";
}

.v2-Voted:before {
  content: "\ebde";
}

.v2-Waiting-App:before {
  content: "\ebdf";
}

.v2-Waiting-Award-Badge:before {
  content: "\ebe0";
}

.v2-Waiting-room:before {
  content: "\ebe1";
}

.v2-Washing-1:before {
  content: "\ebe2";
}

.v2-Washing-2:before {
  content: "\ebe3";
}

.v2-Washing-3:before {
  content: "\ebe4";
}

.v2-Watch-1:before {
  content: "\ebe5";
}

.v2-Watch-2:before {
  content: "\ebe6";
}

.v2-Wet-Ironing:before {
  content: "\ebe7";
}

.v2-White-House:before {
  content: "\ebe8";
}

.v2-White-Radish:before {
  content: "\ebe9";
}

.v2-Windmill:before {
  content: "\ebea";
}

.v2-Wing-Star:before {
  content: "\ebeb";
}

.v2-Woman:before {
  content: "\ebec";
}

/*.v2-Women's-Collar:before {*/
/*content: "\ebed";*/
/*}*/
.v2-Worlld-Cup:before {
  content: "\ebee";
}

.v2-Wrench-Tool:before {
  content: "\ebef";
}

.v2-Writing-Group:before {
  content: "\ebf0";
}

.v2-WWW-Doc:before {
  content: "\ebf1";
}

.v2-XML-1:before {
  content: "\ebf2";
}

.v2-XML-2:before {
  content: "\ebf3";
}

.v2-Yacht:before {
  content: "\ebf4";
}

.v2-Zeppelin:before {
  content: "\ebf5";
}

.v2-Zucchini:before {
  content: "\ebf6";
}

.v2-D-File:before {
  content: "\ebf7";
}

.v2-DS:before {
  content: "\ebf8";
}

.v2-Add-House:before {
  content: "\ebf9";
}

.v2-Add-Note:before {
  content: "\ebfa";
}

.v2-Add-Post-It:before {
  content: "\ebfb";
}

.v2-Add-Task:before {
  content: "\ebfc";
}

.v2-Atach-Post-It:before {
  content: "\ebfd";
}

.v2-Atention-Note:before {
  content: "\ebfe";
}

.v2-Atention-Task:before {
  content: "\ebff";
}

.v2-Attache-Note:before {
  content: "\ec00";
}

.v2-Attache-Task:before {
  content: "\ec01";
}

.v2-Attention-House:before {
  content: "\ec02";
}

.v2-Attention-Post-It:before {
  content: "\ec03";
}

.v2-Bad-1:before {
  content: "\ec04";
}

.v2-Bad-2:before {
  content: "\ec05";
}

.v2-Bad-3:before {
  content: "\ec06";
}

.v2-Bad-4:before {
  content: "\ec07";
}

.v2-Basic-Shape-2:before {
  content: "\ec08";
}

.v2-Basic-Shape:before {
  content: "\ec09";
}

.v2-Bathroom-Cabinet-1:before {
  content: "\ec0a";
}

.v2-Batroom-Cabinet-2:before {
  content: "\ec0b";
}

.v2-Bench:before {
  content: "\ec0c";
}

.v2-Brick-Gouse:before {
  content: "\ec0d";
}

.v2-Cabinet-1:before {
  content: "\ec0e";
}

.v2-Cabinet-2:before {
  content: "\ec0f";
}

.v2-Cabinet-3:before {
  content: "\ec10";
}

.v2-Cabinet-4:before {
  content: "\ec11";
}

.v2-Cabinet-5:before {
  content: "\ec12";
}

.v2-Chair-1:before {
  content: "\ec13";
}

.v2-Chair-2:before {
  content: "\ec14";
}

.v2-Chair-3:before {
  content: "\ec15";
}

.v2-Change-House:before {
  content: "\ec16";
}

.v2-Circle:before {
  content: "\ec17";
}

.v2-Clear-House:before {
  content: "\ec18";
}

.v2-Clear-Note:before {
  content: "\ec19";
}

.v2-Clear-Post-It:before {
  content: "\ec1a";
}

.v2-Clear-Task:before {
  content: "\ec1b";
}

.v2-Clipboard-Pencil:before {
  content: "\ec1c";
}

.v2-Clipboard2:before {
  content: "\ec1d";
}

.v2-Cliped-Post-It:before {
  content: "\ec1e";
}

.v2-Cone-2:before {
  content: "\ec1f";
}

.v2-Cone-3:before {
  content: "\ec20";
}

.v2-Cone:before {
  content: "\ec21";
}

.v2-Corel-File:before {
  content: "\ec22";
}

.v2-Couch-1:before {
  content: "\ec23";
}

.v2-Couch-2:before {
  content: "\ec24";
}

.v2-Couch-3:before {
  content: "\ec25";
}

.v2-Cube-2:before {
  content: "\ec26";
}

.v2-Cube-3:before {
  content: "\ec27";
}

.v2-Cube:before {
  content: "\ec28";
}

.v2-Cylinder-2:before {
  content: "\ec29";
}

.v2-Cylinder-3:before {
  content: "\ec2a";
}

.v2-Cylinder:before {
  content: "\ec2b";
}

.v2-Dekaeder-2:before {
  content: "\ec2c";
}

.v2-Dekaeder-3:before {
  content: "\ec2d";
}

.v2-Dekaeder:before {
  content: "\ec2e";
}

.v2-Delete-Note:before {
  content: "\ec2f";
}

.v2-Delete-Post-It:before {
  content: "\ec30";
}

.v2-Delete-Task:before {
  content: "\ec31";
}

.v2-Design-Document:before {
  content: "\ec32";
}

.v2-Dodekaeder-1:before {
  content: "\ec33";
}

.v2-Dodekaeder-2:before {
  content: "\ec34";
}

.v2-Dodekaeder-3:before {
  content: "\ec35";
}

.v2-Dodekaeder-4:before {
  content: "\ec36";
}

.v2-Dollar-House:before {
  content: "\ec37";
}

.v2-Done-House:before {
  content: "\ec38";
}

.v2-Done-Note:before {
  content: "\ec39";
}

.v2-Done-Post-It:before {
  content: "\ec3a";
}

.v2-Done-Task:before {
  content: "\ec3b";
}

.v2-Download-Note:before {
  content: "\ec3c";
}

.v2-Download-Post-It:before {
  content: "\ec3d";
}

.v2-Download-Task:before {
  content: "\ec3e";
}

.v2-Dream-House:before {
  content: "\ec3f";
}

.v2-DWG:before {
  content: "\ec40";
}

.v2-DXF:before {
  content: "\ec41";
}

.v2-Eco-House:before {
  content: "\ec42";
}

.v2-Edit-Note:before {
  content: "\ec43";
}

.v2-Edit-Post-It:before {
  content: "\ec44";
}

.v2-Edit-Task:before {
  content: "\ec45";
}

.v2-EMF:before {
  content: "\ec46";
}

.v2-EPS:before {
  content: "\ec47";
}

.v2-Euro-House:before {
  content: "\ec48";
}

.v2-Favorite-House:before {
  content: "\ec49";
}

.v2-Fence:before {
  content: "\ec4a";
}

.v2-For-Rent:before {
  content: "\ec4b";
}

.v2-For-Sale:before {
  content: "\ec4c";
}

.v2-Garage:before {
  content: "\ec4d";
}

.v2-Garden:before {
  content: "\ec4e";
}

.v2-Globe-House:before {
  content: "\ec4f";
}

.v2-Hexaeder-1:before {
  content: "\ec50";
}

.v2-Hexaeder-2:before {
  content: "\ec51";
}

.v2-Hexaeder-3:before {
  content: "\ec52";
}

.v2-Hexaeder:before {
  content: "\ec53";
}

.v2-Hexagon:before {
  content: "\ec54";
}

.v2-Hexagonal-Cone-2:before {
  content: "\ec55";
}

.v2-Hexagonal-Cone-3:before {
  content: "\ec56";
}

.v2-Hexagonal-Cone:before {
  content: "\ec57";
}

.v2-Hexagonal-Cylinder-2:before {
  content: "\ec58";
}

.v2-Hexagonal-Cylinder-3:before {
  content: "\ec59";
}

.v2-Hexagonal-Cylinder:before {
  content: "\ec5a";
}

.v2-House-12:before {
  content: "\ec5b";
}

.v2-House-22:before {
  content: "\ec5c";
}

.v2-House-Care:before {
  content: "\ec5d";
}

.v2-House-Delay:before {
  content: "\ec5e";
}

.v2-House-Dimensions:before {
  content: "\ec5f";
}

.v2-House-Discount:before {
  content: "\ec60";
}

.v2-House-Insurance:before {
  content: "\ec61";
}

.v2-House-Key-2:before {
  content: "\ec62";
}

.v2-House-Key-3:before {
  content: "\ec63";
}

.v2-House-Key:before {
  content: "\ec64";
}

.v2-House-Location:before {
  content: "\ec65";
}

.v2-Ikosaeder-2:before {
  content: "\ec66";
}

.v2-Ikosaeder:before {
  content: "\ec67";
}

.v2-Illustrator-file:before {
  content: "\ec68";
}

.v2-Indesign:before {
  content: "\ec69";
}

.v2-Key-Hold:before {
  content: "\ec6a";
}

.v2-Lamp-1:before {
  content: "\ec6b";
}

.v2-Lamp-2:before {
  content: "\ec6c";
}

.v2-Lamp-3:before {
  content: "\ec6d";
}

.v2-Layer-43:before {
  content: "\ec6e";
}

.v2-Mail-Note:before {
  content: "\ec6f";
}

.v2-Mail-Post-It:before {
  content: "\ec70";
}

.v2-Mail-Task:before {
  content: "\ec71";
}

.v2-MAX:before {
  content: "\ec72";
}

.v2-Mirror-1:before {
  content: "\ec73";
}

.v2-Mirror-2:before {
  content: "\ec74";
}

.v2-Note-1:before {
  content: "\ec75";
}

.v2-Note-2:before {
  content: "\ec76";
}

.v2-Note-Book-1:before {
  content: "\ec77";
}

.v2-Note-Book-2:before {
  content: "\ec78";
}

.v2-Note-Pencil-1:before {
  content: "\ec79";
}

.v2-Note-Pencil-2:before {
  content: "\ec7a";
}

.v2-Notebook-3:before {
  content: "\ec7b";
}

.v2-Octaeder-2:before {
  content: "\ec7c";
}

.v2-Octaeder-3:before {
  content: "\ec7d";
}

.v2-Octaeder:before {
  content: "\ec7e";
}

.v2-Office-Building:before {
  content: "\ec7f";
}

.v2-Office-Chair:before {
  content: "\ec80";
}

.v2-Office-Desk-2:before {
  content: "\ec81";
}

.v2-Office-Desk:before {
  content: "\ec82";
}

.v2-OTF:before {
  content: "\ec83";
}

.v2-Pentagonal-Cone-2:before {
  content: "\ec84";
}

.v2-Pentagonal-Cone-3:before {
  content: "\ec85";
}

.v2-Pentagonal-Cone:before {
  content: "\ec86";
}

.v2-Pentagonal-Cylinder-2:before {
  content: "\ec87";
}

.v2-Pentagonal-Cylinder-3:before {
  content: "\ec88";
}

.v2-Pentagonal-Cylinder:before {
  content: "\ec89";
}

.v2-Personal-Note:before {
  content: "\ec8a";
}

.v2-Personal-Post-It:before {
  content: "\ec8b";
}

.v2-Personal-Task:before {
  content: "\ec8c";
}

.v2-Photoshop:before {
  content: "\ec8d";
}

.v2-Pined-Post-It:before {
  content: "\ec8e";
}

.v2-Piramid-2:before {
  content: "\ec8f";
}

.v2-Piramid-3:before {
  content: "\ec90";
}

.v2-Piramyd-1:before {
  content: "\ec91";
}

.v2-Post-It-Block:before {
  content: "\ec92";
}

.v2-Post-It-Pencil:before {
  content: "\ec93";
}

.v2-Post-It-Settings:before {
  content: "\ec94";
}

.v2-Pound-House:before {
  content: "\ec95";
}

.v2-Question-House:before {
  content: "\ec96";
}

.v2-Question-Note:before {
  content: "\ec97";
}

.v2-Question-Post-It:before {
  content: "\ec98";
}

.v2-Question-Task:before {
  content: "\ec99";
}

.v2-QXD:before {
  content: "\ec9a";
}

.v2-Rectangle-2:before {
  content: "\ec9b";
}

.v2-Rectangle:before {
  content: "\ec9c";
}

.v2-Remouve-House:before {
  content: "\ec9d";
}

.v2-Remouve-Note:before {
  content: "\ec9e";
}

.v2-Remouve-Post-It:before {
  content: "\ec9f";
}

.v2-Remouve-Task:before {
  content: "\eca0";
}

.v2-Rent-Hanger:before {
  content: "\eca1";
}

.v2-Rent-Sale-Sign:before {
  content: "\eca2";
}

.v2-Rent-Sign-2:before {
  content: "\eca3";
}

.v2-Rent-Sign:before {
  content: "\eca4";
}

.v2-Sale-Hanger:before {
  content: "\eca5";
}

.v2-Sale-Sign-2:before {
  content: "\eca6";
}

.v2-Sale-Sign:before {
  content: "\eca7";
}

.v2-Search-House-2:before {
  content: "\eca8";
}

.v2-Search-House:before {
  content: "\eca9";
}

.v2-Search-Note:before {
  content: "\ecaa";
}

.v2-Search-Post-It:before {
  content: "\ecab";
}

.v2-Search-Task:before {
  content: "\ecac";
}

.v2-Secure-House:before {
  content: "\ecad";
}

.v2-Setting-House:before {
  content: "\ecae";
}

.v2-Settings-Note:before {
  content: "\ecaf";
}

.v2-Shape-file-2:before {
  content: "\ecb0";
}

.v2-Shape-File:before {
  content: "\ecb1";
}

.v2-Share-Note:before {
  content: "\ecb2";
}

.v2-Share-Post-It:before {
  content: "\ecb3";
}

.v2-Share-Task:before {
  content: "\ecb4";
}

.v2-Sofa-1:before {
  content: "\ecb5";
}

.v2-Sofa-2:before {
  content: "\ecb6";
}

.v2-Sofa-3:before {
  content: "\ecb7";
}

.v2-Sold-Hanger:before {
  content: "\ecb8";
}

.v2-Sold-Sign-2:before {
  content: "\ecb9";
}

.v2-Sold-Sign:before {
  content: "\ecba";
}

.v2-Sphere-2:before {
  content: "\ecbb";
}

.v2-Sphere-3:before {
  content: "\ecbc";
}

.v2-Sphere:before {
  content: "\ecbd";
}

.v2-Star-House:before {
  content: "\ecbe";
}

.v2-Storage2:before {
  content: "\ecbf";
}

.v2-Sttings-Task:before {
  content: "\ecc0";
}

.v2-SVG:before {
  content: "\ecc1";
}

.v2-SWF:before {
  content: "\ecc2";
}

.v2-Table-1:before {
  content: "\ecc3";
}

.v2-Table-2:before {
  content: "\ecc4";
}

.v2-Table-3:before {
  content: "\ecc5";
}

.v2-Tasks-1:before {
  content: "\ecc6";
}

.v2-Tetraeder-2:before {
  content: "\ecc7";
}

.v2-Tetraeder-3:before {
  content: "\ecc8";
}

.v2-Tetraeder:before {
  content: "\ecc9";
}

.v2-TGA:before {
  content: "\ecca";
}

.v2-TIFF:before {
  content: "\eccb";
}

.v2-Triangle:before {
  content: "\eccc";
}

.v2-True-Type:before {
  content: "\eccd";
}

.v2-TTF:before {
  content: "\ecce";
}

.v2-TV-Desk-2:before {
  content: "\eccf";
}

.v2-TV-Desk:before {
  content: "\ecd0";
}

.v2-Upload-Note:before {
  content: "\ecd1";
}

.v2-Upload-Post-It:before {
  content: "\ecd2";
}

.v2-Upload-Task:before {
  content: "\ecd3";
}

.v2-Vector-File:before {
  content: "\ecd4";
}

.v2-Wardeobe-1:before {
  content: "\ecd5";
}

.v2-Wardrobe-2:before {
  content: "\ecd6";
}

.v2-Wardrobe-3:before {
  content: "\ecd7";
}

.v2-Wardrobe-Mirror:before {
  content: "\ecd8";
}

