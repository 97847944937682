@font-face {
  font-family: 'v4b';
  src: url('fonts/v4b.woff?zbly8g') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v4b-"], [class*=" v4b-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v4b' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v4b-D-Box:before {
  content: "\e900";
}

.v4b-D-Cube:before {
  content: "\e901";
}

.v4b-Accessible:before {
  content: "\e902";
}

.v4b-Active-Protection:before {
  content: "\e903";
}

.v4b-Add-Event:before {
  content: "\e904";
}

.v4b-Add-Shopping-Cart:before {
  content: "\e905";
}

.v4b-Add-User-2:before {
  content: "\e906";
}

.v4b-Add-User:before {
  content: "\e907";
}

.v4b-Add-Users:before {
  content: "\e908";
}

.v4b-Add:before {
  content: "\e909";
}

.v4b-Adjustments:before {
  content: "\e90a";
}

.v4b-Airplane:before {
  content: "\e90b";
}

.v4b-Alarm:before {
  content: "\e90c";
}

.v4b-Alcohol-Drink:before {
  content: "\e90d";
}

.v4b-Anchor:before {
  content: "\e90e";
}

.v4b-Android:before {
  content: "\e90f";
}

.v4b-Announcement:before {
  content: "\e910";
}

.v4b-Anonymus-2:before {
  content: "\e911";
}

.v4b-Anonymus:before {
  content: "\e912";
}

.v4b-Answer-2:before {
  content: "\e913";
}

.v4b-Answer-3:before {
  content: "\e914";
}

.v4b-Answer:before {
  content: "\e915";
}

.v4b-Apron:before {
  content: "\e916";
}

.v4b-Archive-File:before {
  content: "\e917";
}

.v4b-Archive:before {
  content: "\e918";
}

.v4b-Armchair-1:before {
  content: "\e919";
}

.v4b-Armchair-2:before {
  content: "\e91a";
}

.v4b-Armchair-3:before {
  content: "\e91b";
}

.v4b-Armchair-4:before {
  content: "\e91c";
}

.v4b-Artifical-Intelligence-1:before {
  content: "\e91d";
}

.v4b-Artifical-Intelligence-2:before {
  content: "\e91e";
}

.v4b-Artist:before {
  content: "\e91f";
}

.v4b-Assignment:before {
  content: "\e920";
}

.v4b-Astronaut:before {
  content: "\e921";
}

.v4b-At-Symbol-2:before {
  content: "\e922";
}

.v4b-At-Symbol-3:before {
  content: "\e923";
}

.v4b-At-Symbol:before {
  content: "\e924";
}

.v4b-At:before {
  content: "\e925";
}

.v4b-Atlas:before {
  content: "\e926";
}

.v4b-Attach-2:before {
  content: "\e927";
}

.v4b-Attach-3:before {
  content: "\e928";
}

.v4b-Attach-User:before {
  content: "\e929";
}

.v4b-Audio:before {
  content: "\e92a";
}

.v4b-Avatar-Chef:before {
  content: "\e92b";
}

.v4b-Back-Arrow:before {
  content: "\e92c";
}

.v4b-Badminton:before {
  content: "\e92d";
}

.v4b-Baguette:before {
  content: "\e92e";
}

.v4b-Bald-Man:before {
  content: "\e92f";
}

.v4b-Balloons-1:before {
  content: "\e930";
}

.v4b-Balloons-2:before {
  content: "\e931";
}

.v4b-Baloon:before {
  content: "\e932";
}

.v4b-Banknote:before {
  content: "\e933";
}

.v4b-Bar-Chart-2:before {
  content: "\e934";
}

.v4b-Bar-Chart:before {
  content: "\e935";
}

.v4b-Barbeque:before {
  content: "\e936";
}

.v4b-Bat:before {
  content: "\e937";
}

.v4b-Batman:before {
  content: "\e938";
}

.v4b-Battery-Charge:before {
  content: "\e939";
}

.v4b-Battery-Full:before {
  content: "\e93a";
}

.v4b-Battery-Half:before {
  content: "\e93b";
}

.v4b-Battery-Low:before {
  content: "\e93c";
}

.v4b-Beach-Sign:before {
  content: "\e93d";
}

.v4b-Bed-1:before {
  content: "\e93e";
}

.v4b-Bed-2:before {
  content: "\e93f";
}

.v4b-Bed-3:before {
  content: "\e940";
}

.v4b-Bed-4:before {
  content: "\e941";
}

.v4b-Bed-5:before {
  content: "\e942";
}

.v4b-Beer-Glass:before {
  content: "\e943";
}

.v4b-Beer:before {
  content: "\e944";
}

.v4b-Bell-2:before {
  content: "\e945";
}

.v4b-Bell-3:before {
  content: "\e946";
}

.v4b-Bell-4:before {
  content: "\e947";
}

.v4b-Bell-Alarm:before {
  content: "\e948";
}

.v4b-Bell:before {
  content: "\e949";
}

.v4b-Bender:before {
  content: "\e94a";
}

.v4b-Bikini:before {
  content: "\e94b";
}

.v4b-Bill-2:before {
  content: "\e94c";
}

.v4b-Bill:before {
  content: "\e94d";
}

.v4b-Birthay-Hat-2:before {
  content: "\e94e";
}

.v4b-Birthday-1:before {
  content: "\e94f";
}

.v4b-Birthday-2:before {
  content: "\e950";
}

.v4b-Birthday-Cake-1:before {
  content: "\e951";
}

.v4b-Birthday-Cake-2:before {
  content: "\e952";
}

.v4b-Birthday-Card:before {
  content: "\e953";
}

.v4b-Birthday-Decoration-1:before {
  content: "\e954";
}

.v4b-Birthday-Decoration-2:before {
  content: "\e955";
}

.v4b-Birthday-Decoration-3:before {
  content: "\e956";
}

.v4b-Birthday-Greetings-1:before {
  content: "\e957";
}

.v4b-Birthday-Hat3:before {
  content: "\e958";
}

.v4b-Birthday-Invitation:before {
  content: "\e959";
}

.v4b-Birthday-Message-1:before {
  content: "\e95a";
}

.v4b-Birthday-Message-2:before {
  content: "\e95b";
}

.v4b-Birthday-Party:before {
  content: "\e95c";
}

.v4b-Birthday-Picture:before {
  content: "\e95d";
}

.v4b-Birthday-Song:before {
  content: "\e95e";
}

.v4b-Birthyday-Hat-1:before {
  content: "\e95f";
}

.v4b-Bitcoin-2:before {
  content: "\e960";
}

.v4b-Bitcoin-3:before {
  content: "\e961";
}

.v4b-Bitcoin:before {
  content: "\e962";
}

.v4b-Black-Cat:before {
  content: "\e963";
}

.v4b-Blende:before {
  content: "\e964";
}

.v4b-Blender:before {
  content: "\e965";
}

.v4b-Bllboy:before {
  content: "\e966";
}

.v4b-Bloody-Axe:before {
  content: "\e967";
}

.v4b-Bloody-Knife:before {
  content: "\e968";
}

.v4b-Blueprint:before {
  content: "\e969";
}

.v4b-Bluetooth:before {
  content: "\e96a";
}

.v4b-Boat:before {
  content: "\e96b";
}

.v4b-Bone-Hand:before {
  content: "\e96c";
}

.v4b-Bones:before {
  content: "\e96d";
}

.v4b-Book:before {
  content: "\e96e";
}

.v4b-Bookmarrk:before {
  content: "\e96f";
}

.v4b-Bottle-Opener:before {
  content: "\e970";
}

.v4b-Bow-Tie:before {
  content: "\e971";
}

.v4b-Box:before {
  content: "\e972";
}

.v4b-Brake-Link:before {
  content: "\e973";
}

.v4b-Bread-and-wine:before {
  content: "\e974";
}

.v4b-Bread-Knife:before {
  content: "\e975";
}

.v4b-Bricks:before {
  content: "\e976";
}

.v4b-Bride:before {
  content: "\e977";
}

.v4b-Briefcase:before {
  content: "\e978";
}

.v4b-Bring-Down-2:before {
  content: "\e979";
}

.v4b-Bring-Down-3:before {
  content: "\e97a";
}

.v4b-Bring-Down:before {
  content: "\e97b";
}

.v4b-Bring-Up-2:before {
  content: "\e97c";
}

.v4b-Bring-Up-3:before {
  content: "\e97d";
}

.v4b-Bring-Up:before {
  content: "\e97e";
}

.v4b-Brush:before {
  content: "\e97f";
}

.v4b-Bucket:before {
  content: "\e980";
}

.v4b-Bug:before {
  content: "\e981";
}

.v4b-Building-Construction:before {
  content: "\e982";
}

.v4b-Building:before {
  content: "\e983";
}

.v4b-Bunk-Beds:before {
  content: "\e984";
}

.v4b-Bunny-2:before {
  content: "\e985";
}

.v4b-Bunny:before {
  content: "\e986";
}

.v4b-Business-Man-1:before {
  content: "\e987";
}

.v4b-Business-Man-2:before {
  content: "\e988";
}

.v4b-Business-Woman-1:before {
  content: "\e989";
}

.v4b-Business-Woman-2:before {
  content: "\e98a";
}

.v4b-Cad-1:before {
  content: "\e98b";
}

.v4b-Cad-2:before {
  content: "\e98c";
}

.v4b-Cad-3:before {
  content: "\e98d";
}

.v4b-Cad-Compasses:before {
  content: "\e98e";
}

.v4b-Cad-File:before {
  content: "\e98f";
}

.v4b-Cad-Paper:before {
  content: "\e990";
}

.v4b-Cad-Papers:before {
  content: "\e991";
}

.v4b-Cad-Pencil:before {
  content: "\e992";
}

.v4b-Cake-Knife:before {
  content: "\e993";
}

.v4b-Cake-Piece:before {
  content: "\e994";
}

.v4b-Cake-Slice:before {
  content: "\e995";
}

.v4b-Cake:before {
  content: "\e996";
}

.v4b-Calculator:before {
  content: "\e997";
}

.v4b-Calendar:before {
  content: "\e998";
}

.v4b-Callendar:before {
  content: "\e999";
}

.v4b-Camera-1:before {
  content: "\e99a";
}

.v4b-Camera-2:before {
  content: "\e99b";
}

.v4b-Candle-Light:before {
  content: "\e99c";
}

.v4b-Candle:before {
  content: "\e99d";
}

.v4b-Candy-Holder:before {
  content: "\e99e";
}

.v4b-Candy:before {
  content: "\e99f";
}

.v4b-Canlestick:before {
  content: "\e9a0";
}

.v4b-Captain-America:before {
  content: "\e9a1";
}

.v4b-Captain:before {
  content: "\e9a2";
}

.v4b-Captin-Hat:before {
  content: "\e9a3";
}

.v4b-Car-2:before {
  content: "\e9a4";
}

.v4b-Car-3:before {
  content: "\e9a5";
}

.v4b-Car:before {
  content: "\e9a6";
}

.v4b-Castle:before {
  content: "\e9a7";
}

.v4b-Cauldron:before {
  content: "\e9a8";
}

.v4b-Celebration-Glasses:before {
  content: "\e9a9";
}

.v4b-Cemetery-1:before {
  content: "\e9aa";
}

.v4b-Cemetery-2:before {
  content: "\e9ab";
}

.v4b-Cezve:before {
  content: "\e9ac";
}

.v4b-Champagne-in-ice-bucket:before {
  content: "\e9ad";
}

.v4b-Champagne-Open:before {
  content: "\e9ae";
}

.v4b-Champagne:before {
  content: "\e9af";
}

.v4b-Charlie-Chaplin:before {
  content: "\e9b0";
}

.v4b-Check-1:before {
  content: "\e9b1";
}

.v4b-Check-2:before {
  content: "\e9b2";
}

.v4b-Chef:before {
  content: "\e9b3";
}

.v4b-Chef:before {
  content: "\e9b4";
}

.v4b-Chef:before {
  content: "\e9b5";
}

.v4b-Chicken-2:before {
  content: "\e9b6";
}

.v4b-Chicken-3:before {
  content: "\e9b7";
}

.v4b-Chicken:before {
  content: "\e9b8";
}

.v4b-Chilled-Champagne:before {
  content: "\e9b9";
}

.v4b-Chopsticks:before {
  content: "\e9ba";
}

.v4b-Church-Bells:before {
  content: "\e9bb";
}

.v4b-Church:before {
  content: "\e9bc";
}

.v4b-Claptrap:before {
  content: "\e9bd";
}

.v4b-Clock:before {
  content: "\e9be";
}

.v4b-Close:before {
  content: "\e9bf";
}

.v4b-Cloud-2:before {
  content: "\e9c0";
}

.v4b-Cloud-3:before {
  content: "\e9c1";
}

.v4b-Cloud-Off:before {
  content: "\e9c2";
}

.v4b-Cloud:before {
  content: "\e9c3";
}

.v4b-Clown-2:before {
  content: "\e9c4";
}

.v4b-Clown:before {
  content: "\e9c5";
}

.v4b-Cobweb-1:before {
  content: "\e9c6";
}

.v4b-Cobweb-2:before {
  content: "\e9c7";
}

.v4b-Cocktail:before {
  content: "\e9c8";
}

.v4b-Coding:before {
  content: "\e9c9";
}

.v4b-Coffee-Maker:before {
  content: "\e9ca";
}

.v4b-Coffin:before {
  content: "\e9cb";
}

.v4b-Cold:before {
  content: "\e9cc";
}

.v4b-Collaboration:before {
  content: "\e9cd";
}

.v4b-Compass:before {
  content: "\e9ce";
}

.v4b-Computer:before {
  content: "\e9cf";
}

.v4b-Confetti-1:before {
  content: "\e9d0";
}

.v4b-Confetti-2:before {
  content: "\e9d1";
}

.v4b-Connection:before {
  content: "\e9d2";
}

.v4b-Construction-Plan:before {
  content: "\e9d3";
}

.v4b-Cookbook:before {
  content: "\e9d4";
}

.v4b-Cooker-Hood:before {
  content: "\e9d5";
}

.v4b-Cooker:before {
  content: "\e9d6";
}

.v4b-Cooler:before {
  content: "\e9d7";
}

.v4b-Copyright-2:before {
  content: "\e9d8";
}

.v4b-Copyright-3:before {
  content: "\e9d9";
}

.v4b-Copyright:before {
  content: "\e9da";
}

.v4b-Cord:before {
  content: "\e9db";
}

.v4b-Corkscrew:before {
  content: "\e9dc";
}

.v4b-Corn-Candy:before {
  content: "\e9dd";
}

.v4b-Corner-Tub:before {
  content: "\e9de";
}

.v4b-Course:before {
  content: "\e9df";
}

.v4b-Cowboy:before {
  content: "\e9e0";
}

.v4b-Creative-Commun-2:before {
  content: "\e9e1";
}

.v4b-Creative-Commun-3:before {
  content: "\e9e2";
}

.v4b-Creative-Commun:before {
  content: "\e9e3";
}

.v4b-Credit-Card:before {
  content: "\e9e4";
}

.v4b-Crescent:before {
  content: "\e9e5";
}

.v4b-Criminal:before {
  content: "\e9e6";
}

.v4b-Crop:before {
  content: "\e9e7";
}

.v4b-Crow:before {
  content: "\e9e8";
}

.v4b-Cup:before {
  content: "\e9e9";
}

.v4b-Cupcake:before {
  content: "\e9ea";
}

.v4b-Cut:before {
  content: "\e9eb";
}

.v4b-Cutlery-Decoration:before {
  content: "\e9ec";
}

.v4b-Cutlery-Set:before {
  content: "\e9ed";
}

.v4b-Cutlery:before {
  content: "\e9ee";
}

.v4b-Cutting-Board-1:before {
  content: "\e9ef";
}

.v4b-Cutting-Board-2:before {
  content: "\e9f0";
}

.v4b-Daffodil:before {
  content: "\e9f1";
}

.v4b-Darth-Vader:before {
  content: "\e9f2";
}

.v4b-Dashed-Frame-1:before {
  content: "\e9f3";
}

.v4b-Dashed-Frame-2:before {
  content: "\e9f4";
}

.v4b-Dashed-Frame-3:before {
  content: "\e9f5";
}

.v4b-Dashed-Frame-4:before {
  content: "\e9f6";
}

.v4b-Dashed-Frame-5:before {
  content: "\e9f7";
}

.v4b-Dashed-Frame-6:before {
  content: "\e9f8";
}

.v4b-Dashed-Frame-7:before {
  content: "\e9f9";
}

.v4b-Dashed-Frame-8:before {
  content: "\e9fa";
}

.v4b-Dashed-Frame-9:before {
  content: "\e9fb";
}

.v4b-Dashed-Frame-10:before {
  content: "\e9fc";
}

.v4b-Dashed-Frame-11:before {
  content: "\e9fd";
}

.v4b-Dashed-Frame-12:before {
  content: "\e9fe";
}

.v4b-Dashed-Frame-13:before {
  content: "\e9ff";
}

.v4b-Dashed-Frame-14:before {
  content: "\ea00";
}

.v4b-Dashed-Frame-15:before {
  content: "\ea01";
}

.v4b-Dashed-Frame-16:before {
  content: "\ea02";
}

.v4b-Dashed-Frame-17:before {
  content: "\ea03";
}

.v4b-Dashed-Frame-18:before {
  content: "\ea04";
}

.v4b-Dashed-Frame-19:before {
  content: "\ea05";
}

.v4b-Dashed-Frame-20:before {
  content: "\ea06";
}

.v4b-Dashed-Frame-21:before {
  content: "\ea07";
}

.v4b-Dashed-Frame-22:before {
  content: "\ea08";
}

.v4b-Dashed-Frame-23:before {
  content: "\ea09";
}

.v4b-Dashed-Frame-24:before {
  content: "\ea0a";
}

.v4b-Dashed-Frame-25:before {
  content: "\ea0b";
}

.v4b-Dashed-Frame-26:before {
  content: "\ea0c";
}

.v4b-Dashed-Frame-27:before {
  content: "\ea0d";
}

.v4b-Dashed-Frame-28:before {
  content: "\ea0e";
}

.v4b-Dashed-Frame-29:before {
  content: "\ea0f";
}

.v4b-Dashed-Frame-30:before {
  content: "\ea10";
}

.v4b-Dashed-Frame-31:before {
  content: "\ea11";
}

.v4b-Dashed-Frame-32:before {
  content: "\ea12";
}

.v4b-Dashed-Frame-33:before {
  content: "\ea13";
}

.v4b-Dashed-Frame-34:before {
  content: "\ea14";
}

.v4b-Dashed-Frame-35:before {
  content: "\ea15";
}

.v4b-Database-2:before {
  content: "\ea16";
}

.v4b-Database-3:before {
  content: "\ea17";
}

.v4b-Database:before {
  content: "\ea18";
}

.v4b-Deactivate-Protection:before {
  content: "\ea19";
}

.v4b-Deckchair:before {
  content: "\ea1a";
}

.v4b-Delete-User-2:before {
  content: "\ea1b";
}

.v4b-Delete-User:before {
  content: "\ea1c";
}

.v4b-Delete-Users:before {
  content: "\ea1d";
}

.v4b-Design-Mug:before {
  content: "\ea1e";
}

.v4b-Design:before {
  content: "\ea1f";
}

.v4b-Desk-1:before {
  content: "\ea20";
}

.v4b-Desk-2:before {
  content: "\ea21";
}

.v4b-Desk-3:before {
  content: "\ea22";
}

.v4b-Dessert:before {
  content: "\ea23";
}

.v4b-Devil:before {
  content: "\ea24";
}

.v4b-Diamond:before {
  content: "\ea25";
}

.v4b-Dice:before {
  content: "\ea26";
}

.v4b-Dictator:before {
  content: "\ea27";
}

.v4b-Disabled-User-2:before {
  content: "\ea28";
}

.v4b-Disabled-User:before {
  content: "\ea29";
}

.v4b-Disabled-Users:before {
  content: "\ea2a";
}

.v4b-Disc:before {
  content: "\ea2b";
}

.v4b-Disco-Ball:before {
  content: "\ea2c";
}

.v4b-Dismiss-User-2:before {
  content: "\ea2d";
}

.v4b-Dismiss-User:before {
  content: "\ea2e";
}

.v4b-Dismiss-Users:before {
  content: "\ea2f";
}

.v4b-Dispatcher-1:before {
  content: "\ea30";
}

.v4b-Dispatcher-2:before {
  content: "\ea31";
}

.v4b-Diver:before {
  content: "\ea32";
}

.v4b-Diving-Mask:before {
  content: "\ea33";
}

.v4b-DJ:before {
  content: "\ea34";
}

.v4b-Doctor:before {
  content: "\ea35";
}

.v4b-Dollar-2:before {
  content: "\ea36";
}

.v4b-Dollar-3:before {
  content: "\ea37";
}

.v4b-Dollar:before {
  content: "\ea38";
}

.v4b-Dolphin:before {
  content: "\ea39";
}

.v4b-Done-2:before {
  content: "\ea3a";
}

.v4b-Done-3:before {
  content: "\ea3b";
}

.v4b-Done:before {
  content: "\ea3c";
}

.v4b-Door:before {
  content: "\ea3d";
}

.v4b-Download-2:before {
  content: "\ea3e";
}

.v4b-Download-3:before {
  content: "\ea3f";
}

.v4b-Download-Cloud:before {
  content: "\ea40";
}

.v4b-Download:before {
  content: "\ea41";
}

.v4b-Dracula:before {
  content: "\ea42";
}

.v4b-Drive:before {
  content: "\ea43";
}

.v4b-Earth:before {
  content: "\ea44";
}

.v4b-Easter-basket:before {
  content: "\ea45";
}

.v4b-Easter-celebration:before {
  content: "\ea46";
}

.v4b-Easter-Egg-1:before {
  content: "\ea47";
}

.v4b-Easter-Egg-2:before {
  content: "\ea48";
}

.v4b-Easter-Egg-3:before {
  content: "\ea49";
}

.v4b-Easter-message:before {
  content: "\ea4a";
}

.v4b-Easter-willow:before {
  content: "\ea4b";
}

.v4b-Eat-in-Kitchen:before {
  content: "\ea4c";
}

.v4b-Eco-Friendly-2:before {
  content: "\ea4d";
}

.v4b-Eco-Friendly-3:before {
  content: "\ea4e";
}

.v4b-Eco-Friendly:before {
  content: "\ea4f";
}

.v4b-ED-209:before {
  content: "\ea50";
}

.v4b-Egg-Beater:before {
  content: "\ea51";
}

.v4b-Egg-painting:before {
  content: "\ea52";
}

.v4b-Egg-Timer:before {
  content: "\ea53";
}

.v4b-Egg:before {
  content: "\ea54";
}

.v4b-Eggs-1:before {
  content: "\ea55";
}

.v4b-Eggs-2:before {
  content: "\ea56";
}

.v4b-Eject:before {
  content: "\ea57";
}

.v4b-Engagement-1:before {
  content: "\ea58";
}

.v4b-Engagement-2:before {
  content: "\ea59";
}

.v4b-Equalizer:before {
  content: "\ea5a";
}

.v4b-Error-Mark:before {
  content: "\ea5b";
}

.v4b-Error:before {
  content: "\ea5c";
}

.v4b-Eudder:before {
  content: "\ea5d";
}

.v4b-Euro-2:before {
  content: "\ea5e";
}

.v4b-Euro-3:before {
  content: "\ea5f";
}

.v4b-Euro:before {
  content: "\ea60";
}

.v4b-Eva:before {
  content: "\ea61";
}

.v4b-Event-2:before {
  content: "\ea62";
}

.v4b-Event-Reminder:before {
  content: "\ea63";
}

.v4b-Event-Ticket:before {
  content: "\ea64";
}

.v4b-Event:before {
  content: "\ea65";
}

.v4b-Eye:before {
  content: "\ea66";
}

.v4b-Eyeball:before {
  content: "\ea67";
}

.v4b-Farmer:before {
  content: "\ea68";
}

.v4b-Feather-Pen:before {
  content: "\ea69";
}

.v4b-Female:before {
  content: "\ea6a";
}

.v4b-File-1:before {
  content: "\ea6b";
}

.v4b-File-2:before {
  content: "\ea6c";
}

.v4b-Filleting-Knife:before {
  content: "\ea6d";
}

.v4b-Fingerprint:before {
  content: "\ea6e";
}

.v4b-Firefigyhter:before {
  content: "\ea6f";
}

.v4b-Firework-1:before {
  content: "\ea70";
}

.v4b-Firework-2:before {
  content: "\ea71";
}

.v4b-Fish:before {
  content: "\ea72";
}

.v4b-Flag:before {
  content: "\ea73";
}

.v4b-Flash:before {
  content: "\ea74";
}

.v4b-Flashlight:before {
  content: "\ea75";
}

.v4b-Flip-Flops:before {
  content: "\ea76";
}

.v4b-Float:before {
  content: "\ea77";
}

.v4b-Flowchart:before {
  content: "\ea78";
}

.v4b-Flowers:before {
  content: "\ea79";
}

.v4b-Folder:before {
  content: "\ea7a";
}

.v4b-Food-Photo:before {
  content: "\ea7b";
}

.v4b-Fork:before {
  content: "\ea7c";
}

.v4b-Format-Size:before {
  content: "\ea7d";
}

.v4b-Frame-1:before {
  content: "\ea7e";
}

.v4b-Frame-2:before {
  content: "\ea7f";
}

.v4b-Frame-3:before {
  content: "\ea80";
}

.v4b-Frame-4:before {
  content: "\ea81";
}

.v4b-Frame-5:before {
  content: "\ea82";
}

.v4b-Frame-6:before {
  content: "\ea83";
}

.v4b-Frame-7:before {
  content: "\ea84";
}

.v4b-Frame-8:before {
  content: "\ea85";
}

.v4b-Frame-9:before {
  content: "\ea86";
}

.v4b-Frame-10:before {
  content: "\ea87";
}

.v4b-Frame-11:before {
  content: "\ea88";
}

.v4b-Frame-12:before {
  content: "\ea89";
}

.v4b-Frame-13:before {
  content: "\ea8a";
}

.v4b-Frame-14:before {
  content: "\ea8b";
}

.v4b-Frame-15:before {
  content: "\ea8c";
}

.v4b-Frame-16:before {
  content: "\ea8d";
}

.v4b-Frame-17:before {
  content: "\ea8e";
}

.v4b-Frame-18:before {
  content: "\ea8f";
}

.v4b-Frame-19:before {
  content: "\ea90";
}

.v4b-Frame-20:before {
  content: "\ea91";
}

.v4b-Frame-21:before {
  content: "\ea92";
}

.v4b-Frame-22:before {
  content: "\ea93";
}

.v4b-Frame-23:before {
  content: "\ea94";
}

.v4b-Frame-24:before {
  content: "\ea95";
}

.v4b-Frame-25:before {
  content: "\ea96";
}

.v4b-Frame-26:before {
  content: "\ea97";
}

.v4b-Frame-27:before {
  content: "\ea98";
}

.v4b-Frame-28:before {
  content: "\ea99";
}

.v4b-Frame-29:before {
  content: "\ea9a";
}

.v4b-Frame-30:before {
  content: "\ea9b";
}

.v4b-Frame-31:before {
  content: "\ea9c";
}

.v4b-Frame-32:before {
  content: "\ea9d";
}

.v4b-Frame-33:before {
  content: "\ea9e";
}

.v4b-Frame-34:before {
  content: "\ea9f";
}

.v4b-Frame-35:before {
  content: "\eaa0";
}

.v4b-Frame-Hole-1:before {
  content: "\eaa1";
}

.v4b-Frame-Hole-2:before {
  content: "\eaa2";
}

.v4b-Frame-Hole-3:before {
  content: "\eaa3";
}

.v4b-Frame-Hole-4:before {
  content: "\eaa4";
}

.v4b-Frankenstein:before {
  content: "\eaa5";
}

.v4b-Freezer:before {
  content: "\eaa6";
}

.v4b-Full-Moon:before {
  content: "\eaa7";
}

.v4b-Funnel:before {
  content: "\eaa8";
}

.v4b-Gaming-2:before {
  content: "\eaa9";
}

.v4b-Gaming-3:before {
  content: "\eaaa";
}

.v4b-Gaming:before {
  content: "\eaab";
}

.v4b-Gate:before {
  content: "\eaac";
}

.v4b-Ghost:before {
  content: "\eaad";
}

.v4b-Giving-Gift:before {
  content: "\eaae";
}

.v4b-Glass-Decoration:before {
  content: "\eaaf";
}

.v4b-Glass:before {
  content: "\eab0";
}

.v4b-Glasses:before {
  content: "\eab1";
}

.v4b-Global-User-2:before {
  content: "\eab2";
}

.v4b-Global-User:before {
  content: "\eab3";
}

.v4b-Global-Users:before {
  content: "\eab4";
}

.v4b-Globe:before {
  content: "\eab5";
}

.v4b-Glowes:before {
  content: "\eab6";
}

.v4b-Graduated-Man:before {
  content: "\eab7";
}

.v4b-Graduated-Woman:before {
  content: "\eab8";
}

.v4b-Grater:before {
  content: "\eab9";
}

.v4b-Grill:before {
  content: "\eaba";
}

.v4b-Grim-Reaper:before {
  content: "\eabb";
}

.v4b-Groom:before {
  content: "\eabc";
}

.v4b-Ham:before {
  content: "\eabd";
}

.v4b-Hand-2:before {
  content: "\eabe";
}

.v4b-Hand-Blender:before {
  content: "\eabf";
}

.v4b-Hand:before {
  content: "\eac0";
}

.v4b-Hands-with-rings:before {
  content: "\eac1";
}

.v4b-Handshake:before {
  content: "\eac2";
}

.v4b-Harry-Potter:before {
  content: "\eac3";
}

.v4b-Hat:before {
  content: "\eac4";
}

.v4b-Hatchet:before {
  content: "\eac5";
}

.v4b-Heart:before {
  content: "\eac6";
}

.v4b-Herb-Knife:before {
  content: "\eac7";
}

.v4b-Hipster-Girl:before {
  content: "\eac8";
}

.v4b-Hipster-Guy:before {
  content: "\eac9";
}

.v4b-Home-2:before {
  content: "\eaca";
}

.v4b-Home-3:before {
  content: "\eacb";
}

.v4b-Home:before {
  content: "\eacc";
}

.v4b-Honey-Dipper:before {
  content: "\eacd";
}

.v4b-Horn-1:before {
  content: "\eace";
}

.v4b-Horn-2:before {
  content: "\eacf";
}

.v4b-Horn:before {
  content: "\ead0";
}

.v4b-Hot-Chocolate:before {
  content: "\ead1";
}

.v4b-Hot-Drink-1:before {
  content: "\ead2";
}

.v4b-Hot-Drink-2:before {
  content: "\ead3";
}

.v4b-Hot-Drink:before {
  content: "\ead4";
}

.v4b-Hot-Sauce:before {
  content: "\ead5";
}

.v4b-Hot-Temperature:before {
  content: "\ead6";
}

.v4b-House-Construction:before {
  content: "\ead7";
}

.v4b-House-Plan:before {
  content: "\ead8";
}

.v4b-House:before {
  content: "\ead9";
}

.v4b-Household-Mixer:before {
  content: "\eada";
}

.v4b-Ice-Lolly:before {
  content: "\eadb";
}

.v4b-Ice-Skate:before {
  content: "\eadc";
}

.v4b-Icecream:before {
  content: "\eadd";
}

.v4b-Icicle:before {
  content: "\eade";
}

.v4b-Information:before {
  content: "\eadf";
}

.v4b-Invisible:before {
  content: "\eae0";
}

.v4b-Invitation-1:before {
  content: "\eae1";
}

.v4b-Invitation-2:before {
  content: "\eae2";
}

.v4b-Iron-Giant:before {
  content: "\eae3";
}

.v4b-Island:before {
  content: "\eae4";
}

.v4b-Jacuzzi:before {
  content: "\eae5";
}

.v4b-Jason:before {
  content: "\eae6";
}

.v4b-Jing-Jang:before {
  content: "\eae7";
}

.v4b-K5-Robot:before {
  content: "\eae8";
}

.v4b-Kettle:before {
  content: "\eae9";
}

.v4b-Key-1:before {
  content: "\eaea";
}

.v4b-Key-2:before {
  content: "\eaeb";
}

.v4b-Keyboard:before {
  content: "\eaec";
}

.v4b-Kid:before {
  content: "\eaed";
}

.v4b-Kitchen-Clock:before {
  content: "\eaee";
}

.v4b-Kitchen-Scale:before {
  content: "\eaef";
}

.v4b-Kitchen-Sink:before {
  content: "\eaf0";
}

.v4b-Kitchen-Spoon-1:before {
  content: "\eaf1";
}

.v4b-Kitchen-Spoon-2:before {
  content: "\eaf2";
}

.v4b-Kite-1:before {
  content: "\eaf3";
}

.v4b-Kite-2:before {
  content: "\eaf4";
}

.v4b-Knife-Holder:before {
  content: "\eaf5";
}

.v4b-Knife:before {
  content: "\eaf6";
}

.v4b-Ladle:before {
  content: "\eaf7";
}

.v4b-Lamp:before {
  content: "\eaf8";
}

.v4b-Lantern:before {
  content: "\eaf9";
}

.v4b-Layer-10:before {
  content: "\eafa";
}

.v4b-Layer-29:before {
  content: "\eafb";
}

.v4b-Layer-40:before {
  content: "\eafc";
}

.v4b-Layer-58:before {
  content: "\eafd";
}

.v4b-Layers:before {
  content: "\eafe";
}

.v4b-Leaf:before {
  content: "\eaff";
}

.v4b-Left-Arrow-2:before {
  content: "\eb00";
}

.v4b-Lemon-Juicer:before {
  content: "\eb01";
}

.v4b-Lifebelt:before {
  content: "\eb02";
}

.v4b-Lifebuoy:before {
  content: "\eb03";
}

.v4b-Lightbulb:before {
  content: "\eb04";
}

.v4b-Lighthouse:before {
  content: "\eb05";
}

.v4b-Limonade-1:before {
  content: "\eb06";
}

.v4b-Limonade-2:before {
  content: "\eb07";
}

.v4b-Line-Chart:before {
  content: "\eb08";
}

.v4b-Link:before {
  content: "\eb09";
}

.v4b-List:before {
  content: "\eb0a";
}

.v4b-Location-2:before {
  content: "\eb0b";
}

.v4b-Lock-Open:before {
  content: "\eb0c";
}

.v4b-Lock-User-2:before {
  content: "\eb0d";
}

.v4b-Lock-User:before {
  content: "\eb0e";
}

.v4b-Lock-Users:before {
  content: "\eb0f";
}

.v4b-Lock:before {
  content: "\eb10";
}

.v4b-Loction-1:before {
  content: "\eb11";
}

.v4b-Login:before {
  content: "\eb12";
}

.v4b-Logout:before {
  content: "\eb13";
}

.v4b-Lollipop:before {
  content: "\eb14";
}

.v4b-Loudspeaker:before {
  content: "\eb15";
}

.v4b-Magnet:before {
  content: "\eb16";
}

.v4b-Maid:before {
  content: "\eb17";
}

.v4b-Mail-Attachmet:before {
  content: "\eb18";
}

.v4b-Mail:before {
  content: "\eb19";
}

.v4b-Mailbox:before {
  content: "\eb1a";
}

.v4b-Mails:before {
  content: "\eb1b";
}

.v4b-Male:before {
  content: "\eb1c";
}

.v4b-Man-1:before {
  content: "\eb1d";
}

.v4b-Man-2:before {
  content: "\eb1e";
}

.v4b-Man-3:before {
  content: "\eb1f";
}

.v4b-Man-Beard:before {
  content: "\eb20";
}

.v4b-Man-Cap:before {
  content: "\eb21";
}

.v4b-Man-Glasses:before {
  content: "\eb22";
}

.v4b-Man-Hat:before {
  content: "\eb23";
}

.v4b-Map:before {
  content: "\eb24";
}

.v4b-Mariage-Couple:before {
  content: "\eb25";
}

.v4b-Mark-User-2:before {
  content: "\eb26";
}

.v4b-Mark-User:before {
  content: "\eb27";
}

.v4b-Mark-Users:before {
  content: "\eb28";
}

.v4b-Marriage-Settlement:before {
  content: "\eb29";
}

.v4b-Marvin:before {
  content: "\eb2a";
}

.v4b-Matches:before {
  content: "\eb2b";
}

.v4b-Maximize:before {
  content: "\eb2c";
}

.v4b-Measuring-Cup:before {
  content: "\eb2d";
}

.v4b-Meat-Fork:before {
  content: "\eb2e";
}

.v4b-Meat-Grinder:before {
  content: "\eb2f";
}

.v4b-Medal:before {
  content: "\eb30";
}

.v4b-Men-4:before {
  content: "\eb31";
}

.v4b-Menu-1:before {
  content: "\eb32";
}

.v4b-Menu-2:before {
  content: "\eb33";
}

.v4b-Menu:before {
  content: "\eb34";
}

.v4b-Microphone-1:before {
  content: "\eb35";
}

.v4b-Microphone-2:before {
  content: "\eb36";
}

.v4b-Microwave:before {
  content: "\eb37";
}

.v4b-Mini-Dress:before {
  content: "\eb38";
}

.v4b-Minimize:before {
  content: "\eb39";
}

.v4b-Mobile-Phone:before {
  content: "\eb3a";
}

.v4b-Money:before {
  content: "\eb3b";
}

.v4b-Mouse-Pointer-1:before {
  content: "\eb3c";
}

.v4b-Mouse-Pointer-2:before {
  content: "\eb3d";
}

.v4b-Mouse:before {
  content: "\eb3e";
}

.v4b-Mug:before {
  content: "\eb3f";
}

.v4b-Mute:before {
  content: "\eb40";
}

.v4b-Napkin:before {
  content: "\eb41";
}

.v4b-News:before {
  content: "\eb42";
}

.v4b-Newsfeed:before {
  content: "\eb43";
}

.v4b-Night-Candle:before {
  content: "\eb44";
}

.v4b-No-Battery:before {
  content: "\eb45";
}

.v4b-No-Camera:before {
  content: "\eb46";
}

.v4b-No-Copyright-2:before {
  content: "\eb47";
}

.v4b-No-Copyright:before {
  content: "\eb48";
}

.v4b-No-Copzright-3:before {
  content: "\eb49";
}

.v4b-No-Flash:before {
  content: "\eb4a";
}

.v4b-No-Microphone:before {
  content: "\eb4b";
}

.v4b-No-Wifi-Tethering:before {
  content: "\eb4c";
}

.v4b-Note-Book:before {
  content: "\eb4d";
}

.v4b-Notebook:before {
  content: "\eb4e";
}

.v4b-Nurse:before {
  content: "\eb4f";
}

.v4b-Old-Man:before {
  content: "\eb50";
}

.v4b-Olive-Oil-Pot:before {
  content: "\eb51";
}

.v4b-Open-Folder:before {
  content: "\eb52";
}

.v4b-Open-Mail:before {
  content: "\eb53";
}

.v4b-Open:before {
  content: "\eb54";
}

.v4b-Optimus-Prime:before {
  content: "\eb55";
}

.v4b-Other:before {
  content: "\eb56";
}

.v4b-Outlet-Symbol:before {
  content: "\eb57";
}

.v4b-Oval-Frame-1:before {
  content: "\eb58";
}

.v4b-Oval-Frame-2:before {
  content: "\eb59";
}

.v4b-Oven-Gloves:before {
  content: "\eb5a";
}

.v4b-Owl:before {
  content: "\eb5b";
}

.v4b-Palette:before {
  content: "\eb5c";
}

.v4b-Pan:before {
  content: "\eb5d";
}

.v4b-Pancake-Griddle:before {
  content: "\eb5e";
}

.v4b-Pantheon:before {
  content: "\eb5f";
}

.v4b-Paper-Plane:before {
  content: "\eb60";
}

.v4b-Paperclip:before {
  content: "\eb61";
}

.v4b-Parabolic-Antenna:before {
  content: "\eb62";
}

.v4b-Park-2:before {
  content: "\eb63";
}

.v4b-Park-3:before {
  content: "\eb64";
}

.v4b-Park:before {
  content: "\eb65";
}

.v4b-Party-Cake:before {
  content: "\eb66";
}

.v4b-Party-Glasses:before {
  content: "\eb67";
}

.v4b-Party-Greetings-2:before {
  content: "\eb68";
}

.v4b-Pastry-Bag-1:before {
  content: "\eb69";
}

.v4b-Pastry-Bag-2:before {
  content: "\eb6a";
}

.v4b-Pause:before {
  content: "\eb6b";
}

.v4b-Paying-1:before {
  content: "\eb6c";
}

.v4b-Paying-2:before {
  content: "\eb6d";
}

.v4b-Peeler:before {
  content: "\eb6e";
}

.v4b-Pencil:before {
  content: "\eb6f";
}

.v4b-Pepper-Pot-1:before {
  content: "\eb70";
}

.v4b-Pepper-Pot-2:before {
  content: "\eb71";
}

.v4b-Percent:before {
  content: "\eb72";
}

.v4b-Percentage-2:before {
  content: "\eb73";
}

.v4b-Percentage-3:before {
  content: "\eb74";
}

.v4b-Percentage:before {
  content: "\eb75";
}

.v4b-Phone:before {
  content: "\eb76";
}

.v4b-Photo-frame-4:before {
  content: "\eb77";
}

.v4b-Photo-frame-2:before {
  content: "\eb78";
}

.v4b-Photo-frame-3:before {
  content: "\eb79";
}

.v4b-Photo-frame-5:before {
  content: "\eb7a";
}

.v4b-Photo-frame-6:before {
  content: "\eb7b";
}

.v4b-Photo-frame-7:before {
  content: "\eb7c";
}

.v4b-Photo-frame:before {
  content: "\eb7d";
}

.v4b-Photo:before {
  content: "\eb7e";
}

.v4b-Picture:before {
  content: "\eb7f";
}

.v4b-Pie-Chart-1:before {
  content: "\eb80";
}

.v4b-Pie-Chart-2:before {
  content: "\eb81";
}

.v4b-Pillar:before {
  content: "\eb82";
}

.v4b-Pin-1:before {
  content: "\eb83";
}

.v4b-Pin-2:before {
  content: "\eb84";
}

.v4b-Pinata:before {
  content: "\eb85";
}

.v4b-Pizza-Cutter:before {
  content: "\eb86";
}

.v4b-Pizza-Food:before {
  content: "\eb87";
}

.v4b-Pizza-Slice:before {
  content: "\eb88";
}

.v4b-Pizza:before {
  content: "\eb89";
}

.v4b-Plate:before {
  content: "\eb8a";
}

.v4b-Play:before {
  content: "\eb8b";
}

.v4b-Plugin-1:before {
  content: "\eb8c";
}

.v4b-Plugin-2:before {
  content: "\eb8d";
}

.v4b-Policeman:before {
  content: "\eb8e";
}

.v4b-Pompkin:before {
  content: "\eb8f";
}

.v4b-Pool-Ladder:before {
  content: "\eb90";
}

.v4b-Pool:before {
  content: "\eb91";
}

.v4b-Popcorn:before {
  content: "\eb92";
}

.v4b-Pot-1:before {
  content: "\eb93";
}

.v4b-Pot-2:before {
  content: "\eb94";
}

.v4b-Potato-Musher:before {
  content: "\eb95";
}

.v4b-Pound-2:before {
  content: "\eb96";
}

.v4b-Pound-3:before {
  content: "\eb97";
}

.v4b-Pound:before {
  content: "\eb98";
}

.v4b-Power:before {
  content: "\eb99";
}

.v4b-Preferences-1:before {
  content: "\eb9a";
}

.v4b-Preferences-2:before {
  content: "\eb9b";
}

.v4b-Present-1:before {
  content: "\eb9c";
}

.v4b-Present-2:before {
  content: "\eb9d";
}

.v4b-Present-3:before {
  content: "\eb9e";
}

.v4b-Present-4:before {
  content: "\eb9f";
}

.v4b-Present-5:before {
  content: "\eba0";
}

.v4b-Present-Cake:before {
  content: "\eba1";
}

.v4b-Present:before {
  content: "\eba2";
}

.v4b-Presentation:before {
  content: "\eba3";
}

.v4b-Print:before {
  content: "\eba4";
}

.v4b-Prisoner:before {
  content: "\eba5";
}

.v4b-Protection:before {
  content: "\eba6";
}

.v4b-Punk:before {
  content: "\eba7";
}

.v4b-Question-2:before {
  content: "\eba8";
}

.v4b-Question-3:before {
  content: "\eba9";
}

.v4b-Question-Mark:before {
  content: "\ebaa";
}

.v4b-Question:before {
  content: "\ebab";
}

.v4b-Radar:before {
  content: "\ebac";
}

.v4b-Radio:before {
  content: "\ebad";
}

.v4b-Recicle-User-2:before {
  content: "\ebae";
}

.v4b-Recicle-User:before {
  content: "\ebaf";
}

.v4b-Recicle-Users:before {
  content: "\ebb0";
}

.v4b-Record-1:before {
  content: "\ebb1";
}

.v4b-Record-2:before {
  content: "\ebb2";
}

.v4b-Rectangle-Frame-1:before {
  content: "\ebb3";
}

.v4b-Rectangle-Frame-2:before {
  content: "\ebb4";
}

.v4b-Rectangle-Frame-3:before {
  content: "\ebb5";
}

.v4b-Rectangle-Frame-4:before {
  content: "\ebb6";
}

.v4b-Rectangle-Frame-5:before {
  content: "\ebb7";
}

.v4b-Rectangle-Frame-6:before {
  content: "\ebb8";
}

.v4b-Rectangle-Frame-7:before {
  content: "\ebb9";
}

.v4b-Rectangle-Frame-8:before {
  content: "\ebba";
}

.v4b-Rectangle-Frame-9:before {
  content: "\ebbb";
}

.v4b-Rectangle-Frame-10:before {
  content: "\ebbc";
}

.v4b-Rectangle-Frame-11:before {
  content: "\ebbd";
}

.v4b-Rectangle-Frame-12:before {
  content: "\ebbe";
}

.v4b-Rectangle-Frame-13:before {
  content: "\ebbf";
}

.v4b-Refresh-2:before {
  content: "\ebc0";
}

.v4b-Refresh-Users:before {
  content: "\ebc1";
}

.v4b-Refresh:before {
  content: "\ebc2";
}

.v4b-Refrigerator:before {
  content: "\ebc3";
}

.v4b-Reindeer:before {
  content: "\ebc4";
}

.v4b-Reload:before {
  content: "\ebc5";
}

.v4b-Remove-Event:before {
  content: "\ebc6";
}

.v4b-Remove-Shopping-Cart:before {
  content: "\ebc7";
}

.v4b-Remove-User-2:before {
  content: "\ebc8";
}

.v4b-Remove-User:before {
  content: "\ebc9";
}

.v4b-Remove-Users:before {
  content: "\ebca";
}

.v4b-Remove:before {
  content: "\ebcb";
}

.v4b-Reply:before {
  content: "\ebcc";
}

.v4b-Reserved-1:before {
  content: "\ebcd";
}

.v4b-Reserved-2:before {
  content: "\ebce";
}

.v4b-Restaurant-Place:before {
  content: "\ebcf";
}

.v4b-Restaurant-sign-1:before {
  content: "\ebd0";
}

.v4b-Restaurant-Sign-2:before {
  content: "\ebd1";
}

.v4b-Restaurant-Sign-3:before {
  content: "\ebd2";
}

.v4b-Retro-Bot-1:before {
  content: "\ebd3";
}

.v4b-Retro-Bot-2:before {
  content: "\ebd4";
}

.v4b-Retro-Bot-3:before {
  content: "\ebd5";
}

.v4b-Retro-Bot-4:before {
  content: "\ebd6";
}

.v4b-Right-Arrow-2:before {
  content: "\ebd7";
}

.v4b-Roberto:before {
  content: "\ebd8";
}

.v4b-Robocop:before {
  content: "\ebd9";
}

.v4b-Robot-1:before {
  content: "\ebda";
}

.v4b-Robot-2:before {
  content: "\ebdb";
}

.v4b-Robot-3:before {
  content: "\ebdc";
}

.v4b-Robot-4:before {
  content: "\ebdd";
}

.v4b-Robot-5:before {
  content: "\ebde";
}

.v4b-Robot-6:before {
  content: "\ebdf";
}

.v4b-Robot-7:before {
  content: "\ebe0";
}

.v4b-Robot-8:before {
  content: "\ebe1";
}

.v4b-Robot-9:before {
  content: "\ebe2";
}

.v4b-Robot-10:before {
  content: "\ebe3";
}

.v4b-Robot-11:before {
  content: "\ebe4";
}

.v4b-Robot-12:before {
  content: "\ebe5";
}

.v4b-Robot-13:before {
  content: "\ebe6";
}

.v4b-Robot-Arm-1:before {
  content: "\ebe7";
}

.v4b-Robot-Arm-2:before {
  content: "\ebe8";
}

.v4b-Robot-Arm-3:before {
  content: "\ebe9";
}

.v4b-Robot-Arm-4:before {
  content: "\ebea";
}

.v4b-Robot-Arm-5:before {
  content: "\ebeb";
}

.v4b-Robot-Devil:before {
  content: "\ebec";
}

.v4b-Robot-Machine-1:before {
  content: "\ebed";
}

.v4b-Robot-Machine-2:before {
  content: "\ebee";
}

.v4b-Rocket:before {
  content: "\ebef";
}

.v4b-Rolling-Pin:before {
  content: "\ebf0";
}

.v4b-Roof-Plan:before {
  content: "\ebf1";
}

.v4b-Round-Frame-1:before {
  content: "\ebf2";
}

.v4b-Round-Frame-2:before {
  content: "\ebf3";
}

.v4b-Round-Frame-3:before {
  content: "\ebf4";
}

.v4b-Round-Frame:before {
  content: "\ebf5";
}

.v4b-Rug-1:before {
  content: "\ebf6";
}

.v4b-Rug-2:before {
  content: "\ebf7";
}

.v4b-Rug-3:before {
  content: "\ebf8";
}

.v4b-Ruler-1:before {
  content: "\ebf9";
}

.v4b-Ruler-2:before {
  content: "\ebfa";
}

.v4b-Ruler:before {
  content: "\ebfb";
}

.v4b-Sailboat:before {
  content: "\ebfc";
}

.v4b-Salt-Pot:before {
  content: "\ebfd";
}

.v4b-Salver:before {
  content: "\ebfe";
}

.v4b-Sand-Castle:before {
  content: "\ebff";
}

.v4b-Sand-Glass:before {
  content: "\ec00";
}

.v4b-Santa:before {
  content: "\ec01";
}

.v4b-Sauce-Boat:before {
  content: "\ec02";
}

.v4b-Sauce-Spoon:before {
  content: "\ec03";
}

.v4b-Sausage:before {
  content: "\ec04";
}

.v4b-Save:before {
  content: "\ec05";
}

.v4b-Scarf:before {
  content: "\ec06";
}

.v4b-Scream:before {
  content: "\ec07";
}

.v4b-Scythe:before {
  content: "\ec08";
}

.v4b-Search-User-2:before {
  content: "\ec09";
}

.v4b-Search-User:before {
  content: "\ec0a";
}

.v4b-Search-Users:before {
  content: "\ec0b";
}

.v4b-Search:before {
  content: "\ec0c";
}

.v4b-Section-Symbol:before {
  content: "\ec0d";
}

.v4b-Server:before {
  content: "\ec0e";
}

.v4b-Service:before {
  content: "\ec0f";
}

.v4b-Share-User-2:before {
  content: "\ec10";
}

.v4b-Share-User:before {
  content: "\ec11";
}

.v4b-Share-Users:before {
  content: "\ec12";
}

.v4b-Share:before {
  content: "\ec13";
}

.v4b-Sheep-2:before {
  content: "\ec14";
}

.v4b-Sheep:before {
  content: "\ec15";
}

.v4b-Shell-1:before {
  content: "\ec16";
}

.v4b-Shell-2:before {
  content: "\ec17";
}

.v4b-Shop:before {
  content: "\ec18";
}

.v4b-Shopping-2:before {
  content: "\ec19";
}

.v4b-Shopping-3:before {
  content: "\ec1a";
}

.v4b-Shopping-Bag:before {
  content: "\ec1b";
}

.v4b-Shopping-Basket:before {
  content: "\ec1c";
}

.v4b-Shopping-Cart:before {
  content: "\ec1d";
}

.v4b-Shopping:before {
  content: "\ec1e";
}

.v4b-Shot-Glasses:before {
  content: "\ec1f";
}

.v4b-Shower-Tray:before {
  content: "\ec20";
}

.v4b-Shuffle:before {
  content: "\ec21";
}

.v4b-Sieve:before {
  content: "\ec22";
}

.v4b-Signal:before {
  content: "\ec23";
}

.v4b-Signpost:before {
  content: "\ec24";
}

.v4b-Sim-Card:before {
  content: "\ec25";
}

.v4b-Sink-1:before {
  content: "\ec26";
}

.v4b-Sink-2:before {
  content: "\ec27";
}

.v4b-Skis:before {
  content: "\ec28";
}

.v4b-Skull-Cake:before {
  content: "\ec29";
}

.v4b-Skull:before {
  content: "\ec2a";
}

.v4b-Sky-Glasses:before {
  content: "\ec2b";
}

.v4b-Skyscraper:before {
  content: "\ec2c";
}

.v4b-Sledge:before {
  content: "\ec2d";
}

.v4b-Sleeping-2:before {
  content: "\ec2e";
}

.v4b-Sleeping-3:before {
  content: "\ec2f";
}

.v4b-Sleeping:before {
  content: "\ec30";
}

.v4b-Slippery-Road:before {
  content: "\ec31";
}

.v4b-Snow-Boots:before {
  content: "\ec32";
}

.v4b-Snow-Chain:before {
  content: "\ec33";
}

.v4b-Snow-Shoes-1:before {
  content: "\ec34";
}

.v4b-Snow-Shoes-2:before {
  content: "\ec35";
}

.v4b-Snow-Shovel:before {
  content: "\ec36";
}

.v4b-Snowboard:before {
  content: "\ec37";
}

.v4b-Snowcapped-House:before {
  content: "\ec38";
}

.v4b-Snowcapped-Mountains:before {
  content: "\ec39";
}

.v4b-Snowflake-1:before {
  content: "\ec3a";
}

.v4b-Snowflake-2:before {
  content: "\ec3b";
}

.v4b-Snowflake-3:before {
  content: "\ec3c";
}

.v4b-Snowflake-4:before {
  content: "\ec3d";
}

.v4b-Snowing:before {
  content: "\ec3e";
}

.v4b-Snowman:before {
  content: "\ec3f";
}

.v4b-Snowmobile:before {
  content: "\ec40";
}

.v4b-Sofa-1:before {
  content: "\ec41";
}

.v4b-Sofa-2:before {
  content: "\ec42";
}

.v4b-Sofa-3:before {
  content: "\ec43";
}

.v4b-Sofa-4:before {
  content: "\ec44";
}

.v4b-Sofa-5:before {
  content: "\ec45";
}

.v4b-Sofa-6:before {
  content: "\ec46";
}

.v4b-Soldier:before {
  content: "\ec47";
}

.v4b-Soup:before {
  content: "\ec48";
}

.v4b-Speach-Bubble:before {
  content: "\ec49";
}

.v4b-Speach-Bubbles:before {
  content: "\ec4a";
}

.v4b-Speaker:before {
  content: "\ec4b";
}

.v4b-Spices:before {
  content: "\ec4c";
}

.v4b-Spider:before {
  content: "\ec4d";
}

.v4b-Spoon:before {
  content: "\ec4e";
}

.v4b-Square-Frame-1:before {
  content: "\ec4f";
}

.v4b-Square-Frame-2:before {
  content: "\ec50";
}

.v4b-Square-Frame-3:before {
  content: "\ec51";
}

.v4b-Square-Frame-4:before {
  content: "\ec52";
}

.v4b-Square-Frame-5:before {
  content: "\ec53";
}

.v4b-Stairs:before {
  content: "\ec54";
}

.v4b-Stand-Mixer:before {
  content: "\ec55";
}

.v4b-Star:before {
  content: "\ec56";
}

.v4b-Starfish:before {
  content: "\ec57";
}

.v4b-Stop:before {
  content: "\ec58";
}

.v4b-Stove:before {
  content: "\ec59";
}

.v4b-Strand-Bag:before {
  content: "\ec5a";
}

.v4b-Strand-Ball:before {
  content: "\ec5b";
}

.v4b-Straw-Hat:before {
  content: "\ec5c";
}

.v4b-Sugar-Bowl:before {
  content: "\ec5d";
}

.v4b-Suitcase:before {
  content: "\ec5e";
}

.v4b-Summer-Time:before {
  content: "\ec5f";
}

.v4b-Sun-Light:before {
  content: "\ec60";
}

.v4b-Sun:before {
  content: "\ec61";
}

.v4b-Suncream:before {
  content: "\ec62";
}

.v4b-Sunglasses:before {
  content: "\ec63";
}

.v4b-Sunset:before {
  content: "\ec64";
}

.v4b-Sunshade:before {
  content: "\ec65";
}

.v4b-Suntan-Oil:before {
  content: "\ec66";
}

.v4b-Superman:before {
  content: "\ec67";
}

.v4b-Surf-Board:before {
  content: "\ec68";
}

.v4b-Sushi:before {
  content: "\ec69";
}

.v4b-Sweater-1:before {
  content: "\ec6a";
}

.v4b-Sweater-2:before {
  content: "\ec6b";
}

.v4b-Sweater-3:before {
  content: "\ec6c";
}

.v4b-Sweet-16:before {
  content: "\ec6d";
}

.v4b-Swimming-Trunks:before {
  content: "\ec6e";
}

.v4b-Switch-Button-1:before {
  content: "\ec6f";
}

.v4b-Switch-Button-2:before {
  content: "\ec70";
}

.v4b-Syncronizaion:before {
  content: "\ec71";
}

.v4b-Table-1:before {
  content: "\ec72";
}

.v4b-Table-2:before {
  content: "\ec73";
}

.v4b-Table-3:before {
  content: "\ec74";
}

.v4b-Table-4:before {
  content: "\ec75";
}

.v4b-Table-Cad:before {
  content: "\ec76";
}

.v4b-Table:before {
  content: "\ec77";
}

.v4b-Tag:before {
  content: "\ec78";
}

.v4b-Tape-Measure:before {
  content: "\ec79";
}

.v4b-Target:before {
  content: "\ec7a";
}

.v4b-Tea-Sieve:before {
  content: "\ec7b";
}

.v4b-Teapot:before {
  content: "\ec7c";
}

.v4b-Temperature:before {
  content: "\ec7d";
}

.v4b-Tent:before {
  content: "\ec7e";
}

.v4b-Thermometer-1:before {
  content: "\ec7f";
}

.v4b-Thermometer-2:before {
  content: "\ec80";
}

.v4b-Thumbs-Down:before {
  content: "\ec81";
}

.v4b-Thumbs-Up:before {
  content: "\ec82";
}

.v4b-Ticket:before {
  content: "\ec83";
}

.v4b-Time-2:before {
  content: "\ec84";
}

.v4b-Time-3:before {
  content: "\ec85";
}

.v4b-Time:before {
  content: "\ec86";
}

.v4b-Toast:before {
  content: "\ec87";
}

.v4b-Toilet-Paper:before {
  content: "\ec88";
}

.v4b-Toilette:before {
  content: "\ec89";
}

.v4b-Tools-1:before {
  content: "\ec8a";
}

.v4b-Tools-2:before {
  content: "\ec8b";
}

.v4b-Toos:before {
  content: "\ec8c";
}

.v4b-Towel:before {
  content: "\ec8d";
}

.v4b-Transmitter:before {
  content: "\ec8e";
}

.v4b-Trash-Full:before {
  content: "\ec8f";
}

.v4b-Trash:before {
  content: "\ec90";
}

.v4b-Tree-Ghost:before {
  content: "\ec91";
}

.v4b-Truffles:before {
  content: "\ec92";
}

.v4b-T-Shirt-1:before {
  content: "\ec93";
}

.v4b-T-Shirt-2:before {
  content: "\ec94";
}

.v4b-Tub-1:before {
  content: "\ec95";
}

.v4b-Tub-2:before {
  content: "\ec96";
}

.v4b-Tulip-2:before {
  content: "\ec97";
}

.v4b-Tulip:before {
  content: "\ec98";
}

.v4b-Tv-Stand:before {
  content: "\ec99";
}

.v4b-Type:before {
  content: "\ec9a";
}

.v4b-Umbrella:before {
  content: "\ec9b";
}

.v4b-Undo:before {
  content: "\ec9c";
}

.v4b-Up-Arrow:before {
  content: "\ec9d";
}

.v4b-Upgrade:before {
  content: "\ec9e";
}

.v4b-Upload-2:before {
  content: "\ec9f";
}

.v4b-Upload-3:before {
  content: "\eca0";
}

.v4b-Upload-Cloud:before {
  content: "\eca1";
}

.v4b-Upload:before {
  content: "\eca2";
}

.v4b-USB-Device:before {
  content: "\eca3";
}

.v4b-USB:before {
  content: "\eca4";
}

.v4b-User-1:before {
  content: "\eca5";
}

.v4b-User-2:before {
  content: "\eca6";
}

.v4b-User-Car:before {
  content: "\eca7";
}

.v4b-User-Card-2:before {
  content: "\eca8";
}

.v4b-User-Card:before {
  content: "\eca9";
}

.v4b-User-Cloud:before {
  content: "\ecaa";
}

.v4b-User-Database:before {
  content: "\ecab";
}

.v4b-User-Defense-2:before {
  content: "\ecac";
}

.v4b-User-Defense:before {
  content: "\ecad";
}

.v4b-User-Download:before {
  content: "\ecae";
}

.v4b-User-Home:before {
  content: "\ecaf";
}

.v4b-User-Key-2:before {
  content: "\ecb0";
}

.v4b-User-Key:before {
  content: "\ecb1";
}

.v4b-User-Love-2:before {
  content: "\ecb2";
}

.v4b-User-Love:before {
  content: "\ecb3";
}

.v4b-User-Place:before {
  content: "\ecb4";
}

.v4b-User-Rate-2:before {
  content: "\ecb5";
}

.v4b-User-Refresh:before {
  content: "\ecb6";
}

.v4b-User-Settings-2:before {
  content: "\ecb7";
}

.v4b-User-Settings:before {
  content: "\ecb8";
}

.v4b-User-Speed-2:before {
  content: "\ecb9";
}

.v4b-User-Speed:before {
  content: "\ecba";
}

.v4b-User-Statistics-2:before {
  content: "\ecbb";
}

.v4b-User-Statistics:before {
  content: "\ecbc";
}

.v4b-User-Time:before {
  content: "\ecbd";
}

.v4b-User-Upload:before {
  content: "\ecbe";
}

.v4b-User-Wifi:before {
  content: "\ecbf";
}

.v4b-Users-Card:before {
  content: "\ecc0";
}

.v4b-Users-Defense:before {
  content: "\ecc1";
}

.v4b-Users-Key:before {
  content: "\ecc2";
}

.v4b-Users-Love:before {
  content: "\ecc3";
}

.v4b-Users-Place:before {
  content: "\ecc4";
}

.v4b-Users-Settings:before {
  content: "\ecc5";
}

.v4b-Users-Speed-3:before {
  content: "\ecc6";
}

.v4b-Users-Statistics:before {
  content: "\ecc7";
}

.v4b-Users:before {
  content: "\ecc8";
}

.v4b-Vacation-Photo:before {
  content: "\ecc9";
}

.v4b-Video:before {
  content: "\ecca";
}

.v4b-VIP-Ticket:before {
  content: "\eccb";
}

.v4b-VIP:before {
  content: "\eccc";
}

.v4b-Volume:before {
  content: "\eccd";
}

.v4b-Waiter:before {
  content: "\ecce";
}

.v4b-Waiting-User-2:before {
  content: "\eccf";
}

.v4b-Waiting-User:before {
  content: "\ecd0";
}

.v4b-Waiting-Users:before {
  content: "\ecd1";
}

.v4b-Waitress:before {
  content: "\ecd2";
}

.v4b-Wall-E:before {
  content: "\ecd3";
}

.v4b-Wallet-2:before {
  content: "\ecd4";
}

.v4b-Wallet:before {
  content: "\ecd5";
}

.v4b-Watch:before {
  content: "\ecd6";
}

.v4b-Webpage:before {
  content: "\ecd7";
}

.v4b-Wedding-Altar-1:before {
  content: "\ecd8";
}

.v4b-Wedding-Altar-2:before {
  content: "\ecd9";
}

.v4b-Wedding-Bouquet-2:before {
  content: "\ecda";
}

.v4b-Wedding-Bouquet:before {
  content: "\ecdb";
}

.v4b-Wedding-Cake-1:before {
  content: "\ecdc";
}

.v4b-Wedding-Cake-2:before {
  content: "\ecdd";
}

.v4b-Wedding-Cake-3:before {
  content: "\ecde";
}

.v4b-Wedding-Car:before {
  content: "\ecdf";
}

.v4b-Wedding-Date:before {
  content: "\ece0";
}

.v4b-Wedding-Decoration-1:before {
  content: "\ece1";
}

.v4b-Wedding-Decoration-2:before {
  content: "\ece2";
}

.v4b-Wedding-Invitation-2:before {
  content: "\ece3";
}

.v4b-Wedding-Invitation:before {
  content: "\ece4";
}

.v4b-Wedding-music:before {
  content: "\ece5";
}

.v4b-wedding-party:before {
  content: "\ece6";
}

.v4b-Wedding-Photo:before {
  content: "\ece7";
}

.v4b-Wedding-Planner-Book:before {
  content: "\ece8";
}

.v4b-Wedding-Present-1:before {
  content: "\ece9";
}

.v4b-Wedding-Present-2:before {
  content: "\ecea";
}

.v4b-Wedding-Ring:before {
  content: "\eceb";
}

.v4b-Wedding-Tiara:before {
  content: "\ecec";
}

.v4b-Wedding-Way-Sign:before {
  content: "\eced";
}

.v4b-Whetting:before {
  content: "\ecee";
}

.v4b-Wifi-1:before {
  content: "\ecef";
}

.v4b-Wifi-2:before {
  content: "\ecf0";
}

.v4b-Wifi-3:before {
  content: "\ecf1";
}

.v4b-Wifi-Tethering:before {
  content: "\ecf2";
}

.v4b-Wifi:before {
  content: "\ecf3";
}

.v4b-Wine-Glass:before {
  content: "\ecf4";
}

.v4b-Wine-List:before {
  content: "\ecf5";
}

.v4b-Wine:before {
  content: "\ecf6";
}

.v4b-Winter-Calendar:before {
  content: "\ecf7";
}

.v4b-Winter-Cap-1:before {
  content: "\ecf8";
}

.v4b-Winter-Cap-2:before {
  content: "\ecf9";
}

.v4b-Winter-Coat:before {
  content: "\ecfa";
}

.v4b-Winter-Sock:before {
  content: "\ecfb";
}

.v4b-Winter-Socks:before {
  content: "\ecfc";
}

.v4b-Witch-Broom:before {
  content: "\ecfd";
}

.v4b-Witch-Cauldron:before {
  content: "\ecfe";
}

.v4b-Witch:before {
  content: "\ecff";
}

.v4b-Wok-1:before {
  content: "\ed00";
}

.v4b-Wok-2:before {
  content: "\ed01";
}

.v4b-Woman-1:before {
  content: "\ed02";
}

.v4b-Woman-2:before {
  content: "\ed03";
}

.v4b-Woman-3:before {
  content: "\ed04";
}

.v4b-Woman-4:before {
  content: "\ed05";
}

.v4b-Woman-Cap:before {
  content: "\ed06";
}

.v4b-Woman-Glasses:before {
  content: "\ed07";
}

.v4b-Woman-Hat:before {
  content: "\ed08";
}

.v4b-Wooden-House:before {
  content: "\ed09";
}

.v4b-Woodsman:before {
  content: "\ed0a";
}

.v4b-Work-Table:before {
  content: "\ed0b";
}

.v4b-Worker:before {
  content: "\ed0c";
}

.v4b-Workspace-1:before {
  content: "\ed0d";
}

.v4b-Workspace-2:before {
  content: "\ed0e";
}

.v4b-Writing-User-2:before {
  content: "\ed0f";
}

.v4b-Writing-User:before {
  content: "\ed10";
}

.v4b-Writing-Users:before {
  content: "\ed11";
}

.v4b-Zoom-In:before {
  content: "\ed12";
}

.v4b-Zoom-Out:before {
  content: "\ed13";
}
