html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 13px;
}

.default-wrapper {
  max-width: 1200px;
  left: 0;
  right: 0;
  margin: auto;
}

.narrow-wrapper {
  max-width: 1080px;
  left: 0;
  right: 0;
  margin: auto;
}

