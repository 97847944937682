@font-face {
  font-family: 'v2b';
  src: url('fonts/v2b.woff?g563h4') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="v2b-"], [class*=" v2b-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v2b' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v2b-year-aniversary-2:before {
  content: "\e900";
}

.v2b-year-aniversary:before {
  content: "\e901";
}

.v2b-st-Place:before {
  content: "\e902";
}

.v2b-nd-Place:before {
  content: "\e903";
}

.v2b-D-File:before {
  content: "\e904";
}

.v2b-DS:before {
  content: "\e905";
}

.v2b-rd-Place:before {
  content: "\e906";
}

.v2b-Stars:before {
  content: "\e907";
}

.v2b-year-aniversary-22:before {
  content: "\e908";
}

.v2b-year-aniversary2:before {
  content: "\e909";
}

.v2b-year-aniversary-23:before {
  content: "\e90a";
}

.v2b-year-aniversary3:before {
  content: "\e90b";
}

.v2b-Accessibility:before {
  content: "\e90c";
}

.v2b-Acorn:before {
  content: "\e90d";
}

.v2b-Add-App:before {
  content: "\e90e";
}

.v2b-Add-Award-Badge:before {
  content: "\e90f";
}

.v2b-Add-Heart:before {
  content: "\e910";
}

.v2b-Add-House:before {
  content: "\e911";
}

.v2b-Add-Note:before {
  content: "\e912";
}

.v2b-Add-Post-It:before {
  content: "\e913";
}

.v2b-Add-Rating:before {
  content: "\e914";
}

.v2b-Add-Task:before {
  content: "\e915";
}

.v2b-Addon:before {
  content: "\e916";
}

.v2b-Air-balloon:before {
  content: "\e917";
}

.v2b-Air-condition:before {
  content: "\e918";
}

.v2b-Airplane:before {
  content: "\e919";
}

.v2b-Airport:before {
  content: "\e91a";
}

.v2b-Allert-app:before {
  content: "\e91b";
}

.v2b-Allow-Copying:before {
  content: "\e91c";
}

.v2b-Allow-Redistribution:before {
  content: "\e91d";
}

.v2b-Almond:before {
  content: "\e91e";
}

.v2b-Analitics:before {
  content: "\e91f";
}

.v2b-Ananas:before {
  content: "\e920";
}

.v2b-Anchor:before {
  content: "\e921";
}

.v2b-Angle-Ruler:before {
  content: "\e922";
}

.v2b-App-1:before {
  content: "\e923";
}

.v2b-APP-2:before {
  content: "\e924";
}

.v2b-App-Error:before {
  content: "\e925";
}

.v2b-App-Settings:before {
  content: "\e926";
}

.v2b-App-Tile-Task:before {
  content: "\e927";
}

.v2b-App-Window:before {
  content: "\e928";
}

.v2b-Apple:before {
  content: "\e929";
}

.v2b-Application-User:before {
  content: "\e92a";
}

.v2b-Application:before {
  content: "\e92b";
}

.v2b-Arc-de-Triomphe:before {
  content: "\e92c";
}

.v2b-Architecture:before {
  content: "\e92d";
}

.v2b-Arrived:before {
  content: "\e92e";
}

.v2b-Arriving:before {
  content: "\e92f";
}

.v2b-Artichoke:before {
  content: "\e930";
}

.v2b-Asparagus:before {
  content: "\e931";
}

.v2b-Atach-Post-It:before {
  content: "\e932";
}

.v2b-Atention-Note:before {
  content: "\e933";
}

.v2b-Atention-Task:before {
  content: "\e934";
}

.v2b-Attach-App:before {
  content: "\e935";
}

.v2b-Attache-Note:before {
  content: "\e936";
}

.v2b-Attache-Task:before {
  content: "\e937";
}

.v2b-Attention-House:before {
  content: "\e938";
}

.v2b-Attention-Post-It:before {
  content: "\e939";
}

.v2b-Attention:before {
  content: "\e93a";
}

.v2b-Avocado:before {
  content: "\e93b";
}

.v2b-Award-1:before {
  content: "\e93c";
}

.v2b-Award-2:before {
  content: "\e93d";
}

.v2b-Award-Badge-Shopping:before {
  content: "\e93e";
}

.v2b-Award-Stamp-2:before {
  content: "\e93f";
}

.v2b-Award-Stamp:before {
  content: "\e940";
}

.v2b-Axe:before {
  content: "\e941";
}

.v2b-Badge-Star:before {
  content: "\e942";
}

.v2b-Bag-conveyer:before {
  content: "\e943";
}

.v2b-Bag-weight:before {
  content: "\e944";
}

.v2b-Bag:before {
  content: "\e945";
}

.v2b-Bakelite:before {
  content: "\e946";
}

.v2b-Ball:before {
  content: "\e947";
}

.v2b-Banana:before {
  content: "\e948";
}

.v2b-Bank-1:before {
  content: "\e949";
}

.v2b-Bank-2:before {
  content: "\e94a";
}

.v2b-Barber-Shop:before {
  content: "\e94b";
}

/*.v2b-Barber's-Razor:before {*/
/*content: "\e94c";*/
/*}*/
.v2b-Barcode-App:before {
  content: "\e94d";
}

.v2b-Barn:before {
  content: "\e94e";
}

.v2b-Barrow:before {
  content: "\e94f";
}

.v2b-Basic-Shape-2:before {
  content: "\e950";
}

.v2b-Basic-Shape:before {
  content: "\e951";
}

.v2b-Basketball-Cup:before {
  content: "\e952";
}

.v2b-Bathroom-Cabinet-1:before {
  content: "\e953";
}

.v2b-Batroom-Cabinet-2:before {
  content: "\e954";
}

.v2b-Beard-1:before {
  content: "\e955";
}

.v2b-Beard-2:before {
  content: "\e956";
}

.v2b-Beard-3:before {
  content: "\e957";
}

.v2b-Beard-4:before {
  content: "\e958";
}

.v2b-Bed-1:before {
  content: "\e959";
}

.v2b-Bed-2:before {
  content: "\e95a";
}

.v2b-Bed-3:before {
  content: "\e95b";
}

.v2b-Bed-4:before {
  content: "\e95c";
}

.v2b-Bench:before {
  content: "\e95d";
}

.v2b-Berlin-TV-Tower:before {
  content: "\e95e";
}

.v2b-Bicycle-1:before {
  content: "\e95f";
}

.v2b-Bicycle-2:before {
  content: "\e960";
}

.v2b-Big-Ben:before {
  content: "\e961";
}

.v2b-Binar-Code-2:before {
  content: "\e962";
}

.v2b-Binar-Code:before {
  content: "\e963";
}

.v2b-Bishop-1:before {
  content: "\e964";
}

.v2b-Bishop-2:before {
  content: "\e965";
}

.v2b-Bitcoin-App:before {
  content: "\e966";
}

.v2b-Blueberries:before {
  content: "\e967";
}

.v2b-Bologna:before {
  content: "\e968";
}

.v2b-Booklet-Female-1:before {
  content: "\e969";
}

.v2b-Booklet-Female-2:before {
  content: "\e96a";
}

.v2b-Booklet-Male-1:before {
  content: "\e96b";
}

.v2b-Booklet-Male-2:before {
  content: "\e96c";
}

.v2b-Bookmark-App:before {
  content: "\e96d";
}

.v2b-Bow-1:before {
  content: "\e96e";
}

.v2b-Bow-2:before {
  content: "\e96f";
}

.v2b-Brick-Gouse:before {
  content: "\e970";
}

.v2b-Brick:before {
  content: "\e971";
}

.v2b-Bridge-1:before {
  content: "\e972";
}

.v2b-Bridge-2:before {
  content: "\e973";
}

.v2b-Broccoli:before {
  content: "\e974";
}

.v2b-Bucket:before {
  content: "\e975";
}

.v2b-Bug-Fix:before {
  content: "\e976";
}

.v2b-Bug:before {
  content: "\e977";
}

.v2b-BullDozer:before {
  content: "\e978";
}

.v2b-Burj-Khalifa-Dubai:before {
  content: "\e979";
}

.v2b-Bus-Station:before {
  content: "\e97a";
}

.v2b-C-plus-plus-1:before {
  content: "\e97b";
}

.v2b-C-plus-plus-2:before {
  content: "\e97c";
}

.v2b-Cabbage:before {
  content: "\e97d";
}

.v2b-Cabinet-1:before {
  content: "\e97e";
}

.v2b-Cabinet-2:before {
  content: "\e97f";
}

.v2b-Cabinet-3:before {
  content: "\e980";
}

.v2b-Cabinet-4:before {
  content: "\e981";
}

.v2b-Cabinet-5:before {
  content: "\e982";
}

.v2b-Calippers:before {
  content: "\e983";
}

.v2b-Camera:before {
  content: "\e984";
}

.v2b-Caravan:before {
  content: "\e985";
}

.v2b-Carrot:before {
  content: "\e986";
}

.v2b-Cashew:before {
  content: "\e987";
}

.v2b-Castle:before {
  content: "\e988";
}

.v2b-Castte:before {
  content: "\e989";
}

.v2b-Cement-Trovel:before {
  content: "\e98a";
}

.v2b-Cemetery:before {
  content: "\e98b";
}

.v2b-Central-Television-Beijing:before {
  content: "\e98c";
}

.v2b-Chain-Sawe:before {
  content: "\e98d";
}

.v2b-Chair-1:before {
  content: "\e98e";
}

.v2b-Chair-2:before {
  content: "\e98f";
}

.v2b-Chair-3:before {
  content: "\e990";
}

.v2b-Change-House:before {
  content: "\e991";
}

.v2b-Chat-App:before {
  content: "\e992";
}

.v2b-Cherry-Tomato:before {
  content: "\e993";
}

.v2b-Cherry:before {
  content: "\e994";
}

.v2b-Chess-Board:before {
  content: "\e995";
}

.v2b-Chess-Clock:before {
  content: "\e996";
}

.v2b-Chili:before {
  content: "\e997";
}

.v2b-Chinese-Lettuce:before {
  content: "\e998";
}

.v2b-Church-1:before {
  content: "\e999";
}

.v2b-Church-2:before {
  content: "\e99a";
}

.v2b-Circle-Star:before {
  content: "\e99b";
}

.v2b-Circle:before {
  content: "\e99c";
}

.v2b-Clean-Code:before {
  content: "\e99d";
}

.v2b-Clear-House:before {
  content: "\e99e";
}

.v2b-Clear-Note:before {
  content: "\e99f";
}

.v2b-Clear-Post-It:before {
  content: "\e9a0";
}

.v2b-Clear-Rating:before {
  content: "\e9a1";
}

.v2b-Clear-Task:before {
  content: "\e9a2";
}

.v2b-Clipboard-Pencil:before {
  content: "\e9a3";
}

.v2b-Clipboard:before {
  content: "\e9a4";
}

.v2b-Clipboard2:before {
  content: "\e9a5";
}

.v2b-Cliped-Post-It:before {
  content: "\e9a6";
}

.v2b-Cloud-App:before {
  content: "\e9a7";
}

.v2b-Clove:before {
  content: "\e9a8";
}

.v2b-Cocktail:before {
  content: "\e9a9";
}

.v2b-Cocoa-Pod:before {
  content: "\e9aa";
}

.v2b-Coconut:before {
  content: "\e9ab";
}

.v2b-Code-1:before {
  content: "\e9ac";
}

.v2b-Code-2:before {
  content: "\e9ad";
}

.v2b-Code-Doc:before {
  content: "\e9ae";
}

.v2b-Colosseum:before {
  content: "\e9af";
}

.v2b-Comb-1:before {
  content: "\e9b0";
}

.v2b-Comb-2:before {
  content: "\e9b1";
}

.v2b-Compass:before {
  content: "\e9b2";
}

.v2b-Computer-App:before {
  content: "\e9b3";
}

.v2b-Computer-User:before {
  content: "\e9b4";
}

.v2b-Cone-2:before {
  content: "\e9b5";
}

.v2b-Cone-3:before {
  content: "\e9b6";
}

.v2b-Cone:before {
  content: "\e9b7";
}

.v2b-Connecting-Users:before {
  content: "\e9b8";
}

.v2b-Content-View:before {
  content: "\e9b9";
}

.v2b-Cop-Badge:before {
  content: "\e9ba";
}

.v2b-Copyright-App:before {
  content: "\e9bb";
}

.v2b-Copyright-Doc:before {
  content: "\e9bc";
}

.v2b-Copyright:before {
  content: "\e9bd";
}

.v2b-Corel-File:before {
  content: "\e9be";
}

.v2b-Couch-1:before {
  content: "\e9bf";
}

.v2b-Couch-2:before {
  content: "\e9c0";
}

.v2b-Couch-3:before {
  content: "\e9c1";
}

.v2b-Crain-Hook:before {
  content: "\e9c2";
}

.v2b-Crain:before {
  content: "\e9c3";
}

.v2b-Creative-Commun-doc:before {
  content: "\e9c4";
}

.v2b-Creative-Commun:before {
  content: "\e9c5";
}

.v2b-Css-2:before {
  content: "\e9c6";
}

.v2b-css-3:before {
  content: "\e9c7";
}

.v2b-Css1:before {
  content: "\e9c8";
}

.v2b-Cube-2:before {
  content: "\e9c9";
}

.v2b-Cube-3:before {
  content: "\e9ca";
}

.v2b-Cube:before {
  content: "\e9cb";
}

.v2b-Cup-2:before {
  content: "\e9cc";
}

.v2b-Cup-3:before {
  content: "\e9cd";
}

.v2b-Cut:before {
  content: "\e9ce";
}

.v2b-Cutlery:before {
  content: "\e9cf";
}

.v2b-Cutter:before {
  content: "\e9d0";
}

.v2b-Cylinder-2:before {
  content: "\e9d1";
}

.v2b-Cylinder-3:before {
  content: "\e9d2";
}

.v2b-Cylinder:before {
  content: "\e9d3";
}

.v2b-Database:before {
  content: "\e9d4";
}

.v2b-Deer:before {
  content: "\e9d5";
}

.v2b-Dekaeder-2:before {
  content: "\e9d6";
}

.v2b-Dekaeder-3:before {
  content: "\e9d7";
}

.v2b-Dekaeder:before {
  content: "\e9d8";
}

.v2b-Delete-Note:before {
  content: "\e9d9";
}

.v2b-Delete-Post-It:before {
  content: "\e9da";
}

.v2b-Delete-Task:before {
  content: "\e9db";
}

.v2b-Departed:before {
  content: "\e9dc";
}

.v2b-Departing:before {
  content: "\e9dd";
}

.v2b-Design-Document:before {
  content: "\e9de";
}

.v2b-Detail-View:before {
  content: "\e9df";
}

.v2b-Detailes-Pane:before {
  content: "\e9e0";
}

.v2b-Diploma:before {
  content: "\e9e1";
}

.v2b-Disabled-man:before {
  content: "\e9e2";
}

.v2b-Disc-Cutter:before {
  content: "\e9e3";
}

.v2b-Discount-Sticker:before {
  content: "\e9e4";
}

.v2b-Dislike:before {
  content: "\e9e5";
}

.v2b-Dismiss-App:before {
  content: "\e9e6";
}

.v2b-Dismiss-Award-Badge:before {
  content: "\e9e7";
}

.v2b-Dismiss-Heart:before {
  content: "\e9e8";
}

.v2b-Dismiss-Rating:before {
  content: "\e9e9";
}

.v2b-Diving-goggles:before {
  content: "\e9ea";
}

.v2b-Dodekaeder-1:before {
  content: "\e9eb";
}

.v2b-Dodekaeder-2:before {
  content: "\e9ec";
}

.v2b-Dodekaeder-3:before {
  content: "\e9ed";
}

.v2b-Dodekaeder-4:before {
  content: "\e9ee";
}

.v2b-Dog-Tag:before {
  content: "\e9ef";
}

.v2b-Dollar-App:before {
  content: "\e9f0";
}

.v2b-Dollar-House:before {
  content: "\e9f1";
}

.v2b-Done-App:before {
  content: "\e9f2";
}

.v2b-Done-Award-Badge:before {
  content: "\e9f3";
}

.v2b-Done-Heart:before {
  content: "\e9f4";
}

.v2b-Done-House:before {
  content: "\e9f5";
}

.v2b-Done-Note:before {
  content: "\e9f6";
}

.v2b-Done-Post-It:before {
  content: "\e9f7";
}

.v2b-Done-Rating:before {
  content: "\e9f8";
}

.v2b-Done-Task:before {
  content: "\e9f9";
}

.v2b-Door:before {
  content: "\e9fa";
}

.v2b-Download-App:before {
  content: "\e9fb";
}

.v2b-Download-Note:before {
  content: "\e9fc";
}

.v2b-Download-Post-It:before {
  content: "\e9fd";
}

.v2b-Download-Task:before {
  content: "\e9fe";
}

.v2b-Download:before {
  content: "\e9ff";
}

.v2b-Drag-and-Drop:before {
  content: "\ea00";
}

.v2b-Dream-House:before {
  content: "\ea01";
}

.v2b-Drilling-Machine-2:before {
  content: "\ea02";
}

.v2b-Drilling-Machine:before {
  content: "\ea03";
}

.v2b-Drive-App:before {
  content: "\ea04";
}

.v2b-Driver-Licence-Female:before {
  content: "\ea05";
}

.v2b-Driver-License-Male:before {
  content: "\ea06";
}

.v2b-Dropbox-App:before {
  content: "\ea07";
}

.v2b-Dubai:before {
  content: "\ea08";
}

.v2b-Durian:before {
  content: "\ea09";
}

.v2b-DWG:before {
  content: "\ea0a";
}

.v2b-DXF:before {
  content: "\ea0b";
}

.v2b-Earth-Map:before {
  content: "\ea0c";
}

.v2b-Easter-Island:before {
  content: "\ea0d";
}

.v2b-Eat-Heart:before {
  content: "\ea0e";
}

.v2b-Eating-Award-Badge:before {
  content: "\ea0f";
}

.v2b-Eco-House:before {
  content: "\ea10";
}

.v2b-Edit-App:before {
  content: "\ea11";
}

.v2b-Edit-Note:before {
  content: "\ea12";
}

.v2b-Edit-Post-It:before {
  content: "\ea13";
}

.v2b-Edit-Task:before {
  content: "\ea14";
}

.v2b-Eggplant:before {
  content: "\ea15";
}

.v2b-Egypt-Pyramid:before {
  content: "\ea16";
}

.v2b-Eiffel-Tower:before {
  content: "\ea17";
}

.v2b-Elevator:before {
  content: "\ea18";
}

.v2b-Email-App-1:before {
  content: "\ea19";
}

.v2b-Email-App-2:before {
  content: "\ea1a";
}

.v2b-EMF:before {
  content: "\ea1b";
}

.v2b-Empire-State-Building:before {
  content: "\ea1c";
}

.v2b-Empty-Star:before {
  content: "\ea1d";
}

.v2b-EPS:before {
  content: "\ea1e";
}

.v2b-Equalizer:before {
  content: "\ea1f";
}

.v2b-Escalator-down:before {
  content: "\ea20";
}

.v2b-Escalator-up:before {
  content: "\ea21";
}

.v2b-Euro-App:before {
  content: "\ea22";
}

.v2b-Euro-House:before {
  content: "\ea23";
}

.v2b-Exhibition:before {
  content: "\ea24";
}

.v2b-Exit-Full-Screen:before {
  content: "\ea25";
}

.v2b-Exit:before {
  content: "\ea26";
}

.v2b-Factory:before {
  content: "\ea27";
}

.v2b-Favorite-App-1:before {
  content: "\ea28";
}

.v2b-Favorite-App:before {
  content: "\ea29";
}

.v2b-Favorite-House:before {
  content: "\ea2a";
}

.v2b-Favorite-Restaurant:before {
  content: "\ea2b";
}

.v2b-Female-Circle-1:before {
  content: "\ea2c";
}

.v2b-Female-Circle-2:before {
  content: "\ea2d";
}

.v2b-Female-Group-1:before {
  content: "\ea2e";
}

.v2b-Female-Group-2:before {
  content: "\ea2f";
}

.v2b-Fence:before {
  content: "\ea30";
}

.v2b-Fennel:before {
  content: "\ea31";
}

.v2b-Ferris-Wheel:before {
  content: "\ea32";
}

.v2b-Figs:before {
  content: "\ea33";
}

.v2b-Fire-Station:before {
  content: "\ea34";
}

.v2b-Firewall:before {
  content: "\ea35";
}

.v2b-First-ad:before {
  content: "\ea36";
}

.v2b-Flashlight:before {
  content: "\ea37";
}

.v2b-Flippers:before {
  content: "\ea38";
}

.v2b-Flow-Chart-4:before {
  content: "\ea39";
}

.v2b-Flow-Chat-1:before {
  content: "\ea3a";
}

.v2b-Flow-Chat-2:before {
  content: "\ea3b";
}

.v2b-Folder-Female:before {
  content: "\ea3c";
}

.v2b-Folder-Male:before {
  content: "\ea3d";
}

.v2b-Footer:before {
  content: "\ea3e";
}

.v2b-For-Rent:before {
  content: "\ea3f";
}

.v2b-For-Sale:before {
  content: "\ea40";
}

.v2b-Fork-Lift:before {
  content: "\ea41";
}

.v2b-Fountain:before {
  content: "\ea42";
}

.v2b-Fow-Chat-3:before {
  content: "\ea43";
}

.v2b-Fragile:before {
  content: "\ea44";
}

.v2b-Free-Sticker:before {
  content: "\ea45";
}

.v2b-Fuel-Station:before {
  content: "\ea46";
}

.v2b-Full-Screen:before {
  content: "\ea47";
}

.v2b-Full-Star:before {
  content: "\ea48";
}

.v2b-Gallery-App:before {
  content: "\ea49";
}

.v2b-Gallery-View:before {
  content: "\ea4a";
}

.v2b-Game-App:before {
  content: "\ea4b";
}

.v2b-Garage:before {
  content: "\ea4c";
}

.v2b-Garden:before {
  content: "\ea4d";
}

.v2b-Garlic:before {
  content: "\ea4e";
}

.v2b-Ginger:before {
  content: "\ea4f";
}

.v2b-Glasses-1:before {
  content: "\ea50";
}

.v2b-Glasses-2:before {
  content: "\ea51";
}

.v2b-Glasses-3:before {
  content: "\ea52";
}

.v2b-Glasses-4:before {
  content: "\ea53";
}

.v2b-Glasses-5:before {
  content: "\ea54";
}

.v2b-Global-Award-Badge:before {
  content: "\ea55";
}

.v2b-Global-Heart:before {
  content: "\ea56";
}

.v2b-Global-Rating:before {
  content: "\ea57";
}

.v2b-Global-User:before {
  content: "\ea58";
}

.v2b-Global:before {
  content: "\ea59";
}

.v2b-Globe-House:before {
  content: "\ea5a";
}

.v2b-Golden-Gate-Bridge:before {
  content: "\ea5b";
}

.v2b-Golden-Globe:before {
  content: "\ea5c";
}

.v2b-Grain:before {
  content: "\ea5d";
}

.v2b-Grape:before {
  content: "\ea5e";
}

.v2b-Great-Wall:before {
  content: "\ea5f";
}

.v2b-Grid-1:before {
  content: "\ea60";
}

.v2b-Grid-2:before {
  content: "\ea61";
}

.v2b-Group-1:before {
  content: "\ea62";
}

.v2b-Group-2:before {
  content: "\ea63";
}

.v2b-Group-Defense:before {
  content: "\ea64";
}

.v2b-Group-Download:before {
  content: "\ea65";
}

.v2b-Group-Shopping:before {
  content: "\ea66";
}

.v2b-Group-Statistics:before {
  content: "\ea67";
}

.v2b-Group-Upload:before {
  content: "\ea68";
}

.v2b-Hair-Band:before {
  content: "\ea69";
}

.v2b-Half-Star:before {
  content: "\ea6a";
}

.v2b-Hammer:before {
  content: "\ea6b";
}

.v2b-Hand-washing:before {
  content: "\ea6c";
}

.v2b-Hanger:before {
  content: "\ea6d";
}

.v2b-Haricot:before {
  content: "\ea6e";
}

.v2b-Hat-1:before {
  content: "\ea6f";
}

.v2b-Hat-2:before {
  content: "\ea70";
}

.v2b-Hat-3:before {
  content: "\ea71";
}

.v2b-Hat-4:before {
  content: "\ea72";
}

.v2b-Hat-Portrait-Frame:before {
  content: "\ea73";
}

.v2b-Hazelnut:before {
  content: "\ea74";
}

.v2b-Header:before {
  content: "\ea75";
}

.v2b-Headset:before {
  content: "\ea76";
}

.v2b-Heart-Flag:before {
  content: "\ea77";
}

.v2b-Heart-Full:before {
  content: "\ea78";
}

.v2b-Heart-Half:before {
  content: "\ea79";
}

.v2b-Heart-Shopping:before {
  content: "\ea7a";
}

.v2b-Heart-Waiting:before {
  content: "\ea7b";
}

.v2b-Hearts-Empty:before {
  content: "\ea7c";
}

.v2b-Heavy:before {
  content: "\ea7d";
}

.v2b-Helmet:before {
  content: "\ea7e";
}

.v2b-Hexaeder-1:before {
  content: "\ea7f";
}

.v2b-Hexaeder-2:before {
  content: "\ea80";
}

.v2b-Hexaeder-3:before {
  content: "\ea81";
}

.v2b-Hexagon:before {
  content: "\ea82";
}

.v2b-Hexagonal-Cone-2:before {
  content: "\ea83";
}

.v2b-Hexagonal-Cone-3:before {
  content: "\ea84";
}

.v2b-Hexagonal-Cone:before {
  content: "\ea85";
}

.v2b-Hexagonal-Cylinder-2:before {
  content: "\ea86";
}

.v2b-Hexagonal-Cylinder-3:before {
  content: "\ea87";
}

.v2b-Hexagonal-Cylinder:before {
  content: "\ea88";
}

.v2b-High-voltage:before {
  content: "\ea89";
}

.v2b-Hoist:before {
  content: "\ea8a";
}

.v2b-Hoist2:before {
  content: "\ea8b";
}

.v2b-Hold-Heart:before {
  content: "\ea8c";
}

.v2b-Hold-Male:before {
  content: "\ea8d";
}

.v2b-Hold-Star:before {
  content: "\ea8e";
}

.v2b-Home-App:before {
  content: "\ea8f";
}

.v2b-Home:before {
  content: "\ea90";
}

.v2b-Honeymelon:before {
  content: "\ea91";
}

.v2b-Hospital:before {
  content: "\ea92";
}

.v2b-Hot-drink:before {
  content: "\ea93";
}

.v2b-Hot-Sticker:before {
  content: "\ea94";
}

.v2b-Hotel-1:before {
  content: "\ea95";
}

.v2b-Hotel-2:before {
  content: "\ea96";
}

.v2b-Hotel-3:before {
  content: "\ea97";
}

.v2b-Hotel-4:before {
  content: "\ea98";
}

.v2b-House-1:before {
  content: "\ea99";
}

.v2b-House-12:before {
  content: "\ea9a";
}

.v2b-House-2:before {
  content: "\ea9b";
}

.v2b-House-22:before {
  content: "\ea9c";
}

.v2b-House-3:before {
  content: "\ea9d";
}

.v2b-House-4:before {
  content: "\ea9e";
}

.v2b-House-5:before {
  content: "\ea9f";
}

.v2b-House-6:before {
  content: "\eaa0";
}

.v2b-House-7:before {
  content: "\eaa1";
}

.v2b-House-8:before {
  content: "\eaa2";
}

.v2b-House-9:before {
  content: "\eaa3";
}

.v2b-House-Care:before {
  content: "\eaa4";
}

.v2b-House-Delay:before {
  content: "\eaa5";
}

.v2b-House-Dimensions:before {
  content: "\eaa6";
}

.v2b-House-Discount:before {
  content: "\eaa7";
}

.v2b-House-Insurance:before {
  content: "\eaa8";
}

.v2b-House-Key-2:before {
  content: "\eaa9";
}

.v2b-House-Key-3:before {
  content: "\eaaa";
}

.v2b-House-Key:before {
  content: "\eaab";
}

.v2b-House-Location:before {
  content: "\eaac";
}

.v2b-Html-1:before {
  content: "\eaad";
}

.v2b-Html-2:before {
  content: "\eaae";
}

.v2b-html-5:before {
  content: "\eaaf";
}

.v2b-http-1:before {
  content: "\eab0";
}

.v2b-Http-2:before {
  content: "\eab1";
}

.v2b-ID-Female:before {
  content: "\eab2";
}

.v2b-ID-Male:before {
  content: "\eab3";
}

.v2b-ID-Photo:before {
  content: "\eab4";
}

.v2b-Ikosaeder-2:before {
  content: "\eab5";
}

.v2b-Illustrator-file:before {
  content: "\eab6";
}

.v2b-Indesign:before {
  content: "\eab7";
}

.v2b-Information-booklet:before {
  content: "\eab8";
}

.v2b-Information:before {
  content: "\eab9";
}

.v2b-Ironing-1:before {
  content: "\eaba";
}

.v2b-Ironing-2:before {
  content: "\eabb";
}

.v2b-Isolation-Mode:before {
  content: "\eabc";
}

.v2b-Israel:before {
  content: "\eabd";
}

.v2b-Jack-Hammer:before {
  content: "\eabe";
}

.v2b-Java-1:before {
  content: "\eabf";
}

.v2b-Java-2:before {
  content: "\eac0";
}

.v2b-Java-Script:before {
  content: "\eac1";
}

.v2b-Jet-ski:before {
  content: "\eac2";
}

.v2b-Jsx-1:before {
  content: "\eac3";
}

.v2b-Jsx-2:before {
  content: "\eac4";
}

.v2b-Kenu:before {
  content: "\eac5";
}

.v2b-Key-Hold:before {
  content: "\eac6";
}

.v2b-Kilimanjaro:before {
  content: "\eac7";
}

.v2b-King-1:before {
  content: "\eac8";
}

.v2b-King-2:before {
  content: "\eac9";
}

.v2b-Kiwi:before {
  content: "\eaca";
}

.v2b-Knight-1:before {
  content: "\eacb";
}

.v2b-Knight-2:before {
  content: "\eacc";
}

.v2b-Ladder:before {
  content: "\eacd";
}

.v2b-Ladies-Bag:before {
  content: "\eace";
}

.v2b-Lamp-1:before {
  content: "\eacf";
}

.v2b-Lamp-2:before {
  content: "\ead0";
}

.v2b-Lamp-3:before {
  content: "\ead1";
}

.v2b-Large-v2bs-View:before {
  content: "\ead2";
}

.v2b-Layer-43:before {
  content: "\ead3";
}

.v2b-Left-items:before {
  content: "\ead4";
}

.v2b-Left-Sidebar:before {
  content: "\ead5";
}

.v2b-Lemon-1:before {
  content: "\ead6";
}

.v2b-Lemon-2:before {
  content: "\ead7";
}

.v2b-Letter:before {
  content: "\ead8";
}

.v2b-Lettuce:before {
  content: "\ead9";
}

.v2b-Life-jacket:before {
  content: "\eada";
}

.v2b-Lighthouse:before {
  content: "\eadb";
}

.v2b-Like:before {
  content: "\eadc";
}

.v2b-Lips:before {
  content: "\eadd";
}

.v2b-List-View:before {
  content: "\eade";
}

.v2b-Load-App:before {
  content: "\eadf";
}

.v2b-Lock-App-2:before {
  content: "\eae0";
}

.v2b-Lock-App:before {
  content: "\eae1";
}

.v2b-Lock-Award-Badge:before {
  content: "\eae2";
}

.v2b-Lock-Group:before {
  content: "\eae3";
}

.v2b-Lock-Heart:before {
  content: "\eae4";
}

.v2b-Lock-Rating:before {
  content: "\eae5";
}

.v2b-Lollipop-1:before {
  content: "\eae6";
}

.v2b-Lollipop-2:before {
  content: "\eae7";
}

.v2b-Luggage-keeping:before {
  content: "\eae8";
}

.v2b-Luggage-weight:before {
  content: "\eae9";
}

.v2b-Machu-Picchu:before {
  content: "\eaea";
}

.v2b-Mail-App:before {
  content: "\eaeb";
}

.v2b-Mail-Note:before {
  content: "\eaec";
}

.v2b-Mail-Post-It:before {
  content: "\eaed";
}

.v2b-Mail-Task:before {
  content: "\eaee";
}

.v2b-Maize:before {
  content: "\eaef";
}

.v2b-Male-and-Female-1:before {
  content: "\eaf0";
}

.v2b-Male-and-Female-2:before {
  content: "\eaf1";
}

.v2b-Male-Circle-1:before {
  content: "\eaf2";
}

.v2b-Male-Circle-2:before {
  content: "\eaf3";
}

.v2b-Male-Group-1:before {
  content: "\eaf4";
}

.v2b-Male-Group-2:before {
  content: "\eaf5";
}

.v2b-Man-with-Briefcase:before {
  content: "\eaf6";
}

.v2b-Man:before {
  content: "\eaf7";
}

.v2b-Mango:before {
  content: "\eaf8";
}

.v2b-Map:before {
  content: "\eaf9";
}

.v2b-Mark-Bubble-1:before {
  content: "\eafa";
}

.v2b-Mark-Bubble-2:before {
  content: "\eafb";
}

.v2b-Mark-Bubble-3:before {
  content: "\eafc";
}

.v2b-Mark-Group:before {
  content: "\eafd";
}

.v2b-MAX:before {
  content: "\eafe";
}

.v2b-Maximize:before {
  content: "\eaff";
}

.v2b-Medal-1:before {
  content: "\eb00";
}

.v2b-Medal-2:before {
  content: "\eb01";
}

.v2b-Medal-3:before {
  content: "\eb02";
}

.v2b-Medal-4:before {
  content: "\eb03";
}

.v2b-Medal-5:before {
  content: "\eb04";
}

.v2b-Medium-v2bs-View:before {
  content: "\eb05";
}

.v2b-Melon:before {
  content: "\eb06";
}

.v2b-Military-Award-1:before {
  content: "\eb07";
}

.v2b-Military-Award-2:before {
  content: "\eb08";
}

.v2b-Military-Award-3:before {
  content: "\eb09";
}

.v2b-Military-Award-4:before {
  content: "\eb0a";
}

.v2b-Militay-Shield:before {
  content: "\eb0b";
}

.v2b-Minimize-2:before {
  content: "\eb0c";
}

.v2b-Minimize:before {
  content: "\eb0d";
}

.v2b-Mirror-1:before {
  content: "\eb0e";
}

.v2b-Mirror-2:before {
  content: "\eb0f";
}

.v2b-Mosque:before {
  content: "\eb10";
}

.v2b-Mountains:before {
  content: "\eb11";
}

.v2b-Mouse-App:before {
  content: "\eb12";
}

.v2b-Mouve-Piece:before {
  content: "\eb13";
}

.v2b-Multy-Task-2:before {
  content: "\eb14";
}

.v2b-Multy-Task:before {
  content: "\eb15";
}

.v2b-Museum:before {
  content: "\eb16";
}

.v2b-Mushroom-1:before {
  content: "\eb17";
}

.v2b-Mushroom-2:before {
  content: "\eb18";
}

.v2b-Music-App:before {
  content: "\eb19";
}

.v2b-Mustache-1:before {
  content: "\eb1a";
}

.v2b-MySql-1:before {
  content: "\eb1b";
}

.v2b-MySql-2:before {
  content: "\eb1c";
}

.v2b-MySQL-Database:before {
  content: "\eb1d";
}

.v2b-Name-Plate-Female-1:before {
  content: "\eb1e";
}

.v2b-Name-Plate-Female-2:before {
  content: "\eb1f";
}

.v2b-Name-Plate-Female-3:before {
  content: "\eb20";
}

.v2b-Name-Plate-Male-1:before {
  content: "\eb21";
}

.v2b-Name-Plate-Male-2:before {
  content: "\eb22";
}

.v2b-Name-Plate-Male-3:before {
  content: "\eb23";
}

.v2b-Nativity-Facade-Barcelona:before {
  content: "\eb24";
}

.v2b-Network-App:before {
  content: "\eb25";
}

.v2b-New-Sticker:before {
  content: "\eb26";
}

.v2b-No-air-condition:before {
  content: "\eb27";
}

.v2b-No-bleaching:before {
  content: "\eb28";
}

.v2b-No-Copyright-Doc:before {
  content: "\eb29";
}

.v2b-No-Copyright:before {
  content: "\eb2a";
}

.v2b-No-Creative-Commun:before {
  content: "\eb2b";
}

.v2b-No-cut:before {
  content: "\eb2c";
}

.v2b-No-dogs:before {
  content: "\eb2d";
}

.v2b-No-fishing:before {
  content: "\eb2e";
}

.v2b-No-food:before {
  content: "\eb2f";
}

.v2b-No-hoist:before {
  content: "\eb30";
}

.v2b-No-icecream:before {
  content: "\eb31";
}

.v2b-No-ironing:before {
  content: "\eb32";
}

.v2b-No-pack-up:before {
  content: "\eb33";
}

.v2b-No-phone:before {
  content: "\eb34";
}

.v2b-No-Resel:before {
  content: "\eb35";
}

.v2b-No-resell-Doc:before {
  content: "\eb36";
}

.v2b-No-shower:before {
  content: "\eb37";
}

.v2b-No-smoking:before {
  content: "\eb38";
}

.v2b-No-step:before {
  content: "\eb39";
}

.v2b-No-stream:before {
  content: "\eb3a";
}

.v2b-No-swimming:before {
  content: "\eb3b";
}

.v2b-No-touch:before {
  content: "\eb3c";
}

.v2b-No-wet-ironing:before {
  content: "\eb3d";
}

.v2b-No-wetting:before {
  content: "\eb3e";
}

.v2b-Nophoto:before {
  content: "\eb3f";
}

.v2b-Not-Arrived:before {
  content: "\eb40";
}

.v2b-Not-departed:before {
  content: "\eb41";
}

.v2b-Not-drinking-water:before {
  content: "\eb42";
}

.v2b-Not-loud:before {
  content: "\eb43";
}

.v2b-Note-1:before {
  content: "\eb44";
}

.v2b-Note-2:before {
  content: "\eb45";
}

.v2b-Note-Book-1:before {
  content: "\eb46";
}

.v2b-Note-Book-2:before {
  content: "\eb47";
}

.v2b-Note-Pencil-1:before {
  content: "\eb48";
}

.v2b-Note-Pencil-2:before {
  content: "\eb49";
}

.v2b-Notebook-3:before {
  content: "\eb4a";
}

.v2b-Nuclear-Reactor:before {
  content: "\eb4b";
}

.v2b-Nuclear:before {
  content: "\eb4c";
}

.v2b-Obelisc:before {
  content: "\eb4d";
}

.v2b-Octaeder-2:before {
  content: "\eb4e";
}

.v2b-Octaeder-3:before {
  content: "\eb4f";
}

.v2b-Octaeder:before {
  content: "\eb50";
}

.v2b-Office-1:before {
  content: "\eb51";
}

.v2b-Office-2:before {
  content: "\eb52";
}

.v2b-Office-3:before {
  content: "\eb53";
}

.v2b-Office-4:before {
  content: "\eb54";
}

.v2b-Office-5:before {
  content: "\eb55";
}

.v2b-Office-6:before {
  content: "\eb56";
}

.v2b-Office-Building:before {
  content: "\eb57";
}

.v2b-Office-Chair:before {
  content: "\eb58";
}

.v2b-Office-Desk-2:before {
  content: "\eb59";
}

.v2b-Office-Desk:before {
  content: "\eb5a";
}

.v2b-Old-camera-1:before {
  content: "\eb5b";
}

.v2b-Old-camera-2:before {
  content: "\eb5c";
}

.v2b-Old-Sticky-1:before {
  content: "\eb5d";
}

.v2b-Old-Sticky-2:before {
  content: "\eb5e";
}

.v2b-Old-Sticky-3:before {
  content: "\eb5f";
}

.v2b-Old-Telephone:before {
  content: "\eb60";
}

.v2b-Olive:before {
  content: "\eb61";
}

.v2b-Onion:before {
  content: "\eb62";
}

.v2b-Oscar:before {
  content: "\eb63";
}

.v2b-OTF:before {
  content: "\eb64";
}

.v2b-Pack-up:before {
  content: "\eb65";
}

.v2b-Pagoda-Myanmar:before {
  content: "\eb66";
}

.v2b-Pagoda:before {
  content: "\eb67";
}

.v2b-Paifang-China:before {
  content: "\eb68";
}

.v2b-Paint-Brush:before {
  content: "\eb69";
}

.v2b-Paint-Bucket:before {
  content: "\eb6a";
}

.v2b-Paint-Roller:before {
  content: "\eb6b";
}

.v2b-Palm-tree:before {
  content: "\eb6c";
}

.v2b-Pantheon:before {
  content: "\eb6d";
}

.v2b-Papaya:before {
  content: "\eb6e";
}

.v2b-Park:before {
  content: "\eb6f";
}

.v2b-Party-Glasses:before {
  content: "\eb70";
}

.v2b-Passport:before {
  content: "\eb71";
}

.v2b-Pawn-1:before {
  content: "\eb72";
}

.v2b-Pawn-2:before {
  content: "\eb73";
}

.v2b-Pay-Doc:before {
  content: "\eb74";
}

.v2b-Pea:before {
  content: "\eb75";
}

.v2b-Peach-1:before {
  content: "\eb76";
}

.v2b-Peach-2:before {
  content: "\eb77";
}

.v2b-Peanut:before {
  content: "\eb78";
}

.v2b-Pear:before {
  content: "\eb79";
}

.v2b-Pecan:before {
  content: "\eb7a";
}

.v2b-Pentagon:before {
  content: "\eb7b";
}

.v2b-Pentagonal-Cone-2:before {
  content: "\eb7c";
}

.v2b-Pentagonal-Cone-3:before {
  content: "\eb7d";
}

.v2b-Pentagonal-Cone:before {
  content: "\eb7e";
}

.v2b-Pentagonal-Cylinder-2:before {
  content: "\eb7f";
}

.v2b-Pentagonal-Cylinder-3:before {
  content: "\eb80";
}

.v2b-Pentagonal-Cylinder:before {
  content: "\eb81";
}

.v2b-Pepper:before {
  content: "\eb82";
}

.v2b-Personal-Award-Badge:before {
  content: "\eb83";
}

.v2b-Personal-Heart:before {
  content: "\eb84";
}

.v2b-Personal-Note:before {
  content: "\eb85";
}

.v2b-Personal-Post-It:before {
  content: "\eb86";
}

.v2b-Personal-Task:before {
  content: "\eb87";
}

.v2b-Personal-Use-2:before {
  content: "\eb88";
}

.v2b-Personal-Use:before {
  content: "\eb89";
}

.v2b-Pesronal-Use-Doc:before {
  content: "\eb8a";
}

.v2b-Phone-User:before {
  content: "\eb8b";
}

.v2b-Photoshop:before {
  content: "\eb8c";
}

.v2b-php-1:before {
  content: "\eb8d";
}

.v2b-PHP-2:before {
  content: "\eb8e";
}

.v2b-Pin-App:before {
  content: "\eb8f";
}

.v2b-Pined-Post-It:before {
  content: "\eb90";
}

.v2b-Pipe-1:before {
  content: "\eb91";
}

.v2b-Pipe-2:before {
  content: "\eb92";
}

.v2b-Piramid-2:before {
  content: "\eb93";
}

.v2b-Piramid-3:before {
  content: "\eb94";
}

.v2b-Piramyd-1:before {
  content: "\eb95";
}

.v2b-Pisa:before {
  content: "\eb96";
}

.v2b-Pistachio:before {
  content: "\eb97";
}

.v2b-Playground:before {
  content: "\eb98";
}

.v2b-Pliers:before {
  content: "\eb99";
}

.v2b-Plugin:before {
  content: "\eb9a";
}

.v2b-Plum:before {
  content: "\eb9b";
}

.v2b-Pocket-Watch:before {
  content: "\eb9c";
}

.v2b-Podium:before {
  content: "\eb9d";
}

.v2b-Police:before {
  content: "\eb9e";
}

.v2b-Pomegranate:before {
  content: "\eb9f";
}

.v2b-Port:before {
  content: "\eba0";
}

.v2b-Portrait-Frame:before {
  content: "\eba1";
}

.v2b-Post-It-Block:before {
  content: "\eba2";
}

.v2b-Post-It-Pencil:before {
  content: "\eba3";
}

.v2b-Post-It-Settings:before {
  content: "\eba4";
}

.v2b-Post-It:before {
  content: "\eba5";
}

.v2b-Post-Office:before {
  content: "\eba6";
}

.v2b-Potato:before {
  content: "\eba7";
}

.v2b-Pound-House:before {
  content: "\eba8";
}

.v2b-Presentation-1:before {
  content: "\eba9";
}

.v2b-Presentation-2:before {
  content: "\ebaa";
}

.v2b-Presentation-3:before {
  content: "\ebab";
}

.v2b-Preview-Pane:before {
  content: "\ebac";
}

.v2b-Profile-App:before {
  content: "\ebad";
}

.v2b-Profile-Cardiograph:before {
  content: "\ebae";
}

.v2b-Profile-Economy:before {
  content: "\ebaf";
}

.v2b-Profile-Favourites:before {
  content: "\ebb0";
}

.v2b-Profile-Head:before {
  content: "\ebb1";
}

.v2b-Profile-History:before {
  content: "\ebb2";
}

.v2b-Profile-Lightbulb:before {
  content: "\ebb3";
}

.v2b-Profile-Music:before {
  content: "\ebb4";
}

.v2b-Profile-Settings:before {
  content: "\ebb5";
}

.v2b-Profile-Star:before {
  content: "\ebb6";
}

.v2b-Profile-Voltage:before {
  content: "\ebb7";
}

.v2b-Profile:before {
  content: "\ebb8";
}

.v2b-Programing:before {
  content: "\ebb9";
}

.v2b-Public-App:before {
  content: "\ebba";
}

.v2b-Public-Domain:before {
  content: "\ebbb";
}

.v2b-Pumpkin:before {
  content: "\ebbc";
}

.v2b-Puond-App:before {
  content: "\ebbd";
}

.v2b-Queen-1:before {
  content: "\ebbe";
}

.v2b-Queen-2:before {
  content: "\ebbf";
}

.v2b-Question-App:before {
  content: "\ebc0";
}

.v2b-Question-House:before {
  content: "\ebc1";
}

.v2b-Question-Note:before {
  content: "\ebc2";
}

.v2b-Question-Post-It:before {
  content: "\ebc3";
}

.v2b-Question-Task:before {
  content: "\ebc4";
}

.v2b-QXD:before {
  content: "\ebc5";
}

.v2b-Radio:before {
  content: "\ebc6";
}

.v2b-Radish:before {
  content: "\ebc7";
}

.v2b-Raspberry:before {
  content: "\ebc8";
}

.v2b-Razor-Blade:before {
  content: "\ebc9";
}

.v2b-Razor:before {
  content: "\ebca";
}

.v2b-Reamove-Award-Badge:before {
  content: "\ebcb";
}

.v2b-Recording-App:before {
  content: "\ebcc";
}

.v2b-Rectangle-2:before {
  content: "\ebcd";
}

.v2b-Rectangle-Star:before {
  content: "\ebce";
}

.v2b-Rectangle:before {
  content: "\ebcf";
}

.v2b-Refresh-App:before {
  content: "\ebd0";
}

.v2b-Registered-Mark:before {
  content: "\ebd1";
}

.v2b-Reload-App:before {
  content: "\ebd2";
}

.v2b-Remouve-App:before {
  content: "\ebd3";
}

.v2b-Remouve-House:before {
  content: "\ebd4";
}

.v2b-Remouve-Note:before {
  content: "\ebd5";
}

.v2b-Remouve-Post-It:before {
  content: "\ebd6";
}

.v2b-Remouve-Rating:before {
  content: "\ebd7";
}

.v2b-Remouve-Task:before {
  content: "\ebd8";
}

.v2b-Remove-Heart:before {
  content: "\ebd9";
}

.v2b-Rent-Hanger:before {
  content: "\ebda";
}

.v2b-Rent-Sale-Sign:before {
  content: "\ebdb";
}

.v2b-Rent-Sign-2:before {
  content: "\ebdc";
}

.v2b-Rent-Sign:before {
  content: "\ebdd";
}

.v2b-Restore:before {
  content: "\ebde";
}

.v2b-Retina-Scann-App:before {
  content: "\ebdf";
}

.v2b-Retro-Perfume:before {
  content: "\ebe0";
}

.v2b-Rialto-Venice:before {
  content: "\ebe1";
}

.v2b-Ribbon:before {
  content: "\ebe2";
}

.v2b-Right-Sidebar:before {
  content: "\ebe3";
}

.v2b-Rio-de-Jan:before {
  content: "\ebe4";
}

.v2b-Road-Roller:before {
  content: "\ebe5";
}

.v2b-Rode-Signe:before {
  content: "\ebe6";
}

.v2b-Rook-1:before {
  content: "\ebe7";
}

.v2b-Rook-2:before {
  content: "\ebe8";
}

.v2b-Route-66:before {
  content: "\ebe9";
}

.v2b-Rudder:before {
  content: "\ebea";
}

.v2b-Sailboat:before {
  content: "\ebeb";
}

.v2b-Sale-Hanger:before {
  content: "\ebec";
}

.v2b-Sale-Sign-2:before {
  content: "\ebed";
}

.v2b-Sale-Sign:before {
  content: "\ebee";
}

.v2b-Sate-Sticker:before {
  content: "\ebef";
}

.v2b-Saw:before {
  content: "\ebf0";
}

.v2b-School:before {
  content: "\ebf1";
}

.v2b-Scissors:before {
  content: "\ebf2";
}

.v2b-Search-App-1:before {
  content: "\ebf3";
}

.v2b-Search-App-2:before {
  content: "\ebf4";
}

.v2b-Search-Code:before {
  content: "\ebf5";
}

.v2b-Search-Female:before {
  content: "\ebf6";
}

.v2b-Search-House-2:before {
  content: "\ebf7";
}

.v2b-Search-House:before {
  content: "\ebf8";
}

.v2b-Search-Male:before {
  content: "\ebf9";
}

.v2b-Search-Note:before {
  content: "\ebfa";
}

.v2b-Search-Post-It:before {
  content: "\ebfb";
}

.v2b-Search-Task:before {
  content: "\ebfc";
}

.v2b-Search-Within-App:before {
  content: "\ebfd";
}

.v2b-Searching-Group:before {
  content: "\ebfe";
}

.v2b-Secure-House:before {
  content: "\ebff";
}

.v2b-Security-gate:before {
  content: "\ec00";
}

.v2b-Setting-House:before {
  content: "\ec01";
}

.v2b-Settings-Note:before {
  content: "\ec02";
}

.v2b-Sfinx:before {
  content: "\ec03";
}

.v2b-Shanghai-TV-Tower:before {
  content: "\ec04";
}

.v2b-Shape-file-2:before {
  content: "\ec05";
}

.v2b-Shape-File:before {
  content: "\ec06";
}

.v2b-Share-App-1:before {
  content: "\ec07";
}

.v2b-Share-App:before {
  content: "\ec08";
}

.v2b-Share-Note:before {
  content: "\ec09";
}

.v2b-Share-Post-It:before {
  content: "\ec0a";
}

.v2b-Share-Task:before {
  content: "\ec0b";
}

.v2b-Share-with-Group:before {
  content: "\ec0c";
}

.v2b-Sharring-Rights:before {
  content: "\ec0d";
}

.v2b-Shaving-Brush:before {
  content: "\ec0e";
}

.v2b-Sherif-Star:before {
  content: "\ec0f";
}

.v2b-Shoe-1:before {
  content: "\ec10";
}

.v2b-Shoe-2:before {
  content: "\ec11";
}

.v2b-Shop-Rating:before {
  content: "\ec12";
}

.v2b-Shop:before {
  content: "\ec13";
}

.v2b-Shovel:before {
  content: "\ec14";
}

.v2b-Signpost:before {
  content: "\ec15";
}

.v2b-Sillence:before {
  content: "\ec16";
}

.v2b-Single-Female:before {
  content: "\ec17";
}

.v2b-Single-Male:before {
  content: "\ec18";
}

.v2b-Ski:before {
  content: "\ec19";
}

.v2b-Sleeping-bag:before {
  content: "\ec1a";
}

.v2b-Small-v2bs-View:before {
  content: "\ec1b";
}

.v2b-Smoking-area:before {
  content: "\ec1c";
}

.v2b-Social-Media:before {
  content: "\ec1d";
}

.v2b-Sofa-1:before {
  content: "\ec1e";
}

.v2b-Sofa-2:before {
  content: "\ec1f";
}

.v2b-Sofa-3:before {
  content: "\ec20";
}

.v2b-Sold-Hanger:before {
  content: "\ec21";
}

.v2b-Sold-Sign-2:before {
  content: "\ec22";
}

.v2b-Sold-Sign:before {
  content: "\ec23";
}

.v2b-Space-Needle-Seattle:before {
  content: "\ec24";
}

.v2b-Speach-Bubble-1:before {
  content: "\ec25";
}

.v2b-Speach-Bubble-2:before {
  content: "\ec26";
}

.v2b-Speach-Bubble-3:before {
  content: "\ec27";
}

.v2b-Speach-Bubble-4:before {
  content: "\ec28";
}

.v2b-Speach-Bubble-5:before {
  content: "\ec29";
}

.v2b-Speach-Bubble-6:before {
  content: "\ec2a";
}

.v2b-Speach-Bubble-7:before {
  content: "\ec2b";
}

.v2b-Speach-Bubble-8:before {
  content: "\ec2c";
}

.v2b-Speach-Bubble-9:before {
  content: "\ec2d";
}

.v2b-Speach-Bubble-10:before {
  content: "\ec2e";
}

.v2b-Speach-Bubble-11:before {
  content: "\ec2f";
}

.v2b-Speach-Bubbles-1:before {
  content: "\ec30";
}

.v2b-Speach-Bubbles-2:before {
  content: "\ec31";
}

.v2b-Speach-Bubbles-3:before {
  content: "\ec32";
}

.v2b-Speach-Bubbles-5:before {
  content: "\ec33";
}

.v2b-Speach-Bubbles-6:before {
  content: "\ec34";
}

.v2b-Speach-Bubbles-7:before {
  content: "\ec35";
}

.v2b-Speach-Bubbles-8:before {
  content: "\ec36";
}

.v2b-Speach-Bubbles-9:before {
  content: "\ec37";
}

.v2b-Speach-Bubbles-10:before {
  content: "\ec38";
}

.v2b-Speach-Bubbles-11:before {
  content: "\ec39";
}

.v2b-Speach-Bubbles-12:before {
  content: "\ec3a";
}

.v2b-Speash-Bubbles-4:before {
  content: "\ec3b";
}

.v2b-Speech:before {
  content: "\ec3c";
}

.v2b-Sphere-2:before {
  content: "\ec3d";
}

.v2b-Sphere-3:before {
  content: "\ec3e";
}

.v2b-Sphere:before {
  content: "\ec3f";
}

.v2b-Spinach:before {
  content: "\ec40";
}

.v2b-St-Petersburg:before {
  content: "\ec41";
}

.v2b-Stadium:before {
  content: "\ec42";
}

.v2b-Stairs-down:before {
  content: "\ec43";
}

.v2b-Stairs-up:before {
  content: "\ec44";
}

.v2b-Standing-position:before {
  content: "\ec45";
}

.v2b-Star-Bookmark:before {
  content: "\ec46";
}

.v2b-Star-Flag:before {
  content: "\ec47";
}

.v2b-Star-House:before {
  content: "\ec48";
}

.v2b-Star-Shield-1:before {
  content: "\ec49";
}

.v2b-Star-Shield-2:before {
  content: "\ec4a";
}

.v2b-Star-User:before {
  content: "\ec4b";
}

.v2b-Stonehenge:before {
  content: "\ec4c";
}

.v2b-Storage:before {
  content: "\ec4d";
}

.v2b-Storage2:before {
  content: "\ec4e";
}

.v2b-Strawberry:before {
  content: "\ec4f";
}

.v2b-Sttings-Task:before {
  content: "\ec50";
}

.v2b-Suitcase:before {
  content: "\ec51";
}

.v2b-Sunflower-Seed:before {
  content: "\ec52";
}

.v2b-Sunshade:before {
  content: "\ec53";
}

.v2b-Super-Bowl-Cup:before {
  content: "\ec54";
}

.v2b-Surfboard:before {
  content: "\ec55";
}

.v2b-Surveillance:before {
  content: "\ec56";
}

.v2b-Suspenders:before {
  content: "\ec57";
}

.v2b-SVG:before {
  content: "\ec58";
}

.v2b-Sweet-Potato:before {
  content: "\ec59";
}

.v2b-SWF:before {
  content: "\ec5a";
}

.v2b-Switch-App:before {
  content: "\ec5b";
}

.v2b-Switch-Males:before {
  content: "\ec5c";
}

.v2b-Sydney:before {
  content: "\ec5d";
}

.v2b-Synagogue:before {
  content: "\ec5e";
}

.v2b-Table-1:before {
  content: "\ec5f";
}

.v2b-Table-2:before {
  content: "\ec60";
}

.v2b-Table-3:before {
  content: "\ec61";
}

.v2b-Tablet-User:before {
  content: "\ec62";
}

.v2b-Taj-Mahal:before {
  content: "\ec63";
}

.v2b-Targeted-Head:before {
  content: "\ec64";
}

.v2b-Task-Manager:before {
  content: "\ec65";
}

.v2b-Tasks-1:before {
  content: "\ec66";
}

.v2b-Telephone:before {
  content: "\ec67";
}

.v2b-Tent:before {
  content: "\ec68";
}

.v2b-Tetraeder-2:before {
  content: "\ec69";
}

.v2b-Tetraeder-3:before {
  content: "\ec6a";
}

.v2b-Tetraeder:before {
  content: "\ec6b";
}

.v2b-Tetraeder4:before {
  content: "\ec6c";
}

.v2b-Text-Editor:before {
  content: "\ec6d";
}

.v2b-TGA:before {
  content: "\ec6e";
}

.v2b-Theater:before {
  content: "\ec6f";
}

.v2b-Theme:before {
  content: "\ec70";
}

.v2b-Ticket:before {
  content: "\ec71";
}

.v2b-Tie-1:before {
  content: "\ec72";
}

.v2b-Tie-2:before {
  content: "\ec73";
}

.v2b-Tie-3:before {
  content: "\ec74";
}

.v2b-Tie-4:before {
  content: "\ec75";
}

.v2b-TIFF:before {
  content: "\ec76";
}

.v2b-Tiles-View:before {
  content: "\ec77";
}

.v2b-Time-App:before {
  content: "\ec78";
}

.v2b-Timed-Rating:before {
  content: "\ec79";
}

.v2b-Tomato:before {
  content: "\ec7a";
}

.v2b-Tower-Bridge:before {
  content: "\ec7b";
}

.v2b-Trade-Mark:before {
  content: "\ec7c";
}

.v2b-Trafic-Cone:before {
  content: "\ec7d";
}

.v2b-Translate:before {
  content: "\ec7e";
}

.v2b-Trash:before {
  content: "\ec7f";
}

.v2b-Tree-Grid:before {
  content: "\ec80";
}

.v2b-Tree-View-2:before {
  content: "\ec81";
}

.v2b-Tree-View-3:before {
  content: "\ec82";
}

.v2b-Tree-View:before {
  content: "\ec83";
}

.v2b-Triangle-Ruler:before {
  content: "\ec84";
}

.v2b-Triangle:before {
  content: "\ec85";
}

.v2b-Trowel-and-Brick:before {
  content: "\ec86";
}

.v2b-Trowel-and-Wall:before {
  content: "\ec87";
}

.v2b-Truck-1:before {
  content: "\ec88";
}

.v2b-True-Type:before {
  content: "\ec89";
}

.v2b-TTF:before {
  content: "\ec8a";
}

.v2b-Turin:before {
  content: "\ec8b";
}

.v2b-TV-Desk-2:before {
  content: "\ec8c";
}

.v2b-TV-Desk:before {
  content: "\ec8d";
}

.v2b-Two-Females-1:before {
  content: "\ec8e";
}

.v2b-Two-Males-1:before {
  content: "\ec8f";
}

.v2b-Tyle:before {
  content: "\ec90";
}

.v2b-Uefa-Euro-Cup:before {
  content: "\ec91";
}

.v2b-Umbrella:before {
  content: "\ec92";
}

.v2b-Uninstal-App:before {
  content: "\ec93";
}

.v2b-University:before {
  content: "\ec94";
}

.v2b-Upload-App:before {
  content: "\ec95";
}

.v2b-Upload-Note:before {
  content: "\ec96";
}

.v2b-Upload-Post-It:before {
  content: "\ec97";
}

.v2b-Upload-Task:before {
  content: "\ec98";
}

.v2b-Upload:before {
  content: "\ec99";
}

.v2b-User-App:before {
  content: "\ec9a";
}

.v2b-User-Budget:before {
  content: "\ec9b";
}

.v2b-User-Calendar:before {
  content: "\ec9c";
}

.v2b-User-Cloud:before {
  content: "\ec9d";
}

.v2b-User-Cup:before {
  content: "\ec9e";
}

.v2b-User-Database:before {
  content: "\ec9f";
}

.v2b-User-Fingerprint:before {
  content: "\eca0";
}

.v2b-User-Flowchart:before {
  content: "\eca1";
}

.v2b-User-Geometry:before {
  content: "\eca2";
}

.v2b-User-Hierarchy:before {
  content: "\eca3";
}

.v2b-User-Mail:before {
  content: "\eca4";
}

.v2b-User-Password:before {
  content: "\eca5";
}

.v2b-User-Path:before {
  content: "\eca6";
}

.v2b-User-Place:before {
  content: "\eca7";
}

.v2b-User-Rating:before {
  content: "\eca8";
}

.v2b-User-Security-Card:before {
  content: "\eca9";
}

.v2b-User-Security-Female:before {
  content: "\ecaa";
}

.v2b-User-Security-Male:before {
  content: "\ecab";
}

.v2b-User-Settings:before {
  content: "\ecac";
}

.v2b-User-Store:before {
  content: "\ecad";
}

.v2b-User-Target:before {
  content: "\ecae";
}

.v2b-Vector-App:before {
  content: "\ecaf";
}

.v2b-Vector-File:before {
  content: "\ecb0";
}

.v2b-Vest:before {
  content: "\ecb1";
}

.v2b-Victory:before {
  content: "\ecb2";
}

.v2b-Video-App-1:before {
  content: "\ecb3";
}

.v2b-Video-App-2:before {
  content: "\ecb4";
}

.v2b-VIP-Star:before {
  content: "\ecb5";
}

.v2b-Vote-2:before {
  content: "\ecb6";
}

.v2b-Vote-3:before {
  content: "\ecb7";
}

.v2b-Vote-4:before {
  content: "\ecb8";
}

.v2b-Vote-Stamp-2:before {
  content: "\ecb9";
}

.v2b-Vote-Stamp:before {
  content: "\ecba";
}

.v2b-Vote:before {
  content: "\ecbb";
}

.v2b-Voted:before {
  content: "\ecbc";
}

.v2b-Waiting-App:before {
  content: "\ecbd";
}

.v2b-Waiting-Award-Badge:before {
  content: "\ecbe";
}

.v2b-Waiting-room:before {
  content: "\ecbf";
}

.v2b-Wardeobe-1:before {
  content: "\ecc0";
}

.v2b-Wardrobe-2:before {
  content: "\ecc1";
}

.v2b-Wardrobe-3:before {
  content: "\ecc2";
}

.v2b-Wardrobe-Mirror:before {
  content: "\ecc3";
}

.v2b-Washing-1:before {
  content: "\ecc4";
}

.v2b-Washing-2:before {
  content: "\ecc5";
}

.v2b-Washing-3:before {
  content: "\ecc6";
}

.v2b-Watch-1:before {
  content: "\ecc7";
}

.v2b-Watch-2:before {
  content: "\ecc8";
}

.v2b-Wet-Ironing:before {
  content: "\ecc9";
}

.v2b-White-House:before {
  content: "\ecca";
}

.v2b-White-Radish:before {
  content: "\eccb";
}

.v2b-Windmill:before {
  content: "\eccc";
}

.v2b-Wing-Star:before {
  content: "\eccd";
}

.v2b-Woman:before {
  content: "\ecce";
}

/*.v2b-Women's-Collar:before {*/
/*content: "\eccf";*/
/*}*/
.v2b-Worlld-Cup:before {
  content: "\ecd0";
}

.v2b-Wrench-Tool:before {
  content: "\ecd1";
}

.v2b-Writing-Group:before {
  content: "\ecd2";
}

.v2b-WWW-Doc:before {
  content: "\ecd3";
}

.v2b-XML-1:before {
  content: "\ecd4";
}

.v2b-XML-2:before {
  content: "\ecd5";
}

.v2b-Yacht:before {
  content: "\ecd6";
}

.v2b-Zeppelin:before {
  content: "\ecd7";
}

.v2b-Zucchini:before {
  content: "\ecd8";
}

