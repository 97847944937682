@import './assets/scss/generate';
@import './assets/scss/variable';
@import './assets/scss/layout';
@import './assets/scss/mouse';
@import './assets/scss/text';
@import './assets/scss/color';
@import './assets/scss/button';
@import './assets/scss/table';
@import './assets/fonts/Line-Icons/v1/style.css';
@import './assets/fonts/Line-Icons/v2/style.css';
@import './assets/fonts/Line-Icons/v3/style.css';
@import './assets/fonts/Line-Icons/v4/style.css';
@import './assets/fonts/Solid-Icons/v1b/style.css';
@import './assets/fonts/Solid-Icons/v2b/style.css';
@import './assets/fonts/Solid-Icons/v3b/style.css';
@import './assets/fonts/Solid-Icons/v4b/style.css';
@import "./assets/scss/override/form";
@import "./assets/scss/override/tab";
@import '~@angular/material/theming';

@include mat-core();

$lawcost-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: $primary-light,
  A400: $primary,
  A700: $primary-darker,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$lawcost-primary: mat-palette($lawcost-palette, A400, A200, A700);
$lawcost-accent: mat-palette($mat-amber, A200, A100, A400);
$lawcost-theme: mat-light-theme($lawcost-primary, $lawcost-accent);

@include angular-material-theme($lawcost-theme);

@include angular-material-typography(mat-typography-config(
  $font-family: 'Nanum Gothic',
  $headline: mat-typography-level(24px, 32px, 500),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 500),
  $subheading-1: mat-typography-level(15px, 24px, 500),
  $body-2: mat-typography-level(13px, 24px, 600),
  $body-1: mat-typography-level(13px, 20px, 500),
  $caption: mat-typography-level(11px, 20px, 500),
  $button: mat-typography-level(13px, 13px, 500))
);
